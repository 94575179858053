import dayjs from "dayjs"

const columns = () => [
    {
        title: "打赏书籍",
        width: 200,
        align: "center",
        dataIndex: "book",
        key: "book",
        render: (_, record) => (
            record?.book?.title
        )
    },
    {
        title: "打赏书币",
        width: 150,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "打赏时间",
        width: 180,
        align: "center",
        dataIndex: "addtime",
        key: "addtime",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    
]

export default columns