import { Button, Popover, Space } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";

const columns = ({
  bookChange,
  handleDelete,
  handleUpdateLanding,
  updateLoading,
  advertiseEdit,
}) => [
  {
    title: "ID",
    width: 80,
    align: "center",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "转化名称",
    width: 150,
    align: "center",
    dataIndex: "name",
    key: "name",
    editable: true,
    render: (value) => <Link>{value}</Link>,
  },
  {
    title: "绑定的渠道",
    width: 90,
    align: "center",
    dataIndex: "channel",
    key: "channel",
    render: (_, record) => record?.admin_user?.nickname,
  },
  {
    title: "包含广告主ID",
    width: 160,
    align: "center",
    dataIndex: "advertiser_ids",
    key: "advertiser_ids",
    render: (value, record) => {
      let info = value.split(",");
      if (info && info.length > 0) {
        return (
          <Link onClick={() => advertiseEdit(record)}>
            {info[0]}等{info.length}个广告主
          </Link>
        );
      }
      return "";
    },
  },
  {
    title: "转化方式",
    width: 120,
    align: "center",
    dataIndex: "convert_source_type",
    key: "convert_source_type",
  },
  {
    title: "转化类型",
    width: 150,
    align: "center",
    dataIndex: "convert_type",
    key: "convert_type",
    render: (value) => "激活且付费",
  },
  {
    title: "创建时间",
    width: 180,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "操作",
    width: 335,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    fixed: "right",
    render: (_, record) => (
      <Space>
        <Button
          type="primary"
          loading={updateLoading === record?.id}
          onClick={() => handleUpdateLanding(record)}
        >
          更新落地页
        </Button>
        <Button
          type="primary"
          danger
          onClick={() => {
            handleDelete(record);
          }}
        >
          删除记录
        </Button>
        <Button>
          <Link
            to="/admin/batch_plan/transform/detail"
            state={{ id: record?.id }}
          >
            转化详情
          </Link>
        </Button>
        <Popover
          placement="top"
          content={
            <Button
              type="primary"
              ghost
              onClick={() => {
                bookChange(record);
              }}
            >
              修改书籍
            </Button>
          }
        >
          <MoreOutlined />
        </Popover>
      </Space>
    ),
  },
];

export default columns;
