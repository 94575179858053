const columns = () => [
  {
        title: "订单号",
        width: 100,
        align: "center",
        dataIndex: "order_num",
        key: "order_num",
  },    
  {
        title: "平台名称",
        width: 100,
        align: "center",
        dataIndex: "app_type_name",
        key: "app_type_name",
  },  
  {
    title: "外链id",
    width: 100,
    align: "center",
    dataIndex: "promotion_id",
    key: "promotion_id",
  },   
  {
    title: "外链名称",
    width: 100,
    align: "center",
    dataIndex: "promotion_name",
    key: "promotion_name", 
  },  
];

export default columns;
