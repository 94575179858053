import { postRequest } from "./request"

// 回收统计
export const getRecycleStatistics = data => postRequest('/admin/statistics/recycle',data)

// 回收统计消耗时速
export const recycleTimerDivision = data => postRequest('/admin/statistics/recycle_timer_division',data)

// 三方消耗时速
export const dailyTimerDivision = data => postRequest('/admin/statistics/daily_timer_division',data)

// 小说按组回收统计
export const getRecycleGroupStatistics = data => postRequest('/admin/statistics/recycle_group',data)

// IAA组回收统计
export const getRecycleIAAGroupList = data => postRequest('/admin/statistics/recycle_iaa_playlet_group',data)

// 短剧按组回收统计
export const getRecyclePlayletGroupStatistics = data => postRequest('/admin/statistics/recycle_playlet_group',data)

// 小说-按组付费趋势
export const getRecycleGroupTrend = data => postRequest('/admin/statistics/recycle_group_trend',data)

// 短剧-按组付费趋势
export const getRecyclePlayletGroupTrend = data => postRequest('/admin/statistics/recycle_group_playlet_trend',data)

// 回收统计详情
export const getRecycleDetail = data => postRequest('/admin/statistics/recycle_detail', data)

// 付费趋势
export const getRecycleTrend = data => postRequest('/admin/statistics/recycle_trend', data)

// 付费趋势头条数据(广告账户)
export const recycleAccount = data => postRequest('/admin/statistics/recycle_account', data)

// 付费趋势头条数据(广告账户)汇总
export const recycleAccountSummary = data => postRequest('/admin/statistics/recycle_account_summary', data)

// 根据短剧名称（账户名称-模糊匹配）调取头条接口获取所有已建广告数
export const getPromotionsFromPlaylet = data => postRequest('/admin/statistics/get_playlet_promotions', data)

// 付费趋势头条列表计划数据(计划/2.0广告)
export const recycleAccountPlan = data => postRequest('/admin/statistics/recycle_account_plan', data)

// 付费趋势头条列表计划数据(计划/2.0广告)汇总
export const accountPlanSummary = data => postRequest('/admin/statistics/account_plan_summary', data)

// 头条列表计划数据(计划/2.0广告)编辑备注
export const adRemarkEdit = data => postRequest('/admin/statistics/adRemark_save', data)

// 订单明细
export const getOrderItems = data => postRequest('/admin/order/items', data)

// 订单支付占比
export const payRatio = data => postRequest('/admin/order/pay_ratio', data)

// 下单渠道
export const getOrderChannels = data => postRequest('/admin/order/channel', data)

// 下单推广链接
export const getOrderPromotion = data => postRequest('/admin/order/promotion', data)

// 下单用户累计充值
export const getOrderUserCharge = data => postRequest('/admin/order/user_recharge_total', data)

// 下单用户阅读记录列表
export const readRecordList = data => postRequest('/admin/order/read_record', data)

// 下单用户充值记录列表
export const rechargeRecordList = data => postRequest('/admin/order/recharge_record', data)

// 下单用户消费记录列表
export const consumeRecordList = data => postRequest('/admin/order/consume_record', data)

// 下单用户打赏记录列表
export const rewardRecordList = data => postRequest('/admin/order/reward_record', data)

// 下单用户签到记录列表
export const signRecordList = data => postRequest('/admin/order/reward_record', data)

// 投手每日消耗统计
export const getDailyCost = data => postRequest('/admin/statistics/daily_cost', data)

// 三方短剧每日消耗
export const getPlayletDailyCost = data => postRequest('/admin/statistics/daily_playlet_cost', data)

// 实时获取连山消耗数据
export const getLianShanRealCost = data => postRequest('/admin/statistics/lianshan_real_cost', data)

// 亿投短剧每日消耗
export const getPlayletYiTouDailyCost = data => postRequest('/admin/statistics/daily_yitou_playlet_cost', data)

// 投手剧场汇总
export const yiTouPlayletSummary = data => postRequest('/admin/statistics/yitou_playlet_summary', data)

// 头条列表投手筛选数据
export const filterPitchers = data => postRequest('/admin/statistics/filter_pitcher', data)

// 头条列表广告账号筛选数据
export const filterAccounts = data => postRequest('/admin/statistics/filter_account', data)

// 创意2.0素材列表
export const creativeAdList = data => postRequest('/admin/statistics/creative_ad_list', data)

// 短剧付费趋势
export const playletRecycleTrend = data => postRequest('/admin/statistics/playlet_recycle_trend', data)

// 小说多充统计
export const bookMulPayStatistics = data => postRequest('/admin/statistics/book_mul_pay', data)

// 小说多充统计
export const chapterReadRecord = data => postRequest('/admin/statistics/chapter_read_record', data)

// 三方小说汇总统计
export const thirdNovelSummary = data => postRequest('/admin/statistics/third_summary', data)

// 账户分时消耗数据
export const costAdvertiseTimeDivision = data => postRequest('/admin/statistics/cost_advertise_time_division', data)

// 广告分时消耗数据
export const costPromotionTimeDivision = data => postRequest('/admin/statistics/cost_promotion_time_division', data)

// 剧场推广链接回传配置
export const promotionBackSet = data => postRequest('/admin/statistics/promotion_back_set', data)

// 获得广告操作日志
export const getLogList = data => postRequest('/admin/advertise/get_log_list', data)

// 判断是否为短剧投手
export const checkPlayletPitcher = data => postRequest('/admin/user/is_pitcher', data)

// 统计短剧投手每日盈亏情况
export const playletProfitTrend = data => postRequest('/admin/statistics/profit_trend', data)

// 短剧投手每日盈亏情况汇总
export const profitTrendSummary = data => postRequest('/admin/statistics/profit_trend_summary', data)

// 短剧投手每日盈亏详情
export const profitTrendDetail = data => postRequest('/admin/statistics/profit_trend_detail', data)

// 当天盈亏值计算
export const currentProfitTrend = data => postRequest('/admin/statistics/current_profit', data)

// 按组盈亏数据
export const profitTrendGroup = data => postRequest('/admin/statistics/group_profit_trend', data)

// 关键行为（查看广告免费看剧）的盈亏消耗
export const profitTrendActions = data => postRequest('/admin/statistics/profit_trend_actions', data)

// 分时日期的所有短剧
export const playletTimerBook = data => postRequest('/admin/statistics/playlet_timer', data)

// 短剧分时消耗，回收统计
export const playletTimerStatistics = data => postRequest('/admin/statistics/playlet_timer_data', data)

// 每日短剧投放情况
export const playletActiveForDate = data => postRequest('/admin/statistics/active_date_playlet', data)

// 添加投手每日的投放旧剧
export const addPitcherPlayletDay = data => postRequest('/admin/statistics/add_pitcher_playlet', data)

// 短剧每日看板汇总信息
export const getBoardPlayletTotalStatistics = data => postRequest('/admin/board_playlet/total_statistics', data)

// 短剧每日看板列表
export const getBoardPlayletList = data => postRequest('/admin/board_playlet/list', data)

// 短剧ECPM收益列表
export const getECPMList = data => postRequest('/admin/statistics/ecpm_list', data)

// 短剧ECPM收益总计
export const getECPStatistics = data => postRequest('/admin/statistics/total_ecpm_statistics', data)

// ecpm列表获取剧场
export const getThirdECPMPlaylet = data => postRequest('/admin/third_ecpm/get_playlet', data)

// 短剧榜单排行
export const getPlayletListRank = data => postRequest('/admin/statistics/playlet_list_rank', data)

// 短剧榜单短剧包
export const getPlayletPackage = data => postRequest('/admin/statistics/playlet_package', data)

// 小说IAA短剧
export const getIaaPlaylet = data => postRequest('/admin/novel/iaa_playlet', data)

// 每日短剧广告
export const getDailyPlayletAdData = data => postRequest('/admin/statistics/daily_playlet_ad', data)

// 每日短剧广告详情
export const getDailyPlayletAdDetail = data => postRequest('/admin/statistics/daily_playlet_ad_detail', data)
