import { Button, Card, DatePicker, Form, Input } from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";

const { RangePicker } = DatePicker;

export default function Index(props) {
  const { list, total, page, loading, pagesize, perPage } = props;

  // 日期范围筛选
  const onSearch = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    props.onSearch(values);
  };

  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSearch}>
          <Form.Item name="range_date">
            <RangePicker />
          </Form.Item>
          <Form.Item name="promotion_name">
            <Input placeholder="输入推广链接名称" allowClear />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <Table
        dataSource={list}
        columns={columns()}
        total={total}
        page={page}
        loading={loading}
        pagesize={pagesize}
        perPage={perPage}
      />
    </Card>
  );
}
