import { Button, Image, Space } from "antd";
import { Link } from "react-router-dom";

const columns = () => [
  {
    title: "落地页",
    width: 100,
    align: "center",
    dataIndex: "landing",
    key: "landing",
    render: (_, record) => (
      <>
        <div style={{ display: "flex", alignItems: 'center'}}>
          <div>
            <Image
              src={record?.landing?.image_url}
              preview={false}
              width={150}
            />
          </div>
          <div style={{ paddingTop: 18, width: '100%'  }}>
            <div style={{textAlign: 'left', paddingLeft: 20}}>
              {record?.title}
            </div>
            <div style={{textAlign: 'left', paddingLeft: 20}}>ID: {record?.site_id}</div>
          </div>
        </div>
      </>
    ),
  },
  {
    title: "状态",
    width: 80,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (value) =>
      value === "ENABLE"
        ? "已上线"
        : value === "EDIT"
        ? "未上线"
        : value === "AUDIT_DOING"
        ? "审核中"
        : value === "AUDIT_ACCEPTED"
        ? "审核通过"
        : value === "AUDIT_REJECTED"
        ? "审核拒绝"
        : value === "DISABLE"
        ? "不可用"
        : value === "DELETE"
        ? "已删除"
        : "未知状态",
  },
  {
    title: "所属账户",
    width: 120,
    align: "center",
    dataIndex: "advertiser",
    key: "advertiser",
    render: (_, record) => (
      <>
        <div style={{width: '100%', textAlign: 'left', paddingLeft: 10}}>{record.advertise?.account_name}</div>
        <div style={{textAlign: 'left', paddingLeft: 10}}>ID:{record.advertise?.account_id}</div>
      </>
    ),
  },
  {
    title: "创建时间",
    width: 100,
    align: "center",
    dataIndex: "created_at",
    key: "created_at",
  },
  // {
  //   title: "操作",
  //   width: 100,
  //   align: "center",
  //   dataIndex: "operation",
  //   key: "operation",
  //   render: (_, record) => (
  //     <ui style={{display: 'flex', justifyContent: 'center'}}>
  //       <li style={{marginRight: 10}}><Link>预览</Link></li>
  //       <li style={{marginRight: 10}}><Link>下线</Link></li>
  //       <li style={{marginRight: 10}}><Link>删除</Link></li>
  //     </ui>
  //   )
  // },
];

export default columns;
