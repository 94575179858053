import { Button, Card, DatePicker, Form, Space } from 'antd'
import React from 'react'
import { useState } from 'react'
import Table from '../../../../components/TableNoSelection'
import columns from './creativeColumns'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { materialCreativeCost } from '../../../../api'

const { RangePicker } = DatePicker

export default function CreativeDayCost() {
    const [list, setList] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)

    const { state } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        getList()
    }, [])

    const getList = async (params = {}) => {
        const res = await materialCreativeCost({ ...params, id: state.id })
        const { data } = res
        if (res?.code === 200) {
            setList(data?.lists)
            setTotal(data?.total)
            setLoading(false)
        }
    }

    // 排序
    const onChange = (pagination, filters, sorter) => {
        let field = sorter.field
        let orderType = sorter.order === 'ascend' ? 'asc' : 'desc'
        let order = `${field}_${orderType}`
        console.log(pagination, field);
        if (field !== undefined) {
            getList({ order })
        }
    }

    // 日期查询
    const onSubmit = values => {
        let createdAt
        if (values["range_date"]) {
            createdAt = values["range_date"].map((date) => {
                return date.format("YYYYMMDD")
            })
        }
        getList({day: createdAt})
    }

    // 每页显示条数切换回调
    const pagesize = (page, size) => {
        setPage(page)
        getList({ pageSize: size, page })
    }

    return (
        <>
            <Card title={
                <Space size='large'>
                    素材每日消耗
                    <Button type='link' onClick={() => { navigate(-1) }}>返回</Button>
                </Space>
            }
                bodyStyle={{ display: 'none' }}
            />
            <Card title={
                <Form layout='inline' onFinish={onSubmit}>
                    <Form.Item name='range_date'>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>确认</Button>
                    </Form.Item>
                </Form>
            }>
                <Table
                    dataSource={list}
                    columns={columns()}
                    total={total}
                    page={page}
                    loading={loading}
                    pagesize={pagesize}
                    onChange={onChange}
                />
            </Card>
        </>
    )
}
