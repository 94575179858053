import { Checkbox, Empty, Input, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined } from "@ant-design/icons";
import { getAdvertiserList } from "../../../../api/advertise";

const { Search } = Input;

export default function Index(props) {
  const [list, setList] = useState([]);
  const [asyncLoading, setAsyncLoading] = useState(false);
  const [page, setPage] = useState(1)

  useEffect(() => {
    getList({pageSize: 10});
  }, []);

  // 获取登录投手的所有账户
  const getList = async (params = {}) => {
    setAsyncLoading(true)
    const res = await getAdvertiserList(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      setList(list);
    }
    setAsyncLoading(false)
  };

  // 通过账户名称关键词或账户id搜索
  const onShareAccountSearch = (value) => {
    getList({ keyword: value });
  };

  // 复选框选中切换
  const onCheckChange = (e, accountId) => {
    let checked = e.target.checked;
    props.onCheckChange(accountId, checked)
    
  };

  // 移除选择
  const selectedAccountRemove = (item) => {
    props.selectedRemove(item)
  };

  // 清空选择
  const clearAll = () => {
    props.clearAll()
  };

  const fetchData = async (params = {}) => {
    params.pageSize = 10
    return await getAdvertiserList(params);
  };

  // 下拉加载更多
  const loadMore = () => {
    let p1 = page + 1;
    const p = fetchData({ page: p1 });
    p.then((res) => {
      const { data } = res;
      if (res?.code === 200) {
        let existsIds = list.map((item) => item.id);
        let lists = data?.lists;
        let newList = lists.filter((item) => {
          return !existsIds.includes(item.id);
        });
        setList([...list, ...newList]);
      }
    });
    setPage(p1);
    setAsyncLoading(false)
  };

  return (
    <div style={{ display: "flex" }}>
      <ul
        style={{
          border: "1px solid #F0F0F0",
          padding: 10,
          width: "60%",
          overflow: "auto",
        }}
      >
        <li style={{ marginBottom: 20 }}>
          <Search
            placeholder="输入账户名称、ID搜索"
            onSearch={onShareAccountSearch}
          />
        </li>
        {asyncLoading ? (
          <Spin />
        ) : (
          <div
            id="scrollableDiv"
            style={{
              height: 350,
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              dataLength={list.length + 1}
              next={loadMore}
              hasMore={list.length > 9}
              scrollableTarget="scrollableDiv"
            >
              {list.length > 0 ? (
                list.map((item) => {
                  return (
                    <li style={{ marginBottom: 15 }} key={item.id}>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: 30,
                            marginRight: 10,
                          }}
                        >
                          <Checkbox
                            checked={props?.selectAsyncAccount.includes(
                              item.account_id
                            )}
                            onChange={(e) => onCheckChange(e, item.account_id)}
                          />
                        </div>
                        <div>
                          <div>{item.account_name}</div>
                          <div>ID: {item.account_id}</div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <Empty />
              )}
            </InfiniteScroll>
          </div>
        )}
      </ul>
      <ul
        style={{
          height: 425,
          border: "1px solid #F0F0F0",
          padding: 10,
          width: "39%",
          overflow: 'auto'
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "80%" }}>
            已选({props?.selectAsyncAccount.length}/100)
          </div>
          <div>
            <Typography.Text
              type="success"
              disabled={props?.selectAsyncAccount.length === 0}
              style={{ cursor: "pointer" }}
              onClick={clearAll}
            >
              清空
            </Typography.Text>
          </div>
        </div>
        {props?.selectAsyncAccount.length > 0
          ? list.filter((item) => {
                return props?.selectAsyncAccount.includes(item.account_id)
          }).map((item) => {
              return (
                <li
                  key={item.id}
                  style={{
                    marginTop: 10,
                    backgroundColor: "#EBF1F4",
                    padding: 5,
                    display: "flex",
                  }}
                >
                  <div style={{width: '90%'}}>
                    <Typography.Text
                      ellipsis={{ tooltip: item.account_name }}
                    >
                      {item.account_name}
                    </Typography.Text>
                    <div>ID: {item.account_id}</div>
                  </div>
                  <CloseOutlined
                    style={{ cursor: "pointer", marginLeft: 10}}
                    onClick={() => {
                      selectedAccountRemove(item);
                    }}
                  />
                </li>
              );
            })
          : ""}
      </ul>
    </div>
  );
}
