const columns = () => [
    {
        title: "日期",
        width: 120,
        align: "center",
        dataIndex: "date",
        key: "date",
        fixed: 'left',
    },
    {
        title: "短剧名称",
        width: 150,
        align: "center",
        dataIndex: "book_name",
        key: "book_name",
        fixed: 'left',
        ellipsis: true,
    },
    {
        title: "当日消耗",
        width: 120,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "回收",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
        render: value => (
            value || 0
        )
    },
    {
        title: "回收率",
        width: 110,
        align: "center",
        dataIndex: "huishoulv",
        key: "huishoulv",
        render: value => (
            value || 0
        )
    },
    {
        title: "首充客单价",
        width: 120,
        align: "center",
        dataIndex: "kedans",
        key: "kedans",
        render: value => (
            value || 0
        )
    },
    {
        title: "新增用户",
        width: 110,
        align: "center",
        dataIndex: "new_user",
        key: "new_user",
        render: value => (
            value || 0
        )
    },
    {
        title: "首日充值人数",
        width: 120,
        align: "center",
        dataIndex: "count",
        key: "count",
        render: value => (
            value || 0
        )
    },
    {
        title: "新用户付费率",
        width: 110,
        align: "center",
        dataIndex: "new_pay_rate",
        key: "new_pay_rate",
        render: value => (
            value || 0
        )
    },
    {
        title: "今日复充率",
        width: 110,
        align: "center",
        dataIndex: "repeat_rate",
        key: "repeat_rate",
        render: value => (
            value || 0
        )
    },
    {
        title: "累计充值",
        width: 110,
        align: "center",
        dataIndex: "total_money",
        key: "total_money",
        render: value => (
            value || 0
        )
    },
    {
        title: "当日充值金额",
        width: 110,
        align: "center",
        dataIndex: "first_day_pay",
        key: "first_day_pay",
        render: value => (
            value || 0
        )
    },
    {
        title: "当日首充金额",
        width: 110,
        align: "center",
        dataIndex: "first_day_first_pay",
        key: "first_day_first_pay",
        render: value => (
            value || 0
        )
    },
    {
        title: "付费成本",
        width: 120,
        align: "center",
        dataIndex: "paycosts",
        key: "paycosts",
        render: value => (
            value || 0
        )
    },
    {
        title: "激活数",
        width: 100,
        align: "center",
        dataIndex: "active",
        key: "active",
    },
]

export default columns