import { Button, DatePicker, Form, Table, Typography } from 'antd'
import React from 'react'
import { useState, useEffect } from 'react'
import tableEditColumns from './tableEditColumns'
import { materialConSumeSave, materialUserCostEnter } from '../../../api/advertise'
import { useLocation } from 'react-router-dom'

const {Text} = Typography
const {RangePicker} = DatePicker

export default function TableEdit() {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [isVip, setIsVip] = useState(false)

    // 当前的路径
    const pathKey = useLocation().pathname;

    useEffect(() => {
        getList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathKey])

    // 列表数据
    const getList = async (params = {}) => {
        setLoading(true)
        if (pathKey.split('/').includes('novel')) {
            params.type = 'novel'
        } else {
            params.type = 'playlet'
        }
        const res = await materialUserCostEnter(params)
        const { data } = res
        if (res?.code === 200) {
            let list = data?.lists
            let newList = list?.map((item) => {
                item.key = item.id
                return item
            })
            setList(newList)
            setIsVip(data?.is_vip)
            setLoading(false)
        }
    }

    // 日期筛选
    const onchange = values => {
        let createdAt
        if (values['date']) {
            createdAt = values['date'].map((date) => {
                return date.format("YYYYMMDD")
            })
        }
        getList({ range_date: createdAt })
    }

    const onCostChange = async (e, record, field) => {
        if (e.keyCode === 13) {
            let value = e.target.value
            let params = {value, field, day: record?.day}
            const res = await materialConSumeSave(params)
            if (res?.code === 200) {
                getList()
            }
        }
    }

    return (
        <>
            <div style={{ marginBottom: 10 }}>
                <Form layout='inline' onFinish={onchange}>
                    <Form.Item name='date'>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>确定</Button>
                    </Form.Item>
                </Form>
            </div>
            {
                isVip ?
                    <Table
                        dataSource={list}
                        columns={tableEditColumns({onCostChange})}
                        pagination={false}
                        bordered={true}
                        loading={loading}
                        summary={(pageData) => {
                            let totalCost = 0
                            let totalMoneyCount = 0
                            let totalKuaiShouCost = 0
                            let totalOppoCost = 0
                            let totalVivoCost = 0
                            let totalAdqCost = 0
                            let totalClaim = 0
                            let totalAllCost = 0
                            pageData.forEach(({ cost, total_cost, total_kuaishou_cost, total_oppo_cost, total_vivo_cost, total_adq_cost, total_claim, total_all_cost }) => {
                                totalCost += parseFloat(cost)
                                totalMoneyCount += parseFloat(total_cost)
                                totalKuaiShouCost += parseFloat(total_kuaishou_cost)
                                totalOppoCost += parseFloat(total_oppo_cost)
                                totalVivoCost += parseFloat(total_vivo_cost)
                                totalAdqCost += parseFloat(total_adq_cost)
                                totalClaim += parseFloat(total_claim)
                                totalAllCost += parseFloat(total_all_cost)
                            });
                            return (
                                <>
                                    {
                                        pageData && pageData.length > 0 ?
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0} colSpan={2} align='center'>
                                                    <Text type="danger" strong={true}>汇总</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2} align='center'>
                                                    <Text>{totalCost.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={3} align='center'>
                                                    <Text>{totalMoneyCount.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={4} align='center'>
                                                    <Text>{totalKuaiShouCost.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={5} align='center'>
                                                    <Text>{totalOppoCost.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={6} align='center'>
                                                    <Text>{totalVivoCost.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={7} align='center'>
                                                    <Text>{totalAdqCost.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={8} align='center'>
                                                    <Text>{totalClaim.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={9} align='center'>
                                                    <Text>{totalAllCost.toFixed(2)}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row> : ''
                                    }
                                </>
                            );
                        }}
                    /> :
                    <Table
                        dataSource={list}
                        columns={tableEditColumns({onCostChange})}
                        pagination={false}
                        bordered={true}
                        loading={loading}
                    />
            }
        </>
    )
}
