import { Button, Card, Drawer, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../components/TableHasTotal";
import {
  getMessage,
  getMessageDetail,
  updateMessageRead,
} from "../../api/message";
import columns from "./columns";
import useWebSocket from "../../hooks/useWebSocket";

export default function Index() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectKeys, setSelectKeys] = useState([]);
  const [show, setShow] = useState(false);
  const [detailData, setDetailData] = useState({});

  const { sendMessage, ws } = useWebSocket({
    url: process.env.REACT_APP_WEBSOCK_RUL,
    verify: true,
  });

  if (ws) {
    // 获取从服务端推送过来的数据
    ws.onmessage = (e) => {
      console.log(e.data, 2233);
    };
  }

  useEffect(() => {
    getList();
  }, []);

  // 获取消息列表
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getMessage(params);
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newList = lists.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newList);
      setTotal(data?.total);
    }
    setLoading(false);
  };

  // 处理更新消息已读状态
  const readUpdate = async (params) => {
    const res = await updateMessageRead(params);
    if (res?.code === 200) {
      let newList = list.map((item) => {
        if (params?.ids.includes(item.id)) {
          item.is_read = 1;
        }
        return item;
      });
      setList(newList);
      sendMessage(
        JSON.stringify({
          username: localStorage.getItem("username"),
          sourse: "更新已读消息",
        })
      );
    }
  };

  // 详情
  const showDetail = async (id) => {
    const res = await getMessageDetail({ id });
    if (res?.code === 200) {
      const { data } = res;
      if (data?.info?.is_read === 0) {
        readUpdate({ ids: [id] });
      }
      setDetailData(data);
    }
    setShow(true);
  };

  // 关闭详情弹窗
  const onDetailClose = () => {
    setShow(false);
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  // 过滤选中
  const getBatchDisabled = (record) => {
    return record?.is_read === 1;
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectKeys.length > 0;

  // 批量标记已读
  const batchUpdateRead = async () => {
    readUpdate({ ids: selectKeys });
    setSelectKeys([]);
  };

  return (
    <>
      <Card
        title={
          <Button disabled={!hasSelected} onClick={batchUpdateRead}>
            标记为已读
          </Button>
        }
      >
        <Table
          dataSource={list}
          columns={columns({ showDetail })}
          loading={loading}
          page={page}
          perPage={perPage}
          total={total}
          pagesize={pagesize}
          selectRows={selectRows}
          getBatchDisabled={getBatchDisabled}
        />
      </Card>

      {/* 消息详情 */}
      <Drawer
        title={<div>消息详情</div>}
        placement="right"
        width={650}
        onClose={onDetailClose}
        open={show}
        size="small"
      >
        <div>
          <Typography.Title level={5} style={{ fontSize: 14 }}>
            【{detailData?.info?.category}】{detailData?.info?.title}
          </Typography.Title>
          <div style={{ color: "#AAADB2" }}>{detailData?.info?.created_at}</div>
        </div>

        <div style={{ marginTop: 50 }}>
          <div>{detailData?.info?.content}</div>
          {detailData?.info?.category === "出价告警" ? (
            <ul style={{ marginTop: 20 }}>
              <li style={{ display: "flex", marginBottom: 10 }}>
                <div style={{ width: 160 }}>手动头条广告出价：</div>
                <div>{detailData?.cpa_bid || 0}</div>
              </li>
              <li style={{ display: "flex" }}>
                <div style={{ width: 160 }}>推广链接最高出价限制：</div>
                <div>{detailData?.promotion?.panel?.bid_limit || 0}</div>
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
      </Drawer>
    </>
  );
}
