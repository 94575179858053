import { Button, Space, Tag } from "antd"
import dayjs from "dayjs"

const columns = ({authorizationCancel}) => [
    {
        title: "登录名称",
        width: 120,
        align: "center",
        dataIndex: "username",
        key: "username",
        ellipsis: true,
    },
    {
        title: "用户名称",
        width: 120,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
    },
    {
        title: "用户状态",
        width: 120,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: value => (
            value === 1 ? <Tag color="green">正常</Tag> : <Tag color="red">停用</Tag>
        )
    },
    {
        title: "创建时间",
        width: 150,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "操作",
        width: 150,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Space>
                <Button type="primary" danger onClick={()=> {authorizationCancel(record)}}>取消授权</Button>
            </Space>
        )
    },
]

export default columns