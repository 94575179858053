import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Table,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import columns from "./columns";
import bookColumns from "./bookColumns";
import { useEffect } from "react";
import {
  checkPlayletPitcher,
  dailyTimerDivision,
  getPlayletYiTouDailyCost,
} from "../../../../api/statistics";
import { promotionStatusSave } from "../../../../api/advertise";
import pitcherColumns from "./pitcherColumns";
import dayjs from "dayjs";
import ChartLine from "../../../../components/ChartLine";
const { RangePicker } = DatePicker;

const { Text } = Typography;

export default function Index() {
  const [activeTabKey, setActiveTabKey] = useState("tab1");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPitcher, setIsPitcher] = useState(true);
  const [conditions, setConditions] = useState([]);
  const [statusLoading, setStatusLoading] = useState(0);
  const [divisionModalShow, setDivisionModalShow] = useState(false);
  const [divisionData, setDivisionData] = useState([]);

  useEffect(() => {
    getRole();
    getList();
  }, []);

  const [form] = Form.useForm();

  // 获取对应的后台用户角色
  const getRole = async () => {
    const res = await checkPlayletPitcher();
    if (res?.code === 200) {
      const { data } = res;
      setIsPitcher(data?.is_pitcher);
    }
  };

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getPlayletYiTouDailyCost(params);
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 标签切换
  const onTabChange = async (value) => {
    setActiveTabKey(value);
    form.resetFields();
    getList({ tab: value });
  };

  const tabList = [
    {
      key: "tab1",
      tab: "推广链接",
    },
    {
      key: "tab3",
      tab: "投手消耗",
    },
    {
      key: "tab2",
      tab: "短剧消耗",
    },
  ];

  // 修改推广链接状态
  const onStatusChange = async (record, status) => {
    let id = record?.promotion_primary_id;
    setStatusLoading(id);
    let value = status === true ? 1 : 0;
    const res = await promotionStatusSave({ id, status: value });
    if (res?.code === 200) {
      message.success("状态更改成功");
      setStatusLoading(0);
      getList({ ...conditions });
    }
  };

  const getColumns = (tab) => {
    let returnColumns;
    switch (tab) {
      case "tab1":
        returnColumns = columns({ onStatusChange, statusLoading });
        break;
      case "tab2":
        returnColumns = bookColumns({ showEstimateDetail });
        break;
      case "tab3":
        returnColumns = pitcherColumns();
        break;
      default:
        break;
    }
    return returnColumns;
  };

  // 筛选表单提交
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    setConditions({ tab: activeTabKey, ...values });
    getList({ tab: activeTabKey, ...values });
  };

  // 消耗时速弹窗
  const showEstimateDetail = async (bookName) => {
    let day = dayjs().format("YYYYMMDD");
    if (conditions?.range_date) {
      const b = new Set(conditions?.range_date);
      let arr = [...b];
      if (arr.length > 1) {
        message.error("查看消耗时速不能跨日期选择");
        return false;
      }
      day = arr[0];
    }
    setDivisionModalShow(true);
    const res = await dailyTimerDivision({
      book_name: bookName,
      pitcher: conditions?.pitcher,
      day,
    });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      let newList = list.map((item) => {
        item.cost = parseFloat(item.cost);
        return item;
      });
      setDivisionData(newList);
    }
  };

  return (
    <>
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
      >
        <div style={{ marginLeft: -10, marginTop: -10, marginRight: -10 }}>
          <div style={{ marginBottom: 10 }}>
            <Form layout="inline" onFinish={onSubmit} form={form}>
              <Form.Item label="日期" name="range_date">
                <RangePicker style={{ width: 225 }} />
              </Form.Item>
              <>
                <Form.Item label="投手" name="pitcher">
                  <Input
                    allowClear
                    placeholder="输入投手名称"
                    style={{ width: 130 }}
                  />
                </Form.Item>
                {activeTabKey === "tab1" ? (
                  <Form.Item label="推广链接名称" name="promotion_name">
                    <Input
                      allowClear
                      placeholder="推广链接名称"
                      style={{ width: 130 }}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </>
              <Form.Item label="短剧" name="playlet_name">
                <Input
                  allowClear
                  placeholder="输入短剧名称"
                  style={{ width: 130 }}
                />
              </Form.Item>
              <Form.Item label colon={false}>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </Form>
          </div>
          {activeTabKey === "tab3" ? (
            <Table
              sticky
              dataSource={list}
              columns={getColumns(activeTabKey, showEstimateDetail)}
              bordered={true}
              loading={loading}
              pagination={false}
              scroll={{ x: 500 }}
              summary={(pageData) => {
                let totalNewUser = 0;
                let totalCount = 0;
                let totalFirstPayNum = 0;
                let totalMoney = 0;
                let totalMiniMoney = 0;
                let totalByteMoney = 0;
                let totalCost = 0;
                let totalMiniCost = 0;
                let totalByteCost = 0;
                pageData.forEach(
                  ({
                    new_user,
                    count,
                    first_pay_num,
                    total_money,
                    mini_money,
                    byte_money,
                    cost,
                    mini_cost,
                    byte_cost,
                  }) => {
                    totalNewUser += parseInt(new_user);
                    totalCount += parseInt(count);
                    totalFirstPayNum += parseInt(first_pay_num);
                    totalMoney += parseFloat(total_money);
                    totalMiniMoney += parseFloat(mini_money);
                    totalByteMoney += parseFloat(byte_money);
                    totalCost += parseFloat(cost);
                    totalMiniCost += parseFloat(mini_cost);
                    totalByteCost += parseFloat(byte_cost);
                  }
                );
                return (
                  <>
                    {pageData && pageData.length > 0 ? (
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          index={0}
                          colSpan={1}
                          align="center"
                        >
                          <Text type="danger" strong={true}>
                            汇总
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <div>
                            <Text>总新增用户：{totalNewUser}</Text>
                          </div>
                          <div>
                            <Text>总首日充值人数：{totalCount}</Text>
                          </div>
                          <div>
                            <Text>总首日充值笔数：{totalFirstPayNum}</Text>
                          </div>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <div>
                            <Text>总计充值：{totalMoney.toFixed(2)}</Text>
                          </div>
                          <div>
                            <Text>
                              总计微小充值：{totalMiniMoney.toFixed(2)}
                            </Text>
                          </div>
                          <div>
                            <Text>
                              总计抖小充值：{totalByteMoney.toFixed(2)}
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <div>
                            <Text>总计消耗：{totalCost.toFixed(2)}</Text>
                          </div>
                          <div>
                            <Text>
                              总计微小消耗：{totalMiniCost.toFixed(2)}
                            </Text>
                          </div>
                          <div>
                            <Text>
                              总计抖小消耗：{totalByteCost.toFixed(2)}
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    ) : (
                      ""
                    )}
                  </>
                );
              }}
            />
          ) : (
            <Table
              sticky
              dataSource={list}
              columns={getColumns(activeTabKey, showEstimateDetail)}
              bordered={true}
              loading={loading}
              pagination={false}
              scroll={{ x: 500 }}
            />
          )}
        </div>
      </Card>

      {/* 弹窗显示分时消耗时速 */}
      <Modal
        open={divisionModalShow}
        onCancel={() => setDivisionModalShow(false)}
        title="分时消耗时速"
        maskClosable={false}
        footer={null}
        width={1000}
      >
        <Divider />
        <ChartLine
          data={divisionData}
          xField="time"
          yField="cost"
          //  seriesField="day"
        />
      </Modal>
    </>
  );
}
