import React from "react";
import { useState } from "react";
import Table from "../../../components/TableNoSelection";
import { Card, DatePicker, Select, Space } from "antd";
import columns from "./columns";
import { useEffect } from "react";
import { getReaders } from "../../../api/book";
import RechargeModal from "./Recharge";
import ReadModal from "./Read";
import SignModal from "./Sign";
import ConsumeModal from "./Consume";
import CompensateModal from "./Compensate";
import {
  getCompensateRecord,
  getConsumeRecord,
  getFilterPromotions,
  getReadRecord,
  getRechargeRecord,
  getSignRecord,
} from "../../../api/advertise";

const { RangePicker } = DatePicker;

export default function Index() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rechargeShow, setRechargeShow] = useState(false);
  const [signShow, setSignShow] = useState(false);
  const [readShow, setReadShow] = useState(false);
  const [consumeShow, setconsumeShow] = useState(false);
  const [compensateShow, setCompensateShow] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [recordTotal, setRecordTotal] = useState(0);
  const [recordLoading, setRecordLoading] = useState(false);
  const [recordPage, setRecordPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [currentUid, setCurrentUid] = useState(0);
  const [promotionOptions, setPromotionOptions] = useState([]);

  useEffect(() => {
    getList();
    getPromotions();
  }, []);

  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getReaders(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  const getPromotions = async () => {
    const res = await getFilterPromotions();
    if (res?.code === 200) {
      const { data } = res;
      const lists = data?.lists.map((item) => {
        return { label: item.remark, value: item.id };
      });
      setPromotionOptions(lists);
    }
  };

  // 获取充值明细
  const getRechargeList = async (params = {}) => {
    setRecordLoading(true);
    const res = await getRechargeRecord(params);
    if (res?.code === 200) {
      const { data } = res;
      setRecordList(data?.lists);
      setRecordTotal(data?.total);
      setRecordLoading(false);
    }
  };

  // 获取签到明细
  const getSignList = async (params = {}) => {
    setRecordLoading(true);
    const res = await getSignRecord(params);
    if (res?.code === 200) {
      const { data } = res;
      setRecordList(data?.lists);
      setRecordTotal(data?.total);
      setRecordLoading(false);
    }
  };

  // 获取消费记录
  const getConsumeList = async (params = {}) => {
    setRecordLoading(true);
    const res = await getConsumeRecord(params);
    if (res?.code === 200) {
      const { data } = res;
      setRecordList(data?.lists);
      setRecordTotal(data?.total);
      setRecordLoading(false);
    }
  };

  // 获取阅读记录
  const getReadList = async (params = {}) => {
    setRecordLoading(true);
    const res = await getReadRecord(params);
    if (res?.code === 200) {
      const { data } = res;
      setRecordList(data?.lists);
      setRecordLoading(false);
    }
  };

  // 获取补偿记录
  const getCompensateList = async (params = {}) => {
    setRecordLoading(true);
    const res = await getCompensateRecord(params);
    if (res?.code === 200) {
      const { data } = res;
      setRecordList(data?.lists);
      setRecordTotal(data?.total);
      setRecordLoading(false);
    }
  };

  // 各明细列表
  const showDetail = (record, source, params = {}) => {
    let uid = record?.id;
    setCurrentUid(uid);
    switch (source) {
      case "recharge":
        setRechargeShow(true);
        getRechargeList({ uid, ...params });
        break;
      case "sign":
        setSignShow(true);
        getSignList({ uid, ...params });
        break;
      case "read":
        setReadShow(true);
        getReadList({ uid, ...params });
        break;
      case "compensate":
        setCompensateShow(true);
        getCompensateList({ uid, ...params });
        break;
      case "consume":
        setconsumeShow(true);
        getConsumeList({ uid, ...params });
        break;
      default:
        break;
    }
  };

  // 充值明细弹窗关闭
  const rechargeClose = () => {
    setRechargeShow(false);
    setRecordList([]);
    setRecordPage(1);
    setRecordPerPage(10);
  };

  // 消费弹窗关闭
  const consumeClose = () => {
    setconsumeShow(false);
    setRecordList([]);
    setRecordPage(1);
    setRecordPerPage(10);
  };

  // 签到弹窗关闭
  const signClose = () => {
    setSignShow(false);
    setRecordList([]);
    setRecordPage(1);
    setRecordPerPage(10);
  };

  // 阅读记录弹窗关闭
  const readClose = () => {
    setReadShow(false);
    setRecordList([]);
  };

  // 补偿明细弹窗关闭
  const compensateClose = () => {
    setCompensateShow(false);
    setRecordList([]);
    setRecordPage(1);
    setRecordPerPage(10);
  };

  // 读者列表分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  // 明细记录条件查询
  const recordSearch = (values, source) => {
    showDetail({ id: currentUid }, source, values);
  };

  // 明细记录分页
  const recordPageSize = (params, source) => {
    setRecordPage(params.page);
    setRecordPerPage(params.pageSize);
    showDetail({ id: currentUid }, source, params);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 日期范围筛选查询
  const dateChange = (values) => {
    let rangeDate;
    if (values) {
      rangeDate = values.map((date) => {
        return date.format("YYYY-MM-DD");
      });
    }
    getList({ range_date: rangeDate });
  };

  // 推广链接筛选查询
  const promotionChange = (value) => {
    getList({ promotion_id: value });
  };

  return (
    <>
      <Card
        title={
          <Space>
            <RangePicker onChange={dateChange} />
            <Select
              allowClear
              placeholder="选择推广链接"
              showSearch
              filterOption={filterOption}
              options={promotionOptions}
              onChange={promotionChange}
              style={{ width: 180 }}
            />
          </Space>
        }
      >
        <Table
          dataSource={list}
          columns={columns({ showDetail })}
          page={page}
          perPage={perPage}
          total={total}
          loading={loading}
          pagesize={pagesize}
        />
      </Card>

      {/* 充值记录弹窗 */}
      <RechargeModal
        show={rechargeShow}
        onClose={rechargeClose}
        list={recordList}
        page={recordPage}
        perPage={recordPerPage}
        total={recordTotal}
        loading={recordLoading}
        pagesize={recordPageSize}
        onSearch={recordSearch}
      />

      {/* 消费明细弹窗 */}
      <ConsumeModal
        show={consumeShow}
        onClose={consumeClose}
        list={recordList}
        total={recordTotal}
        page={recordPage}
        perPage={recordPerPage}
        loading={recordLoading}
        pagesize={recordPageSize}
        onSearch={recordSearch}
      />

      {/* 签到明细弹窗 */}
      <SignModal
        show={signShow}
        onClose={signClose}
        list={recordList}
        total={recordTotal}
        page={recordPage}
        perPage={recordPerPage}
        loading={recordLoading}
        pagesize={recordPageSize}
        onSearch={recordSearch}
      />

      {/* 补偿明细弹窗 */}
      <CompensateModal
        show={compensateShow}
        onClose={compensateClose}
        list={recordList}
        total={recordTotal}
        page={recordPage}
        perPage={recordPerPage}
        loading={recordLoading}
        pagesize={recordPageSize}
        onSearch={recordSearch}
      />

      {/* 阅读记录弹窗 */}
      <ReadModal
        show={readShow}
        onClose={readClose}
        list={recordList}
        loading={recordLoading}
        onSearch={recordSearch}
      />
    </>
  );
}
