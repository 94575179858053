import { Tag } from "antd"
import dayjs from "dayjs"
import { Link } from "react-router-dom"

const columns = () => [
    {
        title: "推广链接",
        width: 150,
        align: "center",
        dataIndex: "promotion",
        key: "promotion",
        ellipsis: true,
        fixed: 'left',
        render: (_,record) => (
            record?.promotion?.remark || '-'
        )
    },
    {
        title: "所属渠道",
        width: 150,
        align: "center",
        dataIndex: "admin_user",
        key: "admin_user",
        ellipsis: true,
        fixed: 'left',
        render: (_,record) => (
            record?.channel?.nickname
        )
    },
    {
        title: "订单号",
        width: 120,
        align: "center",
        dataIndex: "payid",
        key: "payid",
        ellipsis: true,
    },
    {
        title: "订单类型",
        width: 100,
        align: "center",
        dataIndex: "paytype",
        key: "paytype",
        render: (value) => (
            value === 1 ? <Tag>VIP会员</Tag> : (value === 2 ? <Tag>普通充值</Tag> : '未知')
        )
    },
    {
        title: "用户",
        width: 120,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        render: (_, record) => {
            return <>
                <div><img src={record?.user?.avatar} alt='头像' width={30}/></div>
                <Link to="/admin/statistics/items/user" state={{...record}}>{record?.user?.id}</Link>
            </>
        }
    },
    {
        title: "充值次数",
        width: 100,
        align: "center",
        dataIndex: "nums",
        key: "nums",
    },
    {
        title: "小说",
        width: 120,
        align: "center",
        dataIndex: "bookid",
        key: "bookid",
        render: (_, record) => (
            record?.book?.title
        )
    },
    {
        title: "充值金额",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "支付方式",
        width: 120,
        align: "center",
        dataIndex: "type",
        key: "type",
        filters: [
            {
                text: '公众号支付',
                value: 1
            },
            {
                text: '第三方支付',
                value: 2
            },
            {
                text: 'H5支付',
                value: 3
            },
            {
                text: '通联支付',
                value: 4
            },
            {
                text: '支付宝wap支付',
                value: 15
            },
            {
                text: '支付宝app支付',
                value: 11
            },
            {
                text: '微信app支付',
                value: 12
            },
            {
                text: '微信H5支付',
                value: 13
            },
            {
                text: '首信易支付',
                value: 20
            },
            {
                text: '苹果支付',
                value: 19
            },
            {
                text: '米花微信h5支付',
                value: 21
            },
            {
                text: '米花支付宝支付',
                value: 22
            },
        ],
        render: value => {
            let payType = {1: '公众号支付', 2: '第三方支付', 4: '通联支付', 3: 'H5支付', 15: '支付宝wap支付', 11: '支付宝app支付', 12: '微信app支付', 13: '微信H5支付', 20: '首信易支付', 19: '苹果支付', 21: '米花微信h5支付', 22: '米花支付宝支付'}
            return payType[value] || '-'
        }
    },
    {
        title: "订单状态",
        width: 120,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: (value) => {
            switch(value) {
                case 0:
                    return <Tag color="error">未支付</Tag>
                case 1:
                    return <Tag color="success">已支付</Tag>
                case 2:
                    return <Tag color="warning">已支付</Tag>
                default:
                    return <Tag color="info">未知</Tag>
            }
        }
    },
    {
        title: "手机型号",
        width: 100,
        align: "center",
        dataIndex: "phone_type",
        key: "phone_type",
        render: (_,record) => {
            let type = record?.user?.phone_type
            switch(type) {
                case 1:
                    return 'huawei'
                case 2:
                    return 'xiaomi'
                case 3:
                    return 'oppo'
                case 4:
                    return 'other'
                case 5:
                    return 'vivo'
                default:
                    return '未知'
            }

        }
    },
    {
        title: "添加时间",
        width: 150,
        align: "center",
        dataIndex: "addtime",
        key: "addtime",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "支付时间",
        width: 150,
        align: "center",
        dataIndex: "paytime",
        key: "paytime",
        render: (value, record) => (
            record?.status === 0 ? '-' : dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "创建时间",
        width: 150,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: (_,record) => (
            dayjs.unix(record?.user?.create_time).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "最近渠道变更时间",
        width: 150,
        align: "center",
        dataIndex: "register_time",
        key: "register_time",
        render: (_,record) => (
            dayjs.unix(record?.user?.update_promotions_time).format('YYYY-MM-DD HH:mm:ss')
        )
    },
]

export default columns