import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AudienceTarget, MSchedule } from "@bytedance-ad/mui-react";
import { setConfig } from "@bytedance-ad/mui-react";
import CheckedCard from "../../../../components/CheckedCard";
import {
  asyncAdvertiseAnchor,
  changeList,
  deleteUserTemplate,
  doSaveUserTemplate,
  getAdvertiseConvertId,
  getByteAppAssets,
  getMonitor,
  getProductList,
  getProductPlatform,
  getTemplateRow,
  getUserTemplate,
  landingCheck,
} from "../../../../api/advertise";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../config";
import dayjs from "dayjs";
import Table from "../../../../components/TableNoSelection";
import RowSelectTable from "../../../../components/TableHasTotal";

const { Meta } = Card;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function AddProject(props) {
  const [curValue, setCurValue] = useState({
    district: "REGION",
    city: [
      34, 50, 35, 44, 62, 45, 52, 12, 13, 31, 42, 23, 46, 41, 43, 22, 32, 36,
      21, 15, 64, 63, 51, 61, 14, 65, 54, 53, 33, 371600, 370500, 371400,
      371700, 370100, 370800, 371500, 370200, 371100, 370900, 370700, 371000,
      370600, 370300, 370400,
    ],
    region_versio: "1.0.0",
    location_type: "ALL",
    device_brand: ["HONOR", "HUAWEI", "XIAOMI", "OPPO", "VIVO"],
  });
  const [extensions] = useState({
    delivery_range: "UNIVERSAL",
    landing_type: "EXTERNAL",
    // hiddenComponents: ['gender', 'age', 'crowd'] //配置巨量引擎开放平台
  });

  const [mScheduleValue, setMScheduleValue] = useState({
    schedule_time: "",
  });
  const [budgetValue, setBudgetValue] = useState({
    // schedule_type: props?.projectData?.schedule_type || "SCHEDULE_FROM_NOW",
    // schedule_time: props?.projectData?.schedule_time || "unlimited",
    bid_type: "CUSTOM",
    budget_mode: "BUDGET_MODE_INFINITE",
    pricing: "PRICING_OCPM",
    range_date: [],
  });
  const [mediaOptions] = useState([
    { value: "INVENTORY_FEED", label: "今日头条" },
    { value: "INVENTORY_VIDEO_FEED", label: "西瓜视频" },
    { value: "INVENTORY_AWEME_FEED", label: "抖音" },
    { value: "INVENTORY_TOMATO_NOVEL", label: "番茄小说" },
    { value: "INVENTORY_UNION_SLOT", label: "穿山甲" },
    { value: "UNION_BOUTIQUE_GAME", label: "Ohayoo精品游戏" },
  ]);
  const [landing, setLanding] = useState("APP");
  const [inventoryCatalog, setInventoryCatalog] = useState("MANUAL");
  const [deliveryMode, setdDliveryMode] = useState("MANUAL");
  const [transformOptions, setTransformOptions] = useState([]);
  const [checkAll, setCheckAll] = useState(true);
  const [checkedList, setCheckedList] = useState(
    props?.projectData?.inventory_type || [
      "INVENTORY_FEED",
      "INVENTORY_VIDEO_FEED",
      "INVENTORY_AWEME_FEED",
      "INVENTORY_TOMATO_NOVEL",
      "INVENTORY_UNION_SLOT",
      "UNION_BOUTIQUE_GAME",
    ]
  );
  const [advertiserId, setAdvertiserId] = useState("");
  const [userTempShow, setUserTempShow] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);

  const [deleteUserTempShow, setDeleteUserTempShow] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [platformSelectType, setPlatformSelectType] = useState("NO_MAP");
  const [productPlatformShow, setProductPlatformShow] = useState(false);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [productList, setProductList] = useState([]);
  const [platformLoading, setPlatformLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [role, setRole] = useState([]);
  const [byteAssets, setByteAssets] = useState([]);
  const [externalAction, setExternalAction] = useState("AD_CONVERT_TYPE_PAY");
  const [deepBidAction, setDeepBidType] = useState("DEEP_BID_DEFAULT");
  const [accountNums, setAccountNums] = useState(0);

  setConfig({
    host: `${BASE_URL}`,
    params: {
      advertiser_id: 1772265153683598,
    },
  });

  const [form] = Form.useForm();
  const [platformForm] = Form.useForm();
  const [tempForm] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({
      app_promotion_type: "DOWNLOAD",
      download_type: "EXTERNAL_URL",
    });
    getUserTemp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReturnPreData(props?.projectData);
  }, [props]);

  // 获取转化列表
  const getChangeList = async (type = "APP") => {
    // 默认获取应用转化列表
    const res = await changeList({ convert_source_type: [type], pageSize: 0 });
    const { data } = res;
    if (res?.code === 200) {
      const lists = data.lists;
      const options = lists?.map(function (item) {
        return {
          label: item?.name,
          value: item?.id,
          advertiser_ids: item?.advertiser_ids,
        };
      });
      let role = data.role;
      if (role.includes("pitcher") || role.includes("agent_pitcher")) {
        form.setFieldsValue({ product_setting: "SINGLE" });
        setPlatformSelectType("SINGLE");
        setRole(data.role);
      }
      setTransformOptions(options);
    }
  };

  // 转化切换
  const onHandleChange = async (value) => {
    if (budgetValue?.micro_promotion_type === "BYTE_APP") {
      const assetsRes = await getByteAppAssets({ change_id: value });
      if (assetsRes.code === 200) {
        const { data } = assetsRes;
        let newData = data?.map((item) => {
          return {
            label:
              item?.asset_name + " (小程序资产id: " + item?.instance_id + ")",
            value: item?.instance_id,
          };
        });
        form.setFieldsValue({ instance_id: newData[0]?.value });
        getAdvertiseConvertId({ change_id: value, instance_id: newData[0]?.value });
        setByteAssets(newData);
      }
    }
    let fillterOption = transformOptions.filter((item) => {
      return item?.value === value;
    });
    if (fillterOption.length > 0) {
      let nums = fillterOption[0]?.advertiser_ids?.split(",").length;
      setAccountNums(nums);
    }
    const res = await getMonitor({ change_id: value, type: landing });
    if (res?.code === 200) {
      const { data } = res;
      form.setFieldsValue({
        download_url: data?.down_url,
        action_track_url: data?.url,
      });
      setAdvertiserId(data?.advertiser_id);
    }
  };

  // 切换小程序资产获取
  const onByteChange = (value) => {
    let changeId = form.getFieldValue("change_id");
    if (changeId) {
      getAdvertiseConvertId({ change_id: changeId, instance_id: value });
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const receive = (value) => {
    // 不能缺少这一步
    setCurValue(value);
  };

  const receiveMSchedule = (value) => {
    // 不能缺少这一步
    setMScheduleValue(value);
  };

  const options = [
    { title: "应用推广", description: "推广您线上的APP", value: "APP" },
    {
      title: "销售线索",
      description: "为您的生意销售收集用户线索",
      value: "THIRD_EXTERNAL",
    },
    {
      title: "快应用",
      description: "吸引更多的用户进入快应用",
      value: "QUICK_APP",
    },
    {
      title: "小程序",
      description: "吸引更多的用户进入小程序或小游戏",
      value: "MICRO_GAME",
    },
  ];

  // 应用切换时重置某些已填写的信息
  const onChecked = (value) => {
    getChangeList(value);
    setLanding(value);
    form.resetFields(["change_id", "download_url"]);
    // 自动生成项目名称
    let proName = localStorage.getItem("username") + "_";
    // 切换后默认选中值
    if (value === "APP") {
      form.setFieldsValue({
        app_promotion_type: "DOWNLOAD",
        download_type: "EXTERNAL_URL",
      });
      form.resetFields(["asset_type"]);
      proName += "应用推广项目_";
      setInventoryCatalog("MANUAL");
      form.setFieldsValue({
        inventory_catalog: "MANUAL",
        budget_mode: "BUDGET_MODE_INFINITE",
      });
      setBudgetValue({ budget_mode: "BUDGET_MODE_INFINITE" });
    } else if (value === "LINK" || value === "THIRD_EXTERNAL") {
      form.setFieldsValue({ asset_type: "ORANGE" });
      form.resetFields(["app_promotion_type", "download_type"]);
      proName += "销售线索项目_";
      setInventoryCatalog("UNIVERSAL_SMART");
      setdDliveryMode("PROCEDURAL");
      form.setFieldsValue({
        inventory_catalog: "UNIVERSAL_SMART",
        budget_mode: "BUDGET_MODE_DAY",
        delivery_mode: "PROCEDURAL",
      });
      setBudgetValue({ ...budgetValue, budget_mode: "BUDGET_MODE_DAY" });
    } else if (value === "QUICK_APP") {
      proName += "快应用项目_";
      form.resetFields(["app_promotion_type", "download_type", "asset_type"]);
      setInventoryCatalog("MANUAL");
      form.setFieldsValue({
        inventory_catalog: "MANUAL",
        budget_mode: "BUDGET_MODE_INFINITE",
      });
      setBudgetValue({ ...budgetValue, budget_mode: "BUDGET_MODE_INFINITE" });
    } else {
      proName += "小程序项目_";
      form.resetFields(["app_promotion_type", "download_type", "asset_type"]);
      setInventoryCatalog("MANUAL");
      form.setFieldsValue({
        inventory_catalog: "MANUAL",
        budget_mode: "BUDGET_MODE_INFINITE",
      });
      setBudgetValue({ ...budgetValue, budget_mode: "BUDGET_MODE_INFINITE" });
    }
    let month = dayjs().format("MM");
    let date = dayjs().format("DD");
    let time = dayjs().format("HH:mm:ss");
    proName += month + "_" + date + "_" + time;
    form.setFieldsValue({ project_name: proName });

    if (value === "MICRO_GAME") {
      setCurValue({ device_brand: [] });
      form.setFieldsValue({
        micro_promotion_type: "WECHAT_APP",
      });
    }
  };

  const inventoryChange = (e) => {
    setInventoryCatalog(e.target.value);
  };

  // 媒体选择是否全选
  const inventoryTypeChange = (e) => {
    let checked = e.target.checked;
    let checkedValues = [];
    if (checked) {
      checkedValues = mediaOptions.map((option) => {
        return option.value;
      });
    }
    setCheckedList(checkedValues);
    setCheckAll(!checkAll);
  };

  // 媒体选择单个选持
  const onSingleChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === mediaOptions.length);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  // 投放时间切换
  const scheduleTypeChange = (e) => {
    let value = e.target.value;
    let tempValue = budgetValue;
    delete tempValue.schedule_type;
    setBudgetValue({ ...tempValue, schedule_type: value });
  };

  // 日期切换
  const rangeDateChange = (values) => {
    let rangeDate;
    if (values) {
      rangeDate = values.map((date) => {
        return date.format("YYYYMMDD");
      });
      let tempValue = budgetValue;
      delete tempValue.range_date;
      setBudgetValue({
        ...tempValue,
        start_time: rangeDate[0],
        end_time: rangeDate[1],
      });
    }
  };

  // 投放时段切换
  const scheduleTimeChange = (e) => {
    let value = e.target.value;
    let tempValue = budgetValue;
    delete tempValue.schedule_time;
    setBudgetValue({ ...tempValue, schedule_time: value });
  };

  // 竞价策略切换
  const bidTypeChange = (e) => {
    let value = e.target.value;
    let tempValue = budgetValue;
    delete tempValue.bid_type;
    // let mode = value === 'NO_BID' ? 'BUDGET_MODE_DAY' : 'BUDGET_MODE_INFINITE'
    setBudgetValue({ ...tempValue, bid_type: value });
    if (
      value === "NO_BID" &&
      form.getFieldValue("budget_optimize_switch") === "ON"
    ) {
      form.setFieldsValue({ project_operation: "DISABLE" });
    } else {
      form.setFieldsValue({ project_operation: "ENABLE" });
    }
  };

  // 投放速度切换
  const bidSpeedChange = (e) => {
    let value = e.target.value;
    let tempValue = budgetValue;
    delete tempValue.bid_speed;
    setBudgetValue({ ...tempValue, bid_speed: value });
  };

  // 是否开户择优
  const budgetSwitchChange = (e) => {
    let value = e.target.value;
    setBudgetValue({ ...budgetValue, budget_optimize_switch: value });
    if (value === "ON") {
      form.setFieldsValue({ project_operation: "DISABLE" });
    } else {
      form.setFieldsValue({ project_operation: "ENABLE" });
    }
  };

  // 预算切换
  const budgetChange = (value) => {
    let tempValue = budgetValue;
    delete tempValue.budget;
    setBudgetValue({ ...tempValue, project_budget: value });
  };

  // 项目预算切换
  const budgetModeChange = (e) => {
    let value = e.target.value;
    let tempValue = budgetValue;
    delete tempValue.budget_mode;
    setBudgetValue({ ...tempValue, budget_mode: value });
  };

  // 投放内容切换
  const onPromotionTypeChange = (e) => {
    let value = e.target.value;
    let tempValue = budgetValue;
    delete tempValue.micro_promotion_type;
    if (value === "BYTE_APP") {
      setdDliveryMode("PROCEDURAL");
      setBudgetValue({
        ...tempValue,
        delivery_mode: "PROCEDURAL",
        budget_mode: "BUDGET_MODE_DAY",
        micro_promotion_type: value,
        budget: 99999.99,
      });
      form.setFieldsValue({
        delivery_mode: "PROCEDURAL",
        budget_mode: "BUDGET_MODE_DAY",
        budget: 99999.99,
      });
    } else {
      setdDliveryMode("MANUAL");
      setBudgetValue({
        ...tempValue,
        delivery_mode: "MANUAL",
        budget_mode: "BUDGET_MODE_INFINITE",
        micro_promotion_type: value,
      });
      form.setFieldsValue({
        delivery_mode: "MANUAL",
        budget_mode: "BUDGET_MODE_INFINITE",
      });
    }
    if (value === "WECHAT_APP") {
      getChangeList("MICRO_GAME");
    } else if (value === "BYTE_APP") {
      getChangeList("TIK_PROGRAME");
    }
  };

  // 优化目标切换
  const actionChange = (e) => {
    let value = e.target.value;
    if (value === "AD_CONVERT_TYPE_ACTIVE") {
      setDeepBidType("ROI_COEFFICIENT");
      form.setFieldsValue({
        deep_bid_type: "ROI_COEFFICIENT",
        deep_external_action: "AD_CONVERT_TYPE_LT_ROI",
      });
    } else if (value === "AD_CONVERT_TYPE_PAY") {
      setDeepBidType(null);
      form.setFieldsValue({ deep_bid_type: null });
    }
    setExternalAction(value);
  };

  // 深度优化目标切换
  const onDeepTypeChange = (value) => {
    setDeepBidType(value);
    form.setFieldsValue({
      deep_external_action: "AD_CONVERT_TYPE_PURCHASE_ROI",
    });
  };

  // 投放模式切换
  const deliveryModeChange = (e) => {
    let value = e.target.value;
    let tempValue = budgetValue;
    delete tempValue.budget_mode;
    setdDliveryMode(value);
    if (value === "MANUAL") {
      setBudgetValue({
        ...tempValue,
        budget_mode: "BUDGET_MODE_INFINITE",
        delivery_mode: "MANUAL",
      });
      form.setFieldsValue({
        budget_mode: "BUDGET_MODE_INFINITE",
        project_operation: "ENABLE",
      });
    } else {
      setBudgetValue({
        ...tempValue,
        budget_mode: "BUDGET_MODE_DAY",
        delivery_mode: "PROCEDURAL",
        budget: 99999.99,
      });
      form.setFieldsValue({
        budget_mode: "BUDGET_MODE_DAY",
        budget: 99999.99,
        project_operation: "DISABLE",
      });
    }
  };

  // 获取用户所有模板
  const getUserTemp = async (params = {}) => {
    const res = await getUserTemplate(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      setTemplateList(list);
      let newList = list.map((item) => {
        return { label: item?.template_name, value: item?.id };
      });
      setTemplateOptions(newList);
    }
  };

  // 保存用户定向模板
  const saveUserTemplate = () => {
    setUserTempShow(true);
  };

  // 关闭用户定向模板弹窗
  const closeUserTemp = () => {
    setUserTempShow(false);
    setUserLoading(false);
    tempForm.resetFields();
  };

  // 判断是否为json字符串
  const isJSON = (str) => {
    if (typeof str == "string") {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };

  // 用户定向模板切换
  const tempChange = async (value) => {
    if (value) {
      const res = await getTemplateRow({ id: value });
      const { data } = res;
      let city = isJSON(data?.city) ? JSON.parse(data?.city) : "";
      let age = isJSON(data?.age) ? JSON.parse(data?.age) : "";
      let device_brand = isJSON(data?.device_brand)
        ? JSON.parse(data?.device_brand)
        : "";
      let platform = isJSON(data?.platform) ? JSON.parse(data?.platform) : "";
      let ac = isJSON(data?.ac) ? JSON.parse(data?.ac) : "";
      let device_type = isJSON(data?.device_type)
        ? JSON.parse(data?.device_type)
        : "";
      let retargeting_tags_exclude = isJSON(data?.retargeting_tags_exclude)
        ? JSON.parse(data?.retargeting_tags_exclude)
        : "";
      let retargeting_tags_include = isJSON(data?.retargeting_tags_include)
        ? JSON.parse(data?.retargeting_tags_include)
        : "";
      let hide_if_exists =
        data?.hide_if_exists === 0
          ? "UNLIMITED"
          : data?.hide_if_exists === 1
          ? "FILTER"
          : "TARGETING";
      setCurValue({
        district: "REGION",
        city,
        location_type: "ALL",
        age,
        gender: data?.gender,
        retargeting_tags_exclude,
        retargeting_tags_include,
        platform,
        ac,
        device_type,
        device_brand,
        hide_if_converted: data?.hide_if_converted,
        hide_if_exists,
      });
    }
  };

  // 删除定向模板弹窗
  const deleteTemp = () => {
    setDeleteUserTempShow(true);
  };

  const templateDateChange = (value) => {
    let date;
    if (value) {
      date = value.format("YYYY-MM-DD");
      getUserTemp({ create_time: date });
    } else {
      getUserTemp();
    }
  };

  // 关闭删除定向模板弹窗
  const closeDeleteUserTemp = () => {
    setDeleteUserTempShow(false);
  };

  // 删除定向模板表格列
  const columns = [
    {
      title: "模板名称",
      dataIndex: "template_name",
      key: "template_name",
    },
    {
      title: "添加时间",
      dataIndex: "create_time",
      key: "create_time",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "操作",
      dataIndex: "opration",
      key: "opration",
      render: (_, record) => (
        <Button
          type="primary"
          danger
          onClick={() => onHandleDeleteTemp(record)}
        >
          删除
        </Button>
      ),
    },
  ];

  // 处理删除定向模板
  const onHandleDeleteTemp = (record) => {
    confirm({
      title: "确定删除该用户定向模板？",
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        const res = await deleteUserTemplate({ id: record?.id });
        if (res?.code === 200) {
          message.success(record?.template_name + " 用户定向模板已删除", 2);
          getUserTemp();
        }
      },
    });
  };

  // 处理保存用户模板
  const onSaveUserTemp = async (value) => {
    setUserLoading(true);
    tempForm
      .validateFields()
      .then(async () => {
        let params = { ...value, ...curValue };
        const res = await doSaveUserTemplate(params);
        if (res?.code === 200) {
          message.success("用户定向模板保存成功", 2);
          closeUserTemp();
          getUserTemp();
        }
      })
      .catch(() => {
        return;
      });
  };

  // 提交表单
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        setNextLoading(true);
        const values = form.getFieldValue();
        if (budgetValue.schedule_time === "assign") {
          delete budgetValue.schedule_time;
          budgetValue.schedule_time = mScheduleValue.schedule_time;
        } else {
          delete budgetValue.schedule_time;
          budgetValue.schedule_time = "";
        }
        if (
          budgetValue.bid_type === "NO_BID" &&
          budgetValue.budget_mode === "BUDGET_MODE_INFINITE" &&
          values["budget_optimize_switch"] === "ON"
        ) {
          message.error("竞价策略最大转化 (NO_BiD), 请设置项目预算", 2);
          setNextLoading(false);
          return false;
        }

        if (budgetValue.budget_mode === "BUDGET_MODE_INFINITE") {
          delete budgetValue.budget;
        }

        if (role.includes("pitcher") || role.includes("agent_pitcher")) {
          if (selectedRowKeys.length === 0) {
            message.error("请选择商品", 2);
            setNextLoading(false);
            return false;
          }
        }

        if (values?.product_setting && values?.product_setting === "SINGLE") {
          if (selectedRowKeys.length === 0) {
            message.error("已开启产品库，请选择商品", 2);
            setNextLoading(false);
            return false;
          }
          let rows = selectedRowKeys[0].split("@");
          values.product_id = rows[0];
          values.platform_product_name = rows[1];
          values.product_platform_id = rows[2];
        }

        if (values?.delivery_mode === "MANUAL" && values?.project_roi_goal) {
          values.project_roi_goal = 0;
          values.project_cpa_bid = 0;
        }

        // 批量小程序广告时，广告账户落地页是否已设置
        if (landing === "MICRO_GAME") {
          const res = await landingCheck({ change_id: values.change_id });
          if (res?.code === 200) {
            const { data } = res;
            values.external_url_list = data;
            setNextLoading(false);
          } else {
            setNextLoading(false);
            return false;
          }
        }

        if (
          values.micro_promotion_type === "BYTE_APP" &&
          values.delivery_mode === "PROCEDURAL" &&
          values.budget_mode === "BUDGET_MODE_INFINITE"
        ) {
          message.error("抖小项目预算仅支持日预算", 2);
          setNextLoading(false);
          return false;
        }
        if (showBidCap() === 1 && !values?.project_cpa_bid) {
          message.error("项目出价不能为空", 2);
          setNextLoading(false);
          return false;
        }

        if (
          values.hasOwnProperty("project_cpa_bid") &&
          values.project_cpa_bid !== "" &&
          (parseFloat(values.project_cpa_bid) < 0.01 ||
            parseFloat(values.project_cpa_bid) > 10000)
        ) {
          message.error("抖小项目出价取值范围: 0.01-10000元", 2);
          setNextLoading(false);
          return false;
        }

        if (values?.product_setting === "NO_MAP" && !values?.project_cpa_bid) {
          message.error("项目未出价，请选择关联产品库", 2);
          setNextLoading(false);
          return false;
        }

        if (
          deliveryMode === "PROCEDURAL" &&
          values?.project_roi_goal &&
          values?.project_roi_goal > 5
        ) {
          message.error("ROI系数, 大于0, 不超过5", 2);
          setNextLoading(false);
          return false;
        }

        let subData = {
          landing_type: landing,
          inventory_type: checkedList,
          ...values,
          ...curValue,
          ...budgetValue,
          account_nums: accountNums,
        };
        props.onSubmit(subData);
        // 同步拉取下广告主锚点
        asyncAdvertiseAnchor({ change_id: subData.change_id });
      })
      .catch((e) => {
        let errs = e?.errorFields;
        let messages = errs.map((err) => {
          return err.errors[0];
        });
        message.error(messages[0], 2);
        return;
      });
  };

  const platformChange = (e) => {
    let value = e.target.value;
    setPlatformSelectType(value);
    setSelectedRowKeys([]);
  };

  // 关闭产品库弹窗
  const closeproductPlatform = () => {
    setProductPlatformShow(false);
    platformForm.resetFields();
    setProductList([]);
  };

  // 选择商品库弹窗
  const selectProductPlatform = async () => {
    let changeId = form.getFieldValue("change_id") || 0;
    setProductPlatformShow(true);
    const res = await getProductPlatform({ change_id: changeId });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.list?.map((item) => {
        return { label: item?.name, value: item?.platform_id };
      });
      setPlatformOptions(list);
    }
  };

  // 商品库切换
  const changeSelect = async (value) => {
    let changeId = form.getFieldValue("change_id") || 0;
    setPlatformLoading(true);
    const res = await getProductList({
      change_id: changeId,
      platform_id: value,
    });
    if (res?.code === 200) {
      setPlatformLoading(false);
      const { data } = res;
      const list = data?.list?.map((item) => {
        item.key = item.product_id + "@" + item.name + "@" + item.platform_id;
        return item;
      });
      setProductList(list);
    }
  };

  // 选中商品
  const selectRows = (rows) => {
    if (rows.length > 1) return message.error("只能选择一个商品", 2);
    setSelectedRowKeys(rows);
  };

  const productSelected = () => {
    if (selectedRowKeys.length === 0) return message.error("请选择商品", 2);
    closeproductPlatform();
  };

  // 返回上一步时的数据回显
  const getReturnPreData = (data) => {
    if (data?.product_id && data?.product_platform_id) {
      let productData =
        data?.product_id +
        "@" +
        data?.platform_product_name +
        "@" +
        data?.product_platform_id;
      setSelectedRowKeys([productData]);
    }
    getChangeList(data?.landing_type);
    setLanding(data?.landing_type);
    setInventoryCatalog(data?.inventory_catalog);
    setPlatformSelectType(data?.product_setting);
    setCurValue({
      district: data?.district,
      city: data?.city,
      location_type: data?.location_type,
      age: data?.age,
      gender: data?.gender,
      device_type: data?.device_type,
      device_brand: data?.device_brand,
      retargeting_tags_exclude: data?.retargeting_tags_exclude,
      retargeting_tags_include: data?.retargeting_tags_include,
      platform: data?.platform,
      ac: data?.ac,
      hide_if_converted: data?.hide_if_converted,
      hide_if_exists: data?.hide_if_exists,
    });
    setBudgetValue({
      ...budgetValue,
      bid_type: data?.bid_type || "CUSTOM",
      schedule_time: data?.schedule_time ? "assign" : "unlimited",
      budget_mode: data?.budget_mode,
      schedule_type: data?.schedule_type || "SCHEDULE_FROM_NOW",
      range_date: [dayjs(data?.start_time), dayjs(data?.end_time)],
      micro_promotion_type: data?.micro_promotion_type || "WECHAT_APP",
      delivery_mode: data?.delivery_mode || "MANUAL",
    });
    setMScheduleValue({ schedule_time: data?.schedule_time });
    setDeepBidType(data?.deep_bid_type);
    form.setFieldsValue(data);
  };

  // 项目出价是否显示
  const showBidCap = () => {
    let budgetShow = 1; // 显示出价
    if (
      budgetValue.bid_type === "NO_BID" ||
      externalAction === "AD_CONVERT_TYPE_ACTIVE" ||
      budgetValue?.delivery_mode === 'MANUAL'
    ) {
      budgetShow = 0
    } 
    return budgetShow
  };

  return (
    <>
      <Card
        title={<Meta title="创建项目" />}
        styles={{ header: { paddingTop: 10, paddingBottom: 10 } }}
        style={{ border: "1px solid transparent" }}
      >
        <Form
          layout="vertical"
          initialValues={{
            inventory_catalog: inventoryCatalog,
            marketing_goal: "VIDEO_AND_IMAGE",
            micro_promotion_type: "WECHAT_APP",
            external_action: "AD_CONVERT_TYPE_PAY",
            // budget_mode: 'BUDGET_MODE_DAY'
            delivery_mode: deliveryMode,
            product_setting: "NO_MAP",
            schedule_type: "SCHEDULE_FROM_NOW",
            bid_type: "CUSTOM",
            project_operation: "ENABLE",
            budget_optimize_switch: budgetValue?.budget_optimize_switch,
          }}
          form={form}
        >
          <Form.Item label="推广目的" name="landing_type">
            <CheckedCard
              options={options}
              onChecked={onChecked}
              landingType={props?.projectData?.landing_type}
            />
          </Form.Item>
          <Form.Item
            label="营销场景"
            name="marketing_goal"
            tooltip={{
              title:
                "营销场景指营销行为发生的具体场景，不同场景会对应不同推广能力",
              icon: <InfoCircleOutlined />,
              placement: "topLeft",
            }}
            rules={[{ required: true, message: "选择营销场景" }]}
          >
            <Radio.Group>
              <Radio.Button buttonStyle="solid" value="VIDEO_AND_IMAGE">
                短视频/图片
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          {landing === "MICRO_GAME" ? (
            <Card title="投放内容与目标">
              <Form.Item label="投放内容" name="micro_promotion_type">
                <Radio.Group
                  options={[
                    { label: "微信小程序", value: "WECHAT_APP" },
                    { label: "字节小程序", value: "BYTE_APP" },
                  ]}
                  optionType="button"
                  onChange={onPromotionTypeChange}
                />
              </Form.Item>
              <Form.Item label="优化目标" name="external_action">
                <Radio.Group
                  options={[
                    { label: "付费", value: "AD_CONVERT_TYPE_PAY" },
                    {
                      label: "关键行为",
                      value: "AD_CONVERT_TYPE_GAME_ADDICTION",
                    },
                    {
                      label: "激活",
                      value: "AD_CONVERT_TYPE_ACTIVE",
                    },
                  ]}
                  optionType="button"
                  onChange={actionChange}
                />
              </Form.Item>
            </Card>
          ) : (
            ""
          )}
          {externalAction === "AD_CONVERT_TYPE_PAY" ? (
            <>
              <Form.Item label="深度优化方式" name="deep_bid_type">
                <Select
                  options={[
                    { label: "ROI系数", value: "ROI_COEFFICIENT" },
                    { label: "无深度优化", value: "DEEP_BID_DEFAULT" },
                  ]}
                  allowClear
                  placeholder="请选择"
                  onChange={onDeepTypeChange}
                  style={{ width: "60%" }}
                />
              </Form.Item>
              {deepBidAction === "ROI_COEFFICIENT" ? (
                <Form.Item label="深度优化目标" name="deep_external_action">
                  <Radio.Group
                    optionType="button"
                    options={[
                      {
                        label: "付费ROI",
                        value: "AD_CONVERT_TYPE_PURCHASE_ROI",
                      },
                    ]}
                  />
                </Form.Item>
              ) : (
                ""
              )}
            </>
          ) : externalAction === "AD_CONVERT_TYPE_ACTIVE" ? (
            <>
              <Form.Item label="深度优化目标" name="deep_external_action">
                <Select
                  options={[
                    { label: "广告变现ROI", value: "AD_CONVERT_TYPE_LT_ROI" },
                  ]}
                  style={{ width: "60%" }}
                />
              </Form.Item>
              <Form.Item
                label="深度优化方式"
                name="deep_bid_type"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  optionType="button"
                  options={[
                    {
                      label: "ROI系数",
                      value: "ROI_COEFFICIENT",
                    },
                  ]}
                />
              </Form.Item>
            </>
          ) : (
            ""
          )}

          {landing === "THIRD_EXTERNAL" ||
          landing === "LINK" ||
          landing === "MICRO_GAME" ? (
            <Form.Item
              label="投放模式"
              name="delivery_mode"
              rules={[{ required: true, message: "请选择投放模式" }]}
              onChange={deliveryModeChange}
            >
              <Radio.Group
                options={[
                  { label: "手动投放", value: "MANUAL" },
                  { label: "自动投放", value: "PROCEDURAL" },
                ]}
                optionType="button"
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            label={
              landing === "APP"
                ? "应用转化"
                : landing === "THIRD_EXTERNAL"
                ? "线索转化"
                : landing === "QUICK_APP"
                ? "快应用转化"
                : "小程序转化"
            }
            name="change_id"
            rules={[{ required: true, message: "请选择转化" }]}
          >
            <Select
              allowClear
              style={{ width: "60%" }}
              options={transformOptions}
              showSearch
              filterOption={filterOption}
              onChange={onHandleChange}
            />
          </Form.Item>
          {budgetValue?.micro_promotion_type === "BYTE_APP" ? (
            <Form.Item
              label="字节小程序事件资产"
              name="instance_id"
              rules={[{ required: true, message: "请选择字节小程序事件资产" }]}
            >
              <Select
                placeholder="请选择"
                allowClear
                options={byteAssets}
                style={{ width: "60%" }}
                onChange={onByteChange}
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            label="项目名称"
            name="project_name"
            rules={[{ required: true, message: "请输入项目名称" }]}
          >
            <Input
              placeholder="请输入项目名称"
              style={{ width: "60%" }}
              allowClear
            />
          </Form.Item>
          {landing === "LINK" || landing === "THIRD_EXTERNAL" ? (
            <Form.Item
              label="投放内容"
              name="asset_type"
              rules={[{ required: true, message: "请选择落地页类型" }]}
            >
              <Radio.Group>
                <Radio.Button buttonStyle="solid" value="ORANGE">
                  橙子落地页
                </Radio.Button>
                <Radio.Button buttonStyle="solid" value="THIRDPARTY">
                  自研落地页
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : (
            ""
          )}
          {landing === "APP" ? (
            <>
              <Form.Item label="子目标" name="app_promotion_type">
                <Radio.Group>
                  <Radio.Button buttonStyle="solid" value="DOWNLOAD">
                    应用下载
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="下载链接"
                name="download_url"
                rules={[{ required: true, message: "请选择下载链接" }]}
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                label="下载方式"
                name="download_type"
                rules={[{ required: true, message: "请输入项目名称" }]}
              >
                <Radio.Group>
                  <Radio.Button buttonStyle="solid" value="EXTERNAL_URL">
                    落地页下载
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
          ) : (
            ""
          )}
          <Form.Item label="投放位置" name="inventory_catalog">
            <Radio.Group
              options={
                landing !== "THIRD_EXTERNAL"
                  ? [
                      { label: "首选媒体", value: "MANUAL" },
                      { label: "通投智选", value: "UNIVERSAL_SMART" },
                    ]
                  : [{ label: "通投智选", value: "UNIVERSAL_SMART" }]
              }
              onChange={inventoryChange}
              optionType="button"
            />
          </Form.Item>
          {inventoryCatalog === "MANUAL" ? (
            <Form.Item label="媒体选择">
              <Card width={500}>
                <Row>
                  <Col span={24} style={{ marginBottom: 15 }}>
                    <Checkbox onChange={inventoryTypeChange} checked={checkAll}>
                      全选
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox.Group
                      style={{ width: "100%" }}
                      options={mediaOptions}
                      value={checkedList}
                      onChange={onSingleChange}
                    />
                  </Col>
                </Row>
              </Card>
            </Form.Item>
          ) : (
            ""
          )}

          <Form.Item
            label={
              <Space>
                用户定向
                <Select
                  placeholder="选择用户定向模板"
                  allowClear
                  style={{ width: 200 }}
                  options={templateOptions}
                  showSearch
                  filterOption={filterOption}
                  onChange={tempChange}
                />
                <Button type="primary" danger onClick={deleteTemp}>
                  删除用户定向模板
                </Button>
              </Space>
            }
          >
            <Card>
              <>
                <AudienceTarget
                  curValue={curValue}
                  isUpdate={props?.isUpdate}
                  receive={receive}
                  extensions={extensions}
                />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={saveUserTemplate}>
                    添加用户定向模板
                  </Button>
                </div>
              </>
            </Card>
          </Form.Item>
          <Form.Item
            label="监测链接"
            name="action_track_url"
            // rules={[{ required: true, message: "监测链接不能为空" }]}
          >
            <Input readOnly />
          </Form.Item>
          <Card title="产品库" style={{ marginBottom: 10 }}>
            <Form.Item
              label="产品库"
              name="product_setting"
              rules={[
                {
                  required:
                    role.includes("pitcher") || role.includes("agent_pitcher")
                      ? true
                      : false,
                  message: "请选择产品库",
                },
              ]}
            >
              <Radio.Group
                options={[
                  { label: "不启用", value: "NO_MAP" },
                  { label: "启用", value: "SINGLE" },
                ]}
                optionType="button"
                onChange={platformChange}
              />
            </Form.Item>
            {platformSelectType === "SINGLE" ? (
              <Button type="primary" onClick={selectProductPlatform}>
                选择产品库
              </Button>
            ) : (
              ""
            )}
            {selectedRowKeys.length > 0 ? (
              <div
                style={{
                  marginTop: 10,
                  backgroundColor: "#F5F5F5",
                  fontWeight: "bold",
                }}
              >
                {selectedRowKeys[0].split("@")[1]}
              </div>
            ) : (
              ""
            )}
          </Card>
          <Form.Item label="排期与预算">
            <Card type="inner">
              <Form.Item
                label={
                  <Typography.Text strong style={{ fontSize: 14.5 }}>
                    投放时间
                  </Typography.Text>
                }
                name="schedule_type"
                initialValue={budgetValue.schedule_type}
              >
                <Radio.Group
                  onChange={scheduleTypeChange}
                  value={budgetValue.schedule_type}
                >
                  <Radio.Button value="SCHEDULE_FROM_NOW">
                    从今天起长期投放
                  </Radio.Button>
                  <Radio.Button value="SCHEDULE_START_END">
                    设置开始和结束日期
                  </Radio.Button>
                </Radio.Group>
                {budgetValue.schedule_type === "SCHEDULE_START_END" ? (
                  <Form.Item style={{ marginTop: 20 }}>
                    <RangePicker
                      style={{ width: 300 }}
                      disabledDate={disabledDate}
                      onChange={rangeDateChange}
                      value={budgetValue?.range_date}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </Form.Item>
              <Form.Item
                label={
                  <Typography.Text strong style={{ fontSize: 14.5 }}>
                    投放时段
                  </Typography.Text>
                }
                name="schedule_time"
              >
                <Radio.Group
                  onChange={scheduleTimeChange}
                  value={budgetValue.schedule_time}
                >
                  <Radio.Button value="unlimited">不限</Radio.Button>
                  <Radio.Button value="assign">指定时间段</Radio.Button>
                </Radio.Group>
                {budgetValue.schedule_time === "assign" ? (
                  <Form.Item>
                    <MSchedule
                      curValue={mScheduleValue}
                      receive={receiveMSchedule}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </Form.Item>
              <Form.Item
                label={
                  <Typography.Text strong style={{ fontSize: 14.5 }}>
                    竞价策略
                  </Typography.Text>
                }
                name="bid_type"
              >
                <Radio.Group
                  onChange={bidTypeChange}
                  value={budgetValue.bid_type}
                >
                  <Radio.Button value="CUSTOM">稳定成本</Radio.Button>
                  <Radio.Button value="UPPER_CONTROL">控制成本</Radio.Button>
                  <Radio.Button value="NO_BID">最大转化</Radio.Button>
                </Radio.Group>
              </Form.Item>
              {budgetValue.bid_type === "CUSTOM" ? (
                <Form.Item
                  label={
                    <Typography.Text strong style={{ fontSize: 14.5 }}>
                      投放速度
                    </Typography.Text>
                  }
                  name="bid_speed"
                >
                  <Radio.Group onChange={bidSpeedChange} defaultValue="FAST">
                    <Radio.Button value="BALANCE">匀速</Radio.Button>
                    <Radio.Button value="FAST">加速</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              ) : (
                ""
              )}
              {budgetValue.bid_type === "NO_BID" ? (
                <Form.Item
                  label={
                    <Typography.Text strong style={{ fontSize: 14.5 }}>
                      预算择优分配
                    </Typography.Text>
                  }
                  name="budget_optimize_switch"
                >
                  <Radio.Group onChange={budgetSwitchChange}>
                    <Radio.Button value="OFF">不开启</Radio.Button>
                    <Radio.Button value="ON">开启</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              ) : (
                ""
              )}
              <Form.Item
                label={
                  <Typography.Text strong style={{ fontSize: 14.5 }}>
                    项目预算
                  </Typography.Text>
                }
                name="budget_mode"
                initialValue={budgetValue.budget_mode}
              >
                <Radio.Group
                  onChange={budgetModeChange}
                  options={
                    landing !== "THIRD_EXTERNAL"
                      ? [
                          { label: "不限", value: "BUDGET_MODE_INFINITE" },
                          { label: "设置预算", value: "BUDGET_MODE_DAY" },
                        ]
                      : [{ label: "设置预算", value: "BUDGET_MODE_DAY" }]
                  }
                  optionType="button"
                />
              </Form.Item>
              {budgetValue.budget_mode === "BUDGET_MODE_DAY" ? (
                <Form.Item
                  label={
                    <Typography.Text strong style={{ fontSize: 14.5 }}>
                      日预算
                    </Typography.Text>
                  }
                  name="project_budget"
                  rules={[
                    {
                      required: true,
                      message: `${
                        budgetValue.bid_type === "NO_BID" &&
                        budgetValue?.budget_optimize_switch === "OFF"
                          ? "请输入预算, 不少于100元, 不超过9999999.99元"
                          : "请输入预算, 不少于300元, 不超过9999999.99元"
                      }`,
                    },
                  ]}
                >
                  <InputNumber
                    addonAfter="元"
                    style={{ width: 250 }}
                    min={
                      budgetValue.bid_type === "NO_BID" &&
                      budgetValue?.budget_optimize_switch === "OFF"
                        ? 100
                        : 300
                    }
                    max={9999999.99}
                    value={budgetValue.budget}
                    onChange={budgetChange}
                    placeholder={
                      budgetValue.bid_type === "NO_BID" &&
                      budgetValue?.budget_optimize_switch === "OFF"
                        ? "取值范围: 100-99999.99元"
                        : "取值范围: 300-99999.99元"
                    }
                    changeOnWheel={false}
                  />
                </Form.Item>
              ) : (
                ""
              )}
              {showBidCap() === 1 ? (
                <Form.Item
                  label={
                    <Typography.Text strong style={{ fontSize: 14.5 }}>
                      项目出价
                    </Typography.Text>
                  }
                  name="project_cpa_bid"
                >
                  <Input
                    type="number"
                    addonAfter="元"
                    allowClear
                    style={{ width: 250 }}
                    min={0.01}
                    max={10000}
                    placeholder="取值范围：0.01-10000元"
                  />
                </Form.Item>
              ) : (
                ""
              )}
              {deepBidAction === "ROI_COEFFICIENT" &&
              (deliveryMode === "PROCEDURAL" ||
                budgetValue?.delivery_mode === "PROCEDURAL") ? (
                <Form.Item
                  label={
                    <Typography.Text strong style={{ fontSize: 14.5 }}>
                      ROI系数
                    </Typography.Text>
                  }
                  name="project_roi_goal"
                  rules={[
                    {
                      required: true,
                      message: "请输入ROI系数, 大于0, 不超过5",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    max={5}
                    placeholder="请输入"
                    style={{ width: 250 }}
                  />
                </Form.Item>
              ) : (
                ""
              )}

              <Form.Item
                label={
                  <Typography.Text strong style={{ fontSize: 14.5 }}>
                    付费方式
                  </Typography.Text>
                }
                name="pricing"
              >
                <Radio.Group defaultValue={budgetValue.pricing}>
                  <Radio.Button value="PRICING_OCPM">
                    按展示付费(oCPM)
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Form.Item>

          <Form.Item
            label="项目状态"
            name="project_operation"
            rules={[{ required: true }]}
          >
            <Card title="项目状态设置">
              <Form.Item name="project_operation">
                <Radio.Group>
                  <Radio.Button value="ENABLE">开启</Radio.Button>
                  <Radio.Button value="DISABLE">关闭</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={nextLoading}
              >
                下一步新建广告
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      {/* 保存用户定向模板弹窗 */}
      <Modal
        open={userTempShow}
        title="保存用户定向模板"
        onCancel={closeUserTemp}
        footer={null}
      >
        <Form onFinish={onSaveUserTemp} form={tempForm}>
          <Form.Item
            label="模板备注"
            name="template_name"
            rules={[{ required: true, message: "模板备注不能为空" }]}
          >
            <Input placeholder="输入模板备注名称" allowClear />
          </Form.Item>
          <Form.Item label colon={false} labelCol={{ span: 17 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={userLoading}>
                确定
              </Button>
              <Button onClick={closeUserTemp}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {/* 删除用户定向模板弹窗 */}
      <Modal
        open={deleteUserTempShow}
        onCancel={closeDeleteUserTemp}
        cancelText="关闭"
        width={800}
        style={{ height: 580, overflow: "auto", border: "none" }}
      >
        <Card title={<DatePicker onChange={templateDateChange} />}>
          <Table
            dataSource={templateList}
            columns={columns}
            pagination={false}
          />
        </Card>
      </Modal>

      {/* 产品库弹窗 */}
      <Modal
        open={productPlatformShow}
        onCancel={closeproductPlatform}
        width={650}
        onOk={productSelected}
      >
        <Form form={platformForm}>
          <Form.Item label="产品库" name="product_platform">
            <Select
              placeholder="选择产品库"
              allowClear
              options={platformOptions}
              showSearch
              filterOption={filterOption}
              onChange={changeSelect}
              style={{ width: "95%" }}
            />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <Card
                  title="商品列表"
                  headStyle={{ backgroundColor: "#F8F8F9" }}
                  style={{ height: 250, width: 575, overflowY: "auto" }}
                >
                  <RowSelectTable
                    columns={[
                      {
                        title: "商品id",
                        dataIndex: "product_id",
                        key: "product_id",
                      },
                      {
                        title: "商品名称",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "商品描述",
                        dataIndex: "description",
                        key: "description",
                      },
                    ]}
                    selectRows={selectRows}
                    loading={platformLoading}
                    dataSource={productList}
                    pagination={false}
                  />
                </Card>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
