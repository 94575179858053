import React from 'react'
import Table from '../../../../components/TableNoSelection'
import { Card, Input, Select, message } from 'antd'
import columns from './columns'
import { useState } from 'react'
import { resultReUp } from '../../../../api/playlet'

const { Search } = Input
const { Option } = Select

export default function Index(props) {
    const [searchType, setSearchType] = useState(1)
    const {
        list, total, page, perPage, pagesize,
    } = props

    const onChange = value => {
        setSearchType(value)
    }

    const selectBefore = (
        <Select defaultValue={1} onChange={onChange}>
            <Option value={1}>OPENID</Option>
            <Option value={2}>广告ID</Option>
        </Select>
    );

    // 筛选查询
    const onSearch = value => {
        let params={}
        if (searchType === 1 && value) {
            params.openid = value
        } else if (searchType === 2 && value) {
            params.aid = value
        }
        props.onSearch(params)
    }

    // 重新回传
    const handleReUp = async record => {
        const res = await resultReUp({id: record?.id, app_type: record?.app_type})
        if (res?.code === 200) {
            message.success('重新回传成功', 1);
            props.onSearch();
        }
    }

    return (
        <Card
            extra={
                <Search
                    placeholder={searchType === 1 ? "输入OPENID" : '输入计划ID'}
                    allowClear
                    enterButton="搜索"
                    addonBefore={selectBefore}
                    onSearch={onSearch}
                />
            }
        >
            <Table
                dataSource={list}
                columns={columns({handleReUp})}
                total={total}
                page={page}
                perPage={perPage}
                pagesize={pagesize}
            />
        </Card>
    )
}
