import { Tag } from "antd"
import dayjs from "dayjs"

const addColumns = () => [
    {
        title: "用户ID",
        width: 120,
        align: "center",
        dataIndex: "id",
        key: "id",
        fixed: true
    },
    {
        title: "登录名称",
        width: 100,
        align: "center",
        dataIndex: "username",
        key: "username",
        fixed: true
    },
    {
        title: "用户名称",
        width: 120,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
    },
    {
        title: "组织架构",
        width: 180,
        align: "center",
        dataIndex: "organizations",
        key: "organizations",
        ellipsis: true,
        render: value => {
            const res = Array.isArray(value) && value.map((item) => {
                return item.name
            })
            return res?.join()
        }
    },
    {
        title: "状态",
        width: 100,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: status => (
            status === 1 ? <Tag color="green">正常</Tag> : <Tag color="red">禁用</Tag>
        )
    },
    {
        title: "创建时间",
        width: 180,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: text => (
            dayjs.unix(text).format('YYYY-MM-DD HH:mm:ss')
        )
    }
]

export default addColumns