import { Typography } from "antd";
import { Link } from "react-router-dom";

const columns = () => [
  {
    title: "账户ID",
    width: 100,
    align: "center",
    dataIndex: "advertiser_id",
    key: "advertiser_id",
    render: value => (
      <Link to={`https://ad.oceanengine.com/superior/promote-manage/project?aadvid=${value}`} target="_blank">{value}</Link>
    )
  },
  {
    title: "账户名称",
    width: 120,
    align: "center",
    dataIndex: "account_name",
    key: "account_name",
    render: (_, record) => record?.advertise?.account_name,
  },
  
  {
    title: "竞价消耗",
    width: 100,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
  },
  {
    title: "昨日同期",
    width: 100,
    align: "center",
    dataIndex: "yesterday_cost",
    key: "yesterday_cost",
  },
  {
    title: "消耗环比增长值",
    width: 100,
    align: "center",
    dataIndex: "chain_increase",
    key: "chain_increase",
  },
  {
    title: "环比增长率",
    width: 100,
    align: "center",
    dataIndex: "chain_rate",
    key: "chain_rate",
    render: (_, record) => {
      let rate = record.chain_rate;
      if(rate=='-'){
        return rate;
      }
      if (rate >= 0) {
        return <Typography.Text type="success" style={{fontSize: 12}}>{rate + '%'}</Typography.Text>
      } else {
        return <Typography.Text type="danger" style={{fontSize: 12}}>{rate + '%'}</Typography.Text>
      }
    }
  },
  {
    title: "有消耗广告数",
    width: 80,
    align: "center",
    dataIndex: "cost_ad_num",
    key: "cost_ad_num",
  },
  {
    title: "环比增长率",
    width: 80,
    align: "center",
    dataIndex: "chain_ad_increase_rate",
    key: "chain_ad_increase_rate",
    render: (_, record) => {
      let rate = record.chain_ad_increase_rate;
      if(rate=='-'){
        return rate;
      }
      if (rate >= 0) {
        return <Typography.Text type="success" style={{fontSize: 12}}>{rate + '%'}</Typography.Text>
      } else {
        return <Typography.Text type="danger" style={{fontSize: 12}}>{rate + '%'}</Typography.Text>
      }
    }
  },
  {
    title: "环比增长值",
    width: 80,
    align: "center",
    dataIndex: "chain_ad_increase_num",
    key: "chain_ad_increase_num",
  },
  {
    title: "新建广告数",
    width: 80,
    align: "center",
    dataIndex: "new_create_ad_num",
    key: "new_create_ad_num",
  },
  {
    title: "环比增长率",
    width: 80,
    align: "center",
    dataIndex: "chain_new_increase_rate",
    key: "chain_new_increase_rate",
    render: (_, record) => {
      let rate = record.chain_new_increase_rate;
      if(rate=='-'){
        return rate;
      }
      if (rate >= 0) {
        return <Typography.Text type="success" style={{fontSize: 12}}>{rate + '%'}</Typography.Text>
      } else {
        return <Typography.Text type="danger" style={{fontSize: 12}}>{rate + '%'}</Typography.Text>
      }
    }
  },
  {
    title: "新建且消耗广告数",
    width: 80,
    align: "center",
    dataIndex: "new_create_cost_num",
    key: "new_create_cost_num",
  },
];

export default columns;
