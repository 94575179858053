import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  materialDelete,
  materialList,
  handleMaterialLock,
  handleUpdateStatus,
  getMaterialDetail,
  getPitcherList,
  updateMaterialShare,
  setMaterialPublic,
  handleUpdateLabel,
  batchUpdateMaterialShare,
  handleUpdateRecommend,
  getMenuChildren,
} from "../../../../api";
import Table from "../../../../components/TableHasTotal";
import columns from "./columns";
import {
  getAdvertiserList,
  getBatchShareMaterials,
  getFilterSuCai,
  getFilterTouShou,
  getMaterialLabels,
  getTotalCost,
  materialAsyncTaskCreate,
  playletList,
} from "../../../../api/advertise";
import { deleteObject } from "../../../../lib/TosClient";
import EditableCell from "../../../../components/Editable/EditableCell";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function Local() {
  const [selectKeys, setSelectKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [conditions, setConditions] = useState([]);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [remarks, setRemarks] = useState();
  const [status, setStatus] = useState(0);
  const [modifyId, setModifyId] = useState(0);
  const [filterTouShouOptions, setFilterTouShouOptions] = useState([]);
  const [filterSucaiOptions, setFilterSucaiOptions] = useState([]);
  const [totalCost, setTotalCost] = useState(-1);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [belongModalOpen, setbelongModalOpen] = useState(false);
  const [batchShareModalOpen, setBatchShareModalOpen] = useState(false);
  const [batchPushModalOpen, setBatchPushModalOpen] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [materialLabels, setMaterialLabels] = useState([]);
  const [materialDetail, setMaterialDetail] = useState({});
  const [batchShareMaterials, setBatchShareMaterials] = useState({});
  const [batchShareMaterialsAll, setBatchShareMaterialsAll] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [belongConfirmLoading, setBelongConfirmLoading] = useState(false);
  const [showType, setShowType] = useState(1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isSuper, setIsSuper] = useState(false);
  const [isOur, setIsOur] = useState(false);
  const [role, setRole] = useState("");
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [batchShareType, setBatchShareType] = useState(1);
  const [shareMaterialIds, setShareMaterialIds] = useState([]);
  const [materialBelongDetail, setMaterialBelongDetail] = useState([]);
  const [playletOptions, setPlayletOptions] = useState([])

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [shareForm] = Form.useForm();
  const [batchShareForm] = Form.useForm();
  const [pushModalForm] = Form.useForm();
  const [belongModalForm] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);

  if (state) {
    form.setFieldsValue({book_id: state.book_id})
  }

  useEffect(() => {
    getData()
  }, []);

  // 获取视频素材列表
  const getList = async (params = {}) => {
    setLoading(true);
    if (state) {
      params.book_id = state.book_id
      getMenuChildren({key: 'material'})
    }
    const res = await materialList({ ...params, video: 1 });
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setIsSuper(data?.is_super);
      setIsOur(data?.is_our);
      setRole(data?.role);
      setLoading(false);
    }
  };

  // 获取列表的投手筛选数据
  const getFilterPitcher = async () => {
    const res = await getFilterTouShou();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterTouShouOptions(newList);
    }
  };

  // 获取列表的素材手筛选数据
  const getFilterMaterialer = async () => {
    const res = await getFilterSuCai();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterSucaiOptions(newList);
    }
  };

  // 获取广告主列表
  const getAdvertisers = async () => {
    const res = await getAdvertiserList();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setAdvertiserOptions(newList);
    }
  };

  // 获取短剧列表
  const getPlaylets = async () => {
    const res = await playletList()
    if (res?.code === 200) {
      const {data} = res
      let lists = data?.lists
      let newList = lists.map((item) => {
        return {label: item.book_name, value: item.book_id}
      })
      setPlayletOptions(newList)
    }
  }

  const getData = () => {
    Promise.all([getList(conditions),  getFilterPitcher(), getFilterMaterialer(), getLabels(), getPlaylets() ])
  }

  const layout = {
    labelCol: { xl: { span: 7 } },
    wrapperCol: { xl: { span: 16 } },
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
    },
  };

  // 表单筛选查询
  const handleSearch = async (values) => {
    let createdAt = [];
    if (values["create_time"]) {
      createdAt = values["create_time"].map((date) => {
        return date.format("YYYY-MM-DD");
      });
      values.create_time = createdAt;
    }
    setConditions(values);
    let submit = false;
    for (const key in values) {
      if (values.hasOwnProperty.call(values, key)) {
        const element = values[key];
        if (element) {
          submit = true;
        }
      }
    }
    // 只有有筛选参数时才获取总消耗
    getList(values);
    if (submit) {
      const res = await getTotalCost({ ...values, video: 1 });
      const { data } = res;
      if (res?.code === 200) {
        setTotalCost(data?.total_cost);
      }
    } else {
      setTotalCost(-1);
    }
    setPage(1);
    setPerPage(10);
  };

  // 表单重置
  const formReset = () => {
    form.resetFields()
    navigate('')
  }

  const tosMaterialDel = async (objName) => {
    await deleteObject(objName);
  };

  // 单个删除
  const materialDel = (id) => {
    confirm({
      title: "确定要删除该素材？",
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        const res = await materialDelete({ ids: [id] });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          const newList = list.filter((item) => {
            return item.id !== id;
          });
          setList(newList);
          const filtered = list.filter((item) => {
            return item.id === id;
          });
          tosMaterialDel(filtered[0]?.attach_video?.path);
        }
      },
    });
  };

  // 批量删除
  const batchDel = () => {
    confirm({
      title: "确定对选中的素材进行批量删除？",
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        const res = await materialDelete({ ids: selectKeys });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          const newList = list.filter((item) => {
            return selectKeys.indexOf(item.id) === -1;
          });
          setList(newList);
        }
      },
    });
  };

  // 素材一键转公
  const batchSetPublic = () => {
    confirm({
      title: "确定对选中的素材转为公有素材？",
      onOk: async () => {
        const res = await setMaterialPublic({ ids: selectKeys });
        if (res?.code === 200) {
          message.success("素材转化成功", 2);
          setSelectKeys([]);
          selectRows([]);
          const newList = list.map((item) => {
            if (selectKeys.includes(item.id)) {
              item.show_type = 1;
            }
            return item;
          });
          setList(newList);
        }
      },
    });
  };

  // 素材上锁|解锁
  const materialLock = (record) => {
    let title = record.lock === 1 ? "确认解锁该素材" : "确定要锁定该素材";
    confirm({
      title: title,
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        let lock = record.lock === 1 ? 0 : 1;
        const res = await handleMaterialLock({ ids: [record.id], lock });
        if (res?.code === 200) {
          let msg = record.lock === 1 ? "素材解锁成功" : "素材上锁成功";
          message.success(msg, 1);
          const newList = list.map((item) => {
            if (item.id === record.id) {
              item["lock"] = lock;
            }
            return item;
          });
          setList(newList);
        }
      },
    });
  };

  // 批量一键上锁
  const batchLock = () => {
    confirm({
      title: "确定对选中的素材进行一键上锁？",
      cancelText: "取消",
      okText: "确定",
      onOk: async () => {
        const res = await handleMaterialLock({ ids: selectKeys, lock: 1 });
        if (res?.code === 200) {
          message.success("上锁成功", 1);
          const newList = list.map((item) => {
            if (selectKeys.indexOf(item.id) !== -1) {
              item["lock"] = 1;
            }
            return item;
          });
          setList(newList);
          setSelectKeys([]);
        }
      },
    });
  };

  // 获取投手数据
  const getToushou = async () => {
    const res = await getPitcherList();
    const { data } = res;
    if (res?.code === 200) {
      const result = data?.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(result);
    }
  };

  // 获取素材标签
  const getLabels = async (params = {}) => {
    const res = await getMaterialLabels(params);
    const { data } = res;
    if (res?.code === 200) {
      let labels = data?.labels;
      let newLabels = labels.map((item) => {
        return { label: item, value: item };
      });
      setMaterialLabels(newLabels);
    }
  };

  // 素材共享|取消共享
  const materialShare = async (record) => {
    if (record?.show_type === 1) {
      message.info("该素材已是公有素材，无需共享", 2);
      return false;
    }
    setShareModalOpen(true);
    const res = await getMaterialDetail({ id: record?.id });
    if (res?.code === 200) {
      const {
        data: { material },
      } = res;
      setShowType(material?.show_type);
      shareForm.setFieldsValue({ show_type: material?.show_type });
      setMaterialDetail(material);
      let ids = material?.toushouid?.split(",");
      let newIds = ids.map((id) => {
        return parseInt(id);
      });
      getToushou();
      shareForm.setFieldsValue({ id: material?.id, toushouid: newIds });
    }
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectKeys.length > 0;

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    setConditions({ page, pageSize: size, ...conditions });
  };

  // 排序
  const tableOnChange = (pagination, filters, sorter) => {
    let field = sorter?.field;
    let orderType = sorter?.order;
    let params = {};
    if (orderType === "descend" && field) {
      params = { ...conditions, order: field + "_" + "desc" };
    } else if (orderType === "ascend" && field) {
      params = { ...conditions, order: field + "_" + "asc" };
    } else {
      params = conditions;
    }
    setConditions({
      ...params,
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
    getList({
      ...params,
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // 修改备注 | 状态
  const handleRemark = (record) => {
    setStatusModalOpen(true);
    setRemarks(record.remarks);
    setStatus(record.status);
    console.log(record);
    setModifyId(record.id);
  };

  // 修改备注|状态关闭弹窗
  const modalClose = () => {
    setStatusModalOpen(false);
    form1.resetFields();
  };

  const statusOptions = [
    { label: "关闭", value: 0 },
    { label: "开启", value: 1 },
  ];

  // 处理修改备注状态
  const handleUpdateRemark = async () => {
    const values = form1.getFieldValue();
    const res = await handleUpdateStatus(values);
    if (res?.code === 200) {
      message.success("修改成功", 1);
      const newList = list.map((item) => {
        if (item.id === values.id) {
          item.remarks = values.remarks;
          item.status = values.status;
        }
        return item;
      });
      setList(newList);
      modalClose();
    }
  };

  // 关闭共享素材弹窗
  const shareModalClose = () => {
    setShareModalOpen(false);
    setConfirmLoading(false);
    shareForm.resetFields();
  };

  // 处理共享素材编辑
  const handleUpdateShare = async () => {
    setConfirmLoading(true);
    let values = shareForm.getFieldsValue();
    let params = {
      ids: [values?.id],
      show_type: values?.show_type,
    };
    if (values?.show_type === 2) {
      params.toushou_ids = values?.toushouid;
    }
    const res = await updateMaterialShare(params);
    if (res?.code === 200) {
      message.success("素材共享成功", 2);
      setTimeout(() => {
        shareModalClose();
        getList(conditions);
      }, 1000);
    }
  };

  // 关闭批量共享弹窗
  const batchShareModalCLose = () => {
    batchShareForm.resetFields();
    setSelectKeys([]);
    setBatchShareModalOpen(false);
    setConfirmLoading(false);
  };

  // 处理批量共享弹窗
  const handleUpdatebatchShare = async () => {
    setConfirmLoading(true);
    let values = batchShareForm.getFieldValue("toushouid");
    const res = await batchUpdateMaterialShare({
      toushou_ids: values,
      ids: batchShareType === 1 ? selectKeys : shareMaterialIds,
    });
    if (res?.code === 200) {
      setTimeout(() => {
        message.success("批量素材共享成功");
        batchShareModalCLose();
        getList(conditions);
      }, 1000);
    } else {
      setConfirmLoading(false);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const belongChange = (e) => {
    let value = e.target.value;
    setShowType(value);
  };

  // 处理label标签
  const handleSave = async (row) => {
    if (!row.label) {
      return false;
    }
    setLoading(true);
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    let label = row?.label.split(",");
    const res = await handleUpdateLabel({ id: row.id, label });
    if (res?.code === 200) {
      setList(newData);
    }
    setLoading(false);
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  // 重新获取表格列字段
  const getColumns = ({
    materialDel,
    materialLock,
    materialShare,
    handleRemark,
    isSuper,
    isOur,
    onRecommendChange,
    updateShowType,
  }) => {
    let newColumns = columns({
      materialDel,
      materialLock,
      materialShare,
      handleRemark,
      isSuper,
      isOur,
      onRecommendChange,
      updateShowType,
    });
    return newColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: "label",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  };

  // 是否为重点素材
  const onRecommendChange = (record, value) => {
    handleUpdateRecommend({ id: record?.id, recommend: value });
  };

  // 批量共享素材
  const batchDispatch = async () => {
    let filtered = list.filter((item) => {
      return selectKeys.includes(item.id) && item.show_type === 2;
    });
    if (filtered.length === 0) {
      message.error("已选素材全是公有素材");
      return false;
    }
    setBatchShareMaterials(filtered);
    setBatchShareModalOpen(true);
    // 获取分配的投手信息
    const res = await getPitcherList();
    if (res?.code === 200) {
      const { data } = res;
      let newData = data.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newData);
    }
  };

  // 批量推送素材到头条(生成推送任务)
  const batchPushAd = () => {
    setBatchPushModalOpen(true);
    getAdvertisers();
  };

  // 关闭批量推送素材到头条弹窗
  const batchPushModalCLose = () => {
    setBatchPushModalOpen(false);
    pushModalForm.resetFields();
    setConfirmLoading(false);
    setSelectKeys([]);
  };

  // 处理批量素材推送
  const handlebatchPush = () => {
    pushModalForm
      .validateFields()
      .then(async () => {
        setConfirmLoading(true);
        const values = pushModalForm.getFieldsValue();
        let params = { ...values, material_ids: selectKeys };
        const res = await materialAsyncTaskCreate(params);
        if (res?.code === 200) {
          message.success(
            "推送同步任务已生成，可到同步素材查看任务完成情况",
            2
          );
          setTimeout(() => {
            batchPushModalCLose();
          }, 1000);
        } else {
          message.success("添加失败", 2);
          setLoading(false);
        }
      })
      .catch(() => {});
  };

  const onSelectChange = async (e) => {
    let value = e.target.value;
    setBatchShareType(value);
    if (value === 2) {
      const res = await getBatchShareMaterials({ ...conditions });
      if (res?.code === 200) {
        const { data } = res;
        let lists = data?.lists;
        setBatchShareMaterialsAll(lists);
        let materialIds = lists?.map((item) => {
          return item?.id;
        });
        setShareMaterialIds(materialIds);
      }
    }
  };

  // 打开素材归属弹窗
  const updateShowType = (record) => {
    let newIds = [];
    setShowType(record?.show_type);
    if (record?.show_type === 2) {
      let ids = record?.toushouid?.split(",");
      newIds = ids.map((id) => {
        return parseInt(id);
      });
    }
    belongModalForm.setFieldsValue({
      show_type: record?.show_type,
      id: record?.id,
      toushouid: newIds,
    });
    setbelongModalOpen(true);
    setMaterialBelongDetail(record);
    getToushou();
  };

  // 关闭素材归属弹窗
  const belongModalClose = () => {
    setbelongModalOpen(false);
    setShowType(0);
    setMaterialBelongDetail([]);
    belongModalForm.resetFields();
    setBelongConfirmLoading(false);
  };

  // 素材公有|私有切换
  const onShowTypeChange = (e) => {
    let value = e.target.value;
    setShowType(value);
  };

  // 素材归属重置
  const handleUpdateBelongTo = async () => {
    setBelongConfirmLoading(true);
    let values = belongModalForm.getFieldsValue();
    let params = {
      ids: [values?.id],
      show_type: values?.show_type,
    };
    if (values?.show_type === 2) {
      params.toushou_ids = values?.toushouid;
    }
    const res = await updateMaterialShare(params);
    if (res?.code === 200) {
      message.success("素材归属重置成功", 2);
      setTimeout(() => {
        belongModalClose();
        getList(conditions);
      }, 1000);
    }
  };

  return (
    <div className="local">
      <Card
        style={{ marginBottom: 10 }}
        styles={{ body: { padding: 0, paddingTop: 5 } }}
        size="small"
      >
        <Form {...layout} form={form} onFinish={handleSearch}>
          <Row gutter={4}>
            <Col xl={{ span: 8 }}>
              <Form.Item name="create_time" label="上传日期">
                <RangePicker />
              </Form.Item>
            </Col>
            <Col xl={{ span: 6 }}>
              <Form.Item name="uploader" label="上传用户">
                <Select
                  showSearch
                  allowClear
                  placeholder="请选择上传用户"
                  options={filterSucaiOptions}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 5 }}>
              <Form.Item name="id" label="素材ID">
                <Input placeholder="素材ID" allowClear />
              </Form.Item>
            </Col>
            <Col xl={{ span: 5 }}>
              <Form.Item
                label="归属"
                name="show_type"
                labelCol={{ xl: { span: 9 } }}
              >
                <Select
                  allowClear
                  placeholder="选择素材属性"
                  options={[
                    { label: "公有", value: 1 },
                    { label: "私有", value: 2 },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col xl={{ span: 8 }}>
              <Form.Item name="title" label="素材名称">
                <Input placeholder="素材名称" allowClear />
              </Form.Item>
            </Col>
            <Col xl={{ span: 6 }}>
              <Form.Item name="toushou_id" label="投手筛选">
                <Select
                  allowClear
                  showSearch
                  placeholder="请选择投手"
                  options={filterTouShouOptions}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="头条素材ID" name="creative_id">
                <Input allowClear placeholder="输入头条素材id" />
              </Form.Item>
            </Col>
            <Col xl={{ span: 5 }}>
              <Form.Item
                label="转化"
                name="convert"
                labelCol={{ xl: { span: 9 } }}
              >
                <Select
                  allowClear
                  placeholder="筛选是否转化素材"
                  options={[
                    { label: "转化", value: 1 },
                    { label: "非转化", value: 0 },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={4}>
            <Col span={8}>
              <Form.Item
                name="label"
                label="素材标签"
                // style={{ marginLeft: -325 }}
              >
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="选择素材标签"
                  options={materialLabels}
                  filterOption={filterOption}
                  autoClearSearchValue={false}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="短剧筛选" name="book_id">
                <Select
                  placeholder="选择短剧"
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  options={playletOptions}
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 5 }}>
              <Form.Item name="is_recommend" label="加星">
                <Select
                  placeholder="素材是否加星"
                  allowClear
                  options={[
                    { label: "是", value: 1 },
                    { label: "否", value: 0 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 5 }}>
              <Form.Item label colon={false}>
                <Space>
                  <Button type="primary" htmlType="submit" size="small">
                    查询
                  </Button>
                  <Button
                    onClick={formReset}
                    size="small"
                  >
                    重置
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label colon={false} {...formItemLayout}>
            <div style={{ display: "flex" }}>
              {totalCost >= 0 ? (
                <div>
                  <Typography.Text type="danger">
                    总消耗：{totalCost}
                  </Typography.Text>
                </div>
              ) : (
                ""
              )}
            </div>
          </Form.Item>
        </Form>
      </Card>
      <Card
        title={
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => navigate("/admin/material/video/add_video")}
            >
              添加视频素材
            </Button>
            {role.includes("material") ? (
              <Button size="small" type="link">
                <Link to="/admin/material/video/public">公有转化素材</Link>
              </Button>
            ) : (
              ""
            )}
            <Button
              type="primary"
              size="small"
              disabled={!hasSelected}
              onClick={batchLock}
            >
              一键上锁
            </Button>
            <Button
              type="primary"
              size="small"
              danger
              onClick={batchDel}
              disabled={!hasSelected}
            >
              批量删除
            </Button>
            <Button
              type="primary"
              size="small"
              ghost
              onClick={batchSetPublic}
              disabled={!hasSelected}
            >
              一键转公有
            </Button>
            <Button
              type="primary"
              size="small"
              ghost
              disabled={!hasSelected}
              onClick={batchDispatch}
            >
              批量共享素材
            </Button>
            <Button
              type="primary"
              size="small"
              ghost
              disabled={!hasSelected}
              onClick={batchPushAd}
            >
              批量推送到头条
            </Button>
          </Space>
        }
      >
        <Table
          size="small"
          components={components}
          dataSource={list}
          columns={getColumns({
            materialDel,
            materialLock,
            materialShare,
            handleRemark,
            isSuper,
            isOur,
            onRecommendChange,
            updateShowType,
          })}
          selectRows={selectRows}
          loading={loading}
          pagesize={pagesize}
          page={page}
          perPage={perPage}
          total={total}
          onChange={tableOnChange}
        />
      </Card>

      {/* 修改备注|状态 */}
      <Modal
        title="修改备注|状态"
        open={statusModalOpen}
        onCancel={modalClose}
        onOk={handleUpdateRemark}
      >
        <Form
          initialValues={{ remarks: remarks, status, id: modifyId }}
          onFinish={handleUpdateRemark}
          form={form1}
        >
          <Form.Item label="备注" name="remarks">
            <Input />
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Radio.Group options={statusOptions} />
          </Form.Item>
          <Form.Item name="id" colon={false} style={{ display: "none" }}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* 共享素材 */}
      <Modal
        title={
          <>
            共享素材
            <Divider />
          </>
        }
        open={shareModalOpen}
        onCancel={shareModalClose}
        onOk={handleUpdateShare}
        width={650}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <Form labelCol={{ span: 3 }} form={shareForm}>
          <Form.Item name="id" colon={false} hidden>
            <Input />
          </Form.Item>
          <Form.Item label="素材">
            <div>{materialDetail?.title}</div>
            {materialDetail?.file_id ? (
              <video
                alt=""
                controls
                src={materialDetail?.url}
                controlsList="nodownload"
                preload="none"
                width={220}
                poster={`${materialDetail?.url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
              />
            ) : (
              <img
                alt=""
                src={materialDetail?.url}
                // width={300}
              />
            )}
          </Form.Item>
          <Form.Item label="归属" name="show_type">
            <Radio.Group onChange={belongChange}>
              <Radio value={1}>公有</Radio>
              <Radio value={2}>私有</Radio>
            </Radio.Group>
          </Form.Item>
          {showType === 2 ? (
            <Form.Item label="投手归属" name="toushouid">
              <Select
                mode="multiple"
                options={pitcherOptions}
                allowClear
                filterOption={filterOption}
              />
            </Form.Item>
          ) : (
            ""
          )}
        </Form>
      </Modal>

      {/* 批量共享素材 */}
      <Modal
        title={
          <>
            批量共享素材 (
            {batchShareType === 1
              ? `已选择${batchShareMaterials.length}条`
              : "所有素材"}
            )
            <Divider />
          </>
        }
        open={batchShareModalOpen}
        onCancel={batchShareModalCLose}
        onOk={handleUpdatebatchShare}
        width={650}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <Form form={batchShareForm}>
          <Form.Item label colon={false}>
            <Radio.Group
              options={[
                { label: "当前页素材", value: 1 },
                { label: "筛选条件所有素材", value: 2 },
              ]}
              defaultValue={1}
              onChange={onSelectChange}
            />
          </Form.Item>
          <Form.Item label="已选素材">
            {batchShareType === 2 && batchShareMaterialsAll.length > 0 ? (
              <div>
                符合条件的批量的素材共: {batchShareMaterialsAll.length}条记录
              </div>
            ) : (
              <ul>
                {batchShareMaterials.length > 0 &&
                  batchShareMaterials?.map((item) => {
                    return (
                      <li key={item.id}>
                        id: {item.id} - 标题: {item.title}
                      </li>
                    );
                  })}
              </ul>
            )}
          </Form.Item>
          <Form.Item label="投手归属" name="toushouid">
            <Select
              mode="multiple"
              options={pitcherOptions}
              allowClear
              filterOption={filterOption}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* 素材公有、私有转化及投手重新分配 */}
      <Modal
        title={
          <>
            素材归属修改
            <Divider />
          </>
        }
        open={belongModalOpen}
        onCancel={belongModalClose}
        onOk={handleUpdateBelongTo}
        width={650}
        confirmLoading={belongConfirmLoading}
        destroyOnClose={true}
      >
        <Form labelCol={{ span: 3 }} form={belongModalForm}>
          <Form.Item name="id" colon={false} hidden>
            <Input />
          </Form.Item>
          <Form.Item label="素材">
            <div>{materialBelongDetail?.title}</div>
            <video
              alt=""
              controls
              src={materialBelongDetail?.attach_video?.oss_url}
              controlsList="nodownload"
              preload="none"
              width={220}
              poster={`${materialBelongDetail?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
            />
          </Form.Item>
          <Form.Item label="归属" name="show_type">
            <Radio.Group
              onChange={onShowTypeChange}
              options={[
                { label: "公有", value: 1 },
                { label: "私有", value: 2 },
              ]}
            />
          </Form.Item>
          {showType === 2 ? (
            <Form.Item label="投手归属" name="toushouid">
              <Select
                mode="multiple"
                options={pitcherOptions}
                allowClear
                filterOption={filterOption}
              />
            </Form.Item>
          ) : (
            ""
          )}
        </Form>
      </Modal>

      {/* 批量推送素材到头条 */}
      <Modal
        title={
          <>
            批量推送素材 ({`已选择${selectKeys.length}条`})
            <Divider />
          </>
        }
        open={batchPushModalOpen}
        onCancel={batchPushModalCLose}
        onOk={handlebatchPush}
        width={650}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <Form form={pushModalForm}>
          <Form.Item
            label="上传到所选广告主"
            name="advertiser_ids"
            rules={[{ required: true, message: "请选择广告账户" }]}
          >
            {advertiserOptions?.length == 0 ? (
              <Spin />
            ) : (
              <Select
                mode="multiple"
                allowClear
                placeholder="选择一个或多个广告主"
                showSearch
                filterOption={filterOption}
                options={advertiserOptions}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
