import { Card, List, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { getTaskList } from "../../../api/asset";
import { Link } from "react-router-dom";

export default function Index() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [initLoading, setInitLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  // 任务列表数据
  const getList = async (params = {}) => {
    setInitLoading(true);
    const res = await getTaskList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
    }
    setInitLoading(false);
  };

  // 分页
  const handleChange = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  return (
    <Card>
      <List
        pagination={{
          position: "both",
          align: "end",
          total: total,
          showSizeChanger: true,
          showTotal: (total) => `共 ${total} 条`,
          pageSizeOptions: [10, 20, 30],
          current: page,
          onChange: handleChange,
          pageSize: perPage,
          hideOnSinglePage: true
        }}
        loading={initLoading}
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Link to={`/admin/asset/account_generate?taskNo=${item.task_no}`}>
                查看小程序资产
              </Link>,
              <Link to={`/admin/asset/landing?taskNo=${item.task_no}`}>
                查看落地页
              </Link>,
            ]}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                title={item.title}
                description={`任务ID: ${item.task_no}`}
              />
            </Skeleton>
            <div style={{ display: "flex", width: '42%' }}>
              <div style={{width: '35%', marginRight: 20}}>{item?.msg}</div>
              <div style={{width: '30%'}}>
                {item?.exec_status === 0
                  ? "执行中"
                  : item.exec_status === 1
                  ? "执行完成"
                  : "执行失败"}
              </div>
              <div>{item.created_at}</div>
            </div>
          </List.Item>
        )}
        size="small"
      />
    </Card>
  );
}
