import { Button, Card, DatePicker, Form, Input, Select } from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import seftColumns from "./selfColumns"
import { useState } from "react";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;

export default function Index(props) {
  const [searchType, setSearchType] = useState(1);
  const { list, total, page, loading, pagesize, perPage } = props;

  // 用于区分是自研小说还是三方外部小说
  const category = props?.category || 0;

  // 三方小说跟自研小说订单区分
  const getColumns = () => {
    if (!category) {
        return columns()
    } else {
        return seftColumns()
    }
  }

  const onChange = (value) => {
    setSearchType(value);
  };

  // 订单号，用户id, 外链id筛选查询
  const onSearch = (value) => {
    let params = {};
    if (searchType === 1) {
      params.order_num = value;
    } else if (searchType === 2) {
      params.user_id = value;
    } else {
      params.promotion_id = value;
    }
    props.onSearch(params);
  };

  // 支付时间查询
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    props.onSearch({ range_date: rangeDate });
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange}>
      <Option value={1}>订单编号</Option>
      <Option value={2}>外链id</Option>
    </Select>
  );

  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSubmit}>
          <Form.Item label="支付时间" name="range_date">
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      }
      extra={
        <Search
          placeholder={
            searchType === 1 ? "订单编号" : "外链id"
          }
          allowClear
          enterButton="搜索"
          addonBefore={selectBefore}
          onSearch={onSearch}
        />
      }
    >
      <Table
        dataSource={list}
        columns={getColumns()}
        total={total}
        page={page}
        perPage={perPage}
        loading={loading}
        pagesize={pagesize}
        onChange={props.onChange}
      />
    </Card>
  );
}
