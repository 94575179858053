import { Tag } from "antd";
import dayjs from "dayjs";

const columns = () => [
  {
    title: "订单ID",
    width: 150,
    align: "center",
    dataIndex: "payid",
    key: "payid",
    ellipsis: true,
    fixed: 'left',
  },
  {
    title: "订单类型",
    width: 100,
    align: "center",
    dataIndex: "paytype",
    key: "paytype",
    render: value => (
        value === 1 ? 'VIP会员' : '普通用户'
    )
  },
  {
    title: "用户",
    width: 120,
    align: "center",
    dataIndex: "nickname",
    key: "nickname",
    render: (_, record) => (
      <>
      <img src={record?.common_user?.avatar} alt="用户头像" width={30}/>
      <div>{record?.common_user?.nickname}({record?.common_user?.openid})</div>
      </>
    )
  },
  {
    title: "充值金额",
    width: 100,
    align: "center",
    dataIndex: "money",
    key: "money",
  },
  {
    title: "支付方式",
    width: 110,
    align: "center",
    dataIndex: "type",
    key: "type",
    render: value => {
        let payType = {1: '公众号支付', 2: '第三方支付', 4: '通联支付', 3: 'H5支付', 15: '支付宝wap支付', 11: '支付宝app支付', 12: '微信app支付', 13: '微信H5支付', 20: '首信易支付', 19: '苹果支付', 21: '米花微信h5支付', 22: '米花支付宝支付'}
        return payType[value] || '-'
    }
  },
  {
    title: "订单状态",
    width: 100,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: value => (
        value === 1 ? <Tag color="success">已支付</Tag> : <Tag color="error">未支付</Tag>
    )
  },
  {
    title: "添加时间",
    width: 160,
    align: "center",
    dataIndex: "addtime",
    key: "addtime",
    render: value => (
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    )
  },
];

export default columns;
