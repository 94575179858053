import { Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";

const columns = ({ superRole, showEstimateDetail }) => [
  {
    title: "短剧信息",
    width: 250,
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    render: (_, record, index) => (
      <>
        {superRole ? (
          <ul>
            <li>
              短剧名称:{" "}
              <Typography.Text
                style={{ width: 180 }}
                ellipsis={{ tooltip: record?.book_name }}
                type="danger"
              >
                {record?.book_name}
              </Typography.Text>
            </li>
            <li>
              剧场名称:{" "}
              <Typography.Text>{record?.playlet_name}</Typography.Text>
            </li>
          </ul>
        ) : (
          <Row gutter={24}>
            <Col span={6}>序号：{index + 1}</Col>
            <Col span={18}>
              <ul>
                <li>
                  短剧名称:{" "}
                  <Typography.Text
                    style={{ width: 180 }}
                    ellipsis={{ tooltip: record?.book_name }}
                    type="danger"
                  >
                    {record?.book_name}
                  </Typography.Text>
                </li>
                <li>
                  剧场名称:{" "}
                  <Typography.Text>{record?.playlet_name}</Typography.Text>
                </li>
              </ul>
            </Col>
          </Row>
        )}
      </>
    ),
  },
  {
    title: "用户人数",
    width: 180,
    dataIndex: "member_info",
    key: "member_info",
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            新增用户数: {record?.new_user}
          </Typography.Text>
        </li>
        <li>首日充值人数: {record?.count}</li>
        <li>首日充值笔数: {record?.first_pay_num}</li>
        <li>新用户付费率: {record?.new_pay_rate || 0}</li>
      </ul>
    ),
  },
  {
    title: "广告|账户数 (有消耗)",
    width: 160,
    dataIndex: "plan_ad_nums",
    key: "plan_ad_nums",
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            在投账户数：{record?.account_nums}
          </Typography.Text>
        </li>
        <li>在投广告数：{record?.ad_nums}</li>
      </ul>
    ),
  },
  {
    title: "充值金额",
    width: 180,
    dataIndex: "money",
    key: "money",
    sorter: (a, b) => a.total_money - b.total_money,
    sortDirections: ["descend"],
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            累计充值: {record?.total_money || 0}
          </Typography.Text>
        </li>
        <li>当日充值金额: {record?.first_day_pay || 0}</li>
        <li>当日首充金额: {record?.first_day_first_pay || 0}</li>
        <li>首日客单价: {record?.kedans || 0}</li>
        <li>付费成本: {record?.paycosts || 0}</li>
      </ul>
    ),
  },
  {
    title: "累计回本率",
    width: 180,
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => a.cost - b.cost,
    sortDirections: ["descend"],
    render: (_, record) => (
      <ul>
        <li>
          <Typography.Text type="danger">
            今日消耗: {record?.cost || 0}
          </Typography.Text>
        </li>
        <li>回收: {record?.money || 0}</li>
        <li>回收率: {record?.huishoulv || 0}</li>
        <li>今日复充率: {record?.repeat_rate || 0}</li>
        <li><Link onClick={() => showEstimateDetail(record?.book_name)}>查看消耗时速</Link></li>
      </ul>
    ),
  },
];

export default columns;
