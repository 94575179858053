import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import React from "react";
import { PlusOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import Table from "../../../../components/TableNoSelection";
import { useState, useEffect } from "react";
import columns from "./columns";
import { useNavigate } from "react-router-dom";
import "./index.less";
import { getTouTiaoPromotions } from "../../../../api/advertise";
import { autoRuleStatusUpdate, createAutoRule, getAutoRuleDetail, getAutoRuleList, updateAutoRule } from "../../../../api/autoRule";

const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [subject, setSubject] = useState(1);
  const [isSelectAll, setIsSelectAll] = useState(true);
  const [selectPromotions, setSelectPromotions] = useState([]);
  const [ruleLoading, setRuleLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(0);
  const [fieldValue, setFieldValue] = useState([]);
  const [operateOption, setOperateOption] = useState('send_message');
  const [operateTimes, setOperateTimes] = useState('1次');
  const [execTimeType, setExecTimeType] = useState("延时40分钟");
  const [promotionList, setPromotionList] = useState([]);
  const [filterPromotionList, setFilterPromotionList] = useState([]);
  const [operate, setOperate] = useState('add')

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  // 自动规则列表
  const getList = async(params = {}) => {
    setLoading(true)
    const res = await getAutoRuleList(params)
    if (res?.code === 200) {
      const {data} = res
      setList(data?.lists)
      setTotal(data?.total)
      setLoading(false)
    }
  };

  // 列表分页
  const pagesize = (page, size) => {
    setPage(page)
    setPerPage(size)
    getList({page, PageSize: size})
  }

  // 由于有关键词筛选，统一入口获取广告列表
  const getPromotionList = () => {
    if (filterPromotionList.length > 0) {
      return filterPromotionList;
    } else {
      return promotionList;
    }
  };

  // 获取指定广告列表
  const getPromotions = async () => {
    const res = await getTouTiaoPromotions();
    if (res?.code === 200) {
      const { data } = res;
      setPromotionList(data?.lists);
    }
  };

  // 列表自动规则状态修改
  const onStatusChange = async (record, status) => {
    setStatusLoading(record?.id)
    const res = await autoRuleStatusUpdate({id: record?.id, status})
    if (res?.code === 200) {
      let newList = list.map((item) => {
        if (item?.id === record?.id) {
          item.status = status ? 1 : 0
        }
        return item
      })
      setList(newList)
    }
    setStatusLoading(0)
  }

  // 广告的选择框是否选中
  const checkIsSelect = (adId) => {
    let selectIds = selectPromotions.map((item) => {
      return item?.id;
    });
    return selectIds.includes(adId);
  };

  // 广告是否选中切换
  const selected = [...selectPromotions];
  const onCheckChange = (e) => {
    let checked = e.target.checked;
    let value = e.target.id;
    let name = e.target.name;
    if (checked) {
      selected.push({ id: value, name });
      setSelectPromotions(selected);
    } else {
      let newList = selectPromotions.filter((item) => {
        return item.id !== value;
      });
      setSelectPromotions(newList);
    }
  };

  // 删除选择
  const selectedDel = (adId) => {
    let newList = selectPromotions.filter((item) => {
      return item.id !== adId;
    });
    setSelectPromotions(newList);
  };

  // 清空所有的选择
  const onClear = () => {
    setSelectPromotions([]);
  };

  // 清除空格
  function clearBr(key) {
    key = key.replace(/<\/?.+?>/g, "");
    key = key.replace(/[\r\n]/g, "");
    return key;
  }

  // 广告名称关键词搜索
  const onSearch = (e) => {
    let inputValue = e.target.value;
    inputValue = clearBr(inputValue);
    if (e.keyCode === 13) {
      if (inputValue) {
        let newList = promotionList.filter((item) => {
          return (
            item?.ad_name?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          );
        });
        setFilterPromotionList(newList);
      } else {
        setFilterPromotionList([]);
      }
    }
  };

  // 新建规则弹窗
  const createRule = () => {
    setOperate('add')
    setShow(true);
  };

  // 编辑规则弹窗
  const ruleUpdate = async (id) => {
    setOperate('edit')
    const res = await getAutoRuleDetail({id})
    if (res?.code === 200) {
      const {data: {info}} = res
      const ruleContent = JSON.parse(info?.rule_content)
      form.setFieldsValue({
        id: info?.id,
        apply_type: info?.apply_type,
        is_all: info?.select_all,
        items: JSON.parse(info?.rule_condition),
        operate_option: ruleContent?.operate_option,
        operate_order: ruleContent?.operate_order,
        exec_time: info?.exec_time,
        name: info?.name
      })
    }
    setShow(true);
  }

  // 关闭新建规则弹窗
  const onRuleClose = () => {
    setShow(false);
    form.resetFields();
    form.setFieldsValue({ items: [{}] });
    setRuleLoading(false)
  };

  // 应用对象切换
  const onSubJectChange = (e) => {
    let value = e.target.value;
    setSubject(value);
  };

  // 是否全部广告切换
  const onSelectAdChange = (e) => {
    let value = e.target.value;
    if (value === 1) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
      getPromotions();
    }
  };

  //
  const onFieldChange = (key, value) => {
    let fieldKey = "field_" + key;
    setFieldValue({ ...fieldValue, [fieldKey]: value });
  };

  // 执行内容指令切换
  const onOrderChange = (value) => {
    setOperateOption(value);
  };

  // 发送通知次数
  const onTimesChange = (value) => {
    setOperateTimes(value);
  };

  // 执行时间切换
  const onExecTimeChange = (e) => {
    let value = e.target.value;
    setExecTimeType(value);
  };

  // 处理添加自动规则
  const handleCreate = async (values) => {
    const res = await createAutoRule(values)
    if (res?.code === 200) {
      message.success('自动规则创建成功')
      setTimeout(() => {
        getList()
        onRuleClose()
        setRuleLoading(false)
      }, 1000);
    }
  }

  // 处理编辑自动规则
  const handleUpdate = async (values) => {
    const res = await updateAutoRule(values)
    if (res?.code === 200) {
      message.success('自动规则编辑成功')
      setTimeout(() => {
        getList()
        onRuleClose()
        setRuleLoading(false)
      }, 1000);
    }
  }

  // 新建规则表单提交
  const ruleFormSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        setRuleLoading(true)
        const values = form.getFieldsValue();
        if (values["operate_option"] && values["operate_option"] === "send_message") {
          values.operate_order = {
            object: "每天",
            times: operateTimes,
          };
        }
        if (values["is_all"] === 2) {
          values.promotion_ids = selectPromotions.map((item) => {
            return item.id;
          });
        }
        if (operate === 'add') {
          handleCreate(values)
        } else {
          delete values.promotion_ids
          handleUpdate(values)
        }
      })
      .catch(() => {
        return;
      });
  };

  return (
    <>
      <Card
        title={
          <Space>
            <Button size="small" type="primary" onClick={createRule}>
              新建规则
            </Button>
            <Button size="small" onClick={() => navigate(-1)}>
              返回头条列表
            </Button>
          </Space>
        }
      >
        <Table
          dataSource={list}
          columns={columns({onStatusChange, ruleUpdate, statusLoading})}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
        />
      </Card>

      {/* 新建自动规则 */}
      <Drawer
        title={<div>{operate === 'add' ? '新建规则' : '编辑规则'}</div>}
        placement="right"
        width={800}
        open={show}
        onClose={onRuleClose}
        footer={
          <div style={{ textAlign: "right", padding: 10 }}>
            <Space>
              <Button onClick={onRuleClose}>取消</Button>
              <Button
                type="primary"
                onClick={ruleFormSubmit}
                loading={ruleLoading}
              >
                提交
              </Button>
            </Space>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            apply_type: subject,
            is_all: isSelectAll ? 1 : 2,
            items: [{}],
            notice: 1,
            exec_time: execTimeType,
            operate_option: "send_message",
            operate_order: "on",
          }}
        >
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="应用对象"
            name="apply_type"
            rules={[{ required: true, message: "请选择应用对象" }]}
          >
            <Radio.Group
              options={[
                { label: "应用至广告", value: 1 },
                { label: "应用至账户", value: 3 },
              ]}
              optionType="button"
              onChange={onSubJectChange}
            />
          </Form.Item>
          {subject === 1 ? (
            <>
              <Form.Item
                label
                colon={false}
                name="is_all"
                style={{ marginTop: -40 }}
              >
                <Radio.Group
                  options={[
                    { label: "全部广告", value: 1 },
                    { label: "指定广告", value: 2 },
                  ]}
                  onChange={onSelectAdChange}
                  disabled={operate === 'edit'}
                />
              </Form.Item>

              {!isSelectAll ? (
                <>
                  <Form.Item>
                    <Input
                      placeholder="输入广告名称, 按回车键搜索"
                      allowClear
                      style={{ width: "65%" }}
                      onKeyDown={onSearch}
                    />
                  </Form.Item>
                  <Row gutter={24}>
                    <Col span={16}>
                      <Form.Item>
                        <Card
                          title="广告"
                          headStyle={{ backgroundColor: "#F8F8F9" }}
                          style={{ height: 200, overflowY: "auto" }}
                          size="small"
                        >
                          {getPromotionList().length > 0 ? (
                            getPromotionList().map((item) => {
                              return (
                                <div
                                  key={item?.ad_id}
                                  style={{ paddingLeft: 10, marginBottom: 10 }}
                                >
                                  <Checkbox
                                    onChange={onCheckChange}
                                    id={item?.ad_id}
                                    name={item?.ad_name}
                                    checked={checkIsSelect(item?.ad_id)}
                                  >
                                    {item?.ad_name}
                                  </Checkbox>
                                  <div
                                    style={{
                                      marginLeft: 25,
                                      color: "#B3B6BA",
                                      fontSize: 12,
                                    }}
                                  >
                                    ID: {item?.ad_id}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              size="small"
                            />
                          )}
                        </Card>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Card
                        title={`已选 (${selectPromotions.length})`}
                        headStyle={{ backgroundColor: "#F8F8F9" }}
                        style={{ height: 200, overflowY: "auto" }}
                        size="small"
                        extra={
                          <Button
                            size="small"
                            type="link"
                            disabled={selectPromotions.length === 0}
                            onClick={onClear}
                          >
                            清空
                          </Button>
                        }
                      >
                        {selectPromotions.length > 0 ? (
                          selectPromotions.map((item) => {
                            return (
                              <div
                                key={item?.id}
                                style={{
                                  backgroundColor: "#F4F4F5",
                                  marginBottom: 10,
                                  paddingLeft: 10,
                                  paddingTop: 8,
                                  paddingBottom: 5,
                                  borderRadius: 5,
                                  marginRight: -10,
                                }}
                              >
                                <div style={{ display: "flex", width: "95%" }}>
                                  <div>
                                    <Text
                                      ellipsis={{
                                        tooltip: item.name,
                                      }}
                                      style={{ width: 170 }}
                                    >
                                      {item.name}
                                    </Text>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      marginTop: -6,
                                      fontSize: 12,
                                      color: "#A3A6AC",
                                      cursor: "pointer",
                                      marginRight: -5,
                                    }}
                                    onClick={() => selectedDel(item.id)}
                                  >
                                    <CloseOutlined />
                                  </div>
                                </div>

                                <div style={{ fontSize: 12, color: "#B3B6BA" }}>
                                  ID: {item?.id}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            size="small"
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          <Form.Item
            label="规则内容"
            name="items"
            rules={[{ required: true, message: "请设置规则内容" }]}
          >
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                    border: "1px solid #F0F0F0",
                    padding: 20,
                    borderRadius: 5,
                    paddingBottom: 0,
                  }}
                >
                  <div style={{ fontSize: 12, fontWeight: "bold" }}>
                    符合以下所有条件时
                  </div>
                  {fields.map((field) => (
                    <Row
                      gutter={8}
                      key={field.key}
                      style={{ marginBottom: -20 }}
                    >
                      <Col span={5}>
                        <Form.Item
                          name={[field.name, "time"]}
                          style={{ marginBottom: 0 }}
                          initialValue={"current"}
                        >
                          <Select
                            options={[
                              { label: "当天", value: "current" },
                              // { label: "过去3天", value: 2 },
                              // { label: "过去5天", value: 3 },
                              // { label: "过去天7", value: 4 },
                            ]}
                            size="small"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          name={[field.name, "field"]}
                          style={{ marginBottom: 0 }}
                          initialValue={"recycle_rate"}
                        >
                          <Select
                            options={[
                              { label: "回收率", value: "recycle_rate" },
                              { label: "消耗", value: "cost" },
                            ]}
                            size="small"
                            onChange={(value) =>
                              onFieldChange(field.name, value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          name={[field.name, "operate"]}
                          style={{ marginBottom: 0 }}
                          initialValue={"<="}
                        >
                          <Select
                            options={[
                              { label: "小于等于", value: "<=" },
                              { label: "大于等于", value: ">=" },
                            ]}
                            size="small"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          name={[field.name, "value"]}
                          style={{ marginBottom: 0 }}
                          rules={[
                            {
                              required: true,
                              message: "请输入数字，最多两位小数",
                            },
                          ]}
                        >
                          <Input
                            addonBefore={
                              fieldValue[`field_${field.name}`] &&
                              fieldValue[`field_${field.name}`] === 
                                "cost"
                                ? "金额"
                                : "百分比"
                            }
                            addonAfter={
                              fieldValue[`field_${field.name}`] &&
                              fieldValue[`field_${field.name}`] === "cost"
                                ? "元"
                                : "%"
                            }
                            placeholder="请输入"
                            size="small"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item>
                          {fields.length === 1 ? (
                            <Tooltip
                              title="自动规则至少需要设置一个条件"
                              overlayStyle={{ fontSize: 12 }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                size="small"
                                onClick={() => {
                                  remove(field.name);
                                }}
                                disabled
                              ></Button>
                            </Tooltip>
                          ) : (
                            <Button
                              icon={<DeleteOutlined />}
                              size="small"
                              onClick={() => {
                                remove(field.name);
                              }}
                            ></Button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item label colon={false} style={{ marginTop: -25 }}>
                    <Button
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      type="dashed"
                      block
                    >
                      新增规则条件
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Form.Item>

          <div
            style={{
              marginTop: -15,
              border: "1px solid #F0F0F0",
              borderRadius: 5,
              padding: 20,
              paddingBottom: 0,
              marginBottom: 20,
            }}
          >
            <div style={{ fontSize: 12, fontWeight: "bold" }}>
              则执行如下操作
            </div>
            <Row gutter={8} style={{ marginTop: -15 }}>
              <Col span={5}>
                <Form.Item label colon={false} name="operate_option">
                  <Select
                    options={[
                      // { label: "开关广告", value: "switch_advertise" },
                      { label: "仅发送通知", value: "send_message" },
                    ]}
                    size="small"
                    onChange={onOrderChange}
                  />
                </Form.Item>
              </Col>
              <Col span={operateOption === "switch_advertise" ? 5 : 18}>
                <Form.Item label colon={false} name="operate_order">
                  {operateOption === "switch_advertise" ? (
                    <Select
                      options={[
                        { label: "开启广告", value: "on" },
                        { label: "暂停广告", value: "off" },
                      ]}
                      size="small"
                    />
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: 10 }}>调整频率</div>
                      <div>
                        <Select
                          options={[{ label: "每天", value: 1 }]}
                          style={{ width: 130 }}
                          size="small"
                          defaultValue={1}
                        />
                      </div>
                      <div style={{ width: 80 }}>
                        <Select
                          options={[
                            { label: "1次", value: "1次" },
                            { label: "2次", value: "2次" },
                            { label: "3次", value: "3次" },
                            { label: "4次", value: "4次" },
                            { label: "5次", value: "5次" },
                            { label: "不限", value: "不限" },
                          ]}
                          size="small"
                          defaultValue={"1次"}
                          onChange={onTimesChange}
                        />
                      </div>
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Form.Item label="检查时间" rules={[{ required: true }]}>
            <Form.Item style={{ marginBottom: 5 }}>
              <Text
                secondary="true"
                style={{ fontSize: 12, color: "#666", paddingLeft: 20 }}
              >
                注：延时时间说明: 延时40分钟表示当前时间前40分钟的数据;
                延时60分钟表示当前时间前1小时的数据
              </Text>
            </Form.Item>
            <Form.Item name="exec_time">
              <Radio.Group
                options={[
                  { label: "延时40分钟", value: "延时40分钟" },
                  { label: "延时60分钟", value: "延时60分钟" },
                ]}
                optionType="button"
                onChange={onExecTimeChange}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <>
                <span>发送通知</span>
                <span style={{ fontSize: 12, color: "#666" }}>
                  【规则执行成功后，会给您发送通知。您可在消息中心修改接收对象与通知方式(站内信方式)】
                </span>
              </>
            }
            name="notice"
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={[{ label: "发送通知", value: 1 }]}
              optionType="button"
            />
          </Form.Item>
          <Form.Item label="规则名称" name="name" rules={[{ required: true }]}>
            <Input
              placeholder="请输入规则名称"
              allowClear
              showCount
              maxLength={50}
              style={{ width: "65%" }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
