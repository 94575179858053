import { getRequest, postRequest } from "./javaRequest"

// 获取ipu/arpu用户占比
export const getDeliveryARPU = data => getRequest('/delivery/admin/iaa/ecpm/getIpuArpu', data)

// 短剧ecpm获取档位
export const getDeliveryEcpmLevel = data => getRequest('/delivery/admin/iaa/ecpm/getLevel', data)

// 获取ipu/ecpm用户占比
export const getDeliveryEcpm = data => getRequest('/delivery/admin/iaa/ecpm/getIpuEcpm', data)

//获取达成/非达成用户的平均arpu值
export const getDeliveryAvgArpu = data => getRequest('/delivery/admin/iaa/ecpm/getAvgArpu', data)

// 小说ecpm获取档位
export const getDeliveryEcpmNovelLevel = data => getRequest('/delivery/admin/novel/ecpm/getLevel', data)

// 获取小说ipu/arpu用户占比
export const getDeliveryNovelARPU = data => getRequest('/delivery/admin/novel/ecpm/getIpuArpu', data)

// 获取小说ipu/ecpm用户占比
export const getDeliveryNovelEcpm = data => getRequest('/delivery/admin/novel/ecpm/getIpuEcpm', data)

// 短剧ecpm列表
export const getPlayletEcpmList = data => postRequest('/delivery/admin/stats/ecpm/income', data)

// 短剧ecpm列表
export const getPlayletECPStatistics = data => postRequest('/delivery/admin/stats/ecpm/income/total', data)

// 小说ecpm列表
export const getNovelEcpmList = data => getRequest('/delivery/admin/novel/ecpm/getEcpmList', data)

// 小说ecpm列表
export const getNovelEcpmStatistics = data => getRequest('/delivery/admin/novel/ecpm/getEcpmTotal', data)

export const getDailyProfit = data => getRequest('/delivery/admin/iaa/profit/daily', data, { severTip: false })

export const getDailyProfitGroup = data => getRequest('/delivery/admin/iaa/profit/daily/group', data)

// 查询用户等级，是否是超管和代理商
export const getUserLevel = data => postRequest('/delivery/admin/stats/ecpm/income/getUserLevel', data)