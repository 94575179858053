import React, { useEffect } from 'react'
import { useState } from 'react'
import ResultComponent from '../Public/Result'
import { playletResult } from '../../../api/playlet'


export default function Result() {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getList()
  }, [])

  const appType = 2005

  const getList = async (params = {}) => {
    setLoading(true)
    const res = await playletResult({ ...params, app_type: appType })
    const { data } = res
    if (res?.code === 200) {
      setList(data?.lists)
      setLoading(false)
      setTotal(data?.total)
    }
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    getList({ page, pageSize: size })
  }

  const onSearch = params => {
    getList(params)
  }

  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage: pageSize,
    onSearch, app_type: appType,
  }

  return (
    <ResultComponent {...propsData} />
  )
}
