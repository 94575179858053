import {
  Button,
  Card,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Typography,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import columns from "./columns";
import {
  getDailyPlayletAdData,
  getDailyPlayletAdDetail,
} from "../../../../api/statistics";
import { numDiv, numMulti } from "../../../../util/calculate";

const {Text} = Typography
const {RangePicker} = DatePicker

export default function Index(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [detailData, setDetailData] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getDailyPlayletAdData({ ...params, type: "iaa" });
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
    }
    setLoading(false);
  };

  // 表单查询提交
  const onSubmit = (values) => {
    let rangeDate;
    if (values?.range_date) {
      rangeDate = values.range_date.map((date) => {
        return date.format("YYYYMMDD");
      })
      values.range_date = rangeDate;
    }
    getList(values);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 点击查看详情
  const showDetail = async (record) => {
    setModalShow(true);
    setModalLoading(true);
    const res = await getDailyPlayletAdDetail(record);
    if (res?.code === 200) {
      const { data } = res;
      setDetailData(data?.lists);
    }
    setModalLoading(false);
  };

  // 关闭弹窗
  const onClose = () => {
    setModalShow(false);
    setDetailData([]);
  };

  // 详情列
  const detailColumns = [
    {
      title: "账户",
      width: 100,
      align: "center",
      dataIndex: "account",
      key: "account",
      render: (_, record) => (
        <>
          <div>{record?.account_name}</div>
          <div>ID:{record?.advertiser_id}</div>
        </>
      ),
    },
    {
      title: "今日新建广告",
      width: 100,
      align: "center",
      dataIndex: "new_ad_count",
      key: "new_ad_count",
      render: (value) => value || 0,
    },
    {
      title: "今日在消耗广告",
      width: 100,
      align: "center",
      dataIndex: "active_ad_count",
      key: "active_ad_count",
    },
    {
      title: "消耗",
      width: 100,
      align: "center",
      dataIndex: "cost",
      key: "cost",
      sorter: (a, b) => a.cost - b.cost,
      sortDirections: ["descend"],
    },
    {
      title: "回收",
      width: 100,
      align: "center",
      dataIndex: "money",
      key: "money",
      sorter: (a, b) => a.money - b.money,
      sortDirections: ["descend"],
    },
    {
      title: "当日收益",
      width: 100,
      align: "center",
      dataIndex: "total_new_ad_cash",
      key: "total_new_ad_cash",
      sorter: (a, b) => a.total_new_ad_cash - b.total_new_ad_cash,
      sortDirections: ["descend"],
    },
    {
      title: "总收益",
      width: 100,
      align: "center",
      dataIndex: "total_ad_cash",
      key: "total_ad_cash",
      sorter: (a, b) => a.total_ad_cash - b.total_ad_cash,
      sortDirections: ["descend"],
    },
  ];

  return (
    <>
      <Card
        type="inner"
        title={
          <Form layout="inline" form={form} onFinish={onSubmit}>
            <Form.Item label="日期" name="range_date">
              <RangePicker style={{width: 230}}/>
            </Form.Item>
            <Form.Item label="短剧" name="book_name">
              <Input allowClear placeholder="输入短剧" />
            </Form.Item>
            <Form.Item label="投手" name="admin_id">
              <Select
                placeholder="选择投手"
                allowClear
                showSearch
                filterOption={filterOption}
                style={{ width: 120 }}
                options={props?.admins || []}
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button onClick={() => form.resetFields()}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
      >
        <div style={{ textAlign: "right", marginTop: -10 }}>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            注意：这里只统计在投放的广告，在审核中或已暂停等状态广告不作统计
          </Typography.Text>
        </div>

        <Table
          sticky
          dataSource={list}
          columns={columns({ showDetail })}
          loading={loading}
          pagination={false}
          bordered={true}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalAdCount = 0;
            let totalAccountCount = 0;
            let totalActiveAdCount = 0;
            let totalActiveAccountCount = 0;
            let totalCost = 0;
            let totalMoney = 0;
            let totalNewAdCach = 0;
            let totalAdCach = 0;
            pageData.forEach(
              ({
                ad_count,
                account_count,
                active_ad_count,
                active_account_count,
                cost,
                money,
                new_ad_cash,
                ad_cash,
              }) => {
                totalAdCount += ad_count;
                totalAccountCount += account_count;
                totalActiveAdCount += active_ad_count;
                totalActiveAccountCount += active_account_count;
                totalCost += parseFloat(cost);
                totalMoney += parseFloat(money);
                totalNewAdCach += parseFloat(new_ad_cash);
                totalAdCach += parseFloat(ad_cash);
              }
            );
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{totalAdCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>{totalAccountCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>{totalActiveAdCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>{totalActiveAccountCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{totalCost?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>{totalMoney?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>{totalNewAdCach?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text>{totalAdCach?.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="center">
                      <Text>{totalCost ? numMulti(numDiv((totalMoney + totalNewAdCach), totalCost), 100).toFixed(2) + '%' : '0.00%'}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="center">
                      <Text>{totalCost ? numMulti(numDiv((totalMoney + totalAdCach), totalCost), 100).toFixed(2) + '%' : '0.00%'}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>

      {/* 查看详情弹窗  */}
      <Drawer
        title="每日短剧广告详情"
        open={modalShow}
        onClose={onClose}
        maskClosable={false}
        width={850}
        loading={modalLoading}
      >
        <Card>
          <Table
            dataSource={detailData}
            columns={detailColumns}
            pagination={false}
          />
        </Card>
      </Drawer>
    </>
  );
}
