import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import columns from "./columns";
import bookColumns from "./bookColumns";
import channelColumns from "./channelColumns";
import { useEffect } from "react";
import { getDailyCost } from "../../../api/statistics";
import { numDiv, numMulti } from "../../../util/calculate";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [activeTabKey, setActiveTabKey] = useState("tab1");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getDailyCost(params);
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setLoading(false);
    }
  };

  const tabList = [
    {
      key: "tab1",
      tab: "推广链接",
    },
    {
      key: "tab2",
      tab: "小说消耗",
    },
  ];

  // 标签切换
  const onTabChange = (value) => {
    form.resetFields();
    setActiveTabKey(value);
    getList({ tab: value });
  };

  const getColumns = (tab) => {
    let returnColumns;
    switch (tab) {
      case "tab1":
        returnColumns = columns();
        break;
      case "tab2":
        returnColumns = bookColumns();
        break;
      case "tab3":
        returnColumns = channelColumns();
        break;
      default:
        break;
    }
    return returnColumns;
  };

  // 条件筛选查询
  const onSearchSubmit = (values) => {
    let rangeDate;
    if (values?.range_date) {
      rangeDate = values.range_date.map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    getList(values);
  };

  const getTableSummary = (totalCost, totalMoneyCount, totalPayman) => {
    if (activeTabKey === "tab1") {
      return (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={3} align="center">
            <Text type="danger" strong={true}>
              汇总
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="center">
            <Text>{totalCost?.toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align="center">
            <Text>{totalMoneyCount?.toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} align="center">
            <Text>
              {parseInt(totalCost)
                ? numMulti(numDiv(totalMoneyCount, totalCost), 100).toFixed(2) +
                  "%"
                : "0%"}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} align="center">
            <Text>{totalPayman}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} align="center">
            <Text>
              {parseInt(totalPayman)
                ? numDiv(totalCost, totalPayman).toFixed(2)
                : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} align="center">
            <Text>
              {parseInt(totalPayman)
                ? numDiv(totalMoneyCount, totalPayman).toFixed(2)
                : 0}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    } else {
      return (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={2} align="center">
            <Text type="danger" strong={true}>
              汇总
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="center">
            <Text>{totalCost?.toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="center">
            <Text>{totalMoneyCount?.toFixed(2)}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align="center">
            <Text>
              {parseInt(totalCost)
                ? numMulti(numDiv(totalMoneyCount, totalCost), 100).toFixed(2) +
                  "%"
                : "0%"}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} align="center">
            <Text>{totalPayman}</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} align="center">
            <Text>
              {parseInt(totalPayman)
                ? numDiv(totalCost, totalPayman).toFixed(2)
                : 0}
            </Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} align="center">
            <Text>
              {parseInt(totalPayman)
                ? numDiv(totalMoneyCount, totalPayman).toFixed(2)
                : 0}
            </Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      );
    }
  };

  return (
    <Card
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      <Card
        type="inner"
        title={
          <Form layout="inline" form={form} onFinish={onSearchSubmit}>
            <Form.Item label="日期" name="range_date">
              <RangePicker />
            </Form.Item>
            {activeTabKey === "tab1" ? (
              <>
                <Form.Item label="推广链接" name="promotion_name">
                  <Input
                    allowClear
                    placeholder="输入推广链接名称"
                    size="middle"
                    style={{ width: 150 }}
                  />
                </Form.Item>
                <Form.Item label="投手" name="pitcher">
                  <Input
                    allowClear
                    placeholder="输入投手名称"
                    size="middle"
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </>
            ) : (
              ""
            )}

            <Form.Item label="小说" name="book_name">
              <Input
                allowClear
                placeholder="输入小说名称"
                size="middle"
                style={{ width: 150 }}
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
                <Button>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
      >
        <Table
          sticky
          dataSource={list}
          columns={getColumns(activeTabKey)}
          bordered={true}
          loading={loading}
          pagination={false}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalCost = 0;
            let totalMoneyCount = 0;
            let totalPayman = 0;
            pageData.forEach(({ cost, money, count }) => {
              totalCost += parseFloat(cost);
              totalMoneyCount += parseFloat(money);
              totalPayman += parseInt(count);
            });
            return (
              <>
                {pageData && pageData.length > 0
                  ? getTableSummary(totalCost, totalMoneyCount, totalPayman)
                  : ""}
              </>
            );
          }}
        />
      </Card>
    </Card>
  );
}
