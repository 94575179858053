const columns = () => [
    {
        title: "章节序号",
        width: 180,
        align: "center",
        dataIndex: "idx",
        key: "idx",
        render: (_, record) => (
            record?.idx + '-' + record?.title
        )
    },
    {
        title: "阅读统计",
        width: 120,
        align: "center",
        dataIndex: "read_count",
        key: "read_count",
    },
    {
        title: "折损率",
        width: 120,
        align: "center",
        dataIndex: "lost_rate",
        key: "lost_rate",
    },
]

export default columns