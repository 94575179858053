import { Button, Space, Typography, Popconfirm, Tag, Row } from "antd";
import { numDiv, numMulti, numSub } from "../../util/calculate";
import { Link } from "react-router-dom";

const columns = () => [
  {
    title: "广告主id",
    width: 100,
    align: "center",
    dataIndex: "advertiser_id",
    key: "advertiser_id",
  },
  {
    title: "项目id",
    width: 100,
    align: "center",
    dataIndex: "project_id",
    key: "project_id",
  },
  {
    title: "项目名称",
    width: 100,
    align: "center",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "监测地址",
    width: 100,
    align: "center",
    dataIndex: "action_track_url",
    key: "action_track_url",
  },
  {
    title: "项目最近修改时间",
    width: 100,
    align: "center",
    dataIndex: "project_modify_time",
    key: "project_modify_time",
  },
  {
    title: "投手",
    width: 100,
    align: "center",
    dataIndex: "nickname",
    key: "nickname",
    render: (_, record) => {
      return <>
        {`${record?.user?.nickname}`}
      </>
    }
  },
];

export default columns;
