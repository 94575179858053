import dayjs from "dayjs"

const columns = () => [
    {
        title: "小说",
        width: 150,
        align: "center",
        dataIndex: "book",
        key: "book",
        render: (_, record) => (
            record?.book?.title
        )
    },
    {
        title: "章节",
        width: 150,
        align: "center",
        dataIndex: "chapter",
        key: "chapter",
        render: (_, record) => (
            record?.chapter?.title
        )
    },
    {
        title: "时间",
        width: 180,
        align: "center",
        dataIndex: "update_time",
        key: "update_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    
]

export default columns