import { Button, Col, Popover, Row, Space } from "antd"
import { Link } from "react-router-dom";

const options = [
    '现代言情', '历史架空', '古代言情', '社会风云',
    '悬疑推理', '仙侠奇缘', '游戏科幻', '玄幻仙侠',
    '都市异能', '精彩书库', '年代种田'
]

const columns = () => [
    {
        title: "日期",
        width: 160,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "素材名称",
        width: 250,
        align: "center",
        dataIndex: "video",
        key: "video",
        ellipsis: true,
        render: (_, record) => {
            return <Row justify="start">
                    <Col span={6}>
                        <Popover placement="right" content={ 
                            record?.type === 1 || record?.type === 2 ?
                            <video 
                                alt="" 
                                muted
                                controls
                                src={record?.oss_url} 
                                width={350} 
                                poster={`${record?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                            /> : <img 
                                    alt="" 
                                    src={record?.oss_url} 
                                    width={350}
                                />
                            
                        }>
                            {
                                record?.type === 1 || record?.type === 2 ? 
                                <video 
                                    alt="" 
                                    src={record?.oss_url} 
                                    width={20} 
                                    poster={`${record?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                                /> :
                                <img 
                                    alt="" 
                                    src={record?.oss_url} 
                                    width={50} 
                                    style={{marginTop: 5, marginRight: 10}}
                                />
                            }
                            
                        </Popover>
                    </Col>
                    <Col span={4}>
                        <div>
                        {record?.title}
                        <div>ID:{record?.type === 3 ? record?.photo_id : record?.file_id}</div>
                        </div>
                    </Col>
            </Row>
        }
    },
    {
        title: "消耗",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "素材类型",
        width: 120,
        align: "center",
        dataIndex: "type",
        key: "type",
        render: text => (
            text === 1 ? '竖版视频' : (text === 2 ? '横版视频' : '大图横图')
        )
    },
    {
        title: "分类",
        width: 100,
        align: "center",
        dataIndex: "category",
        key: "category",
        render: text => (
            options[text]
        )
    },
    {
        title: "归属",
        width: 100,
        align: "center",
        dataIndex: "show_type",
        key: "show_type",
        render: text => (
            text === 1 ? '公有' : '私有'
        )
    },
    {
        title: "上传者",
        width: 120,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
    },
    {
        title: "投手",
        width: 120,
        align: "center",
        dataIndex: "toushouname",
        key: "toushouname",
        ellipsis: true,
    },
    {
        title: "操作",
        align: "center",
        width: 120,
        dataIndex: "operation",
        key: "operation",
        fixed: 'right',
        render: (_, record) => (
            <>
                <Space>
                    <Button type="primary" ghost>
                        <Link
                            to={record?.type === 1 || record?.type === 2 ? `/admin/material/video/video_data_detail` : `/admin/material/image/img_data_detail`}
                            state={{ ...record }}
                        >
                            数据详情
                        </Link>
                    </Button>
                </Space>
            </>
        )
    },
]

export default columns