import { Button, Col, Row, Tag } from "antd"
import dayjs from "dayjs"

const columns = ({showDetail}) => [
    {
      title: "ID",
      width: 150,
      align: "center",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
    },
    {
      title: "用户名",
      width: 100,
      align: "center",
      dataIndex: "nickname",
      key: "nickname",
      ellipsis: true,
      render: (_,record) => (
        record?.username && record?.nickname ? `${record?.username} (${record?.nickname})` : '-'
      )
    },
    {
      title: "用户类型",
      width: 100,
      align: "center",
      dataIndex: "isvip",
      key: "isvip",
      render: value => (
        value === 1 ? 'VIP会员' : '普通用户'
      )
    },
    {
      title: "书币",
      width: 100,
      align: "center",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "关注",
      width: 100,
      align: "center",
      dataIndex: "isguanzhu",
      key: "isguanzhu",
      render: value => (
        value === 1 ? <Tag color="#87d068">已关注</Tag> : <Tag color="#f50">未关注</Tag>
      )
    },
    {
      title: "累计充值",
      width: 100,
      align: "center",
      dataIndex: "leiji",
      key: "leiji",
      render: (_, record) => (
        record?.pay_log_sum_money ? (`${record?.pay_log_sum_money}(${record?.pay_log_count}次)`) : 0
      )
    },
    {
      title: "渠道",
      width: 100,
      align: "center",
      dataIndex: "qudao",
      key: "qudao",
    },
    {
      title: "来源",
      width: 100,
      align: "center",
      dataIndex: "source",
      key: "source",
      
    },
    {
      title: "手机型号",
      width: 100,
      align: "center",
      dataIndex: "phone_type",
      key: "phone_type",
      render: value => {
        let type = ['无', 'huawei', 'xiaomi', 'oppo', 'other', 'vivo']
        return type[value]
      }
    },
    {
      title: "注册时间",
      width: 160,
      align: "center",
      dataIndex: "create_time",
      key: "create_time",
      render: value => (
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
      )
    },
    {
      title: "最近变更时间",
      width: 160,
      align: "center",
      dataIndex: "update_promotions_time",
      key: "update_promotions_time",
      render: value => (
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
      )
    },
    {
      title: "最近一次所在的城市",
      width: 110,
      align: "center",
      dataIndex: "last_city",
      key: "last_city",
    },
    {
      title: "操作",
      width: 210,
      align: "center",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => (
        <>
        <Row gutter={10}>
          <Col span={12}><Button onClick={() => showDetail(record, 'recharge')}>充值记录</Button></Col>
          <Col span={12}><Button onClick={() => showDetail(record, 'consume')}>消费明细</Button></Col>
        </Row>
        <Row gutter={10} style={{marginTop: 5}}>
          <Col span={12}><Button onClick={() => showDetail(record, 'sign')}>签到明细</Button></Col>
          <Col span={12}><Button onClick={() => showDetail(record, 'compensate')}>补偿明细</Button></Col>
        </Row>
        <Row style={{marginTop: 5}}>
          <Col span={12} onClick={() => showDetail(record, 'read')}><Button>阅读记录</Button></Col>
        </Row>
        </>
      )
    },
]

export default columns