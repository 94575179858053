// import { Link } from "react-router-dom";
// import { Button } from "antd";

import { numAdd, numDiv, numMulti } from "../../../util/calculate";

const columns = () => [
  {
    title: "日期",
    width: 80,
    align: "center",
    dataIndex: "day",
    key: "day",
    fixed: "left",
  },
  {
    title: "新增用户",
    width: 80,
    align: "center",
    dataIndex: "new_user",
    key: "new_user",
  },
  {
    title: "累计充值",
    width: 80,
    align: "center",
    dataIndex: "total_money",
    key: "total_money",
  },
  {
    title: "累计消耗",
    width: 80,
    align: "center",
    dataIndex: "total_cost",
    key: "total_cost",
  },
  {
    title: "NO_BID充值",
    width: 80,
    align: "center",
    dataIndex: "noBid_money",
    key: "noBid_money",
  },
  {
    title: "NO_BID消耗",
    width: 80,
    align: "center",
    dataIndex: "noBid_cost",
    key: "noBid_cost",
  },
  {
    title: "广告收益",
    width: 80,
    align: "center",
    dataIndex: "ad_cash",
    key: "ad_cash",
  },
  {
    title: "回收率",
    width: 80,
    align: "center",
    dataIndex: "rate",
    key: "rate",
    render: (_, record) => {
      let rate =
        parseFloat(record?.total_cost) && (parseFloat(record?.total_money) || parseFloat(record?.ad_cash))
          ? numMulti(numDiv(numAdd(record?.total_money * 1, record?.ad_cash * 1), record?.total_cost * 1), 100).toFixed(2) +
            "%"
          : 0;
      return <div>{rate}</div>;
    },
  },
//   {
//     title: "盈亏",
//     width: 80,
//     align: "center",
//     dataIndex: "profit",
//     key: "profit",
//     render: (value, record) => {
//       return <>
//         <div>{value}</div>
//         <Button type="link" onClick={() => showDetail(record)}>查看详情</Button>
//       </>
//     }
//   },
];

export default columns;
