const columns = () => [
    {
        title: "素材分日转化",
        width: 100,
        align: "center",
        dataIndex: "label",
        key: "label",
    },
    {
        title: "总消耗",
        width: 100,
        align: "center",
        dataIndex: "total_cost",
        key: "total_cost",
    },
    {
        title: "转化成本",
        width: 100,
        align: "center",
        dataIndex: "convert_cost",
        key: "convert_cost",
    },
    {
        title: "在投素材数量(百分比)",
        width: 100,
        align: "center",
        dataIndex: "material_num_percent",
        key: "material_num_percent",
        render:(_, record) => (
            <>
                {record?.num}({record?.material_percent}%)
            </>
        )
    },
    {
        title: "平均每个素材在投广告数量",
        width: 100,
        align: "center",
        dataIndex: "avg_cost_ad",
        key: "avg_cost_ad",
    },
]
export default columns;
