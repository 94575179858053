import React, { useEffect, useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Statistic,
} from "antd";
import {
  filterPitchers,
  getThirdECPMPlaylet,
} from "../../../../api/statistics";
import { getPlayletEcpmList, getPlayletECPStatistics } from "../../../../api/delivery";
import dayjs from "dayjs";
import { getRoleOrgan } from "../../../../api/permission";

const { RangePicker } = DatePicker;

const today = dayjs().startOf('day');

export default function EcpmPromotion(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [statisticsData, setStatisticsData] = useState({});
  const [conditions, setConditions] = useState([]);
  const [appOptions, setAppOptions] = useState([])

  const [form] = Form.useForm();

  useEffect(() => {
    if(localStorage.getItem('ty_link_three_obj')){
      const obj = JSON.parse(localStorage.getItem('ty_link_three_obj'))
      form.setFieldsValue({bookName: obj.bookName, adminId: obj.adminId})
    }
    if(props.curName){
      form.setFieldsValue({bookName: props.curName})
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    if (!params?.range_date) {
      let current = dayjs().format('YYYYMMDD')
      params.startDay = current
      params.endDay = current
    } else {
      let rangeDate = params?.range_date
      params.startDay = rangeDate[0]
      params.endDay = rangeDate[1]
    }
    params.statsDim = 'promotion'
    params.pageNum = params.pageNum || page
    params.pageSize = params.pageSize || perPage
    let agentId
    const org = await getRoleOrgan();
    if (org?.code === 200) {
      const { data } = org;
      agentId = data?.agent_id === 0 ? 13 : data?.agent_id
    }
    params.agentId = agentId
    params.profitMode = 'IAA'
    // if(form.getFieldValue('bookName')){
    //   params.bookName = form.getFieldValue('bookName')
    // }
    const res = await getPlayletEcpmList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.list);
      if (data?.total) {
        setTotal(data.total);
      }
    }
    setLoading(false);
  };

  // 总计数据
  const ecpmStatistics = async (params = {}) => {
    if (!params?.range_date) {
      let current = dayjs().format('YYYYMMDD')
      params.startDay = current
      params.endDay = current
    } else {
      let rangeDate = params?.range_date
      params.startDay = rangeDate[0]
      params.endDay = rangeDate[1]
    }
    params.statsDim = 'promotion'
    let agentId
    const org = await getRoleOrgan();
    if (org?.code === 200) {
      const { data } = org;
      agentId = data?.agent_id === 0 ? 13 : data?.agent_id
    }
    params.agentId = agentId
    params.profitMode = 'IAA'
    const res = await getPlayletECPStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setStatisticsData(data);
      if (data?.total) {
        setTotal(data.total);
      }
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 获取剧场
  const getPlaylets = async () => {
    const res = await getThirdECPMPlaylet()
    if (res?.code === 200) {
      const {data} = res
      let list = data?.lists
      let newList = list.map((appName) => {
        return {label: appName, value: appName}
      })
      setAppOptions(newList)
    }
  }

  const getData = () => {
    let values = form.getFieldsValue();
    Promise.all([getList(values), ecpmStatistics(values), getPitchers(), getPlaylets()]);
  };

  // 表单筛选查询
  const onSubmit = () => {
    let values = form.getFieldsValue();
    let rangeDate;
    if (values?.dates) {
      rangeDate = values.dates.map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.dates;
      values.range_date = rangeDate;
    }
    const params = {...values, pageNum: 1, pageSize: 20};
    setConditions(params);
    setPage(1);
    setPerPage(20);
    Promise.all([getList(params), ecpmStatistics(params)]);
  };

  const onReset = () => {
    form.resetFields();
    onSubmit();
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
  };

  // 排序
  const onSortChange = (pagination, filters, sorter) => {
    const sortBy = sorter.order;
    if (sortBy !== undefined) {
      const order = sorter.columnKey;
      const orderByAsc = sortBy === "ascend" ? 1 : 0;
      setConditions({ ...conditions, orderBy: order, orderByAsc });
      getList({
        ...conditions,
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
        orderBy: order,
        orderByAsc,
      });
    } else {
      delete conditions.orderBy
      delete conditions.orderByAsc
      setPage(pagination.current);
      setPerPage(pagination.pageSize);
      getList({
        ...conditions,
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
      })
    }
  };

  return (
    <>
      <Card
        title={
          <Form layout="inline" form={form}>
            <Form.Item label="日期" name="dates">
              <RangePicker  defaultValue={[today, today]} allowClear={false}/>
            </Form.Item>
            <Form.Item label="短剧名称" name="bookName">
              <Input placeholder="输入短剧名称" allowClear/>
            </Form.Item>
            <Form.Item label="投手" name="adminId">
              <Select
                allowClear
                showSearch
                placeholder="请选择投手"
                options={pitcherOptions}
                style={{ width: 150 }}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item label="剧场" name="appName">
              <Select
                allowClear
                showSearch
                placeholder="请选择剧场"
                options={appOptions}
                style={{ width: 150 }}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" onClick={onSubmit}>
                  确定
                </Button>
                <Button onClick={onReset}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
        extra={
          <div style={{width: 200}}>
            <Row gutter={12}>
              <Col span={8}>
                <Statistic
                  title={<div style={{fontSize: 12}}>总计消耗</div>}
                  value={statisticsData?.cost}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{fontSize: 12}}>总收益</div>}
                  value={statisticsData?.totalAdCash}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{fontSize: 12}}>新增收益</div>}
                  value={statisticsData?.todayAdCash}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              {/* <Col span={7}>
                <Statistic
                  title={<div style={{fontSize: 12}}>总回收</div>}
                  value={statisticsData?.total_money}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col> */}
              <Col span={8}>
                <Statistic
                  title={<div style={{fontSize: 12}}>总回收率</div>}
                  value={statisticsData?.totalRecoveryRate + '%'}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={9}>
                <Statistic
                  title={<div style={{fontSize: 12}}>新增回收率</div>}
                  value={statisticsData?.newRecoveryRate + '%'}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
          </div>
        }
      >
        <Table
          dataSource={list}
          columns={columns()}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
          pageOptions={[20, 50, 80]}
          onChange={onSortChange}
        />
      </Card>
    </>
  );
}
