const columns = () => [
    {
        title: "时间点",
        width: 120,
        align: "center",
        dataIndex: "point_in_time",
        key: "point_in_time",
    },
    {
        title: "剧名",
        width: 150,
        align: "center",
        dataIndex: "book_name",
        key: "book_name",
    },
    {
        title: "分时消耗",
        width: 80,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "总消耗",
        width: 80,
        align: "center",
        dataIndex: "total_cost",
        key: "total_cost",
    },
    {
        title: "转化数",
        width: 80,
        align: "center",
        dataIndex: "convert",
        key: "convert",
    },
    {
        title: "回收",
        width: 80,
        align: "center",
        dataIndex: "money",
        key: "money",
        render: value => (
            value || 0
        )
    },
    {
        title: "回收率",
        width: 80,
        align: "center",
        dataIndex: "huishoulv",
        key: "huishoulv",
        render: value => (
            value || '-'
        )
    },
    {
        title: "进粉累计",
        width: 80,
        align: "center",
        dataIndex: "total_user",
        key: "total_user",
        render: value => (
            value || 0
        )
    },
    {
        title: "客单价",
        width: 80,
        align: "center",
        dataIndex: "kedans",
        key: "kedans",
        render: value => (
            value || 0
        )
    },
]

export default columns