import { Button, Card, DatePicker, Form, message } from "antd";
import React from "react";
import Table from "../../../../../components/TableNoSelection";
import columns from "./columns";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { playletRecycleTrend } from "../../../../../api/statistics";
import { payQuPlayletUpdate } from "../../../../../api/promotion";

const { RangePicker } = DatePicker;

// 付费趋势
export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getList = async (params = {}) => {
    setLoading(true);
    const res = await playletRecycleTrend({ ...params, id: state?.id });
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      let newList = lists?.map((item) => {
        item.key = item.id;
        return item;
      });
      setTotal(data?.total);
      setList(newList);
      setLoading(false);
    }
  };

  let addColumnsOptions = [];

  // 动态添加第1天到第7天的列
  for (let i = 0; i <= 6; i++) {
    addColumnsOptions.push({
      title: `第${i + 1}天`,
      width: 130,
      align: "center",
      dataIndex: `day${i}`,
      key: `day${i}`,
      render: (_, record) => {
        let day = `day${i}`;
        let extend = record?.rangeData;
        let recharge = extend[i]?.[day]?.recharge || 0;
        let return_cost = extend[i]?.[day]?.return_cost || "0.00%";
        return (
          <>
            <div style={{ textAlign: "left" }}>充值：{recharge}</div>
            <div style={{ textAlign: "left" }}>回本率：{return_cost}</div>
          </>
        );
      },
    });
  }

  // 根据日期筛选
  const onSearch = (values) => {
    let createdAt = [];
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    let params = { range_date: createdAt };
    getList(params);
  };

  const onChange = async (e, field, id) => {
    if (e.keyCode === 13) {
      let value = e.target.value;
      let msg = field === "cost" ? "成本" : "赔付";
      let params = { id, [field]: parseFloat(value) };
      console.log(params);
      const res = await payQuPlayletUpdate(params);
      if (res?.code === 200) {
        message.success(msg + "设置成功");
        getList({id: state?.id});
      }
    }
  };

  const showCostTrend = (record) => {
    console.log(record);
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  let tableColumns = columns({ onChange, showCostTrend });
  let newColumns = tableColumns.concat(addColumnsOptions);

  return (
    <Card>
      <Card
        type="inner"
        title={
          <Form layout="inline" onFinish={onSearch}>
            <Form.Item name="range_date">
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        }
        extra={
          <Button
            size="small"
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        }
      >
        <Table
          sticky
          dataSource={list}
          columns={newColumns}
          loading={loading}
          bordered={true}
          total={total}
          page={page}
          perPage={perPage}
          pagesize={pagesize}
        />
      </Card>
    </Card>
  );
}
