import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import { useState } from "react";
import { playletOrderPayRatio } from "../../../../api/playlet";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default function Index(props) {
  const [isShow, setIsShow] = useState(false);
  const [ratioList, setRatioList] = useState([]);
  const [ratioLoading, setRatioLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const { list, total, page, loading, pagesize, perPage } = props;

  // 获取列表数据
  const getList = async (params = {}) => {
    setRatioLoading(true);
    const res = await playletOrderPayRatio({
      ...params,
      app_type: props.app_type,
    });
    if (res?.code === 200) {
      const { data } = res;
      setRatioList(data?.lists);
      setRatioLoading(false);
    }
  };

  // 支付时间查询
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.range_date;
    }
    if (
      values["min_money"] &&
      values["max_money"] &&
      values["max_money"] < values["min_money"]
    ) {
      message.error("金额范围条件查询错误");
      return false;
    }
    let params = { ...conditions, range_date: rangeDate, ...values };
    setConditions(params);
    props.onSearch({ ...conditions, ...params });
  };

  // 查看支付占比弹窗
  const showRatioModal = () => {
    setIsShow(true);
    getList(conditions);
  };

  // 关闭弹窗
  const modalClose = () => {
    setIsShow(false);
  };

  // 支付占比显示字段
  const ratioColumns = [
    {
      title: "单笔充值金额",
      width: 100,
      align: "center",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "总计充值金额",
      width: 100,
      align: "center",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "充值人数",
      width: 100,
      align: "center",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "充值占比",
      width: 100,
      align: "center",
      dataIndex: "ratio",
      key: "ratio",
    },
    {
      title: "复充率",
      width: 100,
      align: "center",
      dataIndex: "repeatRate",
      key: "repeatRate",
    },
  ];

  return (
    <>
      <Card
        title={
          <Space>
            <Form onFinish={onSubmit}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="支付时间" name="range_date">
                    <RangePicker
                      size="small"
                      defaultValue={[
                        dayjs(dayjs(), "YYYY-MM-DD"),
                        dayjs(dayjs(), "YYYY-MM-DD"),
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="广告ID" name="ad_id">
                    <Input allowClear size="small" placeholder="输入广告 id" />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="订单号" name="order_num">
                    <Input allowClear size="small" placeholder="输入订单号" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="用户id" name="user_id">
                    <Input allowClear size="small" placeholder="输入用户id" />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="外链id" name="promotion_id">
                    <Input allowClear size="small" placeholder="输入外链id" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="金额范围" style={{ marginBottom: -10 }}>
                    <Form.Item
                      name="min_money"
                      style={{ display: "inline-block" }}
                    >
                      <InputNumber
                        size="small"
                        placeholder="最小值"
                        style={{
                          width: "60%",
                        }}
                        min={0}
                      />
                    </Form.Item>
                    <div
                      style={{
                        display: "inline-block",
                        paddingTop: 3,
                        marginLeft: -69,
                      }}
                    >
                      -
                    </div>
                    <Form.Item
                      name="max_money"
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <InputNumber
                        size="small"
                        placeholder="最大值"
                        style={{
                          width: "60%",
                        }}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                {props?.category ? (
                  <Col span={8}>
                    <Form.Item
                      label="剧场"
                      name="category"
                      labelCol={{ sm: { span: 4 } }}
                    >
                      <Select
                        allowClear
                        placeholder="选择剧场"
                        options={props.playlets}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                <Col span={4}>
                  <Form.Item style={{ marginLeft: 20 }}>
                    <Button
                      type="primary"
                      size="small"
                      onClick={showRatioModal}
                    >
                      查看支付占比
                    </Button>
                  </Form.Item>
                </Col>

                <Col span={2}>
                  <Form.Item>
                    <Button type="primary" size="small" htmlType="submit">
                      确定
                    </Button>
                  </Form.Item>
                </Col>
                {props.totalMoney ? (
                  <Col span={4}>
                    <Form.Item label="订单总金额" style={{ marginLeft: 50 }}>
                      <Typography.Text type="danger">
                        {parseFloat(props.totalMoney).toFixed(2)}
                      </Typography.Text>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Form>
          </Space>
        }
        headStyle={{ paddingTop: 10 }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          dataSource={list}
          columns={columns()}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
          onChange={props.onChange}
        />
      </Card>

      {/* 支付占比弹窗 */}
      <Modal
        open={isShow}
        onCancel={modalClose}
        width={650}
        footer={null}
        style={{ height: 700, overflowY: "auto" }}
      >
        <Card style={{ marginTop: 20 }} size="small">
          <Table
            size="small"
            dataSource={ratioList}
            columns={ratioColumns}
            loading={ratioLoading}
            pagination={false}
            bordered={true}
          />
        </Card>
      </Modal>
    </>
  );
}
