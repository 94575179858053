import { Button } from "antd"

const columns = ({deleteAccount}) => [
    {
        title: "转化名称",
        width: 150,
        align: "center",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
    },
    {
        title: "广告主",
        width: 165,
        align: "center",
        dataIndex: "advertiser_id_info",
        key: "advertiser_id_info",
        render: (_, record) => {
            return <>
                <div>{record?.advertiser?.account_name}</div>
                <div>({record?.advertiser_id})</div>
            </>
        }
    },
    {
        title: "转化方式",
        width: 110,
        align: "center",
        dataIndex: "convert_source_type",
        key: "convert_source_type",
    },
    {
        title: "转化类型",
        width: 120,
        align: "center",
        dataIndex: "convert_type",
        key: "convert_type",
        render: value => (
            '激活且付费'
        )
    },
    {
        title: "操作",
        width: 100,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_,record) => (
            <Button type="primary" onClick={() => deleteAccount(record)}>删除账户</Button>
        )
    },
    // {
    //     title: "落地页",
    //     width: 200,
    //     align: "center",
    //     dataIndex: "external_url",
    //     key: "external_url",
    //     ellipsis: true,
    // },
    // {
    //     title: "监测链接",
    //     width: 200,
    //     align: "center",
    //     dataIndex: "action_track_url",
    //     key: "action_track_url",
    //     ellipsis: true,
    // },
    // {
    //     title: "状态",
    //     width: 120,
    //     align: "center",
    //     dataIndex: "result_status",
    //     key: "result_status",
    //     render: (_, record) => {
    //         let info = record.result && JSON.parse(record.result)
    //         if (info && Array.isArray(info)) {
    //             return `code: ${info?.code}, message: ${info?.message}`
    //         }
    //         return ''
    //     }
    // },
]

export default columns