import Card from 'antd/es/card/Card'
import React from 'react'
import Table from '../../../components/TableNoSelection'
import columns from './columns'
import { useState } from 'react'
import { useEffect } from 'react'
import { doPushAgain, getBackFilterData, getBackRecord } from '../../../api/advertise'
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, message } from 'antd'
import { useLocation, useNavigate } from 'react-router'

const { RangePicker } = DatePicker

export default function Index() {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [promotionOptions, setPromotionOptions] = useState([])
  const [packageOptions, setPackageOptions] = useState([])
  const [channelOptions, setChannelOptions] = useState([])
  const [conditions, setConditions] = useState([])

  const {state} = useLocation()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  if (state) {
    form.setFieldsValue({aid: state?.ad_id})
  }

  useEffect(() => {
    getList()
    getFilterDataList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true)
    if (state) {
      params.aid = state?.ad_id
    }
    const res = await getBackRecord(params)
    const { data } = res
    if (res?.code === 200) {
      setList(data?.lists)
      setLoading(false)
      setTotal(data?.total)
    }
  }

  // 表单筛选数据
  const getFilterDataList = async () => {
    const res = await getBackFilterData()
    const { data } = res
    if (res?.code === 200) {
      let promotions = data?.promotions
      let channels = data?.channels
      let packages = data?.packages
      let newPromotions = promotions.map((item) => {
        return { label: item?.remark, value: item?.id }
      })
      let newChannels = channels.map((item) => {
        return { label: item?.nickname, value: item?.id }
      })
      let newPackages = packages.map((item) => {
        return { label: item?.aliasname, value: item?.app_type }
      })
      setPromotionOptions(newPromotions)
      setChannelOptions(newChannels)
      setPackageOptions(newPackages)
    }
  }

  // 表单筛选查询
  const onSearch = values => {
    let rangeDate
    if (values['create_time']) {
      rangeDate = values['create_time'].map((date) => {
        return date.format('YYYY-MM-DD')
      })
      values.create_time = rangeDate
    }
    let params = {...conditions, ...values}
    setConditions(params)
    getList(params)
  }

  // 表单重置
  const doReset = () => {
    navigate('/admin/statistics/back_record_toutiao', {state: null})
    form.resetFields()
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPerPage(size)
    let params = {...conditions, page, pageSize: size }
    setConditions(params)
    getList(params)
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  }

  // 重新回传
  const handlePushAgain = async id => {
    const res = await doPushAgain({id})
    if (res?.code === 200) {
      message.success('回传成功', 2)
      getList();
    }
  }

  return (
    <Card
      title={
        <Form onFinish={onSearch} form={form}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label='渠道' name='uid'>
                <Select
                  placeholder='选择渠道'
                  allowClear
                  options={channelOptions}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='推广链接' name='promotion_id'>
                <Select
                  placeholder='选择推广链接'
                  allowClear
                  options={promotionOptions}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='应用包' name='app_type'>
                <Select
                  placeholder='选择应用包'
                  allowClear
                  options={packageOptions}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label='回传时间' name='create_time'>
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='计划' name='aid'>
                <Input placeholder='搜索计划' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Space>
                  <Button type='primary' htmlType='submit'>确定</Button>
                  <Button onClick={doReset}>重置</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
      headStyle={{ paddingTop: 10 }}
    >
      <Table
        dataSource={list}
        columns={columns({handlePushAgain})}
        page={page}
        perPage={perPage}
        total={total}
        loading={loading}
        pagesize={pagesize}
      />
    </Card>
  )
}
