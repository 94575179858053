let OSS = require('ali-oss')
 
let client = new OSS({
  region: 'oss-cn-hangzhou',    //创建的时候，bucket所在的区域
  accessKeyId: 'LTAI4GBLqZkSAQJRYpq3pz6U',// 阿里云控制台创建的AccessKey
  accessKeySecret: 'hrxbUL59AWOyDqwUS4SpkwGUN7Y8CO', //阿里云控制台创建的AccessSecret
  bucket: 'advertiser-material'  //创建的bucket的名称
})
 
//普通上传
export const upload = async (ObjName, fileUrl) => {  
  try {    
    let result = await client.put(`${ObjName}`, fileUrl)    
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
    return result  
  } catch (e) {    
   console.log(e)  
  }
}
//分片上传
export const multipartUpload = async (ObjName, fileUrl, options = {}) => {  
    try {    
      let result = await client.multipartUpload(`${ObjName}`, fileUrl, options)    
      // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
      return result  
    } catch (e) {    
     console.log(e)  
    }
  }
 
// 上传成功之后，转换真实的地址
export const signatureUrl= async (ObjName) => {    
  try {        
    let result = await client.signatureUrl(`${ObjName}`)    
    return result  
  } catch (e) {    
    console.log(e)  
  }
}
 
export const getFileNameUUID = () => {  
  function rx() {    
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)  
  }  
  return `${+new Date()}_${rx()}`
}