import { Button, Space } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { EyeOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";

const columns = ({ deleteRecord, retryErrorTask }) => [
  {
    title: "记录ID",
    width: 100,
    align: "center",
    dataIndex: "id",
    key: "id",
    fixed: "left",
  },
  {
    title: "广告名称",
    width: 120,
    align: "center",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    fixed: "left",
  },
  {
    title: "广告创建者",
    width: 120,
    align: "center",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    render: (_, record) => record?.admin_user?.nickname,
  },
  {
    title: "广告计划执行结果",
    width: 160,
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      let adId = record?.ad_id;
      if (record?.total_num === record?.exec_num) {
        return <div>共{record?.total_num}个广告执行中</div>;
      } else {
        if (adId) {
          return (
            <>
              <div>
                {adId}等{record?.success_num}个广告完成
              </div>
              <div>执行中：{record?.exec_num}</div>
              <div>失败数：{record?.error_num}</div>
            </>
          );
        } else {
          return (
            <>
              <div>共{record?.total_num}个广告</div>
              <div>失败数：{record?.error_num}</div>
              <div>执行中：{record?.exec_num}</div>
            </>
          );
        }
      }
    },
  },
  {
    title: "转化类型",
    width: 120,
    align: "center",
    dataIndex: "change_info",
    key: "change_info",
    render: (_, record) => {
      let type = record?.project?.landing_type;
      let externalAction = record?.project?.external_action
      let info
      type === "QUICK_APP"
        ? info = "快应用一跳"
        : type === "APP"
        ? info = "应用(二跳)"
        : type === "MICRO_GAME"
        ? info = "小程序"
        : info = "线索";
        return <>
          <div>{info}</div>
          <div>【{externalAction === 'AD_CONVERT_TYPE_PAY' ? '付费' : externalAction === 'AD_CONVERT_TYPE_GAME_ADDICTION' ? '关键行为' : '激活'}】</div>
        </>
    },
  },
  {
    title: "广告主",
    width: 160,
    align: "center",
    dataIndex: "advertiser_ids",
    key: "advertiser_ids",
    ellipsis: true,
    render: (text, record) => {
      let advertiseToutiao = record?.advertise_toutiao;
      if (advertiseToutiao && advertiseToutiao.length > 0) {
        return advertiseToutiao
          .map((item) => {
            return item.account_name;
          })
          .join(",");
      }
      return text;
    },
  },
  {
    title: "广告出价",
    width: 100,
    align: "center",
    dataIndex: "cpa_bid",
    key: "cpa_bid",
  },
  {
    title: "项目出价",
    width: 100,
    align: "center",
    dataIndex: "project_cpa_bid",
    key: "project_cpa_bid",
    render: (_, record) => (
      record?.project?.cpa_bid || 0
    )
  },
  {
    title: "创建时间",
    width: 180,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "操作",
    width: 250,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    fixed: "right",
    render: (_, record) => (
      <>
        <Space>
          <Button type="primary" ghost size="small" icon={<EyeOutlined />}>
            <Link
              to="/admin/batch_plan/advertise_new/detail"
              state={{ id: record.id }}
            >
              查看详情
            </Link>
          </Button>
          <Button type="primary" ghost size="small" icon={<CopyOutlined />}>
            <Link
              to={record?.project?.change_id ? "/admin/batch_plan/advertise_new/select_add" : '/admin/batch_plan/simplify_add'}
              state={{ record, operate: "copy" }}
            >
              复制
            </Link>
          </Button>
          <Button
            type="primary"
            size="small"
            danger
            icon={<DeleteOutlined />}
            onClick={() => deleteRecord(record)}
          >
            删除
          </Button>
        </Space>
        {record?.error_num > 0 ? (
          <div style={{ marginTop: 10, textAlign: "left", marginLeft: 42 }}>
            <Button
              type="primary"
              size="small"
              onClick={() => retryErrorTask(record)}
            >
              重新执行失败计划
            </Button>
          </div>
        ) : (
          ""
        )}
      </>
    ),
  },
];

export default columns;
