import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import {
  getPromotionConfig,
  getQuickAppList,
  getThirdBooks,
  promotionConfigSet,
} from "../../../api/advertise";
import { useEffect } from "react";
import {
  createChapterUrl,
  getBookChapter,
  getPitcherList,
  getPromotionList,
  promotionRemarkEdit,
  promotionUpdate,
} from "../../../api";
import {
  getDefaultQianZi,
  getPackage,
  getResourceScore,
} from "../../../api/promotion";

const { Text } = Typography;

/** 推广链接列表组件 */
export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [backShow, setBackShow] = useState(false);
  const [application, setApplication] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [bookOptions, setBookOptions] = useState([]);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [tips, setTips] = useState("用于检测能看到第几章");
  const [pitchers, setPitchers] = useState([]);

  const [form] = Form.useForm();
  const [backForm] = Form.useForm();

  useEffect(() => {
    getList();
    getApplications();
    getPitchers();
  }, []);

  const getList = async (params = {}) => {
    const res = await getPromotionList(params);
    const { data } = res;
    if (res.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 获取投手信息
  const getPitchers = async () => {
    const res = await getPitcherList();
    if (res?.code === 200) {
      const { data } = res;
      const newList = data.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setPitchers(newList);
    }
  };

  // 应用包
  const getApplications = async () => {
    const res = await getPackage({ type: 1 });
    const { data } = res;
    if (res?.code === 200) {
      let quickPackage = data?.lists;
      let newQuick = quickPackage.map((item) => {
        return {
          label: item.aliasname,
          value: item.package,
          app_type: item.app_type,
        };
      });
      setApplication(newQuick);
    }
  };

  // 获取对应包的渠道
  const getQuickApp = async (params = {}) => {
    const res = await getQuickAppList(params);
    const { data } = res;
    if (res?.code === 200) {
      const newList = data.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setChannelOptions(newList);
    }
  };

  // 获取投放的书籍信息
  const getBooks = async (params = {}) => {
    const res = await getThirdBooks(params);
    const { data } = res;
    if (res?.code === 200) {
      const lists = data?.lists;
      const newList = lists.map((item) => {
        return { label: item.title, value: item.book_id };
      });
      setBookOptions(newList);
      form.setFieldsValue({ book_id: newList[0]?.value });
    }
  };

  // 获取推广小说章节
  const getChapter = async (params = {}) => {
    const res = await getBookChapter(params);
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      const newLists = lists.map((item) => {
        return { label: item.title, value: item.id };
      });
      setChapterOptions(newLists);
    }
  };

  // 应用包切换
  const applicationChange = (e) => {
    let bag = e.target.value;
    form.setFieldsValue({
      channel: "",
      bid: "",
      cid: "",
      follow_chapter: "",
      words_charge: "",
      charge_chapter: "",
    });
    let filterd = application.filter((item) => {
      return item.value === bag;
    });
    getQuickApp({ bag });
    getBooks({
      uid_type: filterd[0]?.uid_type,
      application_type: filterd[0]?.app_type,
    });
  };

  // 根据渠道切换或书籍切换获取默认收费信息
  const getDefaultInfo = async (params = {}) => {
    const res = await getDefaultQianZi(params);
    return res;
  };

  // 充值获得的书币书券额度
  const getScores = async (params = {}) => {
    const res = await getResourceScore(params);
    const { data } = res;
    if (res?.code === 200) {
      setTips(data?.text);
    }
  };

  // 渠道切换
  const channelSelect = (value) => {
    form.setFieldsValue({ bid: "" });
  };

  // 小说切换
  const onSelect = (value) => {
    let uid = form.getFieldValue("channel");
    getChapter({ book_id: value });
    form.setFieldsValue({ cid: "" });
    // 只有当渠道切换获取到的默认信息为空时，才需要根据小说来获取默认记录
    let res = getDefaultInfo({ uid, bid: value });
    res.then((res) => {
      const {
        data: { data },
      } = res;
      if (res?.code === 200 && data) {
        let qianzimoney = data?.qianzimoney ? data?.qianzimoney : 16;
        let charge = data?.sfzj ? data?.sfzj : 24;
        form.setFieldsValue({ follow_chapter: data?.gzzj ? data?.gzzj : 7 });
        form.setFieldsValue({ words_charge: qianzimoney });
        form.setFieldsValue({ charge_chapter: charge });
      }
    });
  };

  // 获得的书币书券额度
  const handleScore = (e, field) => {
    let params = form.getFieldsValue([
      "bid",
      "words_charge",
      "charge_chapter",
      "score",
    ]);
    if (
      params?.bid &&
      params?.words_charge &&
      params?.charge_chapter &&
      params?.score
    ) {
      getScores(params);
    }
  };

  // 表单布局
  const layout = {
    labelCol: { sm: { span: 4 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 生成推广链接弹窗
  const generateUrl = () => {
    if (application.length === 0) {
      message.error("还未分配或绑定应用包", 2);
      return false;
    }
    setType("add");
    setShow(true);
  };

  // 关闭弹窗
  const onCancel = () => {
    form.resetFields();
    setShow(false);
    setBtnLoading(false);
    setTips("用于检测能看到第几章");
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 表单提交
  const onSubmit = async (values) => {
    let filterd = bookOptions.filter((item) => {
      return item.value === values.bid;
    });
    if (filterd.length > 0) {
      values.book_title = filterd[0]?.label;
    }
    let appFilterd = application.filter((item) => {
      return item.value === values.app_bag;
    });
    if (appFilterd.length > 0) {
      values.bag_title = appFilterd[0]?.label;
    }
    setBtnLoading(true);
    if (type === "add") {
      doCreate(values);
    } else {
      doUpdate(values);
    }
  };

  // 生成推广链接
  const doCreate = async (params = {}) => {
    const res = await createChapterUrl(params);
    if (res.code === 200) {
      message.success("推广链接生成成功", 1);
      setTimeout(() => {
        setBtnLoading(false);
        onCancel();
        // 调用获取列表接口
        getList();
      }, 1000);
    } else {
      setBtnLoading(false);
    }
  };

  // 修改推广链接
  const doUpdate = async (params = {}) => {
    const res = await promotionUpdate(params);
    if (res.code === 200) {
      message.success("修改链接生成成功", 1);
      setTimeout(() => {
        setBtnLoading(false);
        onCancel();
        // 调用获取列表接口
        getList();
      }, 1000);
    } else {
      setBtnLoading(false);
    }
  };

  // 修改备注
  const onChange = async (e, record) => {
    let remark = e.target.value;
    if (e.keyCode === 13) {
      const res = await promotionRemarkEdit({ id: record.id, remark });
      if (res.code === 200) {
        message.success("修改备注成功", 1);
        const newList = list.map((item) => {
          if (item.id === record.id) {
            item.remark = remark;
          }
          return item;
        });
        setList(newList);
      }
    }
  };

  // 修改推广链接
  const handleUpdate = (record) => {
    let channel = parseInt(record?.uid);
    let bid = parseInt(record?.bid);
    let cid = parseInt(record?.cid);
    getQuickApp({ bag: record?.app_bag });
    let filterd = application.filter((item) => {
      return item.app_bag === record?.app_bag;
    });
    getBooks({ uid_type: filterd[0]?.uid_type });
    getChapter({ book_id: bid });
    form.setFieldsValue({ ...record, channel, bid, cid });
    setType("edit");
    setShow(true);
  };

  const pagesize = (page, size) => {
    setPage(page);
    getList({ page, pageSize: size });
  };

  // 获取已配置的回传设置
  const getConfig = async (params = {}) => {
    const res = await getPromotionConfig(params);
    const { data } = res;
    if (res?.code === 200) {
      backForm.setFieldsValue(data);
    }
  };

  // 回传设置
  const backReset = (record) => {
    backForm.setFieldsValue({ id: record.id });
    getConfig(record);
    setBackShow(true);
  };

  // 关闭回传设置弹窗
  const onBackCancel = () => {
    backForm.resetFields();
    setBackShow(false);
    setBackLoading(false);
  };

  // 回传设置表单提交
  const backFormSubmit = async (values) => {
    setBackLoading(true);
    const res = await promotionConfigSet(values);
    if (res?.code === 200) {
      message.success("设置成功", 1);
      setTimeout(() => {
        onBackCancel();
      }, 1000);
    } else {
      setBackLoading(false);
    }
  };
  
  const pitcherChange = value => {
    getList({admin_id: value})
  }

  return (
    <div className="promotion">
      <Card
        title={
          <Button type="primary" onClick={generateUrl}>
            生成推广链接
          </Button>
        }
        extra={
          <Form layout="inline">
            <Form.Item label="投手筛选">
              <Select
                allowClear
                placeholder="选择投手"
                options={pitchers}
                style={{ width: 180 }}
                onChange={pitcherChange}
              />
            </Form.Item>
          </Form>
        }
      >
        <Table
          dataSource={list}
          columns={columns({ onChange, handleUpdate, backReset })}
          bordered={true}
          total={total}
          pagesize={pagesize}
          page={page}
          loading={loading}
        />
      </Card>

      {/* 生成推广链接弹窗 */}
      <Modal
        title={
          <Card
            title={type === "add" ? "生成推广链接" : "修改推广链接"}
            style={{ border: "none" }}
            bodyStyle={{ display: "none" }}
          />
        }
        open={show}
        onCancel={onCancel}
        width={800}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          {...layout}
          onFinish={onSubmit}
          form={form}
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="应用包"
            name="app_bag"
            rules={[{ required: true, message: "请选择应用包" }]}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group options={application} onChange={applicationChange} />
          </Form.Item>
          <Form.Item
            label="选择渠道"
            name="channel"
            rules={[{ required: true, message: "请选择渠道" }]}
          >
            <Select
              allowClear
              style={{ width: "90%" }}
              options={channelOptions}
              showSearch
              filterOption={filterOption}
              onSelect={channelSelect}
            />
          </Form.Item>
          <Form.Item
            label="推广小说"
            name="bid"
            rules={[{ required: true, message: "请选择推广小说" }]}
          >
            <Select
              allowClear
              style={{ width: "90%" }}
              options={bookOptions}
              showSearch
              filterOption={filterOption}
              onSelect={onSelect}
            />
          </Form.Item>
          <Form.Item
            label="推广小说章节"
            name="cid"
            rules={[{ required: true, message: "请选择推广小说章节" }]}
          >
            <Select
              allowClear
              style={{ width: "90%" }}
              options={chapterOptions}
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item label="加桌章节">
            <Form.Item name="follow_chapter" style={{ marginBottom: 0 }}>
              <Input
                style={{ width: "90%" }}
                type="number"
                min={1}
                placeholder="不填默认第 7 章"
              />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              (默认第 7 章)
            </Text>
          </Form.Item>
          <Form.Item label="千字收费">
            <Form.Item name="words_charge" style={{ marginBottom: 0 }}>
              <Input
                style={{ width: "90%" }}
                type="number"
                min={1}
                placeholder="不填默认 16 书币"
                onBlur={(e) => handleScore(e, "words_charge")}
              />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              (默认 16 书币)
            </Text>
          </Form.Item>
          <Form.Item label="收费章节">
            <Form.Item name="charge_chapter" style={{ marginBottom: 0 }}>
              <Input
                style={{ width: "90%" }}
                type="number"
                min={1}
                placeholder="不填默认第 24 章"
                onBlur={(e) => handleScore(e, "charge_chapter")}
              />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              (默认第 24 章)
            </Text>
          </Form.Item>
          <Form.Item label="假设充值获得的书币书券额度">
            <Form.Item name="score" style={{ marginBottom: 0 }}>
              <Input
                style={{ width: "90%" }}
                type="number"
                min={1}
                onBlur={(e) => handleScore(e, "score")}
              />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              ({tips})
            </Text>
          </Form.Item>
          <Form.Item label colon={false} labelCol={{ span: 17 }}>
            <Space>
              <Button onClick={onCancel}>取消</Button>
              <Button type="primary" htmlType="submit" loading={btnLoading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {/* 回传设置弹窗 */}
      <Modal
        title="回传设置"
        open={backShow}
        onCancel={onBackCancel}
        width={800}
        footer={null}
        destroyOnClose={true}
      >
        <Divider></Divider>
        <Form
          layout="vertical"
          form={backForm}
          onFinish={backFormSubmit}
          initialValues={{
            hour: 0,
            max_money: 0,
          }}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name="hour"
                label={
                  <Space>
                    回传几小时内
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      (默认24小时)
                    </Text>
                  </Space>
                }
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="min_money"
                label={
                  <Space>
                    回传金额下限
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      (小于该金额的不回传)
                    </Text>
                  </Space>
                }
              >
                <Input type="number" min={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="max_money"
                label={
                  <Space>
                    回传金额上限
                    <Text
                      type="secondary"
                      style={{ fontSize: 12, width: 130 }}
                      ellipsis={{
                        tooltip: "大于该金额的不回传, 不填或者填0依旧回传",
                      }}
                    >
                      (大于该金额的不回传, 不填或者填0依旧回传)
                    </Text>
                  </Space>
                }
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name="num"
                label={
                  <Space>
                    每几条
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      (不填默认为1)
                    </Text>
                  </Space>
                }
                rules={[{ required: true, message: "每几条数不能为空" }]}
              >
                <Input type="number" min={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="back_num"
                label={
                  <Space>
                    回传几条
                    <Text
                      type="secondary"
                      style={{ fontSize: 12, width: 150 }}
                      ellipsis={{
                        tooltip:
                          "默认为1, 如每几条填5, 这里填4就是每5条回传4条, 相当于80%回传",
                      }}
                    >
                      (默认为1, 如每几条填5, 这里填4就是每5条回传4条,
                      相当于80%回传)
                    </Text>
                  </Space>
                }
                rules={[{ required: true, message: "回传条数不能为空" }]}
              >
                <Input type="number" min={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label colon={false}>
                <Space>
                  <Button onClick={onBackCancel}>取消</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={backLoading}
                  >
                    确定
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
