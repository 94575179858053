import { Button, Col, Popover, Row, Space } from "antd"
import dayjs from "dayjs";
import { MoreOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

const options = [
    '现代言情', '历史架空', '古代言情', '社会风云',
    '悬疑推理', '仙侠奇缘', '游戏科幻', '玄幻仙侠',
    '都市异能', '精彩书库', '年代种田'
]

const columns = ({ materialDel, materialLock, materialShare, handleRemark }) => [
    {
        title: "视频",
        width: 250,
        align: "center",
        dataIndex: "video",
        key: "video",
        ellipsis: true,
        fixed: 'left',
        render: (_, record) => {
            return <Row justify="start">
                <Col span={7}>
                    <Popover placement="right" content={
                        <img 
                        alt="" 
                        src={record?.attach_img?.oss_url} 
                        width={300}
                    />
                    }>
                        <img 
                            alt="" 
                            src={record?.attach_img?.oss_url} 
                            width={50} 
                        />
                    </Popover>
                </Col>
                <Col span={2} style={{marginTop: -6}}>
                    <div>
                    {record?.title}
                    <div>ID:{record?.photo_id}</div>
                    </div>
                </Col>
            </Row>
        }
    },
    {
        title: "素材类型",
        width: 120,
        align: "center",
        dataIndex: "type",
        key: "type",
        render: text => (
            text === 1 ? '竖版视频' : (text === 2 ? '横版视频' : '大图横图')
        )
    },
    // {
    //     title: "标签",
    //     width: 100,
    //     align: "center",
    //     dataIndex: "label",
    //     key: "label",
    //     render: text => {
    //         let arr = text.split(',');
    //         return arr.map((tag, key) => {
    //             return <Tag color="#108ee9" style={{ marginBottom: 1 }} key={key}>{tag}</Tag>
    //         })
    //     }
    // },
    {
        title: "分类",
        width: 100,
        align: "center",
        dataIndex: "category",
        key: "category",
        render: text => (
            options[text]
        )
    },
    {
        title: "归属",
        width: 100,
        align: "center",
        dataIndex: "show_type",
        key: "show_type",
        render: text => (
            text === 1 ? '公有' : '私有'
        )
    },
    {
        title: "投放类别",
        width: 100,
        align: "center",
        dataIndex: "platform",
        key: "platform",
        render: text => (
            text === 'novel' ? '小说' : (text === 'playlet' ? '短剧' : '-')
        )
    },
    {
        title: "消耗",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        sorter: {}
    },
    {
        title: "被拒数",
        width: 100,
        align: "center",
        dataIndex: "audit_fail_num",
        key: "audit_fail_num",
    },
    {
        title: "上传者",
        width: 120,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
    },
    {
        title: "上传时间",
        width: 160,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: text => (
            dayjs.unix(text).format('YYYY-MM-DD HH:mm::ss')
        )
    },
    {
        title: "是否上锁",
        width: 100,
        align: "center",
        dataIndex: "lock",
        key: "lock",
        render: text => (
            text === 0 ? '未上锁' : '已上锁'
        )
    },
    {
        title: "备注",
        width: 150,
        align: "center",
        ellipsis: true,
        dataIndex: "remarks",
        key: "remarks",
    },
    {
        title: "操作",
        align: "center",
        width: 270,
        dataIndex: "operation",
        key: "operation",
        fixed: 'right',
        render: (_, record) => (
            <>
                <Space>
                    <Button type="primary" size="small" ghost>
                        <Link
                            to={`/admin/material/image/img_data_detail`}
                            state={{ ...record }}
                        >
                            数据详情
                        </Link>
                    </Button>
                    <Button type="primary" size="small" ghost>
                        <Link
                            to={`/admin/material/image/img_creative_cost`}
                            state={{ ...record }}
                        >
                            每日消耗
                        </Link>
                    </Button>
                    <Button type="primary" size="small" onClick={() => { materialShare(record) }}>素材共享</Button>
                    <Popover
                        placement="left"
                        content={
                            <>
                                <div>
                                    <Space>
                                        <Button type="primary" size="small" danger style={{ width: 120 }} onClick={() => { materialDel(record.id) }}>删除素材</Button>
                                        <Button type='primary' size="small" onClick={() => { handleRemark(record) }}>备注|状态修改</Button>
                                    </Space>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <Space>
                                        <Button type='dashed' size="small" danger style={{ width: 120 }} onClick={() => { materialLock(record) }}>{record.lock === 0 ? '素材上锁' : '素材解锁'}</Button>
                                        {/* <Button type="primary" style={{ width: 120 }} onClick={() => { materialShare(record) }}>{record.locked === 0 ? '素材共享' : '取消共享'}</Button> */}
                                    </Space>
                                </div>
                            </>
                        }>
                        <MoreOutlined />
                    </Popover>
                </Space>
            </>
        )
    },
]

export default columns