import {
  Button,
  Card,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Tag,
} from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FileUploader from "../../../../components/FileUploader/imageUploader";
import {
  getDefaultPitcher,
  getMaterialOptions,
  getPitcherList,
  materialAdd,
} from "../../../../api";
import { useEffect } from "react";
import dayjs from "dayjs";
import PitcherModal from "./PitcherModal";
import { getActiveOrg } from "../../../../api/permission";

const { TextArea } = Input;

export default function Add() {
  const [materialType, setMaterialType] = useState(3);
  const [materialCategory, setMaterialCategory] = useState(0);
  const [marterialBelong, setMarterialBelong] = useState(2);
  const [isShowImgModal, setIsShowImgModal] = useState(false);
  const [materialImages, setMaterialImages] = useState([]);
  const [attachIds, setAttachIds] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [pitcher, setPitcher] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pitcherLoading, setPitcherLoading] = useState(false);
  const [isShowPitcherModal, setIsShowPitcherModal] = useState(false);
  const [selectedPitcher, setSelectedPitcher] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    getOptions();
    getToushou();
    getDefaultToushou();
  }, []);

  // 素材类型
  const getOptions = async () => {
    const res = await getMaterialOptions();
    const categoryOptions = res?.data.map((value, key) => {
      return { label: value, value: key };
    });
    setMaterialOptions(categoryOptions);
  };

  // 获取组织架构数据
  const getOrganization = async () => {
    const res = await getActiveOrg()
    if (res?.code === 200) {
        const {data} = res
        const list = data?.lists
        const newList = list.map((item) => {
            return {label: item.name, value: item.id}
        })
        setOrganizationOptions(newList)
    }
  }

  // 获取投手数据
  const getToushou = async () => {
    setPitcherLoading(true)
    const res = await getPitcherList();
    const { data } = res;
    if (res?.code === 200) {
      const result = data?.map((item) => {
        item.key = item.id;
        return item;
      });
      setPitcher(result);
      setPitcherLoading(false);
    }
  };

  // 获取默认投手
  const getDefaultToushou = async () => {
    const res = await getDefaultPitcher();
    const { data } = res;
    if (res?.code === 200) {
        setSelectedPitcher(data?.lists)
        form.setFieldsValue({
          category: parseInt(data?.category),
          toushou: data?.lists,
          platform: data?.platform,
        });
      }
  };

  const layout = {
    labelCol: { xl: { span: 2 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 点击上传文件弹窗
  const uploadModal = (type) => {
    setIsShowImgModal(true);
  };

  // 关闭上传图片弹窗
  const closeImg = () => {
    setIsShowImgModal(false);
  };

  // 获取已上传完成的图片
  const getFileList = (data) => {
    closeImg();
    const ids = data.map((item) => {
      return item.attachId;
    });
    setAttachIds(ids);
    setMaterialImages(data);
  };

  // 选择投手弹窗
  const selectToushou = () => {
    setIsShowPitcherModal(true);
    getOrganization()
  };

  // 关闭投手归属弹窗
  const closePitcher = () => {
    setIsShowPitcherModal(false);
    getToushou()
  };

  // 确认选中投手
  const handleSelected = (selectKeys) => {
    const filtered = pitcher.filter((item) => {
      return selectKeys.includes(item.id);
    });
    setSelectedPitcher(filtered);
    form.setFieldsValue({ toushou: filtered });
  };

  // 投手名称筛选
  const pitcherOnSearch = params => {
    getToushou(params)
  }

  const pitcherTagClose = value => {
    const selectedKeys = form.getFieldValue('toushou')
    const newKeys = selectedKeys.filter((item) => {
        return item.id !== value
    })
    form.setFieldsValue({toushou: newKeys})
  }

  // 表单提交
  const handleSubmit = async (values) => {
    setLoading(true);
    if (materialImages.length === 0) {
      message.error("请上传图片素材", 2);
      setLoading(false);
      return;
    }
    let date;
    if (values["publish_time"]) {
      date = values["publish_time"].format("YYYY-MM-DD HH:mm:ss");
    }

    if (values["tags"]) {
      let tags = values["tags"].replace(/^\s\s*/, "").replace(/\s\s*$/, "");
      let newTags = tags.split("\n").map((item) => {
        return item.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
      });
      values.tags = newTags;
    }

    const res = await materialAdd({
      ...values,
      materialImages,
      publish_time: date,
      attachIds,
    });
    if (res?.code === 200) {
      message.success("图片素材添加成功", 2);
      setTimeout(() => {
        setLoading(false);
        navigate("/admin/material/image");
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  // 组装默认的素材名称（当前日期 + 后台登录账号或昵称 + 3位随机数）
  const getMaterialTitle = () => {
    let currentDate = dayjs().format("YYYYMMDD");
    let genRandom = ((Math.random() * (999 - 100 + 1)) | 0) + 100;
    let adminUser = localStorage.getItem("shortname")
      ? localStorage.getItem("shortname")
      : localStorage.getItem("username");
    return currentDate + "_" + adminUser + "_" + genRandom;
  };

  return (
    <div className="resource-local-add">
      <Card title="添加图片素材">
        <Form
          {...layout}
          onFinish={handleSubmit}
          initialValues={{ title: getMaterialTitle() }}
          form={form}
        >
          <Form.Item
            name="title"
            label="素材名称"
            rules={[{ required: true, message: "素材名称不能为空" }]}
          >
            <Input placeholder="请输入素材名称" />
          </Form.Item>
          <Form.Item name="type" label="素材类型" initialValue={materialType}>
            <Radio.Group
              onChange={(e) => {
                setMaterialType(e.target.value);
              }}
            >
              <Radio value={3}>横版大图</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="素材文件" name="material_files">
            <Button
              type="primary"
              ghost
              onClick={() => {
                uploadModal(materialType);
              }}
            >
              上传图片
            </Button>
            {materialImages.length > 0 ? (
              <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                {materialImages.map((item, key) => {
                  return (
                    <div style={{ marginRight: 10, marginTop: 2 }} key={key}>
                      <Image
                        width={100}
                        src={item?.oss_url}
                        style={{ border: "1px solid #F0F0F0", padding: 1 }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </Form.Item>
          <Form.Item
            label="投放类别"
            name="platform"
            rules={[{ required: true, message: "选择投放平台" }]}
          >
            <Radio.Group
              options={[
                { label: "小说", value: "novel" },
                { label: "短剧", value: "playlet" },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="category"
            label="分类"
            initialValue={materialCategory}
          >
            <Radio.Group
              options={materialOptions}
              onChange={(e) => {
                setMaterialCategory(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item name="tags" label="素材标签">
            <TextArea placeholder="多个标签换行" allowClear rows={6} />
          </Form.Item>
          <Form.Item name="publish_time" label="发布时间">
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>
          <Form.Item name="belong" label="归属" initialValue={marterialBelong}>
            <Radio.Group
              onChange={(e) => {
                setMarterialBelong(e.target.value);
              }}
            >
              <Radio value={1}>公有</Radio>
              <Radio value={2}>私有</Radio>
            </Radio.Group>
          </Form.Item>
          {marterialBelong === 2 ? (
            <Form.Item label="投手归属" name="toushou">
              <Input
                readOnly
                style={{ backgroundColor: "#eee" }}
                onClick={selectToushou}
              />
              {/* <Select options={pitcher} mode='multiple' allowClear defaultValue={defaultPitcher} filterOption={filterOption} /> */}
              {selectedPitcher ? (
                <div style={{marginTop: 5}}>
                  {selectedPitcher.map((item) => {
                    return (
                      <Tag closable key={item.id} style={{marginTop: 5}} onClose={() => pitcherTagClose(item.id)}>
                        {item.nickname}
                      </Tag>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                提交
              </Button>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      {/* 选择归属投手弹窗 */}
      <PitcherModal
        isShow={isShowPitcherModal}
        closeModal={closePitcher}
        list={pitcher}
        handleSure={handleSelected}
        onSearch={pitcherOnSearch}
        orgOptions={organizationOptions}
        loading={pitcherLoading}
      />
      {/* 添加图片素材弹窗 */}
      <Modal
        open={isShowImgModal}
        onCancel={closeImg}
        width={800}
        maskClosable={false}
        closable={false}
        // onOk={handleSure}
        destroyOnClose={true}
        footer={null}
      >
        <FileUploader
          uploadBtnName="选择图片"
          getFileList={getFileList}
          closeModal={closeImg}
        />
      </Modal>
    </div>
  );
}
