import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import { filterPitchers } from "../../../api/statistics";
import dayjs from "dayjs";
import { getNewPlayletList, newPlayletAdd, newPlayletUpdate } from "../../../api";
import { getFastPlaylet } from "../../../api/playlet";

const { RangePicker } = DatePicker;
export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [modalType, setModalType] = useState('')

  useEffect(() => {
    getList();
    getPackages();
  }, []);

  const [form] = Form.useForm();
  const [selectForm] = Form.useForm();

  // 获取列表信息
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getNewPlayletList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 获取对应的三方剧场
  const getPackages = async () => {
    const res = await getFastPlaylet();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists?.map((item) => {
        return { label: item?.aliasname, value: item?.app_type };
      });
      setPackageList(newLists);
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 表单筛选
  const onFormSearch = () => {
    let values = selectForm.getFieldsValue();
    let rangeTime
    if (values?.range_time) {
        rangeTime = values?.range_time.map((date) => {
            return date.format('YYYY-MM-DD')
        })
    }
    values.first_issue = rangeTime
    getList(values)
  }

  // 添加新剧弹窗
  const addPlaylet = () => {
    setModalShow(true);
    setModalType('add')
    getPitchers();
  };

  // 编辑新剧弹窗
  const onUpdate = record => {
    record.app_type = parseInt(record.app_type)
    let adminIds = record.pitchers.split(',').map(value => (parseInt(value)))
    record.time = dayjs(record.first_issue)
    form.setFieldsValue(record)
    form.setFieldsValue({pitchers: adminIds})
    setModalShow(true);
    setModalType('update')
    getPitchers();
  }

  // 关闭添加新剧弹窗
  const onClose = () => {
    setModalShow(false);
    setConfirmLoading(false);
    form.resetFields();
  };

  // 当天之前的日期不可选
  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 处理新剧添加
  const handleAdd = async (params) => {
    const res = await newPlayletAdd(params);
    return res
  }

  // 处理新剧编辑
  const handleUpdate = async (params) => {
    const res = await newPlayletUpdate(params)
    return res
  }

  // 添加新剧表单提交
  const onSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        setConfirmLoading(true);
        const values = form.getFieldValue();
        let firstIssue = values.time.format("YYYY-MM-DD HH:mm");
        values.first_issue = firstIssue;
        let res = {}
        if (values?.id) {
          res = await handleUpdate(values)
        } else {
          res = await handleAdd(values)
        }
        if (res?.code === 200) {
          message.success("新剧配置成功");
          setTimeout(() => {
            onClose();
            getList();
          }, 1000);
        }
        setConfirmLoading(false);
      })
      .catch(() => {
        return;
      });
  };

  return (
    <>
      <Card
        title={
          <Form labelCol={{ span: 6 }} form={selectForm}>
            <Row gutter={12}>
              <Col span={8}>
                <Form.Item label="剧场" name="app_type">
                  <Select
                    allowClear
                    options={packageList}
                    placeholder="剧场筛选"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="短剧名称" name="name">
                  <Input allowClear placeholder="短剧名称" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="首发时间" name="range_time">
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label colon={false}>
                  <Space>
                    <Button type="primary" onClick={onFormSearch}>确定</Button>
                    <Button onClick={() => selectForm.resetFields()}>重置</Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
        extra={
          <Button type="primary" onClick={addPlaylet}>
            添加新剧
          </Button>
        }
      >
        <Table
          dataSource={list}
          columns={columns({onUpdate})}
          pagination={false}
          loading={loading}
        />
      </Card>
      {/* 添加新剧弹窗 */}
      <Modal
        title="新剧设置"
        open={modalShow}
        onCancel={onClose}
        width={650}
        onOk={onSubmit}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Divider />
        <Form form={form}>
          <Form.Item label colon={false} name='id' hidden>
            <Input />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="剧场名称"
                name="app_type"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  placeholder="请选择剧场"
                  showSearch
                  filterOption={filterOption}
                  options={packageList}
                  disabled={modalType === 'update'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="短剧名称"
                name="name"
                rules={[{ required: true }]}
              >
                <Input placeholder="请输入短剧名称" allowClear disabled={modalType === 'update'}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="首发时间"
                name="time"
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="首发时段"
                name="period"
                tooltip="新剧首发后超过了该时段就不算新剧,单位：小时"
                rules={[{ required: true, message: "请输入首发时段" }]}
                initialValue={2}
              >
                <InputNumber
                  placeholder="以小时为单位"
                  min={1}
                  style={{ width: 190 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="选择首发投手"
                name="pitchers"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="请选择投放投手"
                  filterOption={filterOption}
                  options={pitcherOptions}
                  mode="multiple"
                  autoClearSearchValue={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
