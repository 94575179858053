const columns =  () => [
    {
        title: "广告主ID",
        width: 160,
        align: "center",
        dataIndex: "advertiser_id",
        key: "advertiser_id",
        fixed: 'left',
    },
    {
        title: "广告组名称",
        width: 180,
        align: "center",
        dataIndex: "campaign_name_s",
        ellipsis: true,
        key: "campaign_name_s",
        fixed: 'left',
        render: (_, record) => (
            `${record?.campaign_name} (${record?.campaign_id})`
        )
    },
    {
        title: "计划名称",
        width: 180,
        align: "center",
        dataIndex: "ad_name_s",
        ellipsis: true,
        key: "ad_name_s",
        render: (_, record) => (
            `${record?.ad_name} (${record?.ad_id})`
        )
    },
    {
        title: "消耗",
        width: 120,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "展示",
        width: 120,
        align: "center",
        dataIndex: "show",
        key: "show",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "平均展示消耗",
        width: 120,
        align: "center",
        dataIndex: "avg_show_cost",
        key: "avg_show_cost",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "点击",
        width: 120,
        align: "center",
        dataIndex: "click",
        key: "click",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "点击率",
        width: 120,
        align: "center",
        dataIndex: "ctr",
        key: "ctr",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "平均点击消耗",
        width: 120,
        align: "center",
        dataIndex: "avg_click_cost",
        key: "avg_click_cost",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "转化数",
        width: 120,
        align: "center",
        dataIndex: "convert",
        key: "convert",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "平均转化成本",
        width: 120,
        align: "center",
        dataIndex: "convert_cost",
        key: "convert_cost",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "转化率",
        width: 120,
        align: "center",
        dataIndex: "convert_rate",
        key: "convert_rate",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
    {
        title: "卡回传数",
        width: 120,
        align: "center",
        dataIndex: "upnums",
        ellipsis: true,
        key: "upnums",
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
        },
    },
]

export default columns