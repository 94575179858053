
import { Layout, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import './admin.less'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import LeftNav from './LeftNav';
import logo from '../../assets/images/admin-logo.png'
import { connect } from 'react-redux';
import { deleteUserInfo } from '../../redux/actions/login'
import { getMenu } from '../../api';
import { saveMenu } from '../../redux/actions/menu';

const { Content, Footer, Sider } = Layout;

function Admin(props) {
  const [collapsed, setCollapsed] = useState(false);
  const { user, menu } = props
  const isLogin = user.isLogin

  const lRef = useRef()

  useEffect(() => {
    // 已登录状态请求后台首页接口
    if (isLogin) {
      getMenuData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 当前的路径
  const pathKey = useLocation().pathname

  const getMenuData = async () => {
    let category = pathKey.split('/').slice(2, 3)?.toString()
    if (!menu?.children) {
      let params = { category }
      const res = await getMenu(params)
      const { data } = res
      // 把菜单数据保存到redux中
      props.saveMenu({ children: data?.children, parents: data?.parents })
    }
  }

  // 动态切换头部菜单
  const getChangeMenus = menus => {
    props.saveMenu({ children: menus, parents: menu.parents })
  }

  const toLeftNav = () => {
    if(lRef.current){
      lRef.current?.clearSty()
    }
  }

  const toggleCollapsed = (collapsed) => {
    setCollapsed(collapsed)
  }

  const isShow = collapsed ? 'none' : 'block'


  if (!isLogin) {
    return <Navigate to='/login' replace />
  } else {
    return (
      <Layout className='admin'>
        <Sider
          className='sider'
          trigger={null}
          collapsible
          collapsed={collapsed}
        // menuList={menuList}
          width={250}
        >
          <div className="sider-header">
            <Space size='middle'>
              <img src={logo} alt="logo" />
              <h1 style={{ display: `${isShow}` }}>投放系统</h1>
            </Space>
          </div>
          <LeftNav leftNavRef={lRef}/>
        </Sider>
        <Layout>
          <Header
            toggleCollapsed={toggleCollapsed}
            logout={props.logout}
            getChangeMenus={getChangeMenus}
            clearMenuSty={toLeftNav}
          />
          <Content className='content' style={{ overflowY: 'auto' }}>
            <Outlet></Outlet>
          </Content>
          <Footer className='footer'>
            <span style={{ marginRight: '50px' }}>IeasynetPHP 1.4.0 © 2015-23</span>
            <span>Crafted with by 杭州阅云科技有限公司</span>
          </Footer>
        </Layout>
      </Layout>
    )
  }
}


export default connect(
  state => ({ user: state.user, menu: state.menu }),
  {
    logout: deleteUserInfo,
    saveMenu,
  }
)(Admin)
