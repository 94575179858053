import { Button } from "antd"

const columns = ({unbind}) => [
    {
        title: "广告主账号",
        width: 130,
        align: "center",
        dataIndex: "account_name",
        key: "account_name",
        // ellipsis: true,
    },
    {
        title: "广告主ID",
        width: 165,
        align: "center",
        dataIndex: "account_id",
        key: "account_id",
        ellipsis: true,
    },
    {
        title: "账户可用余额",
        width: 120,
        align: "center",
        dataIndex: "valid_balance",
        key: "valid_balance",
    },
    {
        title: "角色",
        width: 100,
        align: "center",
        dataIndex: "role",
        key: "role",
        render: value => (
            value === 1 ? '广告主' : '管家'
        )
    },
    {
        title: "所属管家",
        width: 160,
        align: "center",
        dataIndex: "pid",
        key: "pid",
        render: (_, record) => {
            return <>
                {`${record?.manager?.account_name}(${record?.manager?.account_id})`}
            </>
        }
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        fixed: 'right',
        render: (_, record) => (
            <Button type="primary" onClick={() => unbind(record)}>账号解绑</Button>
        )
    },
]

export default columns