import { postRequest } from "./request"

// 获取应用,包名,书籍来源等
export const getPackageQuick = data => postRequest('/admin/advertise/quick_package', data)

// 获取第三方投放的书籍信息
export const getThirdBooks = data => postRequest('/admin/book/third', data)

// 获取推广链接
export const getPromotionUrl = data => postRequest('/admin/user/promotions', data)

// 获取推广链接回传配置
export const getPromotionConfig = data => postRequest('/admin/book/promotion_config', data)

// 推广链接回传设置
export const promotionConfigSet = data => postRequest('/admin/book/promotion_config_set', data)

// 转化列表
export const changeList = data => postRequest('/admin/advertise/change_list', data)

// 删除转化记录
export const changeDelete = data => postRequest('/admin/advertise/change_delete', data)

// 转化记录修改书籍
export const changeBookUpdate = data => postRequest('/admin/advertise/change_book_update', data)

// 添加转化
export const addChange = data => postRequest('/admin/advertise/change_add', data)

// 添加编辑
export const updateChange = data => postRequest('/admin/advertise/change_update', data)

// 转化详情
export const changeDetail = data => postRequest('/admin/advertise/change_detail', data)

// 转化详情删除广告账户
export const deleteChangeAdvertiser = data => postRequest('/admin/advertise/delete_change_account', data)

// 转化列表更新落地页
export const updateLanding = data => postRequest('/admin/advertise/landing_update', data)

// 批量所选广告主
export const getAdvertiserList = data => postRequest('/admin/advertise/list', data)

// 根据关键词筛选广告主
export const getKeywordAccountList = data => postRequest('/admin/advertise/keyword_account_list', data)

// 根据账户获取头条广告列表
export const getAdToutiaoPromotions = data => postRequest('/admin/advertise/ad_toutiao_promotion', data)

// 获取绑定的快应用
export const getQuickAppList = data => postRequest('/admin/user/quick_app', data)

// 获取公众号渠道
export const getChannel = data => postRequest('/admin/user/channel', data)

// 获取公众号书籍
export const getOfficialBook = data => postRequest('/admin/book/official', data)

// 获取资源视频列表
export const getVideoList = data => postRequest('/admin/material/video_list', data)

// 获取资源图片列表
export const getImageList = data => postRequest('/admin/material/image_list', data)

// 获取资源的标题列表
export const getTitleList = data => postRequest('/admin/material/title_list', data)

// 获取已选中的素材（视频|图片）
export const getSelectedMaterials = data => postRequest('/admin/material/selected_list', data)

// 获取已选中的标题
export const getSelectedTitles = data => postRequest('/admin/material/selected_titles', data)

// 根据分类自动获取素材标题
export const getCopyFilterTitles = data => postRequest('/admin/material/copy_filter_titles', data)

// 获取产品主图
export const getProductImages = data => postRequest('/admin/material/product_attach', data)

// 素材列表筛选的投手数据
export const getFilterTouShou = data => postRequest('/admin/material/filter_toushou', data)

// 素材列表筛选的短剧数据
export const playletList = data => postRequest('/admin/material/filter_playlet', data)

// 素材列表筛选的素材手数据
export const getFilterSuCai = data => postRequest('/admin/material/filter_sucai', data)

// 素材列表筛选的素材手数据
export const getTotalCost = data => postRequest('/admin/material/total_cost', data)

// 手动同步素材列表
export const materialAsyncList = data => postRequest('/admin/material/async', data)

// 获取手动同步的视频或图片素材
export const asyncFilterList = data => postRequest('/admin/material/async_filter_list', data)

// 批量共享时，获取所有符合条件的素材
export const getBatchShareMaterials = data => postRequest('/admin/material/batch_share_all_material', data)

// 根据账户拉取头条素材数据
export const advertiseMaterialPull = data => postRequest('/admin/material/advertiser_pull', data)

// 从本地素材库推送到头条
export const advertiseMaterialPush = data => postRequest('/admin/material/advertiser_push', data)

// 账户素材共享推送
export const advertiseMaterialSharePush = data => postRequest('/admin/material/advertiser_share_push', data)

// 批量删除选中头条素材
export const advertiseMaterialDelete = data => postRequest('/admin/material/advertiser_delete', data)

// 批量清理账户素材
export const AccountMaterialClear = data => postRequest('/admin/material/account_batch_delete', data)

// 手动同步素材任务添加
export const materialAsyncTaskCreate = data => postRequest('/admin/material/async_task_create', data)

// 素材统计--每日消耗
export const materialDailyCost = data => postRequest('/admin/material/daily_cost', data)

// 素材统计--每日消耗总消耗
export const materialDailyCostTotal = data => postRequest('/admin/material/daily_cost_total', data)

// 素材手分组
export const getMaterialGroup = data => postRequest('/admin/organization/group', data)

// 素材统计--每日消耗
export const materialUserCost = data => postRequest('/admin/material/user_cost', data)

// 素材统计--获取筛选的素材手
export const getFilterSuCaiUsers = data => postRequest('/admin/material/filter_suCai_users', data)

// 素材统计--每日消耗手动填写列表
export const materialUserCostEnter = data => postRequest('/admin/material/user_cost_enter', data)

// 素材统计--每日消耗手动填保存
export const materialConSumeSave = data => postRequest('/admin/material/consume_save', data)

// 素材统计--素材手每日实时消耗
export const materialUserCostReal = data => postRequest('/admin/material/user_cost_real', data)

//素材统计--素材分日转化以及在投素材数量比
export const materialCompare = data => postRequest('/admin/material/material_compare_data',data)

// 项目、广告添加
export const projectAdvertiseAdd = data => postRequest('/admin/advertise/project_advertise_create', data)

// 定制版项目、广告添加
export const simplifyProjectAdvertiseAdd = data => postRequest('/admin/advertise/simplify_project_advertise_create', data)

// 广告复制添加
export const AdvertiseCopyAdd = data => postRequest('/admin/advertise/promotion_copy_add', data)

// IAA定制版广告复制添加
export const simplifyPromotionCopyAdd = data => postRequest('/admin/advertise/simplify_promotion_copy_add', data)

// 2.0新版广告列表
export const getPromotionList = data => postRequest('/admin/advertise/promotion_list', data)

// 批量广告详情
export const getPromotionDetail = data => postRequest('/admin/advertise/promotion_ad', data)

// 广告删除
export const promotionDelete = data => postRequest('/admin/advertise/promotion_del', data)

// 根据转化获取监测相关信息
export const getMonitor = data => postRequest('/admin/advertise/monitor_info', data)

// 获取分配的后台投手及管家相关数据
export const getPitcherAdminUser = data => postRequest('/admin/user/pitcher_adminUser', data)

// 获取分配的广告账号
export const getDispatchAdvertisers = data => postRequest('/admin/user/advertisers', data)

// 投手账号--广告主账号分配
export const accountDispatch = data => postRequest('/admin/advertise/dispatch', data)

// 投手账号--广告主账号解绑
export const accountUnbind = data => postRequest('/admin/advertise/account_unbind', data)

// 管家账号信息
export const getManagerAccountInfo = data => postRequest('/admin/advertise/manager_info', data)

// 素材手信息
export const getMaterialMan = data => postRequest('/admin/user/material_man', data)

// 素材标签
export const getMaterialLabels = data => postRequest('/admin/material/labels', data)

// 保存用户定向模板
export const doSaveUserTemplate = data => postRequest('/admin/template/save', data)

// 获取用户定向模板
export const getUserTemplate = data => postRequest('/admin/template/data', data)

// 获取用户定向模板
export const deleteUserTemplate = data => postRequest('/admin/template/user_delete', data)

// 获取用户定向模板
export const getTemplateRow = data => postRequest('/admin/template/row', data)

// 保存产品模板
export const doSaveProductTemplate = data => postRequest('/admin/template/product_save', data)

// 获取产品模板
export const getProductTemplate = data => postRequest('/admin/template/product_temps', data)

// 删除产品模板
export const deleteProductTemplate = data => postRequest('/admin/template/product_delete', data)

// 获取产品模板
export const getProductTemplateRow = data => postRequest('/admin/template/product_row', data)

// 更新计划状态
export const handleUpdatePlanStatus = data => postRequest('/admin/advertise/update_plan_status', data)

// 批量更新计划状态
export const handleBatchUpdatePlanStatus = data => postRequest('/admin/advertise/batch_update_ad_plan_status', data)

// 删除广告计划
export const handleDeletePlan = data => postRequest('/admin/advertise/delete_plan', data)

// 批量删除广告计划
export const handleBatchDeletePlan = data => postRequest('/admin/advertise/batch_delete_plan', data)

// 头条数据批量删除广告计划
export const handleBatchDeleteADPlan = data => postRequest('/admin/advertise/batch_delete_ad_plan', data)

// 获取产品模板
export const retryPromotionPlan = data => postRequest('/admin/advertise/promotion_retry_error_plan', data)

// 同步锚点
export const asyncAdvertiseAnchor = data => postRequest('/admin/advertise/anchor_async', data)

// 获取锚点
export const getAdvertiseAnchors = data => postRequest('/admin/advertise/get_anchors', data)

// 获取头条快应用回传记录
export const getBackRecord = data => postRequest('/admin/advertise/back_record', data)

// 获取头条快应用回传记录列表筛选数据
export const getBackFilterData = data => postRequest('/admin/advertise/back_filter_list', data)

// 重新回传
export const doPushAgain = data => postRequest('/admin/advertise/do_back_again', data)

// 检测广告账户是否已设置落地页
export const landingCheck = data => postRequest('/admin/advertise/landing_check', data)

// 获取投手已建的项目
export const getUserProjects = data => postRequest('/admin/advertise/user_projects', data)

// 获取投手已建的项目
export const getFilteredChangeList = data => postRequest('/admin/advertise/filtered_change_list', data)

// 通过转化获取广告账户的产品库信息
export const getProductPlatform = data => postRequest('/admin/advertise/get_product_platform', data)

// 通过资产获取广告账户的产品库信息
export const getProductPlatAsset = data => postRequest('/admin/advertise/get_product_plat_asset', data)

// 通过转化获取产品库的商品列表
export const getProductList = data => postRequest('/admin/advertise/get_product_list', data)

// 通过资产获取产品库的商品列表
export const getProductListAsset = data => postRequest('/admin/advertise/get_product_list_asset', data)

// 获取代理商管家列表
export const getAgentGuanList = data => postRequest('/admin/advertise/agent_guan_list', data)

// 获取管家列表
export const getGuanList = data => postRequest('/admin/advertise/guan_list', data)

// 获取自己的广告账户列表
export const getAccountList = data => postRequest('/admin/advertise/account_list', data)

// 广告账户最高出价限制设置
export const advertiseBidLimitSet = data => postRequest('/admin/advertise/bid_limit_set', data)

// 添加管家
export const addAgentGuan = data => postRequest('/admin/advertise/add_agent_guan', data)

// 解绑管家
export const unbindAgentGuan = data => postRequest('/admin/advertise/unbind_agent_guan', data)

// 用户充值记录
export const getRechargeRecord = data => postRequest('/admin/user/recharge_record', data)

// 用户签到记录
export const getSignRecord = data => postRequest('/admin/user/sign_record', data)

// 获取用户阅读记录
export const getReadRecord = data => postRequest('/admin/user/read_record', data)

// 获取用户消费明细
export const getConsumeRecord = data => postRequest('/admin/user/consume_record', data)

// 获取用户补偿记录
export const getCompensateRecord = data => postRequest('/admin/user/compensate_record', data)

// 获取用户补偿记录
export const getFilterPromotions = data => postRequest('/admin/user/read_filter_promotion', data)

// 获取代理商信息
export const getAgentData = data => postRequest('/admin/advertise/get_agent', data)

// 三方今日消耗推广链接投放状态修改
export const promotionStatusSave = data => postRequest('/admin/advertise/promotion_status_edit', data)

//提交出价修改
export const saveBidData = data => postRequest('/admin/advertise/save_bid',data)

//获取某个广告修改出价次数
export const getBidUpdateNum = data => postRequest('/admin/advertise/update_bid_num',data);

// 广告批量修改出价
export const batchSaveBidData = data => postRequest('/admin/advertise/batch_save_bid',data);

// 头条数据批量修改出价
export const batchAdSaveBid = data => postRequest('/admin/advertise/batch_ad_save_bid',data);

// 广告一键起量设置
export const qiliangConfigSet = data => postRequest('/admin/advertise/qiliang_set', data)

// 广告批量一键起量设置
export const batchQiliangConfigSet = data => postRequest('/admin/advertise/batch_qiliang_set', data)

// 广告一键起量详情报告
export const getQiliangDetail = data => postRequest('/admin/advertise/qiliang_detail', data)

// 关闭广告一键起量
export const qiliangStop = data => postRequest('/admin/advertise/qiliang_stop', data)

// 检查起量状态
export const qiliangStatusCheck = data => postRequest('/admin/advertise/qiliang_status_check', data)

// 登录用户的头条广告
export const getTouTiaoPromotions = data => postRequest('/admin/advertise/toutiao_promotions', data)

// 获取账户抖音号
export const getAdvertiseAweme = data => postRequest('/admin/advertise/get_aweme_account', data)

// 获取字节小程序的事件资产
export const getByteAppAssets = data => postRequest('/admin/advertise/get_byte_app_assets', data)

// 切换小程序资产获取convert_id
export const getAdvertiseConvertId = data => postRequest('/admin/advertise/byte_convert_id', data)

// 获取转化落地页记录
export const getChangeExternalUrl = data => postRequest('/admin/advertise/external_url_record', data)

// excel导入批量解绑
export const excelExportUnbind = data => postRequest('/admin/advertise/excel_export_unbind', data)

// excel导入批量绑定
export const excelImportBind = data => postRequest('/admin/advertise/export_import_bind', data)

// 批量复制绑定
export const batchCopyBind = data => postRequest('/admin/advertise/batch_copy_bind', data)

// 获取批量广告最近的账户素材数
export const getRecentlyMaterialNums = data => postRequest('/admin/advertise/material_nums', data)

// 获取资产共享时的账户
export const getAssetShareAccount = data => postRequest('/admin/advertise/asset_share_account', data)

