import React, { useState } from 'react'
import { useEffect } from 'react'
import { rechargeRecordList } from '../../../../api/statistics'
import Table from '../../../../components/TableNoSelection'
import columns from './columns'

export default function Index(props) {
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getList()
  }, [])

  const getList = async (params = {}) => {
    const res = await rechargeRecordList({ ...params, uid: props.uid })
    const {data} = res
    if (res?.code === 200) {
      setList(data?.lists)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    getList({page, pageSize: size})
  }

  return (
    <Table 
      dataSource={list}
      columns={columns()}
      total={total}
      page={page}
      loading={loading}
      pagesize={pagesize}
      perPage={pageSize}
    />
  )
}
