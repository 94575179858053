import { Button, Card, Form, Input, Modal, Space, Typography, message } from 'antd'
import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../../../../components/TableHasTotal'
import columns from './columns';
import { useState } from 'react';
import { authorizationUser, doAuthorizationCancel, doDispatchUser, getDispathUser } from '../../../../api/permission';
import { useEffect } from 'react';
import AddModal from './addModal';

const { confirm } = Modal

// 已授权用户角色页面
export default function Authorization() {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [selectKeys, setSelectKeys] = useState([])
    const [page, setPage] = useState(1)
    const [conditions, setConditions] = useState([])
    const [authShow, setAuthShow] = useState(false)
    const [dispatchUser, setDispatchUser] = useState([])
    const [dispatchUserTotal, setDispatchUserTotal] = useState(0)
    const [diapatchUserPage, setDispatchUserPage] = useState(1)
    const [authLoading, setAuthLoading] = useState(false)

    useEffect(() => {
        getList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [filterForm] = Form.useForm()
    const navigate = useNavigate()
    // 接收路由 state 传参的值
    const { state } = useLocation();

    // 获取已授权角色的用户列表
    const getList = async (params = {}) => {
        const res = await authorizationUser({ ...params, role_id: state.id })
        const { data } = res
        if (res?.code === 200) {
            const lists = data?.lists
            const newList = lists.map((item) => {
                item.key = item.id
                return item
            })
            setTotal(data?.total)
            setList(newList)
        }
    }

    // 获取待分配的用户
    const getDispathcUsers = async (params = {}) => {
        const res = await getDispathUser({ ...params, role_id: state.id })
        const { data } = res
        if (res?.code === 200) {
            const lists = data?.lists
            const newLists = lists.map((item) => {
                item.key = item.id
                return item
            })
            setDispatchUser(newLists)
            setDispatchUserTotal(data?.total)
        }
    }

    // 每页显示条数切换回调
    const pagesize = (page, size) => {
        setPage(page)
        setConditions({ ...conditions, pageSize: size, page })
        getList({ ...conditions, pageSize: size, page })
    }

    // 全选或全不选
    const selectRows = selectRows => {
        setSelectKeys(selectRows)
    }

    // 新增用户授权
    const addAuth = () => {
        getDispathcUsers()
        setAuthShow(true)
    }

    // 关闭新增用户弹窗
    const handleClose = () => {
        setAuthShow(false)
        setAuthLoading(false)
    }

    // 新增用户授权查询
    const handleSearch = values => {
        getDispathcUsers(values)
    }

    // 确定分配角色给用户
    const sureDispatch = async userIds => {
        setAuthLoading(true)
        const res = await doDispatchUser({ role_id: state.id, userIds })
        if (res?.code === 200) {
            message.success('操作成功', 1)
            setTimeout(() => {
                getList()
                handleClose()
            }, 1000);
        } else {
            setAuthLoading(false)
        }

    }

    // 单个取消授权
    const authorizationCancel = record => {
        confirm({
            title: `取消用户 ${record.nickname} 授权 ${state.roleName} 角色?`,
            onOk: () => {
                doCancelAuth({role_id: state.id, user_id: record.id})
            }
        })
    }

    // 批量取消授权
    const batchCancel = () => {
        confirm({
            title: `取消选中用户授权 ${state.roleName} 角色?`,
            onOk: () => {
                doCancelAuth({role_id: state.id, userIds: selectKeys})
            }
        })
    }

    // 处理取消授权
    const doCancelAuth = async (params) => {
        const res = await doAuthorizationCancel(params)
        if (res?.code === 200) {
            message.success('操作成功', 1)
            setTimeout(() => {
                getList()
            }, 1000);
        }
    }

    // 批量操作按钮是否可点击操作
    const hasSelected = selectKeys.length > 0

    // 弹窗分页
    const dispatchUserPagesize = (page, size) => {
        setDispatchUserPage(page)
        getDispathcUsers({page, pageSize: size})
    }

    return (
        <div className='authorization'>
            <Card
                bodyStyle={{ display: 'none' }}
                style={{ marginBottom: 10 }}
                title={
                    <Form layout='inline' form={filterForm}>
                        <Form.Item label='登录名称' name='username'>
                            <Input />
                        </Form.Item>
                        <Form.Item label colon={false}>
                            <Space>
                                <Button type='primary' htmlType='submit'>搜索</Button>
                                <Button onClick={() => { filterForm.resetFields() }}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                } />

            <Card title={
                <Space>
                    <Button type='primary' onClick={addAuth}>新增授权</Button>
                    <Button type='primary' danger disabled={!hasSelected} onClick={batchCancel}>批量取消授权</Button>
                    <Button type='primary' ghost icon={<ArrowLeftOutlined />} onClick={() => { navigate(-1) }}>返回</Button>
                </Space>
            }
                extra={
                    <Typography.Title level={5}>已授权角色: {state.roleName}</Typography.Title>
                }
            >
                <Table
                    dataSource={list}
                    columns={columns({ authorizationCancel })}
                    selectRows={selectRows}
                    pagesize={pagesize}
                    page={page}
                    total={total}
                />
            </Card>

            {/* 新增用户授权 */}
            <AddModal
                isShow={authShow}
                handleClose={handleClose}
                roleId={state.id}
                list={dispatchUser}
                handleSearch={handleSearch}
                sureDispatch={sureDispatch}
                loading={authLoading}
                total={dispatchUserTotal}
                pagesize={dispatchUserPagesize}
                page={diapatchUserPage}
            />
        </div>
    )
}
