import { SAVE_MESSAGE, DELETE_MESSAGE } from "../constant";

export default function menuReducer(preState = [], action) {
    const { data, type } = action
    let newState
    switch (type) {
        case SAVE_MESSAGE:
            newState = data
            break;
        case DELETE_MESSAGE:
            newState = []
            break;
        default:
            newState = preState
            break;
    }
    return newState
}