import React from "react"
// 引入编辑器组件
import BraftEditor from "braft-editor"
// 引入编辑器样式
import "braft-editor/dist/index.css"
import { Button, Upload } from "antd"
import { PictureOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { BASE_URL } from "../config"

export default function RichEditor(props) {
  const controls = [
    "bold",
    "font-size",
    "italic",
    "underline",
    "text-color",
    "separator",
    "separator",
    "text-align",
    "media",
  ]

  // 处理图片上传请求后端接口
  const changeUpload = (info) => {
    props.uploadChange(info)
  }

  // 富文本编辑器处理输入文字
  const handleChange = (v) => {
    props.editorChange(v)
  }

  // 添加上传图片
  const extendControls = [
    {
      key: "antd-uploader",
      type: "component",
      component: (
        <Upload
          name="file"
          accept="image/*"
          showUploadList={false}
          onChange={changeUpload}
          action={`${BASE_URL}/upload/image`}
          data={{
            source: `editor/${dayjs().format("YYYY-MM")}/${dayjs().date()}`,
          }}
        >
          <Button
            type="button"
            className="control-item button upload-button"
            data-title="插入图片"
          >
            <PictureOutlined />
          </Button>
        </Upload>
      ),
    },
  ]

  return (
    <div
      className="editor-wrapper"
      style={{ border: "1px solid #B4B5B5", borderRadius: 6 }}
    >
      <BraftEditor
        contentStyle={{
          boxShadow: "inset 0 1px 3px rgba(0,0,0,.1)",
          height: props.height ?? 250,
        }}
        className="my-editor"
        controls={controls}
        placeholder={props.placeholder}
        extendControls={extendControls}
        value={props.content}
        onChange={handleChange}
      />
    </div>
  )
}
