import { Form, Input, Modal, Radio, Tree, message } from 'antd'
import React from 'react'
import { useState } from 'react'
import { getMenuTree, menuUpdate } from '../../../api/permission'

const { Search } = Input
const { DirectoryTree } = Tree

export default function SaveModal(props) {
    const [selectModalShow, setSelectModalShow] = useState(false)
    const [treeTitle, setTreeTitle] = useState('-')
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const { show, handleCancel, form } = props

    const getTreeList = async (param = []) => {
        const res = await getMenuTree(param)
        if (res?.code === 200) {
            setList(res?.data?.lists)
        }
    }

    const layout = {
        labelCol: { xl: { span: 6 } },
        wrapperCol: { xl: { span: 16 } }
    }

    // 选择上级菜单
    const seletMenu = () => {
        setSelectModalShow(true)
        getTreeList()
    }

    // 选中树形结构
    const onSelect = (_, info) => {
        let title = info.node.title
        setTreeTitle(title)
        form.setFieldsValue({pid: info.node.key})
    }

    // 点击确定选择上级组织
    const onSure = () => {
        if (treeTitle === form.getFieldValue('name')) {
            message.error('不能选择自己作为上级', 1)
            return 
        }
        form.setFieldsValue({pname: treeTitle})
        setSelectModalShow(false)
    }

    // 处理菜单修改提交
    const handleSubmit = async () => {
        let params = form.getFieldsValue(['id', 'is_show_level', 'pid', 'title', 'sort', 'status']);
        const res = await menuUpdate(params)
        if (res?.code === 200) {
            message.success('菜单修改成功', 2)
            setTimeout(() => {
                setLoading(false)
                handleCancel()
            }, 100);
        }
    }

    return (
        <>
            <Modal
                title='修改菜单'
                open={show}
                onCancel={handleCancel}
                width={600}
                onOk={handleSubmit}
                confirmLoading={loading}
            >
                <Form {...layout} initialValues={{ status: form.getFieldValue('status'), is_show_level: form.getFieldValue('is_show_level') }} form={form}>
                    <Form.Item hidden name='pid'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='上级菜单' name='pname'>
                        <Search
                            autoComplete='off'
                            readOnly
                            onClick={seletMenu}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label='菜单名称' name='title'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='显示顺序' name='sort'>
                        <Input type='number' />
                    </Form.Item>
                    <Form.Item label='菜单图标' name='icon'>
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item label='菜单状态' name='status'>
                        <Radio.Group>
                            <Radio value={0}>隐藏</Radio>
                            <Radio value={1}>显示</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label='是否只有上层可见' name='is_show_level'>
                        <Radio.Group>
                            <Radio value={0}>否</Radio>
                            <Radio value={1}>是</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 树形结果展示数据弹窗 */}
            <Modal
                title='菜单选择'
                open={selectModalShow}
                onCancel={() => { setSelectModalShow(false) }}
                width={320}
                style={{ top: '25%' }}
            >
                <Form>
                    <Form.Item label='关键字'>
                        <Search />
                    </Form.Item>
                    <Form.Item labelCol={{ sm: { span: 4 } }} label colon={false}>
                        {
                            list.length > 0 ?
                                <DirectoryTree
                                    treeData={list}
                                    showLine={true}
                                    defaultExpandAll={true}
                                    height={250}
                                    expandAction={false}
                                    onSelect={onSelect}
                                    onOk={onSure}
                                /> : ''
                        }

                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
