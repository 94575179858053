import { Button, Card, Space } from 'antd'
import React from 'react'
import Table from '../../../../components/TableNoSelection'
import { useState } from 'react'
import columns from './columns'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPromotionDetail } from '../../../../api/advertise'
import { useEffect } from 'react'

export default function Index() {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)

    const {state} = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        getlist()
    }, [])
   
    const getlist = async (params = {}) => {
        const res = await getPromotionDetail({...params, id: state.id})
        const {data} = res
        if (res?.code === 200) {
            setList(data?.lists)
            setTotal(data?.total)
            setLoading(false)
        }
    }

    const pagesize = (page, size) => {
        setPage(page)
        getlist({pageSize: size, page})
    }

    return (
        <Card title={
            <Space size='large'>
                广告批量详情
                <Button type='link' onClick={() => {navigate(-1)}}>返回</Button>
            </Space>
        }>

            <Table
                dataSource={list}
                columns={columns()}
                total={total}
                page={page}
                loading={loading}
                pagesize={pagesize}
            />
        </Card>
    )
}
