import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";
import { useState, useEffect } from "react";
import columns from "./columns";
import { numDiv, numMulti } from "../../../../../util/calculate";
import { useLocation, useNavigate } from "react-router";
import { getMaterialGroup } from "../../../../../api/advertise";
import dayjs from "dayjs";
import { getPlayletEcpmList, getUserLevel } from "../../../../../api/delivery";
import useTableList from "../../../../../hooks/useTableList";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const today = dayjs().startOf('day');

export default function Index() {
  const { state } = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [conditions, setConditions] = useState({
    pageNum: 1,
    pageSize: 100,
    startDay: dayjs().format('YYYYMMDD'),
    endDay: dayjs().format('YYYYMMDD'),
    statsDim: 'admin',
    profitMode: 'IAA',
    isGetRole: true,
    orgId: state?.org_id,
  });
  const [isSuper, setIsSuper] = useState(false);
  const [isAgent, setIsAgent] = useState(false);

  const { loading, data } = useTableList(getPlayletEcpmList, conditions);
  const { list, total } = data;

  useEffect(() => {
    getOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 组织架构数据
  const getOrganization = async () => {
    try {
      const res1 = await getUserLevel();
      const {isAgent, isSuper} = res1.data;
      setIsSuper(isSuper);
      setIsAgent(isAgent);
      if (isSuper) {
        const res2 = await getMaterialGroup({ tag: "iaa_pitcher" });
        if (res2?.code === 200) {
          const { data } = res2;
          let lists = data?.lists?.map((item) => {
            return { label: item?.name, value: item?.org_id };
          });
          setOrganizationOptions(lists);
          form.setFieldValue('org_id', state?.org_id);
        }
      }
    }
    catch(err) {
      console.log(err, 'getOrganization');
    }
  };

  const onSubmit = (values) => {
    const {org_id, range_date} = values;
    const rangeDate = range_date?.map((date) => {
      return date.format("YYYYMMDD");
    }) || [];

    const startDay = rangeDate[0];
    const endDay = rangeDate[1];
    const submitParams = {
      startDay,
      endDay,
      orgId: org_id,
      pageNum: 1,
      pageSize: 100,
    }

    setConditions({...conditions, ...submitParams});
  };

  // 表单重置
  const onReset = () => {
    form.resetFields();
    navigate("/admin/statistics/theater/push_plt/recycle_plt", { state: null });
  };

  // 分页、排序
  const onChangeTable = (pagination, filters, sorter, action) => {
    if (action.action === 'paginate') {
      const { current, pageSize } = pagination;
      setConditions({ ...conditions, pageNum: current, pageSize });
      return
    };

    if (action.action === 'sort') {
      const { order, columnKey } = sorter;
      const {orderBy, orderByAsc, ...rest} = conditions;
      rest.orderBy = order ? columnKey : undefined;
      rest.orderByAsc = order ? (order === "ascend" ? 1 : 0) : undefined;
      setConditions(rest);
      return
    }
  };

  return (
    <div className="recycle">
      <Card
        title={
          <Form
            layout="inline"
            onFinish={onSubmit}
            form={form}
            initialValues={{
              range_date: [today, today],
            }}
          >
            {isSuper ? (
              <>
                <Form.Item label="分组筛选" name="org_id">
                  <Select
                    options={organizationOptions}
                    style={{ width: 200 }}
                    allowClear
                    placeholder="选择分组"
                  />
                </Form.Item>
              </>
            ) : (
              ""
            )}
            <Form.Item name="range_date">
              <RangePicker allowClear={false} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button onClick={onReset}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
      >
        <Table
          sticky
          dataSource={list}
          columns={columns({ isAgent, isSuper })}
          bordered={true}
          pagination={{
            total,
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: (total) => `共 ${total} 条`,
            current: conditions.pageNum || 1,
            pageSize: conditions.pageSize || 100,
          }}
          loading={loading}
          scroll={{ x: 500 }}
          onChange={onChangeTable}
          summary={(pageData) => {
            let _cost = 0;
            let _todayAdCash = 0;
            let _totalAdCash = 0;
            let _adCount = 0;
            let _adNewActiveCount = 0;
            let _accountCount = 0;
            let _activeAdCount = 0;
            let _activeAccountCount = 0;
            let _playletCount = 0;
            pageData.forEach(
              ({
                cost,
                todayAdCash,
                totalAdCash,
                adCount,
                accountCount,
                activeAdCount,
                activeAccountCount,
                playletCount,
                adNewActiveCount,
              }) => {
                _cost += isNaN(cost) ? 0 : parseFloat(cost);
                _todayAdCash += isNaN(todayAdCash) ? 0 : parseFloat(todayAdCash);
                _totalAdCash += isNaN(totalAdCash) ? 0 : parseFloat(totalAdCash);
                _adCount += isNaN(adCount) ? 0 : parseFloat(adCount);
                _adNewActiveCount += isNaN(adNewActiveCount) ? 0 : parseFloat(adNewActiveCount);
                _accountCount += isNaN(accountCount) ? 0 : parseFloat(accountCount);
                _activeAdCount += isNaN(activeAdCount) ? 0 : parseFloat(activeAdCount);
                _activeAccountCount += isNaN(activeAccountCount) ? 0 : parseFloat(activeAccountCount);
                _playletCount += isNaN(playletCount) ? 0 : parseFloat(playletCount);
              }
            );

            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={2} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text>{_cost.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{_todayAdCash.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>{_totalAdCash.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>{_cost > 0 ? numMulti(numDiv(_todayAdCash, _cost), 100).toFixed(2) + "%" : "0%"}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>{_cost > 0 ? numMulti(numDiv(_totalAdCash, _cost), 100).toFixed(2) + "%": "0%"}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{_adCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{_adNewActiveCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>{_activeAdCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>{_accountCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text>{_activeAccountCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="center">
                      <Text>{_playletCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="center">
                      <Text>--</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>
    </div>
  );
}
