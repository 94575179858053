import { Form, Input, Modal, Radio, Tree, message } from 'antd'
import React, { useState } from 'react'
import { getOrganizationTree } from '../../../api/permission';

const { Search, TextArea } = Input;
const { DirectoryTree } = Tree;

export default function SaveModal(props) {
    const [selectModalShow, setSelectModalShow] = useState(false)
    const [treeTitle, setTreeTitle] = useState(props.pname)
    const [list, setList] = useState([])
    const { title, show, close, submit, form } = props

    const getTreeList = async (param = []) => {
        const res = await getOrganizationTree(param)
        if (res?.code === 200) {
            setList(res?.data?.lists)
        }
    }

    const layout = {
        labelCol: {
            sm: {
                span: 5,
            },
        },
        wrapperCol: {
            xl: {
                span: 16,
            },
        },
    }

    // 选中树形结构
    const onSelect = (_, info) => {
        let title = info.node.title
        setTreeTitle(title)
        form.setFieldsValue({pid: info.node.key})
    }


    const onSearch = value => {
    };

    // 点击确定选择上级组织
    const onSure = () => {
        if (treeTitle === form.getFieldValue('name')) {
            message.error('不能选择自己作为上级', 1)
            return 
        }
        form.setFieldsValue({pname: treeTitle})
        setSelectModalShow(false)
    }


    // 选择上级组织架构
    const seletOrganization = () => {
        setSelectModalShow(true)
        getTreeList()
    }

    // 表单提交
    const handleSubmit = () => {
        form.validateFields().then(() => {
            const values = form.getFieldValue()
            submit(values)
        }).catch(() => {
            return
        })
    }

    return (
        <>
            <Modal
                title={title === 'add' ? '添加组织' : '修改组织'}
                open={show}
                onCancel={close}
                onOk={handleSubmit}
                maskClosable={false}
                confirmLoading={props.loading}
            >
                <Form {...layout}
                    form={form}
                    initialValues={{
                        status: 1,
                        sort: 0,
                        pname: props.pname,
                        pid: props.pid
                    }}
                >
                    <Form.Item style={{display: 'none'}} name='id'>
                        <Input />
                    </Form.Item>
                    <Form.Item style={{display: 'none'}} name='pid'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='上级组织' name='pname'>
                        <Search
                            autoComplete='off'
                            readOnly
                            onClick={seletOrganization}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label='组织名称' name='name' rules={[{ required: true, message: '组织名称不能为空' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='排序' name='sort'>
                        <Input type='number' min={0} />
                    </Form.Item>
                    <Form.Item label='负责人' name='leader'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='联系电话' name='phone'>
                        <Input />
                    </Form.Item>
                    <Form.Item label='组织状态' name='status'>
                        <Radio.Group>
                            <Radio value={0}>停用</Radio>
                            <Radio value={1}>启用</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label='备注' name='remark'>
                        <TextArea />
                    </Form.Item>
                </Form>

            </Modal>

            {/* 弹窗选择对应的上层架构名称 */}
            <Modal
                title='组织选择'
                open={selectModalShow}
                onCancel={() => { setSelectModalShow(false) }}
                width={320}
                style={{ top: '25%' }}
                onOk={onSure}
            >
                <Form>
                    <Form.Item label='关键字'>
                        <Input.Search onSearch={onSearch} />
                    </Form.Item>
                    <Form.Item labelCol={{ sm: { span: 4 } }} label colon={false}>
                        {
                            list.length > 0 ?
                                <DirectoryTree
                                    treeData={list}
                                    showLine={true}
                                    defaultExpandAll={true}
                                    height={250}
                                    expandAction={false}
                                    onSelect={onSelect}
                                /> : ''
                        }

                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
