import {
  Button,
  Card,
  DatePicker,
  Form,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";
import { useState } from "react";
import columns from "./columns";
import playletColumns from "./playletColumns";
import { useEffect } from "react";
import { getRecycleStatistics } from "../../../api/statistics";
import { numDiv, numMulti } from "../../../util/calculate";
import { useLocation, useNavigate } from "react-router";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);

  const { state } = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // 当前的路径
  const pathKey = useLocation().pathname;
  let defaultOpenKey = pathKey.split("/").splice(2);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 列表统计数据
  const getList = async (params = {}) => {
    setLoading(true);
    if (state) {
      params.org_id = state?.org_id;
    }
    if (defaultOpenKey.includes("theater")) {
      params.type = "playlet";
    } else if (defaultOpenKey.includes("novel")) {
      params.type = "novel";
    }
    const res = await getRecycleStatistics(params);
    const { data } = res;
    if (res?.code === 200) {
      setLoading(false);
      let lists = data?.lists;
      const newList = lists?.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newList);
    }
  };
  // 日期范围查询
  const onSubmit = (values) => {
    let createdAt = [];
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.range_date;
    }
    let params = { ...conditions, ...values, range_date: createdAt };
    getList(params);
    setConditions(params);
  };

  // 表单重置
  const onReset = () => {
    form.resetFields();
    navigate(pathKey, { state: null });
  };

  // 不同的页面获取不同的字段显示
  const getColumns = () => {
    if (defaultOpenKey.includes("theater")) {
      return playletColumns();
    } else {
      return columns();
    }
  };

  return (
    <div className="recycle">
      <Card
        title={
          <Form
            layout="inline"
            onFinish={onSubmit}
            form={form}
            initialValues={{
              org_id: state?.org_id,
            }}
          >
            {/* <Form.Item label='组织架构' name='org_id'>
                        <Select
                            options={organizationOptions}
                            style={{ width: 150 }}
                            allowClear
                            placeholder='选择组织架构'
                        />
                    </Form.Item>
                    <Form.Item label='广告类型' name='ad_type'>
                        <Select style={{ width: 150 }} options={[
                            { label: '头条', value: 1 },
                            { label: 'adp', value: 5 },
                        ]} allowClear placeholder='选择广告类型' />
                    </Form.Item> */}
            <Form.Item name="range_date">
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button onClick={onReset}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
      >
        <Table
          sticky
          dataSource={list}
          columns={getColumns()}
          bordered={true}
          pagination={false}
          loading={loading}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalCost = 0;
            let totalMoneyCount = 0;
            let totalPayNum = 0;
            let totalZaiPlan = 0;
            let totalVerifyPlan = 0;
            let totalAllPlan = 0;
            pageData.forEach(
              ({
                cost,
                money,
                pay_user,
                ad_count,
                success_plan,
                all_plan,
              }) => {
                totalCost += parseFloat(cost);
                totalMoneyCount += parseFloat(money);
                totalPayNum += parseFloat(pay_user);
                totalZaiPlan += ad_count === "-" ? 0 : parseInt(ad_count);
                totalVerifyPlan += success_plan === "-" ? 0 : parseInt(success_plan);
                totalAllPlan += all_plan === "-" ? 0 : parseFloat(all_plan);
              }
            );
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={2} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text>{totalCost.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{totalMoneyCount.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>
                        {parseInt(totalCost)
                          ? numMulti(
                              numDiv(totalMoneyCount, totalCost),
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>{totalPayNum}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>
                        {totalPayNum > 0
                          ? numDiv(totalCost, totalPayNum).toFixed(2)
                          : "0.00"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>
                        {totalPayNum > 0
                          ? numDiv(totalMoneyCount, totalPayNum).toFixed(2)
                          : "0.00"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>{totalZaiPlan}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>{totalVerifyPlan}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text>{totalAllPlan}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="center">
                      <Text>---</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>
    </div>
  );
}
