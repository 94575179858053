import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Modal, Radio, Row, Space, Tag, Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    PlusOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import './index.less'
import { getPanels, getRechargeGoods, handleAddPanel, updatePanelsSort } from '../../../../api/promotion';
import Table from '../../../../components/TableNoSelection'
import columns from './columns';
import { useLocation, useNavigate } from 'react-router-dom';

const { confirm } = Modal

export default function Index() {
    const [template, setTemplate] = useState([])
    const [selectKey, setSelectKey] = useState(0)
    const [key, setkey] = useState(0)
    const [checkedKey, setCheckedKey] = useState(false)
    const [goodslist, setGoodsList] = useState([])
    const [page, setPage] = useState(1)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [status, setStatus] = useState(1)
    const [payGoodsTemp, setPayGoodsTemp] = useState([])
    const [noPayGoodsTemp, setNoPayGoodsTemp] = useState([])
    const [radioValue1, setRadioValue1] = useState(0)
    const [radioValue2, setRadioValue2] = useState(0)
    const [operateType, setOperateType] = useState('')
    const [updateRowId, setUpdateRowId] = useState(0)
    const [blackType, setBlackType] = useState(0)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [addTo, setAddTo] = useState(true)

    const [form] = Form.useForm()
    const { state } = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        getList()
        // 清理副作用
        return () => {
            localStorage.removeItem('templates')
        }
    }, [])

    // 根据推广链接获取充值面板记录
    const getList = async (params = {}) => {
        const res = await getPanels({ ...params, promotion_id: state.id })
        const { data } = res
        if (res?.code === 200) {
            let list = data?.list
            let noPayData = list.filter((item) => {
                return item.pay_status === 1;
            })
            let payData = list.filter((item) => {
                return item.pay_status === 2;
            })
            let paySelect = payData.filter((item) => {
                return item.is_select === 1
            })
            let noPaySelect = noPayData.filter((item) => {
                return item.is_select === 1
            })
            let templates = data?.templates
            setPayGoodsTemp(payData)
            setNoPayGoodsTemp(noPayData)
            setRadioValue1(noPaySelect[0]?.id)
            setRadioValue2(paySelect[0]?.id)
            setBlackType(parseInt(list[0]?.black_type))
            let tempSelected = templates.filter((item) => {
                return item.is_selected === 1
            })
            if (tempSelected.length > 0) {
                setCheckedKey(tempSelected[0]?.key)
                if (params?.key) {
                    setSelectKey(params?.key)
                } else {
                    setSelectKey(tempSelected[0]?.key)
                }
            } else {
                setSelectKey(templates[0]?.key)
            }
            let templtates = localStorage.getItem('templates') ? JSON.parse(localStorage.getItem('templates')) : data?.templates
            if (params?.key && params?.key) {
                let filterd = templtates.filter((item) => {
                    return item.key === params?.key
                })
                if (filterd.length === 0) {
                    templates.push({ title: '充值模板' + params?.key, key: params?.key, is_select: 0 })
                    localStorage.setItem('templates', JSON.stringify(templates))
                }
            }

            setTemplate(templtates)
        }
    }

    // 添加充值模板标题
    const addTemplate = () => {
        if (addTo === false) {
            message.error('请先保存新增的充值模板', 1)
            return false
        }
        setkey(key + 1)
        let content
        let temp = template
        if (temp.length === 0) {
            content = { title: '充值模板1', key: 1, is_select: 0 }
        } else {
            let key = temp[temp.length - 1]?.key
            let index = key + 1
            content = { title: '充值模板' + index, key: index, is_select: 0 }
        }
        temp.push(content)
        setTemplate(temp)
        setAddTo(false)
    }

    // 选中充值模板
    const onSelected = key => {
        setSelectKey(key)
        let templateName = '充值模板' + key
        getList({ template_name: templateName, key })
    }

    // 添加充值模板按钮
    const addBtn = (
        <Button
            icon={<PlusOutlined />}
            style={{ width: 105, height: 41 }}
            onClick={addTemplate}
        >
        </Button>
    )

    // 添加已付费模板设置
    const addPayTemp = (
        <Button
            icon={<PlusOutlined />}
            style={{ width: '33%', height: 75, border: '1px solid blue', marginTop: 15 }}
            onClick={() => getProducts(2, 'add')}
        >
        </Button>
    )

    // 添加未付费模板设置
    const addNoPayTemp = (
        <Button
            icon={<PlusOutlined />}
            style={{ width: '33%', height: 75, border: '1px solid blue', marginTop: 15 }}
            onClick={() => getProducts(1, 'add')}
        >
        </Button>
    )

    // 切换选择
    const onCheckChange = (e, key) => {
        // 阻止冒泡事件
        e.stopPropagation()
        confirm({
            title: '确认设置为该充值模板吗？',
            onOk: () => {
                setCheckedKey(key)
                setSelectKey(key)
            }
        })

    }

    // 获取商品数据
    const getGoodList = async (params = {}) => {
        const res = await getRechargeGoods(params)
        const { data } = res
        if (res?.code === 200) {
            setGoodsList(data?.lists);
            setTotal(data?.total)
            setLoading(false)
        }
    }

    // 选择商品弹窗
    const getProducts = async (status, id = 0, type = 'add') => {
        if (template.length === 0) {
            message.error('请先添加充值模板', 2)
            return false
        }
        setShow(true)
        setStatus(status)
        setOperateType(type)
        setUpdateRowId(id)
        getGoodList({ status })
    }

    // 修改面板排序
    const updateSort = async (value, record, type) => {
        if (type === 'pay') {
            let newPayData = payGoodsTemp.map((item) => {
                if (item.id === record.id) {
                    item.orderbys = value
                }
                return item
            })
            setPayGoodsTemp(newPayData)
        } else {
            let newNoPayData = noPayGoodsTemp.map((item) => {
                if (item.id === record.id) {
                    item.orderbys = value
                }
                return item
            })
            setNoPayGoodsTemp(newNoPayData)
        }

        // 只有当已添加的模板才正常更新排序
        if (record.hasOwnProperty('template_name')) {
            const res = await updatePanelsSort({id: record.id, sort: value})
            if (res?.code === 200) {
                getList()
            }
        }
    }

    // 生成付费充值面板
    const createPanel = (data) => {
        return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
                data.map((item) => {
                    return <div key={item?.cxid ? item?.cxid : item.id}>
                        <div
                            onClick={() => { getProducts(2, item.id, 'update') }}
                            style={{
                                border: '1px solid #C8C8C8',
                                marginRight: 10,
                                width: 220,
                                height: 115,
                                marginTop: 10,
                                borderRadius: 8,
                                padding: 10,
                                cursor: 'pointer'
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontSize: 18, fontWeight: 'bold' }}>{item.name}</div>
                                {
                                    item.titilec ? <div style={{ marginTop: 3, marginLeft: 15 }}><Tag color="#f50">{item.titilec}</Tag></div> : ''
                                }
                            </div>
                            <div style={{ marginTop: 10, fontSize: 16 }}>{item.titileb}</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '85%' }}>
                                <Space>
                                    <Radio.Group onChange={onChange2} value={radioValue2}>
                                        <Radio value={item.id}>默认</Radio>
                                    </Radio.Group>
                                    <span>排序：<InputNumber style={{ width: 60 }} size="small" min={0} defaultValue={item.orderbys} onChange={value => updateSort(value, item, 'pay')} /></span>
                                </Space>
                            </div>
                            <Tooltip title='移除'>
                                <DeleteOutlined style={{ color: '#EE8272', cursor: 'pointer', fontSize: 16 }} onClick={() => { onDelete(item.id, 2) }} />
                            </Tooltip>

                        </div>
                    </div>
                })
            }
        </div>
    }

    // 生成未付费充值面板
    const createNoPayPanel = (data) => {
        return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
                data.map((item) => {
                    return <div key={item?.cxid ? item?.cxid : item.id}>
                        <div
                            onClick={() => { getProducts(1, item.id, 'update') }}
                            style={{
                                border: '1px solid #C8C8C8',
                                marginRight: 10,
                                width: 220,
                                height: 115,
                                marginTop: 10,
                                borderRadius: 8,
                                padding: 10,
                                cursor: 'pointer'
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontSize: 18, fontWeight: 'bold' }}>{item.name}</div>
                                {
                                    item.titilec ? <div style={{ marginTop: 3, marginLeft: 15 }}><Tag color="#f50">{item.titilec}</Tag></div> : ''
                                }
                            </div>
                            <div style={{ marginTop: 10, fontSize: 16 }}>{item.titileb}</div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '85%' }}>
                                <Space>
                                    <Radio.Group onChange={onChange1} value={radioValue1}>
                                        <Radio value={item.id}>默认</Radio>
                                    </Radio.Group>
                                    <span>排序：<InputNumber style={{ width: 60 }} size="small" min={0} defaultValue={item.orderbys} onChange={value => updateSort(value, item, 'noPay')} /></span>
                                </Space>
                            </div>
                            <Tooltip title='移除'>
                                <DeleteOutlined style={{ color: '#EE8272', cursor: 'pointer', fontSize: 16 }} onClick={() => { onDelete(item.id, 1) }} />
                            </Tooltip>

                        </div>
                    </div>
                })
            }
        </div>
    }

    // 删除面板
    const onDelete = (id, status) => {
        let newPanel
        if (status === 1) {
            newPanel = noPayGoodsTemp.filter((item) => {
                return item.id !== id
            })
            setNoPayGoodsTemp(newPanel)
        } else {
            newPanel = payGoodsTemp.filter((item) => {
                return item.id !== id
            })
            setPayGoodsTemp(newPanel)
        }
    }

    // 默认选择
    const onChange1 = e => {
        setRadioValue1(e.target.value);
    }
    // 默认选择
    const onChange2 = e => {
        setRadioValue2(e.target.value);
    }

    // 关闭弹窗
    const close = () => {
        form.resetFields()
        setShow(false)
    }

    // 商品名称或价格查询
    const onSearch = values => {
        getGoodList({ status, keywords: values.keywords })
    }

    // 分页
    const pagesize = (page, size) => {
        setPage(page)
        getGoodList({ status, page, pageSize: size })
    }

    // 选择商品
    const onSelectedProduct = (record) => {
        if (operateType === 'add') {
            addSelectProduct(record)
        } else {
            updateSelectProduct(record)
        }
    }

    // 添加新面板
    const addSelectProduct = (record) => {
        setkey(key + 1)
        let pt
        if (status === 1) {
            pt = noPayGoodsTemp
        } else {
            pt = payGoodsTemp
        }
        let filterd = pt.filter((item) => {
            return item.id === record.id || item?.ids === record.id
        })
        if (filterd && filterd?.length > 0) {
            message.error('请勿添加同一个商品', 1)
            return false
        }
        pt.push(record)
        status === 1 ? setNoPayGoodsTemp(pt) : setPayGoodsTemp(pt)
        close()
    }

    // 已有的面板重新选择商品
    const updateSelectProduct = (record) => {
        let pt
        if (status === 1) {
            pt = noPayGoodsTemp
        } else {
            pt = payGoodsTemp
        }
        let f1 = pt.filter((item) => {
            return item.id === record.id || item?.ids === record.id
        })
        if (f1 && f1?.length > 0) {
            message.error('请勿添加同一个商品', 1)
            return false
        }

        let filterd = pt.map((item) => {
            if (item.id === updateRowId) {
                item = record
            }
            return item
        })
        status === 1 ? setNoPayGoodsTemp(filterd) : setPayGoodsTemp(filterd)
        close()
    }

    // 用户黑名单设置
    const onBlackChange = e => {
        setBlackType(e.target.value);
    }

    // 提交保存配置
    const onSubmit = async () => {
        let isSelected = selectKey === checkedKey ? 1 : 0
        let black_type = isNaN(blackType) ? 0 : blackType
        let params = {
            pay: payGoodsTemp,
            noPay: noPayGoodsTemp,
            blackType: black_type,
            template: { title: '充值模板' + selectKey, key: selectKey, is_select: isSelected },
            promotion_id: state.id,
            uid: state.uid,
            pay_select: radioValue2,
            noPay_select: radioValue1,
        }
        if (selectKey === undefined) {
            message.error('请勾选充值模板', 2)
            return false
        }
        if (isNaN(blackType)) {
            message.error('请选择用户黑名单类型', 2)
            return false
        }
        if (template.length === 0) {
            message.error('请先添加充值模板', 2)
            return false
        }
        if (payGoodsTemp.length === 0 && noPayGoodsTemp.length === 0) {
            message.error('请先设置充值面板', 2)
            return false
        }
        if ((radioValue2 === 0 || radioValue2 === undefined) && payGoodsTemp.length >= 1) {
            message.error('请设置【付费用户】的默认商品', 2)
            return false
        }
        if ((radioValue1 === 0 || radioValue1 === undefined) && noPayGoodsTemp.length >= 1) {
            message.error('请设置【未付费用户】的默认商品', 2)
            return false
        }
        if (selectKey === checkedKey) {
            params.is_select = 1
        }
        setSubmitLoading(true)
        const res = await handleAddPanel(params)
        if (res?.code === 200) {
            message.success('充值面板添加成功', 2)
            setTimeout(() => {
                getList({ key: selectKey, template_name: '充值模板' + selectKey })
                setSubmitLoading(false)
                localStorage.removeItem('templates')
            }, 1000);
        }
    }

    return (
        <>
            <Card
                title={
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', marginRight: 15 }}>
                            {
                                template.map((item) => {
                                    return <div
                                        key={item.key}
                                        style={{ display: 'flex', marginRight: 15 }}
                                        onClick={() => { onSelected(item.key) }}
                                        className={selectKey === item.key ? 'selected' : ''}
                                    >
                                        <Button className='title' style={{ width: 110, height: 41 }}>{item.title}</Button>
                                        <Checkbox style={{ marginLeft: -18, marginTop: -2 }} checked={checkedKey === item.key} onClick={(e) => onCheckChange(e, item.key)} />
                                    </div>

                                })
                            }
                        </div>
                        {
                            template && template.length >= 6 ? null : addBtn
                        }
                    </div>
                }
                bodyStyle={{ display: 'none' }}
                extra={
                    <Button onClick={() => { navigate(-1) }}>返回</Button>
                }
            />
            {/* 具体的模板内容 */}
            <Card title='充值模板设置（播放页弹窗）' style={{ marginTop: 15 }}>
                <Form>
                    <Form.Item label="用户">
                        <Radio.Group
                            options={[
                                { label: '默认、白名单', value: 0 },
                                { label: '一类(北京等)', value: 1 },
                                { label: '二类(杭州等)', value: 2 },
                                { label: '三类(16个城市)', value: 3 },
                            ]}
                            value={blackType}
                            onChange={onBlackChange}
                        />
                    </Form.Item>
                </Form>
                <Row gutter={24}>
                    <Col span={12}>
                        <div>
                            <div>已付费用户</div>
                            {
                                createPanel(payGoodsTemp, 2)
                            }
                            {
                                payGoodsTemp && payGoodsTemp.length >= 6 ? null : addPayTemp
                            }
                        </div>

                    </Col>
                    <Col span={12}>
                        <div>
                            <div>未付费用户</div>
                            {
                                createNoPayPanel(noPayGoodsTemp, 1)
                            }
                            {
                                noPayGoodsTemp && noPayGoodsTemp.length >= 6 ? null : addNoPayTemp
                            }
                        </div>

                    </Col>
                </Row>
            </Card>
            <Card
                bodyStyle={{ display: 'none' }}
                style={{ marginTop: 5 }}
                extra={
                    <Space>
                        <Button>取消</Button>
                        <Button type='primary' onClick={onSubmit} loading={submitLoading}>提交</Button>
                    </Space>
                }
            />

            {/* 获取充值商品弹窗 */}
            <Modal
                title={
                    <Form layout='inline' onFinish={onSearch} form={form}>
                        <Form.Item name='keywords'>
                            <Input placeholder='输入商品名称/价格' allowClear />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type='primary' htmlType='submit'>查询</Button>
                                <Button onClick={() => { form.resetFields() }}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                }
                open={show}
                width={800}
                onCancel={close}
                style={{ height: 600 }}
                maskClosable={false}
                destroyOnClose={true}
            >
                <Table
                    dataSource={goodslist}
                    columns={columns({ onSelectedProduct })}
                    loading={loading}
                    total={total}
                    pagesize={pagesize}
                    page={page}
                />
            </Modal>
        </>
    )
}
