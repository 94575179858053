import { Button, Form, Input, Popover, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

const columns = ({ onChange, switchover, showTimeDivision }) => [
  {
    title: "账户名称",
    width: 120,
    align: "center",
    dataIndex: "account_name",
    key: "account_name",
    render: (_, record) => record?.advertise?.account_name,
  },
  {
    title: "账户ID",
    width: 100,
    align: "center",
    dataIndex: "advertiser_id",
    key: "advertiser_id",
    render: value => (
      <Link to={`https://ad.oceanengine.com/superior/promote-manage/project?aadvid=${value}`} target="_blank">{value}</Link>
    )
  },
  {
    title: "备注",
    width: 90,
    align: "center",
    dataIndex: "remark",
    key: "remark",
    ellipsis: true,
    render: (value, record) => {
      return value ? (
        <Popover
          content={
            <Form initialValues={{ title: value }}>
              <Form.Item name="title">
                <Input
                  onKeyDown={(e) => {
                    onChange(e, record);
                  }}
                />
              </Form.Item>
            </Form>
          }
          title="修改备注"
          trigger="click"
        >
          <Text
            style={{ width: 120, cursor: "pointer" }}
            ellipsis={{ tooltip: value }}
          >
            {value}
          </Text>
        </Popover>
      ) : (
        "-"
      );
    },
  },
  {
    title: "新增广告数",
    width: 80,
    align: "center",
    dataIndex: "new_plans",
    key: "new_plans",
    render: (value) => value || 0,
  },
  {
    title: "消耗广告数",
    width: 80,
    align: "center",
    dataIndex: "cost_plans",
    key: "cost_plans",
    render: (value) => value || 0,
  },
  {
    title: "查看广告",
    width: 90,
    align: "center",
    dataIndex: "readplans",
    key: "readplans",
    render: (_, record) => (
      <Button size="small" onClick={() => switchover(record, "tab2")}>
        查看广告
      </Button>
    ),
  },
  {
    title: "消耗",
    width: 80,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
    render: (value, record) => (
        <>
        {value}
        <Button type="link" size="small" onClick={() => showTimeDivision(record)}>查看详情</Button>
        </>
    )
  },
  {
    title: "回收",
    width: 95,
    align: "center",
    dataIndex: "money",
    key: "money",
  },
  {
    title: "回收率",
    width: 95,
    align: "center",
    dataIndex: "huishoulv",
    key: "huishoulv",
  },
  {
    title: "预估时速",
    width: 100,
    align: "center",
    dataIndex: "estimate_cost",
    key: "estimate_cost",
  },
  {
    title: "实际转化成本",
    width: 80,
    align: "center",
    dataIndex: "real_convert_cost",
    key: "real_convert_cost",
  },
  {
    title: "转化成本",
    width: 80,
    align: "center",
    dataIndex: "convert_cost",
    key: "convert_cost",
  },
  {
    title: "转化数",
    width: 80,
    align: "center",
    dataIndex: "convert",
    key: "convert",
    sorter: {}
  },
  {
    title: "转化率(%)",
    width: 90,
    align: "center",
    dataIndex: "convert_rate",
    key: "convert_rate",
  },
  {
    title: "平均千次展示费用",
    width: 80,
    align: "center",
    dataIndex: "avg_show_cost",
    key: "avg_show_cost",
  },
  {
    title: "卡回传数",
    width: 80,
    align: "center",
    dataIndex: "upnum",
    key: "upnum",
  },
  {
    title: "账户余额",
    width: 80,
    align: "center",
    dataIndex: "valid_balance",
    key: "valid_balance",
    render: (_, record) => record?.advertise?.valid_balance,
  },
  {
    title: "展示数",
    width: 80,
    align: "center",
    dataIndex: "show",
    key: "show",
  },
  {
    title: "点击数",
    width: 80,
    align: "center",
    dataIndex: "click",
    key: "click",
  },
  {
    title: "点击率",
    width: 80,
    align: "center",
    dataIndex: "ctr",
    key: "ctr",
  },
  {
    title: "平均点击单价",
    width: 80,
    align: "center",
    dataIndex: "avg_click_costs",
    key: "avg_click_costs",
  },
];

export default columns;
