import { Card, Col, Row, Space, Spin, Table, Tooltip, message } from "antd";
import SearchComponent from "./SearchComponent";
import { Statistic } from "antd";
import "./IAA.css";
import { useState } from "react";
import { getDailyProfit } from "../../../../api/delivery";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import IAADetail from "./Detail";
import _ from "lodash";

const columns = [
  {
    title: "日期",
    dataIndex: "date",
    render: (text) => <b>{text}</b>,
    sorter: (a, b) => a.date - b.date,
  },
  {
    title: (
      <Tooltip title="当日所有广告的消耗数据总和">
        消耗{" "}
        <ExclamationCircleOutlined
          style={{ fontSize: "16px", color: "#08c" }}
        />
      </Tooltip>
    ),
    dataIndex: "cost",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.cost - b.cost,
  },
  {
    title: (
      <Tooltip title="当日ecpm收益数据，包含历史消耗产生的收益数据">
        ecpm收益{" "}
        <ExclamationCircleOutlined
          style={{ fontSize: "16px", color: "#08c" }}
        />
      </Tooltip>
    ),
    dataIndex: "ecpm",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.ecpm - b.ecpm,
  },
  {
    title: "赔付消耗",
    colSpan: 1,
    dataIndex: "compensationCost",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.compensationCost > b.compensationCost,
  },
  {
    title: "回收率",
    dataIndex: "rate",
    render: (text) => (text * 100).toFixed(2) + "%",
    sorter: (a, b) => a.rate - b.rate,
  },
  {
    title: (
      <Tooltip title="盈亏值">
        盈亏{" "}
        <ExclamationCircleOutlined
          style={{ fontSize: "16px", color: "#08c" }}
        />
      </Tooltip>
    ),
    dataIndex: "profit",
    render: (text) => text.toFixed(2),
    sorter: (a, b) => a.profit - b.profit,
  },
];

const IAAPage = () => {
  const [summary, setSummary] = useState([]);
  const [data, setData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleExpand = (expanded, rowKey) => {
    console.log(expandedRowKeys, expanded, rowKey);
    let filteredList = _.filter(
      expandedRowKeys,
      (item) => item !== rowKey?.key
    );
    setExpandedRowKeys(
      expanded ? [...expandedRowKeys, rowKey?.key] : filteredList
    );
  };

  const getData = async (params) => {
    setLoading(true);
    setExpandedRowKeys([]);
    await getDailyProfit(params)
      .then((response) => {
        if (response?.code === 200) {
          //清空expandedKeys
          const { data } = response;
          const dataWithKey = _.map(
            data?.dailyProfitItemList,
            (item, index) => {
              const timestamp = Date.now();
              return {
                ...item,
                key: index + params?.adminId + new Date(timestamp).toString(),
              };
            }
          );

          setData(dataWithKey);
          setSummary({
            cost: data?.totalCost.toFixed(2),
            ecpm: data?.totalEcpm.toFixed(2),
            rate: (data?.rate * 100).toFixed(2),
            profit: data?.totalProfit.toFixed(2),
            adminId: params?.adminId,
          });
        }
      })
      .catch((error) => {
        console.log(error, 'error');
        if (!params.adminId) {
          message.error('请选择投手', 2);
        } else {
          message.error(error || '系统错误', 2);
        };
      })
      .finally((f) => {
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <Space className="sp" direction="vertical" size="large">
        <Spin spinning={loading}>
          <Space>
            <SearchComponent
              withPitcher={true}
              onSearch={(params) => getData(params)}
            />
          </Space>
          <Row gutter={16}>
            <Col span={6}>
              <Card>
                <Statistic title="总消耗" value={summary?.cost} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="总回收" value={summary?.ecpm} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="总回收率" value={summary?.rate} suffix="%" />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="总盈亏"
                  value={summary?.profit}
                  valueStyle={{
                    color: summary?.profit > 0 ? "#cf1322" : "#3f8600",
                  }}
                />
              </Card>
            </Col>
          </Row>
          <Table
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: handleExpand,
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <IAADetail data={record?.appDetail} />
              ),
            }}
            className="tb"
            columns={columns}
            dataSource={data}
            bordered
            key={Math.random()}
          />
        </Spin>
      </Space>
    </div>
  );
};

export default IAAPage;
