import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import columns from "./columns";
import {
  costAdvertiseTimeDivision,
  filterPitchers,
  getPromotionsFromPlaylet,
  recycleAccount,
  recycleAccountSummary,
  getPlayletPackage,
} from "../../../api/statistics";
import ChartDualAxes from "../../../components/ChartDualAxes";

const { Text } = Typography;

export default function List(props) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [accountSummary, setAccountSummary] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [divisionCost, setDivisionCost] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [accountNums, setAccountNums] = useState(-1)
  const [promotionNums, setPromotionNums] = useState(-1)
  const [playletOptions, setPlayletOptions] = useState([]);

  // 当前路径
  const pathKey = useLocation().pathname;

  const navigate = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();

  useEffect(() => {
    Promise.all([getFilterPitchers(), getPlaylet()]).then(() => {
      form.setFieldsValue({
        admin_id: props?.admin_id || props?.conditions?.admin_id || Number(localStorage.getItem('system_from_ecpm_BY_NAME')) || null,
        keyword: props?.conditions?.keyword || props?.conditions?.advertiser_id,
        playletId: props?.playletId || undefined,
      });
    });
    getList();
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey]);

  // 列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await recycleAccount({
      admin_id: props?.admin_id || props?.conditions?.admin_id || Number(localStorage.getItem('system_from_ecpm_BY_NAME')) || null,
      advertiser: props?.conditions?.keyword || props?.conditions?.advertiser_id || null,
      advertiser_name: state?.advertise?.account_name,
      playletId: props?.playletId || undefined,
      ...params,
    });
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      setList(lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 列表统计数据汇总
  const getSummary = async (params = {}) => {
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await recycleAccountSummary({
      admin_id: props?.admin_id || props?.conditions?.admin_id || localStorage.getItem('system_from_ecpm_BY_NAME') || null,
      advertiser: props?.conditions?.advertiser || null,
      advertiser_name: state?.advertise?.account_name,
      playletId: props?.playletId || undefined,
      ...params,
    });
    if (res?.code === 200) {
      const {
        data: { summary },
      } = res;
      setAccountSummary(summary);
    }
  };

  // 筛选投手数据
  const getFilterPitchers = async () => {
    let params = {};
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await filterPitchers(params);
    const { data } = res;
    if (res?.code === 200) {
      if (data?.is_agent && !(localStorage.getItem('system_from_ecpm_BY_NAME'))) {
        navigate("/admin/statistics/theater/push_plt/toutiao_agtplt", {...state});
      }
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 获取短剧列表
  const getPlaylet = async () => {
    const res = await getPlayletPackage();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.book_name, value: item.book_id };
      });
      form.setFieldsValue({ playletld: newLists[0]?.value });
      setPlayletOptions(newLists);
    }
  };

  // 修改备注
  const onChange = () => {};

  // 列表条件查询
  const onSearch = async(values) => {
    let date;
    if (values["date"]) {
      date = values["date"].format("YYYYMMDD");
      values.date = date;
    }
    values.advertiser = values.keyword;
    getList(values);
    getSummary(values);
    setConditions(values);
    if (values.advertiser && typeof(values.advertiser) === 'string') {
      setAccountNums(-1)
      setPromotionNums(-1)
      const res = await getPromotionsFromPlaylet({advertiser: values.keyword, date})
      if (res?.code === 200) {
        const {data} = res
        setAccountNums(data?.account_nums)
        setPromotionNums(data?.promotion_nums)
      }
    }
    props.onSearch(values);
  };

  // 分页
  const onShowChange = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size, ...props?.conditions });
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const switchover = (record, tab) => {
    props.switchover(record, tab);
  };

  // 表单重置
  const formReset = () => {
    form.resetFields();
    props.onSearch([]);
    const values = form.getFieldsValue();
    onSearch(values);
  };

  // 弹窗显示分时曲线
  const showTimeDivision = async (record) => {
    setConfirmLoading(true);
    setModalShow(true);
    const res = await costAdvertiseTimeDivision({
      advertiser_id: record?.advertiser_id,
      ...conditions,
    });
    if (res?.code === 200) {
      const { data } = res;
      setDivisionCost(data);
      setConfirmLoading(false);
    }
  };

  // 消耗、转化数排序
  const onTableChange = (pagination, filter, sorter) => {
    let field = sorter.field;
    let orderType = sorter.order === "ascend" ? "asc" : "desc";
    let order = `${field}_${orderType}`;
    if (field !== undefined) {
      getList({ ...conditions, order });
    }
  };

  const modalClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <Card
        title={
          <Form
            form={form}
            onFinish={onSearch}
          >
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item label="日期筛选" name="date">
                  <DatePicker style={{width: '100%'}} />
                </Form.Item> 
              </Col>
              <Col span={4}>
                <Form.Item label="投手" name="admin_id">
                  <Select
                    allowClear
                    placeholder="投手筛选"
                    options={pitcherOptions}
                    showSearch
                    filterOption={filterOption}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="广告账户" name="keyword">
                  <Input
                    allowClear
                    placeholder="输入账号名称, ID搜索"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                  <Form.Item label="短剧" name="playletId">
                    <Select
                      allowClear
                      placeholder="选择短剧"
                      showSearch
                      filterOption={filterOption}
                      options={playletOptions}
                    />
                  </Form.Item>
                </Col>
              <Col span={4}>
                <Form.Item style={{ marginLeft: 20 }}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      确定
                    </Button>
                    <Button onClick={formReset}>
                      重置
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => navigate(-1)}
                    >
                      返回
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            {
              conditions?.advertiser ? 
              <Row gutter={16}>
                <Col span={5}>
                  <Form.Item label='账户数'>
                    <Typography.Text type="danger">{accountNums >= 0 ? accountNums : <Spin />}</Typography.Text>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label='已建广告数'><Typography.Text type="danger">{promotionNums >= 0 ? promotionNums : <Spin />}</Typography.Text></Form.Item>
                </Col>
              </Row> : ''
            }
          </Form>
        }
        headStyle={{ marginTop: 10 }}
        bodyStyle={{padding:0}}
      >
        <Table
          sticky
          size="small"
          dataSource={list}
          columns={columns({ onChange, switchover, showTimeDivision })}
          loading={loading}
          bordered={true}
          onChange={onTableChange}
          pagination={{
            total: total,
            showSizeChanger: true,
            showQuickJumper: props.total > 50 ? true : false,
            showTotal: (total) => `共 ${total} 条`,
            pageSizeOptions: [10, 15, 20, 30],
            current: page || 1,
            onChange: onShowChange,
            pageSize: perPage,
          }}
          scroll={{ x: 1000 }}
          summary={(pageData) => {
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{accountSummary?.total_new_plans}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>{accountSummary?.total_cost_plans}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>-</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>{accountSummary?.cost}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{accountSummary?.total_money}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>{accountSummary?.total_cost_rate}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>-</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text>{accountSummary?.real_convert_cost}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="center">
                      <Text>{accountSummary?.convert_cost}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="center">
                      <Text>{accountSummary?.convert}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="center">
                      <Text>{accountSummary?.convert_rate}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={14} align="center">
                      <Text>{accountSummary?.avg_show_cost}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={15} align="center">
                      <Text>{accountSummary?.upnums}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={16} align="center">
                      <Text>-</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={17} align="center">
                      <Text>{accountSummary?.show}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={18} align="center">
                      <Text>{accountSummary?.click}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={19} align="center">
                      <Text>{accountSummary?.ctr}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={20} align="center">
                      <Text>{accountSummary?.avg_click_cost}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>

      {/* 弹窗显示分时曲线 */}
      <Modal
        open={modalShow}
        onCancel={modalClose}
        title="分时消耗数据"
        maskClosable={false}
        confirmLoading={confirmLoading}
        footer={null}
        width={1000}
      >
        <Divider />
        <ChartDualAxes
          data={[divisionCost, divisionCost]}
          xField="time_hour"
          yField={["cost", "convert"]}
          meta={{
            cost: {
              alias: "消耗",
            },
            convert: {
              alias: "转化",
            },
          }}
          geometryOptions={[
            {
              geometry: "line",
              color: "#5B8FF9",
            },
            {
              geometry: "line",
              color: "#FFD546",
            },
          ]}
          xAxis={{
            label: {
              offsetX: 10,
              offsetY: 5,
              style: {
                // fill: '#5A8DF6',
                fontSize: 10,
                margin: 10,
              },
            },
          }}
        />
      </Modal>
    </>
  );
}
