import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Row,
    Space,
    Table,
  } from "antd";
  import React from "react";
  import { useLocation } from "react-router-dom";
  import { useState, useEffect } from "react";
  import columns from "./columns";
  import {materialCompare} from "../../../api/advertise";

  export default function Index(props) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
  
    // 当前路径
    const pathKey = useLocation().pathname;
  
    const [form] = Form.useForm();
  
    useEffect(() => {
      getList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathKey]);
  
    //素材分日转化数据以及在投数量对比
    const getList = async (params = {}) => {
      setLoading(true);
      if (pathKey.split("/").includes("novel")) {
        params.type = "novel";
      } else if (pathKey.split("/").includes("theater")) {
        params.type = "playlet";
      }
      const res = await materialCompare({
        ...params,
      });
      if (res?.code === 200) {
        console.log(res,'rrrrr')
        setList(res?.data);
        setLoading(false);
      }
    };
  
    // 列表条件查询
    const onSearch = async(values) => {
      getList(values);
      console.log(props,'onsss')
    };

    return (
      <>
        <Card
          title={
            <Form
              form={form}
              onFinish={onSearch}
            >
              <Row gutter={16}>
                <Col span={7}>
                  <Form.Item label="素材创建日期筛选" name="day">
                    <DatePicker size="small" style={{width: '100%'}} />
                  </Form.Item> 
                </Col>
                <Col span={4}>
                  <Form.Item style={{ marginLeft: 20 }}>
                    <Space>
                      <Button type="primary" htmlType="submit" size="small">
                        确定
                      </Button>
                      
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          }
          headStyle={{ marginTop: 10 }}
          bodyStyle={{padding:0}}
          size="small"
        >
          <Table
            sticky
            size="small"
            dataSource={list}
            columns={columns()}
            loading={loading}
            bordered={true}
            scroll={{ x: 500 }}
            pagination={false}
          />
        </Card>
      </>
    );
  }
  