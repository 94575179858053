/*
 * @Author: fq
 * @email: 3229058754@163.com
 * @Date: 2024-09-03 09:52:17
 * @LastEditTime: 2024-09-03 15:38:47
 * @Description: this is a page
 */
/*
 * axios全局配置
 */
import axios from "axios"
import { message } from "antd"
import store from "../redux/store"
import { deleteUserInfo } from "../redux/actions/login"
import qs from "qs"

const instance = axios.create({
  // baseURL: 'http://192.168.0.18:8000',
  // baseURL: 'https://ad.suyueyun.com',
  timeout: 20000,
})

//配置请求拦截器
instance.interceptors.request.use(
  (config) => {
    //从redux中获取之前保存的token
    let token = store.getState().user.token
    if (token) {
      //如果token存在，就设置到请求头中
      config.headers.Authorization = `Bearer ${token}`
    }
    config.url = "/proxy" + config.url
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

//配置响应拦截器
instance.interceptors.response.use((response) => {
  if (response.data.code === 200) {
    return response.data
  } else if (response.data.code === 403) {
    message.warning(`${response.data.message}，请重新登录`, 2)
    //分发删除用户信息的action
    store.dispatch(deleteUserInfo())
    // 创建资产失败
  } else if(response.data.code === 202) {
    message.error(`${response.data.message}`, 2)
    return response.data 
  } else {
    //提示消息
    message.error(`${response.data.message}`, 2)
    return
  }
  //中断Promise链
  return new Promise(() => {})
})

export const getRequest = (url = "", data = {}, timeout = 20000) => {
  if (qs.stringify(data)) {
    url += url.includes("?")
      ? "&" + qs.stringify(data)
      : "?" + qs.stringify(data)
  }
  return instance.get(url, { timeout })
}

export const postRequest = (url = "", data = {}, timeout = 20000) => {
  return instance.post(url, data, { timeout })
}