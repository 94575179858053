import {
  Card,
  Typography,
  Table,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Empty,
  Row,
  Col,
  Select,
  Pagination,
  Divider,
} from "antd";
import React, { useState } from "react";
import MyTable from "../../../../components/TableNoSelection";
import columns from "./columns";
import PitcherColumns from "./pitcherColumns";
import bookColumns from "./bookColumns";
import playletColumns from "./playletColumns";
import playletSummerColumns from "./playletSummerColumns";
import { useEffect } from "react";
import {
  dailyTimerDivision,
  getLianShanRealCost,
  getPlayletDailyCost,
  promotionBackSet,
} from "../../../../api/statistics";
import { getPlayletPackage } from "../../../../api/playlet";
import { numDiv, numMulti } from "../../../../util/calculate";
import { promotionStatusSave } from "../../../../api/advertise";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import ChartLine from "../../../../components/ChartLine";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function Index() {
  const [activeTabKey, setActiveTabKey] = useState("tab1");
  const [activePlayletTabKey, setActivePlayletTabKey] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [packageTabs, setPackageTabs] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [realCost, setRealCost] = useState({});
  const [statusLoading, setStatusLoading] = useState(0);
  const [show, setShow] = useState(false);
  const [backsetInfo, setBacksetInfo] = useState([]);
  const [detail, setDetail] = useState("");
  const [detailLoaind, setDetailLoading] = useState(false);
  const [superRole, setSuperRole] = useState(false);
  const [agentRole, setAgentRole] = useState(false)
  const [divisionData, setDivisionData] = useState([])
  const [divisionModalShow, setDivisionModalShow] = useState(false)

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getPlayletDailyCost(params);
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists
      let newList = lists.map((item, key) => {
        // 添加序号显示
        item.serial_num = parseInt(key) + 1
        return item
      })
      setList(newList);
      setTotal(data?.total || 0);
      setSuperRole(data?.is_super || false);
      setAgentRole(data?.is_agent)
      setLoading(false);
    }
  };

  // 标签切换
  const onTabChange = async (value) => {
    setActiveTabKey(value);
    if (value === "tab3") {
      const res = await getPlayletPackage();
      if (res?.code === 200) {
        const { data } = res;
        let list = data.lists.map((item) => {
          return { tab: item.aliasname, key: item.app_type };
        });
        list.push({ tab: "剧场汇总", key: -1 });
        setPackageTabs(list);
        setActivePlayletTabKey(list[0]?.key);
        getList({ tab: value, app_type: list[0]?.key });
      }
    } else {
      getList({ tab: value });
    }
    form.resetFields();
  };

  // 剧场标签切换
  const onPackageTabChange = async (value) => {
    setActivePlayletTabKey(value);
    getList({ tab: "tab3", app_type: value });
    form.resetFields();
  };

  // 日期范围查询
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    getList({
      tab: "tab3",
      app_type: activePlayletTabKey,
      ...values,
    });
  };

  const tabList = [
    {
      key: "tab1",
      tab: "推广链接",
    },
    {
      key: "tab4",
      tab: "投手消耗",
    },
    {
      key: "tab2",
      tab: "短剧消耗",
    },
    {
      key: "tab3",
      tab: "剧场消耗",
    },
  ];

  const getColumns = (tab) => {
    let returnColumns;
    switch (tab) {
      case "tab1":
        returnColumns = columns({
          onGetRealCost,
          realCost,
          onStatusChange,
          showDetail,
          superRole,
          agentRole,
          showEstimateDetail,
        });
        break;
      case "tab4":
        returnColumns = PitcherColumns({
          onGetRealCost,
          realCost,
          onStatusChange,
          showDetail,
          superRole,
        });
        break;
      case "tab2":
        returnColumns = bookColumns({superRole, showEstimateDetail});
        break;
      case "tab3":
        if (activePlayletTabKey === -1) {
          returnColumns = playletSummerColumns();
        } else {
          returnColumns = playletColumns();
        }

        break;
    }
    return returnColumns;
  };

  const onFormSearch = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((value) => {
        return value.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    setConditions({ tab: activeTabKey, ...values });
    getList({ tab: activeTabKey, ...values });
  };

  // 点击推广外链查看对应的信息
  const showDetail = async (promotionId, content) => {
    setShow(true);
    setDetailLoading(true);
    const res = await promotionBackSet({ promotion_id: promotionId });
    if (res?.code === 200) {
      const { data } = res;
      setBacksetInfo(data);
      setDetail(content);
      setDetailLoading(false);
    }
  };

  // 关闭弹窗
  const modalClose = () => {
    setShow(false);
  };

  // 获取连山实时消耗数据
  const onGetRealCost = async (promotion_id) => {
    let date = conditions?.date;
    const res = await getLianShanRealCost({ promotion_id, date });
    if (res?.code === 200) {
      const { data } = res;
      let key = `promotion_id_${promotion_id}`;
      setRealCost({ [key]: data[0]?.cost?.toFixed(2) });
    }
  };

  // 投放状态切换
  const onStatusChange = async (record, status) => {
    let id = record?.promotion_primary_id;
    setStatusLoading(id);
    let value = status === true ? 1 : 0;
    const res = await promotionStatusSave({ id, status: value });
    if (res?.code === 200) {
      message.success("状态更改成功");
      setStatusLoading(0);
      getList({ ...conditions });
    }
  };

  // 分页
  const onPageChange = (page) => {
    getList({ ...conditions, page });
  };

  // 排序
  const onSortChange = (pagination, filters, sorter) => {
    let feild = sorter.columnKey;
    let sortBy = sorter.order;
    let order;
    if (feild !== undefined) {
      if (sortBy === "ascend") {
        order = feild + "_" + "asc";
      } else {
        order = feild + "_" + "desc";
      }
      getList({ ...conditions, order });
    }
  };

  // 显示分时的消耗时速
  const showEstimateDetail = async (bookName) => {
    let day = dayjs().format('YYYYMMDD')
    if (conditions?.range_date) {
      const b = new Set(conditions?.range_date)
      let arr = [...b]
      if (arr.length > 1) {
        message.error('查看消耗时速不能跨日期选择');
        return false
      }
      day = arr[0]
    }
    setDivisionModalShow(true)
    const res = await dailyTimerDivision({book_name: bookName, day})
    if (res?.code === 200) {
      const {data} = res
      const list = data?.lists
      let newList = list.map((item) => {
        item.cost = parseFloat(item.cost)
        return item
      })
      setDivisionData(newList)
    }
  }

  return (
    <>
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        tabProps={{ size: "small" }}
      >
        {activeTabKey === "tab3" ? (
          <Card
            title={
              <Form
                onFinish={onSubmit}
                form={form}
                layout="inline"
                style={{ marginBottom: 10 }}
              >
                <Form.Item label="日期" name="range_date">
                  <RangePicker />
                </Form.Item>
                <Form.Item label="投放平台" name="platform">
                  <Select
                    allowClear
                    placeholder="选择投放平台"
                    options={[
                      { label: "微信小程序", value: 1 },
                      { label: "抖音小程序", value: 2 },
                    ]}
                    style={{ width: 150 }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                </Form.Item>
              </Form>
            }
            type="inner"
            tabList={packageTabs}
            tabProps={{ type: "card", size: "small" }}
            onTabChange={onPackageTabChange}
            style={{ marginLeft: -10, marginTop: -10, marginRight: -10 }}
          >
            <Table
              sticky
              dataSource={list}
              columns={getColumns(activeTabKey)}
              bordered={true}
              loading={loading}
              pagination={false}
              summary={(pageData) => {
                let totalCost = 0;
                let totalMoneyCount = 0;
                let totalPayNum = 0;
                pageData.forEach(
                  ({
                    cost,
                    total_money,
                    count,
                  }) => {
                    totalCost += parseFloat(cost || 0);
                    totalMoneyCount += parseFloat(total_money);
                    totalPayNum += parseFloat(count);
                  }
                );
                return (
                  <>
                    {pageData && pageData.length > 0 ? (
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          index={0}
                          colSpan={1}
                          align="center"
                        >
                          <Text type="danger" strong={true}>
                            汇总
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="center">
                          <Text>{totalCost.toFixed(2)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} align="center">
                          <Text>{totalMoneyCount.toFixed(2)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} align="center">
                          <Text>
                            {parseInt(totalCost)
                              ? numMulti(
                                  numDiv(totalMoneyCount, totalCost),
                                  100
                                ).toFixed(2) + "%"
                              : "0%"}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} align="center">
                          <Text>{totalPayNum}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6} align="center">
                          <Text>
                            {totalPayNum > 0
                              ? numDiv(totalCost, totalPayNum).toFixed(2)
                              : "0.00"}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7} align="center">
                          <Text>
                            {totalPayNum > 0
                              ? numDiv(totalMoneyCount, totalPayNum).toFixed(2)
                              : "0.00"}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    ) : (
                      ""
                    )}
                  </>
                );
              }}
              style={{ marginLeft: -10 }}
              size="small"
            />
          </Card>
        ) : (
          <div style={{ marginLeft: -10, marginTop: -10, marginRight: -10 }}>
            <div style={{ marginBottom: 10 }}>
              <Form layout="inline" onFinish={onFormSearch} form={form}>
                <Form.Item label="日期" name="range_date">
                  <RangePicker style={{ width: 225 }} />
                </Form.Item>
                {activeTabKey === "tab1" || activeTabKey === "tab4" ? (
                  <Form.Item label="投手" name="pitcher">
                    <Input
                      allowClear
                      placeholder="输入投手名称"
                      size="middle"
                      style={{ width: 150 }}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
                <Form.Item label="短剧" name="playlet_name">
                  <Input
                    allowClear
                    placeholder="输入短剧名称"
                    size="middle"
                    style={{ width: 150 }}
                  />
                </Form.Item>
                <Form.Item label="剧场" name="theater">
                  <Input
                    allowClear
                    placeholder="输入剧场名称"
                    size="middle"
                    style={{ width: 150 }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    确定
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {/* 分页 */}
            {superRole ? (
              <div style={{ textAlign: "right", marginBottom: 5 }}>
                <Pagination
                  total={total}
                  hideOnSinglePage={true}
                  onChange={onPageChange}
                  pageSize={30}
                  showSizeChanger={false}
                />
              </div>
            ) : (
              ""
            )}
            {superRole ? (
              <MyTable
                dataSource={list}
                columns={getColumns(
                  activeTabKey,
                  onGetRealCost,
                  realCost,
                  onStatusChange,
                  statusLoading,
                  superRole
                )}
                bordered={true}
                loading={loading}
                pagination={false}
                size="small"
                onChange={onSortChange}
              />
            ) : (
              <MyTable
                dataSource={list}
                columns={getColumns(
                  activeTabKey,
                  onGetRealCost,
                  realCost,
                  onStatusChange,
                  statusLoading,
                  superRole,
                  showEstimateDetail
                )}
                virtual={true}
                bordered={true}
                loading={loading}
                pagination={false}
                size="small"
              />
            )}
          </div>
        )}
      </Card>

      {/* 点击推广外链弹窗查看详情信息 */}
      <Modal
        open={show}
        onCancel={modalClose}
        title="推广链接对应的配置详情"
        maskClosable={false}
        footer={<Button onClick={modalClose}>取消</Button>}
      >
        {detailLoaind ? (
          <Spin />
        ) : (
          <div>
            {backsetInfo.length > 0 || detail !== null ? (
              <>
                <div>
                  {backsetInfo ? (
                    <>
                      <Card
                        title={
                          <div style={{ fontSize: 13 }}>推广链接回传配置</div>
                        }
                        size="small"
                      >
                        <Row gutter={12}>
                          <Col span={8}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                marginRight: 10,
                              }}
                            >
                              回传染色时间:
                            </span>
                            <Link>{backsetInfo?.back_hours} 小时</Link>
                          </Col>
                          <Col span={8}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                marginRight: 10,
                              }}
                            >
                              是否首充:
                            </span>
                            <Link>
                              {backsetInfo?.is_first_pay === 1
                                ? "首次充值"
                                : "所有充值"}
                            </Link>
                          </Col>
                        </Row>
                        {backsetInfo?.info
                          ? backsetInfo.info.map((item, key) => {
                              return (
                                <ul
                                  key={key}
                                  style={{
                                    border: "1px solid #E2E3E6",
                                    marginTop: 5,
                                    paddingLeft: 5,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  <li>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        flexDirection: "row",
                                        gap: 20,
                                      }}
                                    >
                                      <div style={{ fontWeight: "bold" }}>
                                        {`配置${key + 1}`}:
                                      </div>
                                      <div>
                                        {item?.min_money} - {item?.max_money}
                                      </div>
                                      <div>
                                        每{item?.num}条，传{item?.back_num}条
                                      </div>
                                      <div>前{item?.block_nums}条不卡</div>
                                    </div>
                                  </li>
                                </ul>
                              );
                            })
                          : ""}
                      </Card>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {detail !== null ? (
                  <Card
                    size="small"
                    title={<div style={{ fontSize: 13 }}>面板或卡回传截图</div>}
                    style={{ marginTop: 10 }}
                  >
                    <div
                      className="editor-wrapper"
                      dangerouslySetInnerHTML={{ __html: detail }}
                    ></div>
                  </Card>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Empty
                description={
                  <span style={{ fontSize: 12, color: "#666" }}>
                    去推广链接设置回传配置, 添加面板或卡回传截图
                  </span>
                }
              />
            )}
          </div>
        )}
      </Modal>

      {/* 弹窗显示分时消耗时速 */}
      <Modal 
        open={divisionModalShow}
        onCancel={() => setDivisionModalShow(false)}
        title="分时消耗时速"
        maskClosable={false}
        footer={null}
        width={1000}
      >
        <Divider />
        <ChartLine 
           data={divisionData}
           xField="time"
           yField="cost"
          //  seriesField="day"
        />
      </Modal>
    </>
  );
}
