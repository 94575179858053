const options = [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "推广链接",
        width: 100,
        align: "center",
        dataIndex: "promotion_title",
        key: "promotion_title",
    },
    {
        title: "新增用户",
        width: 100,
        align: "center",
        dataIndex: "new_user",
        key: "new_user",
    },
    {
        title: "加桌数|加桌率",
        width: 160,
        align: "center",
        dataIndex: "add_table",
        key: "add_table",
        render: (_,record) => (
            record?.addTable + '|' + record?.table_percent
        )
    },
    {
        title: "累计充值",
        width: 100,
        align: "center",
        dataIndex: "money",
        key: "money",
    },
    {
        title: "投放小说",
        width: 100,
        align: "center",
        dataIndex: "book_title",
        key: "book_title",
    },
    {
        title: "成本",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
    },
    {
        title: "自动计算头条成本",
        width: 120,
        align: "center",
        dataIndex: "cal_cost",
        key: "cal_cost",
    },
    {
        title: "赔付",
        width: 120,
        align: "center",
        dataIndex: "claim",
        key: "claim",
    },
    {
        title: "回本率",
        width: 80,
        align: "center",
        dataIndex: "huiben_lv",
        key: "huiben_lv",
    },
    {
        title: "前30天充值",
        width: 110,
        align: "center",
        dataIndex: "day_30_money",
        key: "day_30_money",
        render: (_, record) => (
            record?.day30Money
        )
    },
    {
        title: "回本率30天",
        width: 120,
        align: "center",
        dataIndex: "huiben_lv30",
        key: "huiben_lv30",
        render: (_, record) => {
            return <>
                <div style={{textAlign: 'left'}}>回本率：{record?.percent}</div>
                <div style={{textAlign: 'left'}}>分成后：{record?.afterPercent}</div>
            </>
        }
    },
    {
        title: "首日充值人数",
        width: 100,
        align: "center",
        dataIndex: "first_pay_user",
        key: "first_pay_user",
    },
    {
        title: "首日首充|首日充值",
        width: 150,
        align: "center",
        dataIndex: "first_money",
        key: "first_money",
        render: (_, record) => {
            return `${record?.first_day_first_pay} | ${record?.firstDay}`
        }
    },
    {
        title: "首日首充客单价|首日客单价",
        width: 180,
        align: "center",
        dataIndex: "per_sale",
        key: "per_sale",
        render: (_, record) => {
            return `${record?.firstPer} | ${record?.per}`
        }
    },
    {
        title: "总充值人数|总客单价",
        width: 180,
        align: "center",
        dataIndex: "total_pay",
        key: "total_pay",
        render: (_, record) => {
            return `${record?.total_pay_user} | ${record?.perPrice}`
        }
    },
    {
        title: "当天付费人数成本|总付费人数成本",
        width: 180,
        align: "center",
        dataIndex: "day_cost",
        key: "day_cost",
        render: (_, record) => {
            return `${record?.firstPercent} | ${record?.totalPercent}`
        }
    },
]

// 动态添加第1天到第60天的列
for(let i = 0; i <= 13; i++) {
    options.push({
        title: `第${i+1}天`,
        width: 130,
        align: "center",
        dataIndex: `day${i}`,
        key: `day${i}`,
        render: (_, record) => {
            let day = `day${i}`
            let extend = record?.rangeData
            let recharge = extend[i]?.[day]?.recharge || 0
            let return_cost = extend[i]?.[day]?.return_cost || '0.00%'
            return <>
            <div style={{textAlign: 'left'}}>充值：{recharge}</div>
            <div style={{textAlign: 'left'}}>回本率：{return_cost}</div>
            </>
        }
    })
}

const columns = () => options

export default columns