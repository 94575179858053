import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Radio,
  Rate,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./addplan.less";
import { createFromIconfontCN } from "@ant-design/icons";

const { RangePicker } = DatePicker;

export default function UploadVideo(props) {
  const { show, videoClose, list, sure, total, form, selectVideoList } = props;
  let rows = [];
  if (selectVideoList.length > 0) {
    rows = selectVideoList?.map((item) => {
      return item.id;
    });
  }
  const [activeTabKey, setActiveTabKey] = useState("tab1");
  const [sort, setSort] = useState("asc");
  const [aboveActive, setAboveActive] = useState(false);
  const [belowActive, setBelowActive] = useState(false);
  const [checkedEleValue, setCheckEleValue] = useState(0);
  const [selectRowKeys, setSelectRowKeys] = useState(rows);
  const [selectVideos, setSelectVideos] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabKey]);

  // tablist元素
  const items = [
    {
      key: "tab1",
      label: "我的视频",
    },
  ];

  // 对视频进行排序操作
  const getSortBtnItem = () => {
    const items = [
      { title: "按创建时间", value: 1 },
      { title: "按消耗", value: 2 },
      { title: "按转化", value: 3 },
      { title: "使用数", value: 4 },
      { title: "被拒数", value: 5 },
    ];
    return items.map((item, key) => {
      return (
        <Button
          type="text"
          onClick={() => {
            handleSort(item.value);
          }}
          key={key}
        >
          <div style={{ display: "flex" }}>
            <div>{item.title}</div>
            <div
              style={{
                marginTop: -1,
                fontSize: 12,
                marginLeft: 2,
                color: "#999",
              }}
            >
              <div
                style={{ marginBottom: -12 }}
                className={
                  aboveActive && item.value === checkedEleValue
                    ? "sort-live"
                    : ""
                }
              >
                <CaretUpOutlined />
              </div>
              <div
                className={
                  belowActive && item.value === checkedEleValue
                    ? "sort-live"
                    : ""
                }
              >
                <CaretDownOutlined />
              </div>
            </div>
          </div>
        </Button>
      );
    });
  };

  // 各元素排序高亮显示
  const handleSort = (value) => {
    setCheckEleValue(value);
    if (sort === "asc") {
      setSort("desc");
      setBelowActive(true);
      setAboveActive(false);
    } else {
      setSort("asc");
      setAboveActive(true);
      setBelowActive(false);
    }

    const items = [
      { title: "create_time", value: 1 },
      { title: "cost", value: 2 },
      { title: "convert", value: 3 },
      { title: "use_num", value: 4 },
      { title: "audit_fail_num", value: 5 },
    ];
    let filterd = items.filter((item) => {
      return item.value === value;
    });
    let sortType = sort === "asc" ? "desc" : "asc";
    let params = { order: filterd[0]?.title + "_" + sortType };
    props.onSearch(params);
  };

  // tab切换
  const tabChange = (key) => {
    setActiveTabKey(key);
  };

  // 选中视频
  const selectVideo = (data) => {
    let tempKeys = selectRowKeys;
    if (selectRowKeys.includes(data?.id)) {
      const newVideos = selectVideos.filter((item) => {
        return item.id !== data?.id;
      });
      const newRows = selectRowKeys.filter((item) => {
        return item !== data?.id;
      });
      setSelectRowKeys(newRows);
      setSelectVideos(newVideos);
    } else {
      let newRows;
      let newVideos;
      if (tempKeys.length > 0) {
        newRows = [...selectRowKeys, data?.id];
        newVideos = [
          ...selectVideos,
          { id: data?.id, video: data?.attach_video?.oss_url },
        ];
      } else {
        newRows = [...selectRowKeys, ...rows, data?.id];
        newVideos = [
          ...selectVideos,
          ...selectVideoList,
          { id: data?.id, video: data?.attach_video?.oss_url },
        ];
      }
      setSelectRowKeys(newRows);
      setSelectVideos(newVideos);
    }
  };

  // 是否选中为重点推素材
  const checkSelect = (item, e) => {
    let checked = e.target.checked;
    let newSelectVideos = selectVideos.map((video) => {
      if (video.id === item.id) {
        video.checked = checked;
      }
      return video;
    });
    setSelectVideos(newSelectVideos);
  };

  // 处理关闭弹窗
  const handleClose = () => {
    setSelectRowKeys([]);
    setSelectVideos([]);
    form.resetFields();
    videoClose();
  };

  const onSure = () => {
    sure(selectVideos);
    handleClose();
  };

  // 关键词查询
  const keywordSearch = (value) => {
    props.onSearch({ keyword: value });
  };

  // 视频类型切换
  const changeType = (value) => {
    props.onSearch({ type: value });
  };

  // 是否重点素材切换
  const changeRecommend = (value) => {
    props.onSearch({ is_recommend: value });
  };

  // 标签切换
  const changeLable = (value) => {
    props.onSearch({ label: value });
  };

  // 时间切换
  const dateChange = (value) => {
    let createdAt;
    if (value) {
      createdAt = value.map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    props.onSearch({ create_time: createdAt });
  };

  // 视频标签切换
  const categoryChange = (value) => {
    props.onSearch({ category: value });
  };

  // 素材归属切换
  const materialChange = (value) => {
    props.onSearch({ show_type: value });
  };

  // 是否低效素材筛选
  const lowMaterailChange = (value) => {
    props.onSearch({ is_low: value });
  };

  // 素材审核状态筛选
  const verifyStatusChange = (value) => {
    props.onSearch({ verify_status: value });
  };

  // 选择是否被拒素材
  const failNumChange = (value) => {
    props.onSearch({ fail_num: value });
  };

  // 素材手切换
  const sucaiChange = (value) => {
    props.onSearch({ sucaishou_id: value });
  };

  // 投手切换
  const toushouChange = (value) => {
    props.onSearch({ toushou_id: value });
  };

  // 是否转化素材切换
  const convertChange = (value) => {
    props.onSearch({ is_convert: value });
  };

  // 分页
  const handleChange = (page, size) => {
    props.pagesize(page, size);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 清空所有已选中视频
  const onClear = () => {
    setSelectRowKeys([]);
    setSelectVideos([]);
    props.onClear();
    form.resetFields();
  };

  const MyIcon = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_t51iba45eu.js", // 在 iconfont.cn 上生成
  });

  // 当页全选
  const selectAll = () => {
    let filtered = list.filter((item) => {
      return !selectRowKeys.includes(item.id);
    });

    if (filtered.length > 0) {
      let ids = filtered.map((item) => {
        return item.id;
      });
      setSelectRowKeys([...selectRowKeys, ...ids]);
      let newVideos = filtered.map((item) => {
        return { video: item?.attach_video?.oss_url, id: item.id };
      });
      setSelectVideos([...selectVideos, ...newVideos]);
    }
  };

  const selectCancel = () => {
    setSelectRowKeys([]);
    setSelectVideos([]);
  };

  return (
    <Modal
      open={show}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={videoClose}
      title={
        <Tabs items={items} onChange={tabChange} activeKey={activeTabKey} />
      }
      width={900}
      closable={false}
      footer={null}
    >
      {activeTabKey === "tab1" ? (
        <>
          <Card
            bodyStyle={{ display: "none" }}
            style={{ marginBottom: 10 }}
            headStyle={{ marginTop: 15 }}
            title={
              <Form
                initialValues={{ type: props.type, is_low: 0 }}
                form={form}
                size="small"
              >
                <Row gutter={24} style={{ marginBottom: -15 }}>
                  <Col span={6}>
                    <Form.Item name="keyword">
                      <Input.Search
                        placeholder="可搜索视频名称或ID"
                        onSearch={keywordSearch}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item>
                      <RangePicker onChange={dateChange} />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name="type">
                      <Select
                        options={[
                          { label: "横版视频", value: 2 },
                          { label: "竖版视频", value: 1 },
                        ]}
                        placeholder="视频类型"
                        allowClear
                        onChange={changeType}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="is_recommend">
                      <Select
                        placeholder="素材是否加星"
                        options={[
                          { label: "是", value: 1 },
                          { label: "否", value: 0 },
                        ]}
                        allowClear
                        onChange={changeRecommend}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: -15 }}>
                  <Col span={6}>
                    <Form.Item name="category">
                      <Select
                        options={props.categoryOptions}
                        placeholder="选择分类"
                        allowClear
                        onChange={categoryChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="admin_id">
                      <Select
                        options={props.sucaiOptions}
                        placeholder="选择素材手"
                        allowClear
                        onChange={sucaiChange}
                        showSearch
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="toushou_id">
                      <Select
                        options={props.toushouOptions}
                        placeholder="选择投手"
                        allowClear
                        onChange={toushouChange}
                        showSearch
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="is_convert">
                      <Select
                        options={[
                          { label: "转化素材", value: 1 },
                          { label: "非转化素材", value: 0 },
                        ]}
                        placeholder="是否转化素材"
                        allowClear
                        onChange={convertChange}
                        showSearch
                        filterOption={filterOption}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: -15 }}>
                  <Col span={8}>
                    <Form.Item>
                      <Select
                        placeholder="是否被拒"
                        options={[
                          { label: "有被拒", value: 1 },
                          { label: "未被拒", value: 0 },
                        ]}
                        allowClear
                        onChange={failNumChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="user">
                      <Select
                        options={[
                          { label: "公有", value: 1 },
                          { label: "私有", value: 2 },
                        ]}
                        placeholder="素材归属"
                        allowClear
                        onChange={materialChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="is_low">
                      <Select
                        options={[
                          { label: "不是低效素材", value: 0 },
                          { label: "低效素材", value: 1 },
                          { label: "优质素材", value: 2 },
                          { label: "首发素材", value: 5 },
                        ]}
                        placeholder="素材标签属性"
                        allowClear
                        onChange={lowMaterailChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="label">
                  <Select
                    mode="multiple"
                    placeholder="选择标签"
                    options={props.labels}
                    allowClear
                    onChange={changeLable}
                    showSearch
                    filterOption={filterOption}
                    autoClearSearchValue={false}
                  />
                </Form.Item>
              </Form>
            }
            size="small"
          />
          <Card
            title={
              <Space size="small">
                <Typography.Text style={{ color: "#999" }}>
                  共 {total} 个视频
                </Typography.Text>
                <Button type="link" onClick={selectAll}>
                  全选当页
                </Button>
                <Button type="link" onClick={selectCancel}>
                  取消全选
                </Button>
              </Space>
            }
            extra={<Space size="small">{getSortBtnItem()}</Space>}
            bodyStyle={{ padding: 0 }}
            size="small"
          >
            <List
              grid={{
                gutter: 16,
                column: 5,
              }}
              size="small"
              pagination={{
                position: "both",
                total,
                onChange: handleChange,
                defaultPageSize: 20,
                pageSizeOptions: [20, 25, 30],
                current: props.currentPage,
              }}
              loading={props.loading}
              dataSource={list}
              renderItem={(item) => (
                <List.Item>
                  <div
                    style={{
                      overflowX: "hidden",
                      width: 150,
                      marginLeft: -16,
                      marginTop: 10,
                    }}
                    onClick={() => selectVideo(item)}
                    className={
                      selectRowKeys && selectRowKeys.length > 0
                        ? selectRowKeys.includes(item.id)
                          ? "live"
                          : ""
                        : rows.includes(item.id)
                        ? "live"
                        : ""
                    }
                  >
                    <div style={{ textAlign: "center", marginBottom: 5 }}>
                      <div style={{ fontWeight: "bold", display: "flex" }}>
                        <span style={{marginLeft: 10, marginRight: 5}}>
                          <Rate
                            count={1}
                            style={{ fontSize: 14 }}
                            defaultValue={item?.is_recommend}
                          />
                        </span>
                        {item.type === 1 ? "竖版视频" : "横版大图"}
                        {selectRowKeys && selectRowKeys.length > 0 ? (
                          selectRowKeys.includes(item.id) ? (
                            <div
                              style={{ textAlign: "right", width: "58%" }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Tooltip title="选中则为重点推荐素材">
                                <Checkbox
                                  onChange={(e) => checkSelect(item, e)}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )
                        ) : rows.includes(item.id) ? (
                          <div
                            style={{ textAlign: "right", width: "58%" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Tooltip title="选中则为重点推荐素材">
                              <Checkbox />
                            </Tooltip>
                          </div>
                        ) : (
                          ""
                        )}

                        {item.is_convert ? (
                          <Space>
                            <MyIcon type="icon-qiang" />
                            <span>({item.convert})</span>
                          </Space>
                        ) : (
                          ""
                        )}
                        {item.is_low === 1 ? (
                          <MyIcon
                            type="icon-di-copy"
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : item.is_low === 2 ? (
                          <MyIcon
                            type="icon-youzhi1-copy-copy"
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : item.is_low === 5 ? (
                          <MyIcon
                            type="icon-shoufaxinpin"
                            style={{ fontSize: 20, marginLeft: 5 }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <Tooltip title={item.title}>{item.title} </Tooltip>
                    </div>
                    <video
                      src={item?.attach_video?.oss_url}
                      alt=""
                      controls
                      preload="none"
                      poster={`${item?.attach_video?.oss_url}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                    />
                    <div style={{ display: "flex", backgroundColor: "#eee" }}>
                      <div
                        style={{ marginRight: 3, color: "red", width: "33%" }}
                      >
                        ￥{item?.cost}
                      </div>
                      <div
                        style={{ marginRight: 3, color: "green", width: "38%" }}
                      >
                        使用:{item?.use_num}
                      </div>
                      <div>拒:{item?.audit_fail_num}</div>
                    </div>

                    {selectRowKeys && selectRowKeys.length > 0 ? (
                      selectRowKeys.includes(item.id) ? (
                        <div
                          style={{
                            fontSize: 35,
                            position: "absolute",
                            left: "50%",
                            top: "36%",
                          }}
                        >
                          <MyIcon type="icon-xuanzhong-copy" />
                        </div>
                      ) : (
                        ""
                      )
                    ) : rows.includes(item.id) ? (
                      <div
                        style={{
                          fontSize: 35,
                          position: "absolute",
                          left: "50%",
                          top: "36%",
                        }}
                      >
                        <MyIcon type="icon-xuanzhong-copy" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </>
      ) : (
        ""
      )}
      <Card
        extra={
          <Space>
            <Button onClick={handleClose}>取消</Button>
            <Button type="primary" danger onClick={onClear}>
              清空
            </Button>
            <Button type="primary" onClick={onSure}>
              确定
            </Button>
          </Space>
        }
        title={
          <div style={{ display: "flex" }}>
            <div>已选择 {selectVideos.length || selectVideoList.length}：</div>
            <div
              style={{ width: "85%", whiteSpace: "pre-wrap", marginBottom: 50 }}
            >
              {selectVideos && selectVideos.length > 0
                ? selectVideos.map((item) => {
                    return (
                      <video
                        src={item.video}
                        controls=""
                        key={item.id}
                        width={20}
                        style={{ marginRight: 8 }}
                      ></video>
                    );
                  })
                : selectVideoList.map((item) => {
                    return (
                      <video
                        src={item?.video}
                        controls=""
                        key={item.id}
                        width={20}
                        style={{ marginRight: 8 }}
                      ></video>
                    );
                  })}
            </div>
          </div>
        }
        style={{ marginTop: 10 }}
        bodyStyle={{ display: "none" }}
      />
    </Modal>
  );
}
