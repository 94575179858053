import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import selfColumns from "./selfColumns";
import "./index.less";

import { playletBackSet } from "../../../../api/playlet";

const { Text } = Typography;

export default function Index(props) {
  const [type, setType] = useState(1);
  const {
    show,
    list,
    total,
    page,
    perPage,
    loading,
    pagesize,
    handleAddPromotion,
    backShow,
  } = props;

  const [form] = Form.useForm();
  const [backForm] = Form.useForm();

  // 用于区分是自研小说还是三方外部小说
  const category = props?.category || 0;

  const getColumns = ({ backSet, onStatusChange }) => {
    if (!category) {
      return columns({ backSet });
    } else {
      return selfColumns({ onStatusChange });
    }
  };

  // 修改状态
  const onStatusChange = (record, status) => {
    let id_promotionId = record?.id.split("@@##");
    let value = status === true ? 1 : 0;
    props.promotionStatusEdit({ id: id_promotionId[0], status: value });
  };

  // 添加推广链接弹窗
  const addPromotion = () => {
    props.addPromotion();
  };

  // 关闭弹窗
  const onClose = () => {
    form.resetFields();
    props.onClose();
  };

  // 关闭卡回传设置弹窗
  const onSetClose = () => {
    backForm.resetFields();
    props.onSetClose();
  };

  // form排版
  const layout = {
    labelCol: { sm: { span: 6 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 推广名称搜索
  const onTitleSearch = (value) => {
    props.onTitleSearch(value);
  };

  // 卡回传设置
  const backSet = async (record) => {
    let id_promotionId = record?.id.split("@@##");
    const res = await playletBackSet({
      id: id_promotionId[0],
      promotion_id: record?.promotion_id,
      app_type: props.app_type,
    });
    const { data } = res;
    backForm.setFieldsValue(data?.info);
    backForm.setFieldsValue({
      id: id_promotionId[0],
      promotion_id: id_promotionId[0],
    });
    setType(data?.info?.sbfs);
    props.backSet(record);
  };

  const onTypeChange = (e) => {
    let value = e.target.value;
    setType(value);
  };

  // 添加推广链接
  const onSubmit = () => {
    form
      .validateFields()
      .then(() => {
        let params = form.getFieldsValue();
        handleAddPromotion(params);
      })
      .catch(() => {
        return;
      });
  };

  // 添加或更新推广链接
  const backFormSubmit = () => {
    props.handleBackSet(backForm.getFieldsValue());
  };

  return (
    <>
      <Card
        title={
          !category ? (
            <Button type="primary" onClick={addPromotion}>
              添加推广链接
            </Button>
          ) : (
            ""
          )
        }
        extra={
          <Input.Search
            allowClear
            placeholder="输入推广链接名称"
            onSearch={onTitleSearch}
          />
        }
      >
        <Table
          dataSource={list}
          columns={getColumns({ backSet, onStatusChange })}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
        />
      </Card>

      {/* 添加推广链接弹窗 */}
      <Modal
        title={
          <Card
            title="绑定添加推广链接"
            style={{ border: "none" }}
            styles={{ body: { display: "none" } }}
          />
        }
        open={show}
        onCancel={onClose}
        onOk={onSubmit}
        width={700}
        styles={{ body: { paddingTop: 10 } }}
        maskClosable={false}
        confirmLoading={props.confirmLoading}
        className="promotionAddModal"
      >
        <Form {...layout} form={form} initialValues={{ platform: 2 }}>
          <Form.Item
            label="推广链接名称（备注）"
            name="title"
            rules={[{ required: true, message: "推广链接名称不能为空" }]}
          >
            <Input placeholder="输入推广链接名称（备注）" allowClear />
          </Form.Item>
          <Form.Item
            label="跳转外链id"
            name="promotion_id"
            rules={[{ required: true, message: "跳转链接id不为空" }]}
          >
            <Form.Item name="promotion_id" style={{ marginBottom: 0 }}>
              <Input placeholder="输入跳转外链id" allowClear />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              短剧后台橙子建站里面复制跳转外链id
            </Text>
          </Form.Item>
          <Form.Item
            label="跳转链接"
            name="promotion_url"
            rules={[{ required: true, message: "跳转链接不能为空" }]}
          >
            <Form.Item name="promotion_url" style={{ marginBottom: 0 }}>
              <Input placeholder="输入跳转链接" allowClear />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              短剧后台橙子建站里面复制跳转链接
            </Text>
          </Form.Item>
          <Form.Item
            label="小说名称"
            name="book_name"
            rules={[{ required: true, message: "书本名称不能为空" }]}
          >
            <Form.Item name="book_name" style={{ marginBottom: 0 }}>
              <Input placeholder="输入书本名称" allowClear />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              投放哪本书，会同步到三方书籍库中
            </Text>
          </Form.Item>
          <Form.Item
            label="是否用于投放"
            name="type"
            rules={[{ required: true, message: "请选择是否用于投放" }]}
          >
            <Form.Item name="type" style={{ marginBottom: 0 }}>
              <Radio.Group
                options={[
                  { label: "自己平台投放", value: 1 },
                  { label: "只同步数据", value: 2 },
                ]}
              />
            </Form.Item>
            <Text type="secondary" style={{ fontSize: 12 }}>
              之前老链接直接投放的影潭平台的监测地址,避免我们平台重复回传,选择只用于同步数据
            </Text>
          </Form.Item>
          <Form.Item
            label="投放平台"
            name="platform"
            rules={[{ required: true, message: "请选择投放平台" }]}
            tooltip="用于区分统计抖小，微小平台的数据"
          >
            <Radio.Group
              options={[
                { label: "微信小程序", value: 1 },
                { label: "抖音小程序", value: 2 },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="优化目标"
            name="external_action"
            rules={[{ required: true, message: "请选择优化目标" }]}
            tooltip="用于区分统计IPA, IAA数据"
          >
            <Radio.Group
              options={[
                { label: "付费", value: "AD_CONVERT_TYPE_PAY" },
                {
                  label: "关键行为",
                  value: "AD_CONVERT_TYPE_GAME_ADDICTION",
                },
              ]}
              // disabled={props?.operate === "update"}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* 卡回传设置弹窗 */}
      <Modal
        title={
          <Card
            title="卡回传设置"
            style={{ border: "none" }}
            styles={{ body: { display: "none" } }}
          />
        }
        open={backShow}
        onCancel={onSetClose}
        onOk={backFormSubmit}
        width={800}
        styles={{ body: { paddingTop: 10 } }}
        maskClosable={false}
        confirmLoading={props.confirmLoading}
      >
        <Form
          layout="vertical"
          form={backForm}
          initialValues={{
            is_clue: 0,
            diy_type: 1,
            sbfs: 1,
            is_first_pay: 0,
          }}
        >
          <Form.Item hidden name="id"></Form.Item>
          <Form.Item hidden name="promotion_id"></Form.Item>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={
                  <Space>
                    线索通开关
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      线索通默认关闭，API的均是开启的,该选项只针对头条
                    </Text>
                  </Space>
                }
                name="is_clue"
              >
                <Radio.Group
                  options={[
                    { label: "关闭", value: 0 },
                    { label: "开启", value: 1 },
                  ]}
                  optionType="button"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="上传方式" name="sbfs">
                <Radio.Group
                  options={[
                    { label: "上报用户所有充值行为", value: 0 },
                    {
                      label: "上报用户在关注当天自然日的所有充值行为",
                      value: 1,
                    },
                    {
                      label: "上报用户在关注后24小时内的所有充值行为",
                      value: 2,
                    },
                    { label: "自定义", value: 3 },
                  ]}
                  onChange={onTypeChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {type === 3 ? (
            <Form.Item label colon={false} name="diy_type">
              <Radio.Group
                options={[{ label: "自然日内", value: 1 }]}
                optionType="button"
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="min_money"
                label={
                  <Space>
                    回传金额下限
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      (小于该金额的不回传)
                    </Text>
                  </Space>
                }
              >
                <Input type="number" min={1} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="max_money"
                label={
                  <Space>
                    回传金额上限
                    <Text
                      type="secondary"
                      style={{ fontSize: 12, width: 130 }}
                      ellipsis={{
                        tooltip: "大于该金额的不回传, 不填或者填0依旧回传",
                      }}
                    >
                      (大于该金额的不回传, 不填或者填0依旧回传)
                    </Text>
                  </Space>
                }
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
          </Row>
          {type === 3 || type === 2 ? (
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="num"
                  label={
                    <Space>
                      每几条
                      <Text type="secondary" style={{ fontSize: 12 }}>
                        (不填默认为1)
                      </Text>
                    </Space>
                  }
                >
                  <Input type="number" min={1} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="back_num"
                  label={
                    <Space>
                      回传几条
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, width: 150 }}
                        ellipsis={{
                          tooltip:
                            "默认为1, 如每几条填5, 这里填4就是每5条回传4条, 相当于80%回传",
                        }}
                      >
                        (默认为1, 如每几条填5, 这里填4就是每5条回传4条,
                        相当于80%回传)
                      </Text>
                    </Space>
                  }
                >
                  <Input type="number" min={1} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="是否首充" colon={false} name="is_first_pay">
                  <Select
                    options={[
                      { label: "所有充值", value: 0 },
                      { label: "首充", value: 1 },
                    ]}
                    placeholder="是否首充"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Form>
      </Modal>
    </>
  );
}
