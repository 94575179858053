import { Button, Space } from "antd";
import { Link } from "react-router-dom";

const columns = ({ isAgent, showEstimateDetail }) => [
  {
    title: "优化师",
    width: 80,
    align: "center",
    dataIndex: "adminName",
    key: "adminName",
    render: (text,record)=> (<Link to={`/admin/statistics/theater/push_plt/new_ecpm_list`} onClick={() => {localStorage.setItem('system_from_name_ID', record.adminId);}}>{text}</Link>)
    // fixed: 'left',
  },
  {
    title: "日期",
    width: 80,
    align: "center",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "消耗",
    width: 80,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
    render: (value) => (
      value || 0
    )
  },
  {
    title: "ECPM当日收益",
    width: 110,
    align: "center",
    dataIndex: "today_ad_cash",
    key: "today_ad_cash",
    sorter: {},
    render: (_, record) => (
      record?.todayAdCash || 0
    )
  },
  {
    title: "ECPM总收益",
    width: 100,
    align: "center",
    dataIndex: "total_ad_cash",
    key: "total_ad_cash",
    sorter: {},
    render: (_, record) => (
      record?.totalAdCash || 0
    )
  },
  {
    title: "新增回收率",
    width: 90,
    align: "center",
    dataIndex: "new_recovery_rate",
    key: "new_recovery_rate",
    sorter: {},
    render: (_,record) => (
      record?.newRecoveryRate === 0 ? '0.00%' : record?.newRecoveryRate + '%'
    )
  },
  {
    title: "总回收率",
    width: 80,
    align: "center",
    dataIndex: "total_recovery_rate",
    key: "total_recovery_rate",
    sorter: {},
    render: (_,record) => (
      record?.totalRecoveryRate === 0 ? '0.00%' : record?.totalRecoveryRate + '%'
    )
  },
  {
    title: "当天新建广告数",
    width: 80,
    align: "center",
    dataIndex: "ad_Count",
    key: "ad_Count",
    sorter: {},
    render: (_, record) => (record.adCount || 0)
  },
  {
    title: "当天新建广告数(有消耗)",
    width: 110,
    align: "center",
    dataIndex: "ad_New_Active_Count",
    key: "ad_New_Active_Count",
    sorter: {},
    render: (_, record) => (record.adNewActiveCount || 0)
  },
  {
    title: "在消耗广告数",
    width: 80,
    align: "center",
    dataIndex: "active_Ad_Count",
    key: "active_Ad_Count",
    sorter: {},
    render: (_, record) => (record.activeAdCount || 0)
  },
  {
    title: "当天新建账户数(含老剧)",
    width: 110,
    align: "center",
    dataIndex: "account_Count",
    key: "account_Count",
    sorter: {},
    render: (_, record) => (record.accountCount || 0)
  },
  {
    title: "在消耗账户数",
    width: 80,
    align: "center",
    dataIndex: "active_Account_Count",
    key: "active_Account_Count",
    sorter: {},
    render: (_, record) => (record.activeAccountCount || 0)
  },
  {
    title: "新建短剧数",
    width: 60,
    align: "center",
    dataIndex: "playletCount",
    key: "playletCount",
  },
  {
    title: "操作",
    width: 100,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => (
      <Space size="small">
        <Button type="primary" ghost>
          <Link
            to={
              isAgent
                ? "/admin/statistics/theater/push_plt/toutiao_agtplt"
                : "/admin/statistics/theater/push_plt/toutiao_plt"
            }
            state={{ ...record, tab: "tab1" }}
          >
            头条数据
          </Link>
        </Button>
      </Space>
    ),
  },
];

export default columns;
