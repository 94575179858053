import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import columns from "./columns";
import agentColumns from "./agentColumns";
import {
  currentProfitTrend,
  filterPitchers,
  playletProfitTrend,
  profitTrendDetail,
} from "../../../../api/statistics";
import EditableCell from "../../../../components/Editable/EditableCell";
import { payQuPlayletUpdate } from "../../../../api/promotion";
import { numDiv, numMulti } from "../../../../util/calculate";
import Detail from "./Detail";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pitchers, setPitchers] = useState([]);
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [detailModalLoading, setDetailModalLoading] = useState(false);
  const [tabOptions, setTabOptions] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("");
  const [detailList, setDetailList] = useState([]);
  const [recordItem, setRecordItem] = useState({});
  const [profitLoading, setProfitLoading] = useState(false);
  const [profitBtnShow, setProfitBtnShow] = useState(true);
  const [isAgent, setIsAgent] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getPitchers();
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await playletProfitTrend(params);
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        item.key = item.id;
        return item;
      });
      setIsAgent(data?.is_agent);
      setList(newLists);
      setLoading(false);
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitchers(newList);
      if (!data?.is_super) {
        form.setFieldsValue({ admin_id: data?.current_admin_id });
      }
    }
  };

  // 筛选表单提交
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((value) => {
        return value.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    getList(values);
    setProfitBtnShow(true);
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = async (row) => {
    if (!parseFloat(row.claim)) {
      return false;
    }
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    const res = await payQuPlayletUpdate({
      id: row.payqu_id,
      claim: parseFloat(row.claim),
    });
    if (res?.code === 200) {
      setList(newData);
    }
  };

  const getColumns = (profitBtnShow, showProfit, profitLoading, showDetail) => {
    let newColumns;
    if (isAgent) {
      newColumns = agentColumns(
        profitBtnShow,
        showProfit,
        profitLoading,
        showDetail
      );
    } else {
      newColumns = columns(
        profitBtnShow,
        showProfit,
        profitLoading,
        showDetail
      );
    }
    return newColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: "claim",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  };

  // 获取详情消耗与回收数据
  const getProfitDetail = async (params = {}) => {
    const res = await profitTrendDetail(params);
    if (res?.code === 200) {
      const { data } = res;
      let tabList = data?.playlet;
      let newTabList = tabList.map((item) => {
        return { tab: item.aliasname, key: item.app_type };
      });
      params?.app_type
        ? setActiveTabKey(params?.app_type)
        : setActiveTabKey(newTabList[0]?.key);
      setTabOptions(newTabList);
      setDetailList(data?.lists);
    }
  };

  // 当天获取盈亏值
  const showProfit = async (record) => {
    setProfitLoading(true);
    const res = await currentProfitTrend(record);
    if (res.code === 200) {
      const { data } = res;
      let profit = data?.profit;
      let newLists = list.map((item) => {
        if (record?.day === item?.day) {
          item.profit = profit;
        }
        return item;
      });
      setProfitBtnShow(false);
      setList(newLists);
      setProfitLoading(false);
    }
  };

  // 打开详情弹窗
  const showDetail = async (record) => {
    setDetailModalLoading(true);
    setRecordItem(record);
    let adminId = form.getFieldValue("admin_id");
    setDetailModalShow(true);
    let params = { admin_id: adminId, ...record };
    getProfitDetail(params);
    setDetailModalLoading(false);
  };

  // 盈亏详情tab切换
  const onTabChange = (key) => {
    setActiveTabKey(key);
    let adminId = form.getFieldValue("admin_id");
    setDetailModalShow(true);
    let params = { admin_id: adminId, ...recordItem, app_type: key };
    getProfitDetail(params);
  };

  // 关闭详情弹窗
  const detailModalClose = () => {
    setDetailModalShow(false);
  };

  return (
    <>
      <Card
        title={
          <Form layout="inline" onFinish={onSubmit} form={form}>
            <Form.Item label="选择投手" name="admin_id">
              <Select
                allowClear
                placeholder="请选择"
                options={pitchers}
                style={{ width: 150 }}
              />
            </Form.Item>

            <Form.Item label="日期选择" name="range_date">
              <RangePicker />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
      >
        <h4
          style={{
            wordWrap: "break-word",
            width: "35%",
            float: "right",
            border: "1px solid #F0F0F0",
            marginBottom: 10,
            padding: 5,
            borderRadius: 5
          }}
        >
          {
            "赠款消耗说明：因为赠款涉及共享赠款与赔付赠款，而且只有赠款消耗完后才算，所以这里只作展示，不参与计算盈亏！"
          }
        </h4>
        <Table
          sticky
          dataSource={list}
          columns={getColumns({
            profitBtnShow,
            showProfit,
            profitLoading,
            showDetail,
          })}
          loading={loading}
          components={components}
          bordered={true}
          pagination={false}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalNewUser = 0;
            let totalCost = 0;
            let totalMoney = 0;
            let totalNoBidCost = 0;
            let totalNoBidMoney = 0;
            let totalNewNoBidCost = 0;
            let totalNewNoBidMoney = 0;
            let totalClaim = 0;
            let totalProfit = 0;
            let totalGrantCost = 0;
            pageData.forEach(
              ({
                new_user,
                total_cost,
                total_money,
                noBid_cost,
                noBid_money,
                new_noBid_cost,
                new_noBid_money,
                claim,
                grant_cost,
                profit,
              }) => {
                totalNewUser += parseInt(new_user);
                totalCost += parseFloat(total_cost);
                totalMoney += parseFloat(total_money);
                totalNoBidCost += parseFloat(noBid_cost);
                totalNoBidMoney += parseFloat(noBid_money);
                totalNewNoBidCost += parseFloat(new_noBid_cost);
                totalNewNoBidMoney += parseFloat(new_noBid_money);
                totalClaim += parseFloat(claim);
                totalGrantCost += parseFloat(grant_cost);
                totalProfit += parseFloat(profit);
              }
            );
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={1} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <Text style={{ fontSize: 12 }}>{totalNewUser}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {totalMoney.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {totalCost.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {isAgent
                          ? totalNoBidMoney.toFixed(2)
                          : totalNewNoBidMoney.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {isAgent
                          ? totalNoBidCost.toFixed(2)
                          : totalNewNoBidCost.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {totalGrantCost.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {totalClaim.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {parseFloat(totalCost) && parseFloat(totalMoney)
                          ? numMulti(
                              numDiv(totalMoney * 1, totalCost * 1),
                              100
                            ).toFixed(2) + "%"
                          : 0}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text style={{ fontSize: 12 }}>
                        {totalProfit.toFixed(2)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>

      {/* 盈亏详情 */}
      <Detail
        show={detailModalShow}
        onclose={detailModalClose}
        loading={detailModalLoading}
        tabList={tabOptions}
        list={detailList}
        tabChange={onTabChange}
        activeTabKey={activeTabKey}
      />
    </>
  );
}
