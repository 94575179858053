import { Card, DatePicker, Divider, Modal } from "antd";
import React, { useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";

const { RangePicker } = DatePicker;

export default function Index(props) {
  const [conditions, setConditions] = useState([])
  const { show, onClose, list, total, loading, page, perPage, onSearch } = props;

   // 分页
   const pagesize = (page, size) => {
    let params = {page, pageSize: size, ...conditions}
    props.pagesize(params, 'compensate')
  }

  // 日期范围筛选查询
  const dateChange = values => {
    let rangeDate
    if (values) {
      rangeDate = values.map((date) => {
        return date.format('YYYY-MM-DD')
      })
    }
    setConditions({range_date: rangeDate})
    onSearch({range_date: rangeDate}, 'compensate')
  }

  return (
    <Modal
      title="读者补偿明细"
      open={show}
      onCancel={onClose}
      width={700}
      footer={null}
      maskClosable={false}
      style={{height: 650, overflowY: 'auto'}}
      destroyOnClose={true}
    >
      <Divider />
      <Card title={<RangePicker onChange={dateChange} />}>
        <Table
          dataSource={list}
          columns={columns()}
          page={page}
          total={total}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
        />
      </Card>
    </Modal>
  );
}
