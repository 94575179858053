import React from 'react'
import RecycleComponent from '../Public/Recycle/index'
import { useState } from 'react'
import { useEffect } from 'react'
import { playletPayQu } from '../../../api/playlet'

export default function Recycle() {
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getList()
  }, [])

  const appType = 2004

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true)
    const res = await playletPayQu({...params, app_type: appType})
    const {data} = res
    if (res?.code === 200) {
      setList(data?.lists)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 日期条件筛选
  const onSearch = params => {
    getList(params)
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    getList({page, pageSize: size})
  }

  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage:pageSize,
    onSearch
  }

  return (
    <div className='yingtan_recycle'>
        <RecycleComponent {...propsData} />
    </div>
  )
}
