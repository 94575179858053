import React from "react";
import { Line } from "@ant-design/charts";

export default function ChartLine(props) {
  const { data, xField, yField, seriesField, xAxis, meta } = props;
  const config = {
    data,
    xField,
    yField,
    seriesField,
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    xAxis,
    meta
  };

  return <Line {...config} />;
}
