import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Tag,
  Typography,
  Upload,
  message,
  Spin,
} from "antd";
import React, { createRef, useEffect } from "react";
import {
  PlayCircleOutlined,
  PictureOutlined,
  ShoppingOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import UploadVideo from "./uploadVideo";
import { useState } from "react";
import {
  deleteProductTemplate,
  doSaveProductTemplate,
  getAdvertiseAweme,
  getChangeExternalUrl,
  getCopyFilterTitles,
  getFilterTouShou,
  getImageList,
  getMaterialLabels,
  getMaterialMan,
  getProductTemplate,
  getProductTemplateRow,
  getRecentlyMaterialNums,
  getTitleList,
  getVideoList,
  projectAdvertiseAdd,
} from "../../../../api/advertise";
import {
  getMaterialOptions,
  materialTitleCategory,
  materialTitleSingleUpdate,
} from "../../../../api";
import UploadImg from "./uploadImg";
import SelectTitle from "./selectTitle";
import dayjs from "dayjs";
import { BASE_URL } from "../../../../config";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/TableNoSelection";
import { getStringLength } from "../../../../util";

const { confirm } = Modal;
const { Meta } = Card;

// 添加广告表单
export default function AddPlan(props) {
  const [videoShow, setVideoShow] = useState(false);
  const [imageShow, setImageShow] = useState(false);
  const [titleShow, setTitlsShow] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [selectVideoList, setSelectVideoList] = useState([]);
  const [selectImageList, setSelecImageList] = useState([]);
  const [selectTitleList, setSelectTitleList] = useState([]);
  const [selectCopyTitleList, setSelectCopyTitleList] = useState([]);
  const [videoTotal, setVideoTotal] = useState(0);
  const [imageTotal, setImageTotal] = useState(0);
  const [titleTotal, setTitleTotal] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [pointerList, setPointerList] = useState([]);
  const [tagContent, setTagContent] = useState("");
  const [actionContent, setActionContent] = useState("");
  const [actionList, setActionList] = useState([]);
  const [externalContent, setExternalContent] = useState("");
  const [externalList, setExternalList] = useState([]);
  const [productImg, setProductImg] = useState([]);
  const [videoMode, setVideoMode] = useState(1);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [imageConditions, setImageConditions] = useState([]);
  const [videoConditions, setVideoConditions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [materailLabels, setMaterialLabels] = useState([]);
  const [sucaiOptions, setSucaiOptions] = useState([]);
  const [filterTouShouOptions, setFilterTouShouOptions] = useState([]);
  const [tempShow, setTempShow] = useState(false);
  const [deleteTempShow, setDeleteTempShow] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [tempOptions, setTempOptions] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [anchorSelectType, setAnchorSelectType] = useState("OFF");
  const [identity, setIdentity] = useState("");
  const [videoPage, setVideoPage] = useState(1);
  const [cpaBidType, setCpaBidType] = useState(1);
  const [capBidStatus, setCpaBidStatus] = useState("");
  const [bidMinStatus, setBidMinStatus] = useState("");
  const [bidMaxStatus, setBidMaxStatus] = useState("");
  const [externalChecked, setExternalChecked] = useState(0);
  const [awemeList, setAwemeList] = useState([]);
  const [changeTitle, setChangeTitle] = useState("");
  const [videoSortValue, setVideoSortValue] = useState(1);
  const [activeTypeTabKey, setActiveTypeTabKey] = useState(1);
  const [titleCategory, setTitleCategory] = useState(0);
  const [listLoading, setListLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);

  const { projectData, returnPrevious, projectId, landingType, bidType } =
    props;
  const [form] = Form.useForm();
  const [tempForm] = Form.useForm();
  const [videoForm] = Form.useForm();
  const [imageForm] = Form.useForm();
  const navigate = useNavigate();

  const ref = createRef();

  useEffect(() => {
    // 返回到页面顶部
    ref.current.scrollIntoView();
    getAwemeList();
    getTemplate();
    getMaterialNums();
  }, []);

  // 返回上一步操作（从广告返回到项目）
  const returnPre = () => {
    returnPrevious();
  };

  // 获取账户抖音号
  const getAwemeList = async () => {
    let changeId;
    if (Object.keys(projectData).length > 0) {
      changeId = projectData?.change_id;
    } else {
      changeId = props?.changeId;
    }
    const res = await getAdvertiseAweme({ change_id: changeId });
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let changeName = data?.change_name;
      let newList = list?.map((item) => {
        return {
          label: item?.aweme_name + " (" + item?.aweme_id + ")",
          value: item?.aweme_id,
        };
      });
      setChangeTitle(changeName);
      form.setFieldsValue({ aweme_id: newList[0]?.value });
      setAwemeList(newList);
    }
  };

  // 从创建广告表单页返回到创建项目表单页
  const returnClear = () => {
    confirm({
      title: "确认返回创建项目表单页？",
      content: "返回项目表单项,之前填的记录会丢失",
      onOk: () => {
        returnPrevious();
      },
    });
  };

  // 根据已选项目获取每个广告使用的图片数，视频数，标题数
  const getMaterialNums = async () => {
    if (projectId) {
      const res = await getRecentlyMaterialNums({ project_id: projectId });
      if (res?.code === 200) {
        const { data } = res;
        form.setFieldsValue({
          photo_num: data?.photo_num,
          video_num: data?.video_num,
          title_num: data?.title_num,
        });
      }
    }
  };

  // 获取筛选的素材手
  const getSucai = async () => {
    const res = await getMaterialMan();
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setSucaiOptions(newLists);
    }
  };

  // 获取列表的投手筛选数据
  const getFilterPitcher = async () => {
    const res = await getFilterTouShou();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterTouShouOptions(newList);
    }
  };

  // 添加视频弹窗
  const addVideo = async () => {
    setVideoShow(true);
    getVideos({ type: videoMode });
    getSucai();
    getLabels();
    getFilterPitcher();
  };

  // 关闭的视频弹窗
  const videoClose = () => {
    setVideoMode(1);
    setVideoShow(false);
  };

  // 获取视频列表
  const getVideos = async (params = {}) => {
    setVideoLoading(true);
    const res = await getVideoList({ ...params });
    const { data } = res;
    if (res?.code === 200) {
      const materialOptions = await getMaterialOptions();
      const categoryOptions = materialOptions?.data.map((value, key) => {
        return { label: value, value: key };
      });
      setMaterialOptions(categoryOptions);
      const lists = data?.lists;
      const newLists = lists.map((item) => {
        item.key = item.id;
        return item;
      });
      setVideoLoading(false);
      setVideoList(newLists);
      setVideoTotal(data?.total);
    }
  };

  // 获取图片列表
  const getImages = async (params = {}) => {
    setImageLoading(true);
    const res = await getImageList({ ...params });
    const { data } = res;
    if (res.code === 200) {
      const lists = data?.lists;
      const newLists = lists.map((item) => {
        item.key = item.id;
        return item;
      });
      setImageLoading(false);
      setImageList(newLists);
      setImageTotal(data?.total);
    }
  };

  // 获取素材标签
  const getLabels = async (params = {}) => {
    const res = await getMaterialLabels(params);
    const { data } = res;
    if (res?.code === 200) {
      let labels = data?.labels;
      let newLabels = labels.map((item) => {
        return { label: item, value: item };
      });
      setMaterialLabels(newLabels);
    }
  };

  // 添加图片弹窗
  const addImage = () => {
    setImageShow(true);
    getImages();
    getSucai();
    getLabels();
  };

  // 关闭图片弹窗
  const imageClose = () => {
    setImageShow(false);
  };

  // 确定选中视频
  const onSureVideo = (values) => {
    setSelectVideoList(values);
  };

  // 清空选中视频
  const onClearVideos = () => {
    setSelectVideoList([]);
  };

  // 选中的视频是否随机分配
  const videoSortChange = (e) => {
    let value = e.target.value;
    setVideoSortValue(value);
  };

  // 一键清空已选中的所有素材
  const selectVideosClear = () => {
    setSelectVideoList([])
  }

  // 视频类型筛选
  const changeType = (type) => {
    setVideoMode(type);
    getVideos({ type });
  };

  // 确定选中图片
  const onSureImage = (values) => {
    setSelecImageList(values);
  };

  const onClearImages = () => {
    setSelecImageList([]);
  };

  // 标题分类切换
  const categoryChange = (value) => {
    if (value) {
      setTitleCategory(value);
      getCopyTitleList({ category: value });
    }
  };

  // 手动|自动选择标题tab切换
  const onTypeTabChange = (value) => {
    setActiveTypeTabKey(value);
    if (value === 2) {
      getTitleCategory();
    }
  };

  // 关闭（取消）已选资源
  const resourceClose = (type, id) => {
    if (type === "video") {
      const newList = selectVideoList.filter((item) => {
        item.key = item.id;
        return item.id !== id;
      });
      setSelectVideoList(newList);
    } else {
      const newList = selectImageList.filter((item) => {
        item.key = item.id;
        return item.id !== id;
      });
      setSelecImageList(newList);
    }
  };

  // 标题分类
  const getTitleCategory = async () => {
    const res = await materialTitleCategory();
    if (res?.code === 200) {
      const { data } = res;
      const categoryOptions = data?.lists.map((item) => {
        return { label: item.name, value: parseInt(item.id) + 11 };
      });
      setMaterialOptions(categoryOptions);
    }
  };

  // 获取标题列表数据
  const getTitleData = async (params = {}) => {
    const res = await getTitleList({ ...params });
    const { data } = res;
    if (res?.code === 200) {
      let roleKeys = data?.roleKeys;
      if (roleKeys.includes("playlet") || roleKeys.includes("explaylet")) {
        getTitleCategory();
      } else {
        const materialOptions = await getMaterialOptions();
        const categoryOptions = materialOptions?.data.map((value, key) => {
          return { label: value, value: key };
        });
        setMaterialOptions(categoryOptions);
      }
      let list = data?.lists;
      let newList = list.map((item) => {
        item.key = item.id;
        return item;
      });
      setTitleList(newList);
      setTitleTotal(data?.total);
    }
  };

  // 打开选择标题弹窗
  const selectTitle = () => {
    getTitleData();
    setTitlsShow(true);
  };

  // 关闭选择标题弹窗
  const titleClose = () => {
    setTitlsShow(false);
  };

  // 素材标题分页
  const pagesize = (page) => {
    getTitleData({ page });
  };

  // 是否启用锚点切换
  const anchorChange = (e) => {
    let value = e.target.value;
    setAnchorSelectType(value);
  };

  // 确定选中标题
  const onSureTitle = (record) => {
    setSelectTitleList(record);
  };

  // 清除空格
  function clearBr(key) {
    key = key.replace(/<\/?.+?>/g, "");
    key = key.replace(/[\r\n]/g, "");
    return key;
  }

  // 素材标题输入时实时展示
  const materialTitleChange = (id, e) => {
    let inputValue = e.target.value;
    inputValue = clearBr(inputValue);
    let newList = selectTitleList.map((item) => {
      if (item.id === id) {
        item.title = inputValue;
      }
      return item;
    });
    setSelectTitleList(newList);
  };

  // 素材标题失去焦点时修改
  const updateTitle = (id, e) => {
    let inputValue = e.target.value;
    inputValue = clearBr(inputValue);
    materialTitleSingleUpdate({ id, title: inputValue });
  };

  // 关闭已选中的标题
  const titleRemove = (value) => {
    const newList = selectTitleList.filter((item) => {
      return item !== value;
    });
    setSelectTitleList(newList);
    let targetKeys = newList.map((item) => {
      return item.id;
    });
    setTargetKeys(targetKeys);
  };

  // 获取自动获取的标题列表
  const getCopyTitleList = async (params) => {
    let accounts =
      Object.keys(projectData).length > 0
        ? projectData?.account_nums
        : props?.advertiserIds?.split(".").length;
    let titleNums = form.getFieldValue("title_num");
    let promotionNums = form.getFieldValue("num");
    if (!promotionNums) {
      message.error("请先设置每个户生成广告数");
      return false;
    }
    if (!titleNums) {
      message.error("请先设置每个广告使用标题数");
      return false;
    }
    let limit = accounts * titleNums * promotionNums;
    params.pageSize = limit;
    const res = await getCopyFilterTitles(params);
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      setListLoading(false);
      setSelectCopyTitleList(lists?.data);
    }
  };

  // 自动素材标题下一批
  const selectTitleNext = () => {
    setListLoading(true);
    let page = nextPage;
    page++;
    setNextPage(page);
    getCopyTitleList({ category: titleCategory, page });
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 上传图片按钮
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      ></div>
    </div>
  );

  // 图片上传前
  const productBeforeUpload = async (file) => {
    // 只有投放短剧时，才限制主品图片的尺寸大小
    handleFilebeforeUpload(file, 108, 108)
      .then(() => {
        console.log("上传成功");
      })
      .catch(() => {
        message.error("产品主图尺寸大小只支持108*108", 2);
        setFileList([]);
        setProductImg([]);
      });
  };

  // 上传图片尺寸限制
  const handleFilebeforeUpload = (file, width, height) => {
    // 参数分别是上传的file，想要限制的宽，想要限制的高
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = (e) => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          if (this.width !== width && this.height !== height) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            reject();
          } else {
            resolve();
          }
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  };

  // 点击图片上传
  const handleUploadChange = ({ fileList: newFileList }) => {
    const productImages = newFileList.map((list) => {
      return list?.response?.data?.attach?.id;
    });
    setProductImg(productImages);
    setFileList(newFileList);
  };

  // 收集产品卖点用户输入
  const pointChange = (e) => {
    setTagContent(e.target.value);
  };

  // 产品卖点
  const pointEvent = (e) => {
    let value = e.target.value;
    value = clearBr(value);
    if (e.keyCode === 13) {
      e.preventDefault();
      if (value.indexOf("；") !== -1) {
        value = value.replace(/；/g, ";");
      }
      let arr = value.split(";");
      // 过滤掉空字符的情况
      arr = arr.filter((item) => item);
      let error;
      error = arr.map((item) => {
        let len = getStringLength(item)
        if (len < 6 || len > 9) {
          return "卖点6-9个字符，请正确输入";
        }
        if (pointerList.includes(item)) {
          return item + "该条产品卖点已添加";
        }
      });
      error = error.filter((item) => {
        return item !== undefined;
      });
      if (error.length > 0) {
        message.error(error[0], 2);
        return false;
      }
      let contents = [...pointerList, ...arr];
      if (contents.length > 10) {
        message.error("最多可以添加10条产品卖点", 2);
        return false;
      }
      setPointerList(contents);
      setTagContent("");
    }
  };

  // 点击删除卖点标签项
  const onTagClose = (tag) => {
    const newList = pointerList.filter((value) => {
      return value !== tag;
    });
    setPointerList(newList);
  };

  // 清空卖点标签
  const tagClear = () => {
    setPointerList([]);
  };

  // 收集行动号召力输入
  const actionChange = (e) => {
    setActionContent(e.target.value);
  };

  // 行动号召力
  const actionEvent = (e) => {
    let value = e.target.value;
    value = clearBr(value);
    if (e.keyCode === 13) {
      e.preventDefault();
      if (value.indexOf("；") !== -1) {
        value = value.replace(/；/g, ";");
      }
      let arr = value.split(";");
      // 过滤掉空字符的情况
      arr = arr.filter((item) => item);

      let error;
      error = arr.map((item) => {
        if (item.length < 2 || item.length > 6) {
          return "行动号召力2-6个字符，请正确输入";
        }
        if (actionList.includes(item)) {
          return item + "该条行动号召力已添加";
        }
      });
      error = error.filter((item) => {
        return item !== undefined;
      });
      if (error.length > 0) {
        message.error(error[0], 2);
        return false;
      }

      let contents = [...actionList, ...arr];
      if (contents.length > 10) {
        message.error("最多可以添加10个行动号召力", 2);
        return false;
      }

      setActionContent("");
      setActionList(contents);
    }
  };

  // 打开添加商品模板弹窗
  const saveTemplate = () => {
    setTempShow(true);
  };

  // 关闭商品模板弹窗
  const closeProductTemp = () => {
    setTempShow(false);
    setProductLoading(false);
    tempForm.resetFields();
  };

  // 获取产品模板信息
  const getTemplate = async (params = {}) => {
    const res = await getProductTemplate(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data.lists;
      setTemplateList(list);
      let newList = list.map((item) => {
        return { label: item.title, value: item.id };
      });
      setTempOptions(newList);
    }
  };

  // 切换产品模板
  const onTemplateChange = async (value) => {
    const res = await getProductTemplateRow({ id: value });
    const { data } = res;
    if (res?.code === 200) {
      let template = data?.data;
      let product_image = template?.product_image;
      setProductImg(product_image?.split(","));
      let attach = template?.attach;
      let imgs = attach?.map((value) => {
        return {
          uid: -1,
          url: value,
          type: "image/jpeg",
          status: "done",
          name: "image.png",
        };
      });
      setFileList(imgs);
      let sellPoints = template?.selling_points;
      setPointerList(sellPoints?.split(","));
      let actions = template?.call_to_action_buttons;
      setActionList(actions?.split(","));
      form.setFieldsValue({
        product_title: template?.product_title,
      });
    }
  };

  // 处理保存商品模板
  const onSaveProductTemp = async (values) => {
    let params = form.getFieldsValue();
    let productTitle = params?.product_title;
    if (!productTitle) {
      message.error("产品标题不能为空", 2);
      return false;
    }

    let productImgs = [];
    const { product_image } = params;
    if (product_image) {
      const { fileList } = product_image;
      productImgs = fileList.map((item) => {
        const {
          response: { data },
        } = item;
        return data?.attach?.id;
      });

      // 过滤掉重复的值
      productImgs = Array.from(new Set(productImgs));
    } else {
      productImgs = productImg;
    }
    if (productImgs.length <= 0) {
      message.error("产品图片不能为空", 2);
      return false;
    }
    setProductLoading(true);
    params = {
      ...values,
      selling_points: pointerList,
      call_to_action_buttons: actionList,
    };
    params.product_title = productTitle;
    params.product_image = productImgs;
    const res = await doSaveProductTemplate(params);
    if (res?.code === 200) {
      message.success("产品模板保存成功", 2);
      setTimeout(() => {
        getTemplate();
        closeProductTemp();
      }, 1000);
    } else {
      setProductLoading(false);
    }
  };

  // 删除产品模板弹窗
  const deleteProductTemp = () => {
    setDeleteTempShow(true);
  };

  // 关闭删除产品模板弹窗
  const closeDeleteUserTemp = () => {
    setDeleteTempShow(false);
  };

  // 处理删除产品模板
  const onHandleDeleteTemp = (record) => {
    confirm({
      title: "确定删除该产品模板？",
      onOk: async () => {
        const res = await deleteProductTemplate({ id: record?.id });
        if (res?.code === 200) {
          message.success(record?.title + "  产品模板已删除", 2);
          getTemplate();
        }
      },
    });
  };

  // 模板日期筛选
  const templateDateChange = (value) => {
    let date;
    if (value) {
      date = value.format("YYYY-MM-DD");
      getTemplate({ create_time: date });
    } else {
      getTemplate();
    }
  };

  // 删除定向模板表格列
  const columns = [
    {
      title: "模板名称",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "添加时间",
      dataIndex: "create_time",
      key: "create_time",
      render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "操作",
      dataIndex: "opration",
      key: "opration",
      render: (_, record) => (
        <Button
          type="primary"
          danger
          onClick={() => onHandleDeleteTemp(record)}
        >
          删除
        </Button>
      ),
    },
  ];

  // 点击删除行动号召力标签
  const onActionClose = (value) => {
    const newList = actionList.filter((item) => {
      return item !== value;
    });
    setActionList(newList);
  };

  // 清空行动号召力
  const actionClear = () => {
    setActionList([]);
  };

  // 落地页
  const externalChange = (e) => {
    setExternalContent(e.target.value);
  };

  // 落地页回车事件
  const externalEvent = (e) => {
    let value = e.target.value;
    value = value.replace(/[\r\n]/g, "@@&&");
    if (e.keyCode === 13) {
      e.preventDefault();
      let arr = value.split("@@&&");
      // 过滤掉空字符的情况
      arr = arr.filter((item) => item);
      let newItems = arr.map((item) => {
        if (
          item.indexOf("https://www.chengzijianzhan.com/tetris/page/") !== -1
        ) {
          return item.replace(".com", ".cc");
        }
        return item;
      });
      // 落地页以 https://www.chengzijianzhan.cc/tetris/page/ 开头,
      let filtered = newItems.filter((item) => {
        return (
          item.indexOf("https://www.chengzijianzhan.cc/tetris/page/") === -1
        );
      });
      if (filtered.length > 0) {
        message.error(
          "落地页 " +
            filtered[0] +
            " 没有以https://www.chengzijianzhan.cc/tetris/page/开头, 检查后重新输入"
        );
        return false;
      }

      let newArr = newItems.map((val) => {
        // 过滤掉空格
        return val.replace(/\s*/g, "");
      });
      setExternalContent("");
      if (externalList) {
        setExternalList([...externalList, ...newArr]);
      } else {
        setExternalList(newArr);
      }
    }
  };

  // 点击删除落地页
  const externalRemove = (value) => {
    const newList = externalList.filter((item) => {
      return item !== value;
    });
    setExternalList(newList);
  };

  // 小程序切换共享落地页
  const onExternalChange = async (e) => {
    let value = e.target.value;
    let changeId = 0;
    if (value === 1) {
      if (props?.changeId) {
        changeId = props?.changeId;
      } else {
        changeId = projectData?.change_id;
      }
      const res = await getChangeExternalUrl({ change_id: changeId });
      if (res?.code === 200) {
        const { data } = res;
        setExternalList(data?.external_url || []);
      }
    }
    setExternalChecked(value);
  };

  // 清空落地页
  const externalClear = () => {
    setExternalList([]);
  };

  // 出价类型切换
  const onCpaChange = (e) => {
    let value = e.target.value;
    if (value === 1) {
      form.resetFields(["bid_min", "bid_max"]);
      setCpaBidStatus("");
    } else {
      form.resetFields(["cpa_bid"]);
      setBidMinStatus("");
      setBidMaxStatus("");
    }
    setCpaBidType(value);
  };

  // 固定类型出价
  const onCpaBidChange = (value) => {
    if (parseInt(value) >= 150 && parseInt(value) < 200) {
      setCpaBidStatus("error");
    } else if (parseInt(value) >= 200) {
      setCpaBidStatus("error");
      message.error("固定出价类型-出价过高");
      return false;
    } else {
      setCpaBidStatus("");
    }
  };

  // 随机出价(最小区间)
  const onSectionMinChange = (value) => {
    if (parseInt(value) >= 150 && parseInt(value) < 200) {
      setBidMinStatus("error");
    } else if (parseInt(value) >= 200) {
      setBidMinStatus("error");
      message.error("随机出价类型-最小区间出价过高");
      return false;
    } else {
      setBidMinStatus("");
    }
  };

  // 随机出价(最大区间)
  const onSectionMaxChange = (value) => {
    if (parseInt(value) >= 150 && parseInt(value) < 200) {
      setBidMaxStatus("error");
    } else if (parseInt(value) >= 200) {
      setBidMaxStatus("error");
      message.error("随机出价类型-最大区间出价过高");
      return false;
    } else {
      setBidMaxStatus("");
    }
  };

  // 全部表单提交
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        let advertiseFormData = form.getFieldValue();
        if (
          !advertiseFormData?.video_num &&
          !advertiseFormData?.title_num
        ) {
          message.error("请设置广告使用的视频数,图片数或标题数");
          return false;
        }
        if (
          landingType !== undefined &&
          projectData?.landing_type === undefined
        ) {
          projectData.landing_type = landingType;
          projectData.external_url_list = props.externalUrlList;
        }

        if (selectVideoList.length === 0 && selectImageList.length === 0) {
          message.error("方案素材不能为空", 2);
          setLoading(false);
          return false;
        }

        if (
          (activeTypeTabKey === 1 && selectTitleList.length === 0) ||
          (activeTypeTabKey === 2 && selectCopyTitleList.length === 0)
        ) {
          message.error("方案素材标题不能为空", 2);
          setLoading(false);
          return false;
        }
        let saveData = {
          ...projectData,
          ...advertiseFormData,
          project_id: projectId || 0,
          product_image: productImg,
          selling_points: pointerList,
          call_to_action_buttons: actionList,
          video_type: videoMode,
          image_type: 3,
          creative_material_mode: 1,
          videoList: selectVideoList,
          imageList: selectImageList,
          titleList:
            activeTypeTabKey === 1 ? selectTitleList : selectCopyTitleList,
          external_checked: externalChecked,
          external_url: externalList,
          video_sort: videoSortValue,
        };
        let preTime = saveData["pre_time"]?.format("YYYY-MM-DD HH:mm") || 0;
        saveData.preTime = preTime;
        if (saveData?.landing_type === "MICRO_GAME" && externalChecked === 0) {
          saveData.external_url = [];
        } else if (
          saveData?.landing_type === "MICRO_GAME" &&
          externalChecked === 1 &&
          externalList.length === 0
        ) {
          message.error("选择了共享落地页，还未输入");
          setLoading(false);
          return false;
        }
        if (
          saveData?.micro_promotion_type &&
          saveData?.micro_promotion_type === "BYTE_APP" &&
          externalList?.length > 1
        ) {
          message.error("抖小投放方式只能添加一个共享落地页");
          setLoading(false);
          return false;
        }
        if (
          props?.microPromotionType &&
          props?.microPromotionType === "BYTE_APP" &&
          externalList?.length > 1
        ) {
          message.error("抖小投放方式只能添加一个共享落地页");
          setLoading(false);
          return false;
        }

        if (
          ((projectData?.deep_bid_type === "ROI_COEFFICIENT" &&
            projectData?.delivery_mode === "MANUAL") ||
            (props?.deepBidType === "ROI_COEFFICIENT" &&
              props.deliveryMode === "MANUAL")) &&
          saveData?.roi_goal &&
          saveData?.roi_goal > 5
        ) {
          message.error("ROI系数, 大于0, 不超过5");
          setLoading(false);
          return false;
        }
        // console.log(saveData);
        // setLoading(false);
        const res = await projectAdvertiseAdd(saveData);
        if (res?.code === 200) {
          message.success("操作成功, 等待批量上传至头条", 1);
          setTimeout(() => {
            setLoading(false);
            navigate("/admin/batch_plan/advertise_new");
          }, 1000);
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        let errs = e?.errorFields;
        let messages = errs?.map((err) => {
          return err.errors[0];
        });
        message.error(messages[0], 2);
        return;
      });
  };

  // 视频分页
  const videoPagesize = (page, size) => {
    let params = { ...videoConditions, page, pageSize: size };
    setVideoPage(page);
    // setVideoConditions(params);
    getVideos(params);
  };

  // 视频素材条件查询
  const onVideoSearch = (params) => {
    if (params.hasOwnProperty("type")) {
      setVideoMode(params.type);
    }
    setVideoConditions({ ...videoConditions, ...params });
    getVideos({ ...videoConditions, ...params });
    setVideoPage(1);
  };

  // 图片分页
  const imagePagesize = (page, size) => {
    let params = { ...imageConditions, page, pageSize: size };
    setImageConditions(params);
    getImages(params);
  };

  // 图片素材条件查询
  const onImageSearch = (params) => {
    setImageConditions({ ...imageConditions, ...params });
    getImages({ ...imageConditions, ...params });
  };

  // 标题素材条件查询
  const onTitleSearch = (params) => {
    getTitleData({ ...params });
  };

  const setTarget = (targetKeys) => {
    setTargetKeys(targetKeys);
  };

  // 推广身份切换
  const identityChange = (e) => {
    let value = e.target.value;
    setIdentity(value);
  };

  // 是否显示广告预算与出价
  const getBidBugShow = () => {
    let budgetShow = 1; //预算与出价全部显示
    if (Object.keys(projectData).length > 0) {
      if (
        projectData.bid_type === "NO_BID" &&
        projectData.budget_optimize_switch === "ON"
      ) {
        budgetShow = 2; // 预算与出价全部不显示
      } else if (
        projectData.bid_type === "NO_BID" &&
        projectData.budget_optimize_switch === "OFF"
      ) {
        budgetShow = 3; // 只显示预算不显示出价
      }
      // 推广目的为销售线索时，不设预算与出价
      if (projectData.landing_type === "THIRD_EXTERNAL") {
        budgetShow = 2;
      }
      if (projectData.delivery_mode === "PROCEDURAL") {
        budgetShow = 2;
      } else if (
        projectData.delivery_mode === "MANUAL" &&
        projectData.external_action === "AD_CONVERT_TYPE_ACTIVE"
      ) {
        budgetShow = 3;
      }
    } else {
      if (bidType && bidType === "NO_BID" && props.budgetSwitch === "ON") {
        budgetShow = 2;
      } else if (
        bidType &&
        bidType === "NO_BID" &&
        props.budgetSwitch === "OFF"
      ) {
        budgetShow = 3;
      }
      if (props.deliveryMode === "PROCEDURAL") {
        budgetShow = 2;
      } else if (
        props.deliveryMode === "MANUAL" &&
        props.externalAction === "AD_CONVERT_TYPE_ACTIVE"
      ) {
        budgetShow = 3;
      }
    }
    // 推广目的为销售线索时，不设预算与出价
    if (landingType === "THIRD_EXTERNAL") {
      budgetShow = 2;
    }
    return budgetShow;
  };

  return (
    <>
      <Card title="创建广告" ref={ref}>
        <Form
          layout="vertical"
          initialValues={{
            video_num: 1,
            title_num: 1,
            operation: "DISABLE",
            num: 1,
            creative_auto_generate_switch: "OFF",
            anchor_related_type: anchorSelectType,
            cpa_bid_type: cpaBidType,
            external_checked: externalChecked,
            promotion_identity:
              projectData.landing_type === "MICRO_GAME" ||
              landingType === "MICRO_GAME"
                ? "aweme"
                : "account",
          }}
          form={form}
        >
          <div style={{ marginBottom: 10 }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>
              已选转化名称：
            </span>
            <span>{changeTitle}</span>
          </div>
          <Form.Item label="预约执行时间" name="pre_time">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              placeholder="选择时间"
            />
          </Form.Item>
          <Form.Item
            label="广告名称"
            name="name"
            rules={[{ required: true, message: "广告名称不能为空" }]}
          >
            <Input allowClear style={{ width: "30%" }} />
          </Form.Item>
          <Form.Item
            label="广告状态"
            name="operation"
            rules={[{ required: true, message: "广告名称不能为空" }]}
          >
            <Radio.Group>
              <Radio.Button value="ENABLE">开启</Radio.Button>
              <Radio.Button value="DISABLE">关闭</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item label="每个广告使用图片数" name="photo_num">
            <InputNumber
              min={0}
              changeOnWheel={false}
              style={{ width: "30%" }}
            />
          </Form.Item> */}
          <Form.Item label="每个广告使用视频数" name="video_num" rules={[{required: true, message: '广告使用视频数不能为空'}]}>
            <InputNumber
              min={1}
              changeOnWheel={false}
              style={{ width: "30%" }}
            />
          </Form.Item>
          <Form.Item
            label="每个广告使用标题数"
            name="title_num"
            rules={[{ required: true, message: "广告使用标题数不能为空" }]}
          >
            <InputNumber
              min={1}
              changeOnWheel={false}
              style={{ width: "30%" }}
            />
          </Form.Item>
          <Card title="每个户生成广告数" style={{ marginBottom: 10 }}>
            <Form.Item
              label="所选广告主每个户生成几条广告"
              name="num"
              rules={[{ required: true, message: "批量生成广告数不能为空" }]}
            >
              <InputNumber
                placeholder="批量生成广告"
                min={1}
                style={{ width: "30%" }}
                changeOnWheel={false}
              />
            </Form.Item>
          </Card>
          <Card title="推广身份" style={{ marginBottom: 10 }}>
            <Form.Item
              label="推广身份"
              name="promotion_identity"
              rules={[{ required: true, message: "选择推广身份" }]}
            >
              <Radio.Group onChange={identityChange}>
                <Radio.Button value="aweme">抖音号</Radio.Button>
                <Radio.Button value="account">账户信息</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Card>
          <Card
            title={
              <Meta
                title="广告素材"
                description={
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "normal",
                      color: "#121a1a",
                    }}
                  >
                    添加素材
                  </div>
                }
              />
            }
            headStyle={{ padding: 10 }}
            style={{ marginBottom: 10 }}
          >
            <Form.Item name="resource">
              <Card
                title={
                  <>
                    <div style={{ display: "flex", marginBottom: 20 }}>
                      <div style={{ marginRight: 8 }}>
                        <ShoppingOutlined
                          style={{
                            color: "white",
                            backgroundColor: "#1492FF",
                            borderRadius: 8,
                          }}
                        />
                      </div>
                      <div>基础素材</div>
                    </div>
                    <Space size="middle">
                      <Button
                        type="primary"
                        icon={<PlayCircleOutlined />}
                        onClick={addVideo}
                      >
                        添加视频
                      </Button>
                      <Button icon={<PictureOutlined />} onClick={addImage}>
                        添加图片
                      </Button>
                      <Typography.Text style={{ fontWeight: "normal" }}>
                        视频:{selectVideoList?.length}
                      </Typography.Text>
                      <Typography.Text style={{ fontWeight: "normal" }}>
                        图片:{selectImageList?.length}
                      </Typography.Text>
                      {selectVideoList.length > 0 ? (
                        <>
                        <Radio.Group
                          options={[
                            { label: "顺序", value: 1 },
                            { label: "随机", value: 2 },
                          ]}
                          value={videoSortValue}
                          optionType="button"
                          onChange={videoSortChange}
                        />
                        <Button type="link" onClick={selectVideosClear}>一键清空所选素材</Button>
                        </>
                      ) : (
                        ""
                      )}
                    </Space>
                  </>
                }
                styles={{
                  header: { padding: 20 },
                  body: { height: 350, overflowY: "auto" },
                }}
              >
                <div>
                  {(selectVideoList && selectVideoList.length > 0) ||
                  (selectImageList && selectImageList.length > 0) ? (
                    <>
                      {selectVideoList && selectVideoList.length > 0 ? (
                        <>
                          <div style={{ marginBottom: 8 }}>视频</div>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {selectVideoList.map((item) => {
                              return (
                                <div
                                  style={{
                                    width: 100,
                                    marginRight: 20,
                                    overflowX: "hidden",
                                    borderRadius: 6,
                                    position: "relative",
                                    marginBottom: 5,
                                  }}
                                  key={item.id}
                                >
                                  <CloseCircleOutlined
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      zIndex: 1,
                                      cursor: "pointer",
                                      fontSize: 20,
                                      color: "red",
                                    }}
                                    onClick={() =>
                                      resourceClose("video", item.id)
                                    }
                                  />
                                  <video
                                    src={item?.video}
                                    controls
                                    preload="none"
                                    poster={`${item?.video}?x-oss-process=video/snapshot,t_1,f_jpg,w_180,h_320,m_fast,ar_auto`}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {selectImageList && selectImageList.length > 0 ? (
                        <>
                          <div style={{ marginTop: 30, marginBottom: 8 }}>
                            图片
                          </div>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {selectImageList.map((item) => {
                              return (
                                <div
                                  style={{
                                    width: 100,
                                    marginRight: 20,
                                    overflowX: "hidden",
                                    borderRadius: 6,
                                    position: "relative",
                                  }}
                                  key={item.id}
                                >
                                  <CloseCircleOutlined
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      zIndex: 1,
                                      cursor: "pointer",
                                      fontSize: 20,
                                      color: "red",
                                    }}
                                    onClick={() =>
                                      resourceClose("image", item.id)
                                    }
                                  />
                                  <img alt="" src={item?.img} />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <Empty description="暂无素材内容，可通过上方添加" />
                  )}
                </div>
              </Card>
            </Form.Item>
          </Card>
          <Card title="文案素材" style={{ marginBottom: 10 }}>
            <Card
              type="inner"
              size="small"
              tabList={[
                { key: 1, tab: "手动选择" },
                { key: 2, tab: "自动选择" },
              ]}
              tabProps={{ type: "card" }}
              activeTabKey={activeTypeTabKey}
              onTabChange={onTypeTabChange}
            ></Card>
            {activeTypeTabKey === 1 ? (
              <Form.Item label="标题" name="title">
                <div>
                  <Space>
                    <Button onClick={selectTitle}>选择标题</Button>
                    <Typography.Text style={{ fontWeight: "normal" }}>
                      已选择：{selectTitleList?.length}
                    </Typography.Text>
                    <Button type="link" onClick={() => setSelectTitleList([])}>
                      一键清空
                    </Button>
                  </Space>
                  <Form.Item
                    label
                    rules={[
                      { required: true, message: "文案素材标题不能为空" },
                    ]}
                  >
                    {selectTitleList && selectTitleList?.length > 0
                      ? selectTitleList.map((item) => {
                          return (
                            <div
                              style={{ marginBottom: 10, display: "flex" }}
                              key={item.id}
                            >
                              <Input
                                value={item.title}
                                style={{
                                  backgroundColor: "#eee",
                                  marginRight: 5,
                                }}
                                onBlur={(e) => updateTitle(item.id, e)}
                                onChange={(e) =>
                                  materialTitleChange(item.id, e)
                                }
                              />
                              <CloseOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  titleRemove(item);
                                }}
                              />
                            </div>
                          );
                        })
                      : ""}
                  </Form.Item>
                </div>
              </Form.Item>
            ) : (
              <Form.Item
                label="标题分类"
                name="category"
                style={{ marginTop: 0 }}
              >
                <Select
                  allowClear
                  placeholder="选择分类"
                  showSearch
                  filterOption={filterOption}
                  style={{ width: "30%" }}
                  options={materialOptions}
                  onChange={categoryChange}
                />
                <Space size="small">
                  <Typography.Text
                    style={{ fontWeight: "normal", marginLeft: 15 }}
                  >
                    已选择：{selectCopyTitleList?.length}
                  </Typography.Text>
                  <Button type="link" onClick={selectTitleNext}>
                    下一批
                  </Button>
                  <Button
                    type="link"
                    onClick={() => setSelectCopyTitleList([])}
                  >
                    一键清空
                  </Button>
                </Space>
                {listLoading ? (
                  <div className="example">
                    <Spin />
                  </div>
                ) : (
                  <div style={{ marginTop: 10 }}>
                    {selectCopyTitleList?.length
                      ? selectCopyTitleList.map((item) => {
                          return (
                            <div
                              style={{ marginBottom: 10, display: "flex" }}
                              key={item.id}
                            >
                              <Input
                                value={item.title}
                                style={{
                                  backgroundColor: "#eee",
                                  marginRight: 5,
                                }}
                                onBlur={(e) => updateTitle(item.id, e)}
                                onChange={(e) =>
                                  materialTitleChange(item.id, e)
                                }
                              />
                              <CloseOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  titleRemove(item);
                                }}
                              />
                            </div>
                          );
                        })
                      : ""}
                  </div>
                )}
              </Form.Item>
            )}
          </Card>
          {((Object.keys(projectData).length > 0 &&
            projectData.landing_type === "MICRO_GAME") ||
            (landingType && landingType === "MICRO_GAME")) &&
          form.getFieldValue("promotion_identity") === "aweme" ? (
            <>
              <Card title="抖音号" style={{ marginBottom: 10 }}>
                <Form.Item label="选择抖音号" name="aweme_id">
                  <Select
                    placeholder="请选择抖音号"
                    allowClear
                    options={awemeList}
                    style={{ width: 250 }}
                  />
                </Form.Item>
              </Card>

              <Card title="原生锚点" style={{ marginBottom: 10 }}>
                <Form.Item label="原生锚点" name="anchor_related_type">
                  <Radio.Group
                    options={[
                      { label: "不启用", value: "OFF" },
                      { label: "自动生成", value: "AUTO" },
                      { label: "启用", value: "SELECT" },
                    ]}
                    optionType="button"
                    onChange={anchorChange}
                  />
                </Form.Item>
              </Card>
            </>
          ) : (
            ""
          )}
          <Card
            title={
              <Space>
                产品信息
                <Select
                  allowClear
                  placeholder="选择产品模板"
                  style={{ width: 200 }}
                  options={tempOptions}
                  onChange={onTemplateChange}
                />
                <Button type="primary" danger onClick={deleteProductTemp}>
                  删除产品模板
                </Button>
              </Space>
            }
            style={{ marginBottom: 10 }}
          >
            <Form.Item
              label="产品名称"
              name="product_title"
              rules={[{ required: true, message: "产品名称不能为空" }]}
            >
              <Input
                allowClear
                style={{ width: "30%" }}
                placeholder="输入产品名称"
              />
            </Form.Item>
            <Form.Item label="产品主图" name="product_image">
              <Upload
                name="file"
                accept="image/*"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={productBeforeUpload}
                fileList={fileList}
                multiple={true}
                maxCount={10}
                action={`${BASE_URL}/upload/image`}
                onChange={handleUploadChange}
                data={{
                  source: `advertise_goods/${dayjs().format(
                    "YYYY-MM"
                  )}/${dayjs().date()}`,
                }}
                headers={{
                  Authorization:
                    "Bearer " + localStorage.getItem("admin_token"),
                }}
                progress={{
                  strokeColor: {
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  },
                  size: 3,
                  format: (percent) =>
                    percent && `${parseFloat(percent.toFixed(2))}%`,
                }}
              >
                {fileList.length >= 10 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  产品卖点
                  <Typography.Text type="success">
                    (输入后回车添加, 复制多个卖点用分号 ; 分隔)
                  </Typography.Text>
                </Space>
              }
              name="selling_points"
            >
              <Input.TextArea
                onKeyDown={(e) => {
                  pointEvent(e);
                }}
                rows={4}
                value={tagContent}
                onChange={pointChange}
                placeholder="最多10个产品卖点，每个卖点6-9个字"
                allowClear
              />
              {pointerList?.length > 0 ? (
                <Card
                  style={{ marginTop: 10 }}
                  title={<div>已选：{pointerList?.length}/10</div>}
                  extra={
                    <Button type="link" onClick={tagClear}>
                      清空
                    </Button>
                  }
                >
                  {pointerList?.map((item) => {
                    return (
                      <Tag closable key={item} onClose={() => onTagClose(item)}>
                        {item}
                      </Tag>
                    );
                  })}
                </Card>
              ) : (
                ""
              )}
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  行动号召力
                  <Typography.Text type="success">
                    (输入后回车添加, 复制多个号召力用分号 ; 分隔)
                  </Typography.Text>
                </Space>
              }
              name="action"
            >
              <Input.TextArea
                onKeyDown={(e) => {
                  actionEvent(e);
                }}
                rows={4}
                value={actionContent}
                onChange={actionChange}
                placeholder="最多10个行动号召力，每个2-6个字"
                allowClear
              />
              {actionList?.length > 0 ? (
                <Card
                  style={{ marginTop: 10 }}
                  title={<div>已选：{actionList?.length}/10</div>}
                  extra={
                    <Button type="link" onClick={actionClear}>
                      清空
                    </Button>
                  }
                >
                  {actionList?.map((item) => {
                    return (
                      <Tag
                        closable
                        key={item}
                        onClose={() => onActionClose(item)}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                </Card>
              ) : (
                ""
              )}
            </Form.Item>

            <div style={{ textAlign: "right" }}>
              <Button type="primary" onClick={saveTemplate}>
                保存产品模板
              </Button>
            </div>
          </Card>

          {(Object.keys(projectData).length > 0 &&
            projectData.landing_type === "MICRO_GAME") ||
          (landingType && landingType === "MICRO_GAME") ? (
            <Card>
              <Form.Item label="落地页" name="external_checked">
                <Radio.Group
                  options={[
                    { label: "默认", value: 0 },
                    { label: "使用共享落地页", value: 1 },
                  ]}
                  defaultValue={0}
                  optionType="button"
                  onChange={onExternalChange}
                />
                {externalChecked === 1 ? (
                  <>
                    <Typography.Text type="success">
                      (输入后回车添加, 复制多个落地页换行)
                    </Typography.Text>
                    <Form.Item name="external_url" style={{ marginTop: 20 }}>
                      <Input.TextArea
                        onKeyDown={(e) => {
                          externalEvent(e);
                        }}
                        rows={4}
                        value={externalContent}
                        onChange={externalChange}
                        placeholder="每个广告按顺序取一条, 按回车确认"
                        allowClear
                      />
                      {externalList && externalList?.length > 0 ? (
                        <Card
                          title={
                            <div>已添加 {externalList?.length} 个落地页</div>
                          }
                          extra={
                            <Button type="link" onClick={externalClear}>
                              清空
                            </Button>
                          }
                        >
                          {externalList?.map((item, key) => {
                            return (
                              <div
                                style={{ display: "flex", marginBottom: 10 }}
                                key={key}
                              >
                                <Input
                                  readOnly
                                  value={item}
                                  style={{
                                    backgroundColor: "#eee",
                                    marginRight: 5,
                                  }}
                                />
                                <CloseOutlined
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    externalRemove(item);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </Card>
                      ) : (
                        ""
                      )}
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}
              </Form.Item>
            </Card>
          ) : (
            ""
          )}
          {(Object.keys(projectData).length > 0 &&
            projectData.landing_type !== "MICRO_GAME") ||
          (landingType && landingType !== "MICRO_GAME") ? (
            <Card>
              <Form.Item
                label={
                  <Space>
                    落地页
                    <Typography.Text type="success">
                      (输入后回车添加)
                    </Typography.Text>
                  </Space>
                }
                name="external_url"
              >
                <Input
                  onKeyDown={(e) => {
                    externalEvent(e);
                  }}
                  value={externalContent}
                  onChange={externalChange}
                  placeholder="每个广告按顺序取一条"
                />
                {externalList && externalList?.length > 0 ? (
                  <Card
                    title={<div>已添加 {externalList?.length} 个落地页</div>}
                    extra={
                      <Button type="link" onClick={externalClear}>
                        清空
                      </Button>
                    }
                  >
                    {externalList?.map((item, key) => {
                      return (
                        <div
                          style={{ display: "flex", marginBottom: 10 }}
                          key={key}
                        >
                          <Input
                            readOnly
                            value={item}
                            style={{ backgroundColor: "#eee", marginRight: 5 }}
                          />
                          <CloseOutlined
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              externalRemove(item);
                            }}
                          />
                        </div>
                      );
                    })}
                  </Card>
                ) : (
                  ""
                )}
              </Form.Item>
            </Card>
          ) : (
            ""
          )}

          {(Object.keys(projectData).length > 0 &&
            (projectData.landing_type === "LINK" ||
              projectData.landing_type === "QUICK_APP" ||
              projectData.landing_type === "MICRO_GAME")) ||
          (landingType &&
            (landingType === "LINK" ||
              landingType === "QUICK_APP" ||
              landingType === "MICRO_GAME")) ? (
            <Form.Item
              label="来源"
              name="source"
              rules={[{ required: true, message: "请输入来源" }]}
            >
              <Input
                placeholder="请输入来源"
                allowClear
                style={{ width: "30%" }}
              />
            </Form.Item>
          ) : (
            ""
          )}

          {getBidBugShow() === 1 ? (
            <Card title="广告预算与出价" style={{ marginTop: 10 }}>
              <Form.Item
                label="预算"
                name="budget"
                rules={[{ required: true, message: "请输入预算" }]}
                initialValue={9999999}
              >
                <InputNumber
                  min={300}
                  placeholder="不少于300元，不超过9999999.99元"
                  suffix="元"
                  style={{ width: "30%" }}
                  changeOnWheel={false}
                />
              </Form.Item>
              <Form.Item
                label="出价"
                rules={[{ required: true, message: "请输入出价" }]}
              >
                <Form.Item name="cpa_bid_type">
                  <Radio.Group
                    options={[
                      { label: "固定出价", value: 1 },
                      { label: "随机出价", value: 2 },
                    ]}
                    optionType="button"
                    onChange={onCpaChange}
                  />
                </Form.Item>
                <Form.Item name="cpa_bid">
                  {cpaBidType === 1 ? (
                    <InputNumber
                      min={0.1}
                      max={10000}
                      placeholder="不少于0.1元，不超过10000元"
                      suffix="元"
                      onChange={onCpaBidChange}
                      status={capBidStatus}
                      style={{ width: "30%" }}
                      changeOnWheel={false}
                    />
                  ) : (
                    <>
                      <Form.Item
                        name="bid_min"
                        style={{ display: "inline-block" }}
                      >
                        <InputNumber
                          placeholder="区间价最小值"
                          style={{ width: "80%" }}
                          addonAfter="元"
                          onChange={onSectionMinChange}
                          status={bidMinStatus}
                          changeOnWheel={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: "inline-block",
                          paddingTop: 3,
                          marginLeft: -40,
                          marginRight: 3,
                        }}
                      >
                        -
                      </div>
                      <Form.Item
                        name="bid_max"
                        style={{ display: "inline-block" }}
                      >
                        <InputNumber
                          placeholder="区间价最大值"
                          style={{ width: "80%" }}
                          addonAfter="元"
                          onChange={onSectionMaxChange}
                          status={bidMaxStatus}
                          changeOnWheel={false}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form.Item>
              </Form.Item>
              {(projectData?.deep_bid_type === "ROI_COEFFICIENT" &&
                projectData?.delivery_mode === "MANUAL") ||
              (props?.deepBidType === "ROI_COEFFICIENT" &&
                props.deliveryMode === "MANUAL") ? (
                <Form.Item
                  label="ROI系数"
                  name="roi_goal"
                  rules={[
                    {
                      required: true,
                      message: "请输入ROI系数, 大于0, 不超过5",
                    },
                  ]}
                >
                  <Input type="number" min={0} max={5} placeholder="请输入" />
                </Form.Item>
              ) : (
                ""
              )}
            </Card>
          ) : getBidBugShow() === 3 ? (
            <Card title="广告预算与出价">
              <Form.Item
                label="预算"
                name="budget"
                rules={[{ required: true, message: "请输入预算" }]}
                initialValue={100}
              >
                <InputNumber
                  min={100}
                  placeholder="不少于100元，不超过9999999.99元"
                  suffix="元"
                  style={{ width: "30%" }}
                  changeOnWheel={false}
                />
              </Form.Item>
              {(projectData?.deep_bid_type === "ROI_COEFFICIENT" &&
                projectData?.delivery_mode === "MANUAL") ||
              (props?.deepBidType === "ROI_COEFFICIENT" &&
                props.deliveryMode === "MANUAL") ? (
                <Form.Item
                  label="ROI系数"
                  name="roi_goal"
                  rules={[
                    {
                      required: true,
                      message: "请输入ROI系数, 大于0, 不超过5",
                    },
                  ]}
                >
                  <Input type="number" min={0} max={5} placeholder="请输入" />
                </Form.Item>
              ) : (
                ""
              )}
            </Card>
          ) : (
            ""
          )}
          <Card
            title="智能创意生成"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Form.Item
              label="智能创意自动生成"
              name="creative_auto_generate_switch"
            >
              <Radio.Group
                options={[
                  { label: "关闭", value: "OFF" },
                  { label: "启用", value: "ON" },
                ]}
                optionType="button"
              />
            </Form.Item>
          </Card>
          <Form.Item label colon={false}>
            <Card styles={{ body: { textAlign: "right" } }}>
              <Space>
                <Button type="primary" ghost onClick={returnPre}>
                  上一步
                </Button>
                <Button onClick={returnClear}>返回</Button>
                <Button type="primary" onClick={handleSubmit} loading={loading}>
                  确认提交
                </Button>
              </Space>
            </Card>
          </Form.Item>
        </Form>
      </Card>

      {/* 添加产品模板弹窗 */}
      <Modal
        title="保存产品模板"
        open={tempShow}
        onCancel={closeProductTemp}
        footer={null}
      >
        <Form onFinish={onSaveProductTemp} form={tempForm}>
          <Form.Item
            label="模板备注"
            name="title"
            rules={[{ required: true, message: "模板备注不能为空" }]}
          >
            <Input placeholder="输入模板备注名称" allowClear />
          </Form.Item>
          <Form.Item label colon={false} labelCol={{ span: 17 }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={productLoading}>
                确定
              </Button>
              <Button onClick={closeProductTemp}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {/* 添加视频弹窗 */}
      <UploadVideo
        show={videoShow}
        videoClose={videoClose}
        list={videoList}
        sure={onSureVideo}
        total={videoTotal}
        type={videoMode}
        changeType={changeType}
        onSearch={onVideoSearch}
        form={videoForm}
        pagesize={videoPagesize}
        loading={videoLoading}
        categoryOptions={materialOptions}
        labels={materailLabels}
        sucaiOptions={sucaiOptions}
        selectVideoList={selectVideoList}
        onClear={onClearVideos}
        toushouOptions={filterTouShouOptions}
        currentPage={videoPage}
      />
      {/* 添加图片弹窗 */}
      <UploadImg
        show={imageShow}
        imageClose={imageClose}
        list={imageList}
        total={imageTotal}
        sure={onSureImage}
        pagesize={imagePagesize}
        loading={imageLoading}
        form={imageForm}
        onSearch={onImageSearch}
        labels={materailLabels}
        sucaiOptions={sucaiOptions}
        selectImageList={selectImageList}
        onClear={onClearImages}
      />

      {/* 添加标题弹窗 */}
      <SelectTitle
        show={titleShow}
        titleClose={titleClose}
        list={titleList}
        total={titleTotal}
        pagesize={pagesize}
        sure={onSureTitle}
        targetKeys={targetKeys}
        setTarget={setTarget}
        materialOptions={materialOptions}
        onSearch={onTitleSearch}
      />

      {/* 删除用户定向模板弹窗 */}
      <Modal
        open={deleteTempShow}
        onCancel={closeDeleteUserTemp}
        cancelText="关闭"
        width={800}
        style={{ height: 580, overflow: "auto", border: "none" }}
      >
        <Card title={<DatePicker onChange={templateDateChange} />}>
          <Table
            dataSource={templateList}
            columns={columns}
            pagination={false}
          />
        </Card>
      </Modal>
    </>
  );
}
