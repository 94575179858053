import React from "react";
import Table from "../../../../components/TableNoSelection";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  message,
} from "antd";
import columns from "./columns";
import yitouColumns from "./yitouColumns";
import { playletVirtualBack, resultReUp } from "../../../../api/playlet";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Index(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)

  const { list, total, page, perPage, pagesize, category, ad_id, isSuper } = props;

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  if (ad_id) {
    searchForm.setFieldsValue({aid: ad_id})
  }

  // 当前的路径
  const pathKey = useLocation().pathname;
  const navigate = useNavigate();

  const onFormSubmit = (values) => {
    props.onSearch(values);
  };

  // 重新回传
  const handleReUp = async (record) => {
    const res = await resultReUp({
      id: record?.id,
      app_type: record?.app_type || props?.app_type,
      ...record,
    });
    if (res?.code === 200) {
      message.success("重新回传成功", 1);
      props.onSearch();
    }
  };

  const getColumns = () => {
    if (category) {
      return yitouColumns({ handleReUp });
    } else {
      return columns({ handleReUp });
    }
  };

  // 表单条件重置
  const formReset = () => {
    navigate(pathKey, { state: null });
  };

  // 添加虚拟回传弹窗
  const addVirtualModal = () => {
    setShow(true);
  };

  // 关闭虚拟回传弹窗
  const modalClose = () => {
    form.resetFields();
    setLoading(false)
    setShow(false);
  };

  // 虚拟回传表单提交
  const onVirtualFormSubmit = async values => {
    setLoading(true)
    const res = await playletVirtualBack(values)
    if (res?.code === 200) {
      message.success('虚拟回传操作成功')
      setTimeout(() => {
        modalClose()
      }, 500);
    }
  }

  return (
    <>
      <Card
        title={
          <Form layout="inline" onFinish={onFormSubmit} form={searchForm}>
            {category ? (
              <Form.Item label='选择剧场' name='app_type'>
                <Select
                  allowClear
                  placeholder="选择剧场"
                  style={{ width: 150 }}
                  options={props.playlets}
                  // onChange={props.playletSelect}
                  size="small"
                />
              </Form.Item>
            ) : (
              ''
            )}
            <Form.Item label="推广外链ID" name="promotion_id">
                  <Input allowClear size="small" placeholder="输入推广外链ID" />
                </Form.Item>
                <Form.Item label="广告ID" name="aid">
                  <Input allowClear size="small" placeholder="输入计划ID" />
                </Form.Item>
                <Form.Item label="用户OPENID" name="openid">
                  <Input allowClear size="small" placeholder="输入OPENID" />
                </Form.Item>
                <Form.Item label="回传金额范围" style={{ marginBottom: -10 }}>
                  <Form.Item
                    name="min_money"
                    style={{ display: "inline-block" }}
                  >
                    <InputNumber
                      size="small"
                      placeholder="最小值"
                      style={{
                        width: "80%",
                      }}
                      min={0}
                    />
                  </Form.Item>
                  <div
                    style={{
                      display: "inline-block",
                      paddingTop: 3,
                      marginLeft: -40,
                    }}
                  >
                    {" "}
                    -{" "}
                  </div>
                  <Form.Item
                    name="max_money"
                    style={{
                      display: "inline-block",
                      margin: "0 8px",
                    }}
                  >
                    <InputNumber
                      size="small"
                      placeholder="最大值"
                      style={{
                        width: "80%",
                      }}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item label='日期' name='day'>
                  <DatePicker />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button onClick={formReset} size="small">
                      重置
                    </Button>
                    <Button type="primary" size="small" htmlType="submit">
                      确定
                    </Button>
                  </Space>
                </Form.Item>
          </Form>
        }
      >
        <div style={{ marginBottom: 10 }}>
          <Button type="primary" size="small" onClick={addVirtualModal}>
            添加虚拟回传
          </Button>
        </div>
        <Table
          dataSource={list}
          columns={getColumns()}
          total={total}
          page={page}
          perPage={perPage}
          pagesize={pagesize}
          loading={props.loading}
          pageOptions={props.pageOptions}
          
        />
      </Card>

      {/* 添加虚拟回传弹窗 */}
      <Modal
        open={show}
        onCancel={modalClose}
        title="添加虚拟回传"
        footer={null}
      >
        <Divider />
        <Form
          labelCol={{ sm: { span: 5 } }}
          wrapperCol={{ xl: { span: 16 } }}
          form={form}
          onFinish={onVirtualFormSubmit}
        >
          <Form.Item
            label="广告ID"
            name="aid"
            rules={[{ required: true, message: "请输入广告id" }]}
          >
            <Input allowClear placeholder="输入广告id" />
          </Form.Item>
          <Form.Item label="回传数量" name="numbers">
            <InputNumber
              allowclear='true'
              placeholder="输入虚拟回传数 (不填默认1)"
              style={{ width: "100%" }}
              min={1}
              max={isSuper ? 10000 : 3}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginLeft: "59%" }}>
            <Space>
              <Button onClick={modalClose}>取消</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
