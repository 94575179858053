import { Button } from "antd";
import dayjs from "dayjs";

const columns = ({ handleReUp }) => [
  {
    title: "广告ID",
    width: 140,
    align: "center",
    dataIndex: "ad_id",
    key: "ad_id",
    ellipsis: true,
  },
  {
    title: "推广链接ID",
    width: 90,
    align: "center",
    dataIndex: "promotion_id",
    key: "promotion_id",
  },
  {
    title: "推广链接名称",
    width: 120,
    align: "center",
    dataIndex: "promotion_title",
    key: "promotion_title",
    ellipsis: true,
  },
  {
    title: "订单id",
    width: 80,
    align: "center",
    dataIndex: "order_id",
    key: "order_id",
  },
  {
    title: "订单号",
    width: 120,
    align: "center",
    dataIndex: "order_num",
    key: "order_num",
    ellipsis: true,
  },
  {
    title: "订单金额",
    width: 80,
    align: "center",
    dataIndex: "money",
    key: "money",
  },
  {
    title: "OPENID",
    width: 80,
    align: "center",
    dataIndex: "user_id",
    key: "user_id",
  },
  {
    title: "是否回传",
    width: 120,
    align: "center",
    dataIndex: "is_up",
    key: "is_up",
    render: (value) => (parseInt(value) === 1 ? "正常回传" : (parseInt(value) === 2 ? '金额卡掉' : "回传策略过滤")),
  },
  {
    title: "是否卡掉重新回传",
    width: 100,
    align: "center",
    dataIndex: "re_up",
    key: "re_up",
    render: (value) => (value === 1 ? "是" : "否"),
  },
  {
    title: "回传时间",
    width: 160,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: (value) => dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "操作",
    width: 160,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) =>
      record?.is_up === 0 || record?.is_up === 2 ? (
        <Button type="primary" onClick={() => handleReUp(record)}>
          重新回传
        </Button>
      ) : (
        ""
      ),
  },
];

export default columns;
