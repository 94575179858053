import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getRecycleGroupStatistics } from '../../../api/statistics'
import { Button, Card, DatePicker, Form, Table, Typography } from 'antd'
import columns from './columns'
import {numDiv, numMulti} from '../../../util/calculate'

const {RangePicker} = DatePicker
const {Text} = Typography

export default function Index() {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getList()
  }, [])

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true)
    const res = await getRecycleGroupStatistics(params)
    const {data} = res
    if (res?.code === 200) {
      setList(data)
      setLoading(false)
    }
  }

  // 日期范围筛选查询
  const onSearch = values => {
    let rangeDate
    if (values['range_date']) {
      rangeDate = values['range_date'].map((date) => {
        return date.format('YYYYMMDD')
      })
    }
    getList({range_date: rangeDate})
  }


  return (
    <Card title={
      <Form layout='inline' onFinish={onSearch}>
        <Form.Item name='range_date'>
          <RangePicker />
        </Form.Item>
        <Form.Item>
            <Button type='primary' htmlType='submit'>确定</Button>
        </Form.Item>
      </Form>
    }>
      <Table
        sticky
        dataSource={list}
        columns={columns()}
        loading={loading}
        pagination={false}
        bordered={true}
        scroll={{ x: 500 }}
        summary={(pageData) => {
          let totalCost = 0;
          let totalMoneyCount = 0;
          let totalPayNum = 0;
          let totalZaiPlan = 0;
          let totalVerifyPlan = 0;
          let totalAllPlan = 0;
          pageData.forEach(
            ({
              cost,
              count_money,
              pay_man,
              per_money,
              zaikai_plan,
              audit_plan,
              all_plan,
            }) => {
              totalCost += parseFloat(cost);
              totalMoneyCount += parseFloat(count_money);
              totalPayNum += parseFloat(pay_man);
              totalZaiPlan += zaikai_plan === "-" ? 0 : parseFloat(zaikai_plan);
              totalVerifyPlan += audit_plan === "-" ? 0 : parseFloat(audit_plan);
              totalAllPlan += all_plan === "-" ? 0 : parseFloat(all_plan);
            }
          );
          return (
            <>
              {pageData && pageData.length > 0 ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2} align="center">
                    <Text type="danger" strong={true}>
                      汇总
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <Text>{totalCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Text>{totalMoneyCount.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <Text>
                      {parseInt(totalCost)
                        ? numMulti(
                            numDiv(totalMoneyCount, totalCost),
                            100
                          ).toFixed(2) + "%"
                        : "0%"}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <Text>{totalPayNum}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <Text>
                      {totalPayNum > 0
                        ? numDiv(totalCost, totalPayNum).toFixed(2)
                        : "0.00"}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <Text>
                      {totalPayNum > 0
                        ? numDiv(totalMoneyCount, totalPayNum).toFixed(2)
                        : "0.00"}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    <Text>{totalZaiPlan}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    <Text>{totalVerifyPlan}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center">
                    <Text>{totalAllPlan}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="center">
                    <Text>---</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />

    </Card>
  )
}
