const columns = () => [
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "day",
        key: "day",
        fixed: 'left',
    },
    {
        title: "素材手",
        width: 100,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
        render: (_, record) => (
            record?.su_cai_user?.nickname
        )
    },
    {
        title: "消耗",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        sorter: (a, b) => a.cost - b.cost,
        sortDirections: ['descend'],
    },
    {
        title: "点击数",
        width: 100,
        align: "center",
        dataIndex: "click",
        key: "click",
        sorter: (a, b) => a.click - b.click,
        sortDirections: ['descend'],
    },
    {
        title: "转化数",
        width: 100,
        align: "center",
        dataIndex: "convert",
        key: "convert",
        sorter: (a, b) => a.convert - b.convert,
        sortDirections: ['descend'],
    },
    {
        title: "展示数",
        width: 100,
        align: "center",
        dataIndex: "show",
        key: "show",
        sorter: (a, b) => a.show - b.show,
        sortDirections: ['descend'],
    },
]

export default columns