import dayjs from "dayjs";

const columns = () => [
  {
    title: "ID",
    width: 90,
    align: "center",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "签到时间",
    width: 160,
    align: "center",
    dataIndex: "create_time",
    key: "create_time",
    render: value => (
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    )
  },
];

export default columns;
