/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
const useWebSocket = (props) => {
  const { url, verify } = props
  const ws = useRef()
  const [message, setMessage] = useState({})
  const [readyState, setReadyState] = useState({
    key: 0,
    value: "正在连接中...",
  })

  useEffect(() => {
    if (verify && url) websocketInit()
  }, [ws, verify, url])

  const createWebSocket = () => {
    const stateArr = [
      { key: 0, value: "正在连接中..." },
      { key: 1, value: "已经连接并且可以通讯" },
      { key: 2, value: "连接正在关闭" },
      { key: 3, value: "连接已关闭或没有连接成功" },
    ]

    try {
      ws.current = new WebSocket(url)
      // 连接
      ws.current.onopen = () => {
        setReadyState(stateArr[ws.current?.readyState ?? 0])
      }
      // 关闭
      ws.current.onclose = () => {
        setReadyState(stateArr[ws.current?.readyState ?? 0])
      }
      // 连接出错
      ws.current.onerror = () => {
        setReadyState(stateArr[ws.current?.readyState ?? 0])
      }
      // 接收到数据
      ws.current.onmessage = (e) => {
        setMessage(e.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // websocket初始化
  const websocketInit = () => {
    if (!ws.current || ws.current.readyState === 3) {
      createWebSocket()
    }
  }

  // 关闭websocket
  const closeWebsocket = () => {
    ws.current?.close()
  }
  // 发送数据
  const sendMessage = (data) => {
    ws.current?.send(data)
  }
  // 重连
  const reconnect = () => {
    try {
      closeWebsocket()
      ws.current = null
      createWebSocket()
    } catch (error) {
      console.error(error)
    }
  }

  return {
    message,
    readyState,
    closeWebsocket,
    reconnect,
    sendMessage,
    ws: ws.current,
  }
}

export default useWebSocket
