import { Button, Card, Form, Input, Modal, Space } from 'antd'
import React, { useState } from 'react'
import Table from '../../../../components/TableHasTotal'
import addColumns from './addColumns'

export default function AddModal(props) {
    const [selectKeys, setSelectKeys] = useState([])

    const { isShow, handleClose, list, handleSearch, sureDispatch, loading, total, page } = props
    const [form] = Form.useForm()

    // 全选或全不选
    const selectRows = selectRows => {
        setSelectKeys(selectRows)
    }

    // 搜索查询
    const onSearch = values => {
        handleSearch(values);
    }

    const handleSubmit = () => {
        if (selectKeys.length > 0) {
            sureDispatch(selectKeys);
        }
    }

    // 每页显示条数切换回调
    const pagesize = (page, size) => {
        props.pagesize(page, size)
    }

    const onCancel = () => {
        form.resetFields()
        handleClose()
    }

    return (
        <Modal
            title='选择用户'
            open={isShow}
            onCancel={onCancel}
            width={750}
            onOk={handleSubmit}
            bodyStyle={{height: 550, overflowY: 'auto'}}
            destroyOnClose={true}
            confirmLoading={loading}
        >
            <Card
                style={{ marginBottom: 10 }}
                title={
                    <Form layout='inline' onFinish={onSearch} form={form}>
                        <Form.Item label='登录名称' name='username'>
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type='primary' htmlType='submit'>搜索</Button>
                                <Button onClick={() => {form.resetFields()}}>重置</Button>
                            </Space>
                        </Form.Item>
                    </Form>
                }
            >
                <Table
                    dataSource={list}
                    columns={addColumns()}
                    showSize={false}
                    selectRows={selectRows}
                    pagesize={pagesize}
                    page={page}
                    total={total}
                />
            </Card>
        </Modal>
    )
}
