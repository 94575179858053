import React, { useEffect, useState } from "react";
import { getVersion } from "./api/selfcheck";
import { Button, Modal, Typography } from "antd";
import { AlertOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

export default function CheckVersion() {
  const [version, setVersion] = useState("");
  const [showRefreshMessage, setShowRefreshMessage] = useState(false);

  const pathKey = useLocation().pathname

  useEffect(() => {
    checkVersion();
  }, [pathKey]);

  const checkVersion = async () => {
    // 发送请求检查应用程序版本
    const response = await getVersion();
    let cacheVersion = localStorage.getItem("version");
    // 比较当前版本和最新版本是否一致
    if (response.data.version !== cacheVersion) {
      setVersion(response.data.version);
      setShowRefreshMessage(true);
    } else {
      setShowRefreshMessage(false);
    }
  };

  // 点击刷新
  const handleRefresh = () => {
    localStorage.setItem("version", version);
    // 刷新页面以加载最新版本
    window.location.reload();
  };
  
  return (
    <Modal
      open={showRefreshMessage}
      title={
        <>
          <AlertOutlined />
          <Typography.Text strong>
            有新版本更新, 需要刷新页面后生效 !!!
          </Typography.Text>
        </>
      }
      footer={null}
      closable={false}
    >
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button type="primary" onClick={handleRefresh}>
          刷新
        </Button>
      </div>
    </Modal>
  );
}
