import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/TableHasTotal";
import columns from "./columns";
import {
  changeBookUpdate,
  changeDelete,
  changeList,
  getAdvertiserList,
  getThirdBooks,
  updateChange,
  updateLanding,
} from "../../../api/advertise";
import { useEffect } from "react";
import { useState } from "react";
import EditableCell from "../../../components/Editable/EditableCell";
import { InfoCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export default function Transform() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(0);
  const [selectKeys, setSelectKeys] = useState([]);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [advertiseShow, setAdvertiseShow] = useState(false);
  const [bookOptions, setBookOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [advertiseList, setAdvertiseList] = useState([]);
  const [advertiseLoading, setAdvertiseLoading] = useState(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [advertiseForm] = Form.useForm();

  useEffect(() => {
    getList();
    getBookList();
  }, []);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await changeList(params);
    const { data } = res;
    if (res?.code === 200) {
      const lists = data?.lists;
      const newList = lists.map((item) => {
        item.key = item.id;
        return item;
      });
      setLoading(false);
      setTotal(data?.total);
      setList(newList);
      getAdvertisers();
    }
  };

  // 批量获取广告主
  const getAdvertisers = async (params = {}) => {
    const res = await getAdvertiserList(params);
    const { data } = res;
    if (res?.code === 200) {
      const lists = data?.lists;
      const options = lists.map((item) => {
        return { label: item?.account_name, value: item?.account_id };
      });
      setAdvertiseList(options);
    }
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 每页显示条数切换回调
  const pagesize = (page, size) => {
    setPage(page);
    getList({ pageSize: size, page });
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectKeys.length > 0;

  const getBookList = async () => {
    const res = await getThirdBooks();
    const { data } = res;
    if (res?.code === 200) {
      let options = data?.lists.map((item) => {
        return { label: item.title, value: item.book_id };
      });
      setBookOptions(options);
    }
  };

  // 修改书籍（换绑书籍）
  const bookChange = (record) => {
    setShow(true);
    let bookid = parseInt(record.bookid);
    form.setFieldsValue({ ...record, bookid });
  };

  // 关闭修改书籍弹窗
  const close = () => {
    setShow(false);
    setBtnLoading(false);
  };

  // 删除记录
  const handleDelete = (record) => {
    confirm({
      title: `确定删除转化记录-${record.name}?`,
      onOk: async () => {
        const res = await changeDelete({ ids: [record.id] });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          setTimeout(() => {
            getList();
          }, 1000);
        }
      },
    });
  };

  // 批量删除
  const batchDelete = () => {
    confirm({
      title: `确定删除选中 ${selectKeys.length}条 转化记录？`,
      onOk: async () => {
        const res = await changeDelete({ ids: selectKeys });
        if (res?.code === 200) {
          message.success("删除成功", 1);
          setTimeout(() => {
            setSelectKeys([]);
            getList();
          }, 1000);
        }
      },
    });
  };

  // 修改书籍
  const bookUpdate = async (values) => {
    setBtnLoading(true);
    const res = await changeBookUpdate(values);
    if (res?.code === 200) {
      message.success("修改成功", 1);
      setTimeout(() => {
        const newList = list.map((item) => {
          if (item.id === values.id) {
            item.bookid = values.bookid;
          }
          return item;
        });
        setList(newList);
        close();
      }, 1000);
    } else {
      setBtnLoading(false);
    }
  };

  // 更新落地页信息
  const handleUpdateLanding = async (record) => {
    setUpdateLoading(record?.id);
    const res = await updateLanding({ change_id: record?.id });
    if (res?.code === 200) {
      message.success("转化对应广告户的落地页已更新", 2);
      setTimeout(() => {
        setUpdateLoading(0);
      }, 1000);
    }
  };

  const getColumns = ({
    bookChange,
    handleDelete,
    handleUpdateLanding,
    updateLoading,
    advertiseEdit,
  }) => {
    let newColumns = columns({
      bookChange,
      handleDelete,
      handleUpdateLanding,
      updateLoading,
      advertiseEdit,
    });
    return newColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: "name",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  };

  // 修改转化名称
  const handleSave = async (row) => {
    if (!row.name) {
      return false;
    }
    setLoading(true);
    const newData = [...list];
    const index = newData.findIndex((item) => row.day === item.day);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    await updateChange({ change_id: row.id, name: row.name });
    setLoading(false);
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  // 修改广告账户弹窗
  const advertiseEdit = (record) => {
    let advertiserIds = record?.advertiser_ids?.split(",");
    // 把字符串转化成数值类型
    let newAdvertiserIds = advertiserIds.map((val) => {
      return parseInt(val);
    });
    advertiseForm.setFieldsValue({
      advertiser_ids: newAdvertiserIds,
      change_id: record?.id,
    });
    setAdvertiseShow(true);
  };

  // 处理修改转化的广告账户
  const onSubmit = () => {
    advertiseForm
      .validateFields()
      .then(async () => {
        setAdvertiseLoading(true);
        const values = advertiseForm.getFieldValue();
        const res = await updateChange(values);
        if (res?.code === 200) {
          message.success("转化对应的广告账户修改成功");
          setTimeout(() => {
            setAdvertiseShow(false);
            let newList = list.map((item) => {
              if (item.id === values.change_id) {
                item.advertiser_ids = values?.advertiser_ids.join(",");
              }
              return item;
            });
            setList(newList);
            setAdvertiseLoading(false);
          }, 1000);
        }
      })
      .catch((e) => {
        let errs = e?.errorFields;
        let messages = errs.map((err) => {
          return err.errors[0];
        });
        message.error(messages[0], 2);
        return;
      });
  };

  // 转化名称搜索
  const onSearch = (value) => {
    getList({ name: value });
  };

  return (
    <div className="transform">
      <Card
        title={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                navigate("/admin/batch_plan/transform/add_trans");
              }}
            >
              添加转化
            </Button>
            <Button
              type="primary"
              danger
              disabled={!hasSelected}
              onClick={batchDelete}
            >
              批量删除转化
            </Button>
          </Space>
        }
        extra={
          <Input.Search
            placeholder="输入转化名称"
            allowClear
            enterButton="查询"
            onSearch={onSearch}
          />
        }
      >
        <Table
          columns={getColumns({
            bookChange,
            handleDelete,
            handleUpdateLanding,
            updateLoading,
            advertiseEdit,
          })}
          dataSource={list}
          loading={loading}
          selectRows={selectRows}
          pagesize={pagesize}
          page={page}
          total={total}
          components={components}
        />
      </Card>

      {/* 修改书籍弹窗 */}
      <Modal
        title="修改书籍"
        open={show}
        width={800}
        onCancel={close}
        footer={null}
      >
        <Form form={form} {...layout} onFinish={bookUpdate}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item label="转化名称" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="应用中文名" name="app_name">
            <Input disabled />
          </Form.Item>
          <Form.Item label="选择投放书籍" name="bookid">
            <Select options={bookOptions} allowClear />
          </Form.Item>
          <Form.Item label colon={false} labelCol={{ span: 17 }}>
            <Space size="middle">
              <Button onClick={close}>取消</Button>
              <Button type="primary" htmlType="submit" loading={btnLoading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {/* 修改广告账户 */}
      <Modal
        title="修改广告账户"
        open={advertiseShow}
        width={650}
        onCancel={() => {
          setAdvertiseShow(false);
          setAdvertiseLoading(false);
        }}
        footer={null}
      >
        <Divider />
        <Form form={advertiseForm}>
          <Form.Item hidden name="change_id">
            <Input />
          </Form.Item>
          <Form.Item
            label="选择广告账户"
            name="advertiser_ids"
            rules={[{ required: true, message: "广告账户不能为空" }]}
          >
            <Form.Item name="advertiser_ids" style={{ marginBottom: 0 }}>
              <Select
                mode="multiple"
                showSearch
                allowClear
                placeholder="请选择广告主 (可多选)"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={advertiseList}
                loading={advertiseList.length === 0}
              />
            </Form.Item>
            <Typography.Text type="danger" style={{ fontSize: 12 }}>
              <InfoCircleOutlined />{" "}
              注意：新添加的账户需要跟之前的账户同一主体，不然批量广告不成功！
            </Typography.Text>
          </Form.Item>
          <Form.Item label colon={false} labelCol={{ span: 18 }}>
            <Space>
              <Button
                type="primary"
                onClick={onSubmit}
                loading={advertiseLoading}
              >
                确定
              </Button>
              <Button
                onClick={() => {
                  setAdvertiseShow(false);
                  setAdvertiseLoading(false);
                }}
              >
                取消
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
