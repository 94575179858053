import React, { useEffect, useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./book_columns";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Statistic,
} from "antd";
import {
  filterPitchers,
  getECPMList,
  getECPStatistics,
  getThirdECPMPlaylet,
} from "../../../../api/statistics";

const { RangePicker } = DatePicker;

export default function EcpmBook() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [statisticsData, setStatisticsData] = useState({});
  const [conditions, setConditions] = useState([]);
  const [appOptions, setAppOptions] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    params.tab = 2;
    const res = await getECPMList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 总计数据
  const ecpmStatistics = async (params = {}) => {
    params.tab = 2;
    const res = await getECPStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setStatisticsData(data);
      setTotal(data?.total);
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 获取剧场
  const getPlaylets = async () => {
    const res = await getThirdECPMPlaylet();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((appName) => {
        return { label: appName, value: appName };
      });
      setAppOptions(newList);
    }
  };

  const getData = () => {
    Promise.all([getList(), ecpmStatistics(), getPitchers(), getPlaylets()]);
  };

  // 表单筛选查询
  const onSubmit = () => {
    let values = form.getFieldsValue();
    let rangeDate;
    if (values?.dates) {
      rangeDate = values.dates.map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.dates;
      values.range_date = rangeDate;
    }
    setConditions(values);
    Promise.all([getList(values), ecpmStatistics(values)]);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
  };

  // 排序
  const onSortChange = (pagination, filters, sorter) => {
    let order;
    if (sorter) {
      let feild = sorter.columnKey;
      let sortBy = sorter.order;
      if (feild !== undefined) {
        if (sortBy === "ascend") {
          order = feild + "_" + "asc";
        } else {
          order = feild + "_" + "desc";
        }
        setConditions({ ...conditions, order });
      }
    }
    getList({
      ...conditions,
      page_size: pagination.pageSize,
      page: pagination.current,
      order,
    });
  };

  return (
    <>
      <Card
        title={
          <Form layout="inline" form={form}>
            <Form.Item label="日期" name="dates">
              <RangePicker />
            </Form.Item>
            <Form.Item label="短剧名称" name="book_name">
              <Input placeholder="输入短剧名称" allowClear />
            </Form.Item>
            <Form.Item label="投手" name="admin_id">
              <Select
                allowClear
                showSearch
                placeholder="请选择投手"
                options={pitcherOptions}
                style={{ width: 150 }}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item label="剧场" name="app_name">
              <Select
                allowClear
                showSearch
                placeholder="请选择剧场"
                options={appOptions}
                style={{ width: 150 }}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" onClick={onSubmit}>
                  确定
                </Button>
                <Button>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
        extra={
          <>
            <Row gutter={12}>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总计消耗</div>}
                  value={statisticsData?.total_cost}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总收益</div>}
                  value={statisticsData?.total_ecpmCost}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>新增收益</div>}
                  value={statisticsData?.total_newEcpmCost}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总计回收</div>}
                  value={statisticsData?.total_money}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总回收率</div>}
                  value={statisticsData?.total_rate}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>新增回收率</div>}
                  value={statisticsData?.new_rate}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
          </>
        }
      >
        <Table
          dataSource={list}
          columns={columns()}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
          pageOptions={[20]}
          onChange={onSortChange}
        />
      </Card>
    </>
  );
}
