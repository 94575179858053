import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../components/TableHasTotal";
import columns from "./columns";
import { getFilterSuCai, getMaterialLabels } from "../../../api/advertise";
import { batchUpdateMaterialOpen, materialCustomOpenList } from "../../../api";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1)
  const [perPage, SetPerPage] = useState(10)
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(0);
  const [conditions, setConditions] = useState([]);
  const [filterSucaiOptions, setFilterSucaiOptions] = useState([]);
  const [materialLabels, setMaterialLabels] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);

  useEffect(() => {
    getFilterMaterialer();
    getLabels();
  }, []);

  // 获取视频素材列表
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await materialCustomOpenList({ ...params, video: 1 });
    const { data } = res;
    if (res?.code === 200) {
      console.log(data);
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 获取列表的素材手筛选数据
  const getFilterMaterialer = async () => {
    const res = await getFilterSuCai();
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list?.map((item) => {
        return { label: item?.nickname, value: item?.id };
      });
      setFilterSucaiOptions(newList);
    }
  };

  // 获取素材标签
  const getLabels = async (params = {}) => {
    const res = await getMaterialLabels(params);
    const { data } = res;
    if (res?.code === 200) {
      let labels = data?.labels;
      let newLabels = labels.map((item) => {
        return { label: item, value: item };
      });
      setMaterialLabels(newLabels);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 搜索表单提交
  const onsubmit = (values) => {
    let createdAt;
    if (values["create_time"]) {
      createdAt = values["create_time"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.create_time = createdAt;
    }
    setConditions(values);
    getList(values);
  };

  // 单独设置素材开放
  const onStatusChange = async (record, status) => {
    setItemLoading(record?.id);
    let isOpen = status === true ? 1 : 0
    const res = await batchUpdateMaterialOpen({
      ids: [record?.id],
      is_open: isOpen,
    });
    if (res?.code === 200) {
      let newList = list.map((item) => {
        if (item?.id === record?.id) {
          item.is_open = isOpen
        }
        return item;
      });
      setItemLoading(0)
      setList(newList);
    }
  };

  // 批量开放素材
  const batchOpen = () => {
    confirm({
      title: "确定要开放已选中的" + selectKeys.length + "个素材?",
      onOk: async () => {
        const res = await batchUpdateMaterialOpen({
          ids: selectKeys,
          is_open: 1,
        });
        if (res?.code === 200) {
          message.success("操作成功");
          setSelectKeys([]);
          setTimeout(() => {
            let newList = list.map((item) => {
              if (selectKeys.includes(item.id)) {
                item.is_open = 1;
              }
              return item;
            });
            setList(newList);
          }, 500);
        }
      },
    });
  };

  const getBatchDisabled = (record) => {
    return record?.is_open === 1;
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectKeys.length > 0;

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    SetPerPage(size)
    // getList({...conditions, page, pageSize: size})
  }

  // 排序
  const onChange = (pagination, filters, sorter) => {
    let field = sorter?.field;
    let orderType = sorter?.order;
    let params = {};
    if (orderType === "descend" && field) {
      params = { ...conditions, order: field + "_" + "desc" };
    } else if (orderType === "ascend" && field) {
      params = { ...conditions, order: field + "_" + "asc" };
    } else {
      params = conditions;
    }
    getList({
      ...params,
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  }

  return (
    <Card
      title={
        <Form onFinish={onsubmit}>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item label="上传时间" name="create_time">
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="上传用户" name="uploader">
                <Select
                  showSearch
                  allowClear
                  placeholder="选择上传用户"
                  filterOption={filterOption}
                  options={filterSucaiOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="label" label="素材标签">
                <Select
                  showSearch
                  allowClear
                  placeholder="请选择标签"
                  filterOption={filterOption}
                  options={materialLabels}
                  mode="multiple"
                  autoClearSearchValue={false}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label colon={false}>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
      bodyStyle={{ padding: 10 }}
    >
      <Card
        type="inner"
        bodyStyle={{ padding: 5 }}
        title={
          <Button type="primary" disabled={!hasSelected} onClick={batchOpen}>
            批量开放
          </Button>
        }
      >
        <Table
          dataSource={list}
          columns={columns({ onStatusChange, itemLoading })}
          loading={loading}
          total={total}
          selectRows={selectRows}
          getBatchDisabled={getBatchDisabled}
          pagesize={pagesize}
          onChange={onChange}
          perPage={perPage}
          page={page}
        />
      </Card>
    </Card>
  );
}
