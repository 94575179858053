import { numDiv, numMulti } from "../../../util/calculate";
import { Link } from "react-router-dom";

const columns = () => [
  {
    title: "剧场名称",
    width: 100,
    align: "center",
    dataIndex: "app_name",
    key: "app_name",
  },
  {
    title: "短剧名称",
    width: 120,
    align: "center",
    dataIndex: "book_name",
    key: "book_name",
    render: (_, record) => (
      <Link to={`/admin/material/video`} state={{ book_id: record?.bookid }}>{record?.book_name}</Link>
    ),
  },
  {
    title: "消耗",
    width: 80,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
    sortDirections: ["descend"],
  },
  {
    title: "转化",
    width: 70,
    align: "center",
    dataIndex: "convert",
    key: "convert",
    sorter: {},
    sortDirections: ["descend"],
  },
  {
    title: "回收",
    width: 70,
    align: "center",
    dataIndex: "money",
    key: "money",
    sorter: {},
    sortDirections: ["descend"],
  },
  {
    title: "回收率",
    width: 90,
    align: "center",
    dataIndex: "huishoulv",
    key: "huishoulv",
    render: (_, record) => {
      let totalAmount = parseFloat(record?.money);
      let totalCost = parseFloat(record?.cost);
      let huishoulv = "0.00%";
      if (totalCost) {
        huishoulv =
          numMulti(numDiv(totalAmount, totalCost), 100).toFixed(2) + "%";
      }
      return huishoulv;
    },
  },
  {
    title: "转化成本",
    width: 80,
    align: "center",
    dataIndex: "convert_cost",
    key: "convert_cost",
  },
  {
    title: "UP值",
    width: 70,
    align: "center",
    dataIndex: "up_value",
    key: "up_value",
  },
  {
    title: "新增用户付费率",
    width: 100,
    align: "center",
    dataIndex: "new_pay_rate",
    key: "new_pay_rate",
  },
];

export default columns;
