import {Card, Tabs} from "antd";
import ProfitStatisticsGroupIAP from "./IAP";
import ProfitStatisticsGroupIAA from "./IAA";

const onChange = (key) => {
    console.log(key);
};

const items = [
    {
        key: '1',
        label: 'IAA统计',
        children: (<ProfitStatisticsGroupIAA/>),
    },
    {
        key: '2',
        label: 'IAP统计',
        children: (<ProfitStatisticsGroupIAP/>)
    }
];

const Page = () => {
    return (
        <Card style={{
            width: '100%',
        }}
        >
            <Tabs size="large" defaultActiveKey="1" items={items} onChange={onChange} />
        </Card>)
}

export default Page;