import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import { playletBackSet, promotionDelete } from "../../../../api/playlet";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import BraftEditor from "braft-editor";
import RichEditor from "../../../../components/RichEditor";
import { Link } from "react-router-dom";
import EditableCell from "../../../../components/Editable/EditableCell";
import { numAdd, numDiv, numMulti } from "../../../../util/calculate";
import "./index.less";
import dayjs from "dayjs";
import {
  getNewPlayletList,
  getPromotionPlayletList,
  promotionPlayletAdd,
} from "../../../../api";
import { getAppletsPlat } from "../../../../api/asset";

const { Text } = Typography;
const { confirm } = Modal;

const defaultConfig = [
  {
    key: 0,
    money: 0,
    gold: "",
    gift: 0,
    start_pay: 0,
    unlock: "",
    second_pay: "",
    is_vip: false,
  },
  {
    key: 1,
    money: 0,
    gold: "",
    gift: 0,
    start_pay: 0,
    unlock: "",
    second_pay: "",
    is_vip: false,
  },
  {
    key: 2,
    money: 0,
    gold: "",
    gift: 0,
    start_pay: 0,
    unlock: "",
    second_pay: "",
    is_vip: false,
  },
  {
    key: 3,
    money: 0,
    gold: "",
    gift: 0,
    start_pay: 0,
    unlock: "",
    second_pay: "",
    is_vip: false,
  },
  {
    key: 4,
    money: 0,
    gold: "",
    gift: 0,
    start_pay: 0,
    unlock: "",
    second_pay: "",
    is_vip: false,
  },
  {
    key: 5,
    money: 0,
    gold: "",
    gift: 0,
    start_pay: 0,
    unlock: "",
    second_pay: "",
    is_vip: false,
  },
  {
    key: 6,
    money: 0,
    gold: "",
    gift: 0,
    start_pay: 0,
    unlock: "",
    second_pay: "",
    is_vip: false,
  },
];

export default function Index(props) {
  const [defaultData, setDefaultData] = useState(defaultConfig);
  const [playletType, setPlayletType] = useState(1);
  const [newPlayletOptions, setNewPlayletOptions] = useState([]);
  const [promotionPlayletOptions, setPromotionPlayletOptions] = useState([]);
  const [grade, setGrade] = useState(props?.promotionItem?.grade);
  const [customPlayletName, setCustomPlayletName] = useState("");
  const [addItemLoading, setAddItemLoading] = useState(false);
  const [externalAction, setExternalAction] = useState("AD_CONVERT_TYPE_PAY");
  const [appletOptions, setAppletOptions] = useState([]);
  const {
    show,
    list,
    total,
    page,
    perPage,
    loading,
    handleAddPromotion,
    handleUpdatePromotion,
    pagesize,
    backShow,
    statusLoading,
    panelList,
  } = props;

  useEffect(() => {
    getPromotionPlaylet();
  }, []);

  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setCustomPlayletName(event.target.value);
  };

  let panelTitles;
  panelTitles = panelList?.map((item) => {
    return { id: item.id, name: item.name };
  });

  let panelItems;

  const [form] = Form.useForm();
  const [backForm] = Form.useForm();

  // 用于区分是自研短剧还是三方外部短剧
  const category = props?.category || 0;

  // 添加推广链接弹窗
  const addPromotion = () => {
    props.addPromotion();
  };

  // 关闭弹窗
  const onClose = () => {
    form.resetFields();
    setDefaultData([
      {
        key: 0,
        money: 0,
        gold: "",
        gift: 0,
        start_pay: 0,
        unlock: "",
        second_pay: "",
        is_vip: false,
      },
      {
        key: 1,
        money: 0,
        gold: "",
        gift: 0,
        start_pay: 0,
        unlock: "",
        second_pay: "",
        is_vip: false,
      },
      {
        key: 2,
        money: 0,
        gold: "",
        gift: 0,
        start_pay: 0,
        unlock: "",
        second_pay: "",
        is_vip: false,
      },
      {
        key: 3,
        money: 0,
        gold: "",
        gift: 0,
        start_pay: 0,
        unlock: "",
        second_pay: "",
        is_vip: false,
      },
      {
        key: 4,
        money: 0,
        gold: "",
        gift: 0,
        start_pay: 0,
        unlock: "",
        second_pay: "",
        is_vip: false,
      },
      {
        key: 5,
        money: 0,
        gold: "",
        gift: 0,
        start_pay: 0,
        unlock: "",
        second_pay: "",
        is_vip: false,
      },
      {
        key: 6,
        money: 0,
        gold: "",
        gift: 0,
        start_pay: 0,
        unlock: "",
        second_pay: "",
        is_vip: false,
      },
    ]);
    props.onClose();
  };

  // 关闭卡回传设置弹窗
  const onSetClose = () => {
    backForm.resetFields();
    props.onSetClose();
  };

  // 新|老剧切换
  const playletTypeChange = (e) => {
    let value = e.target.value;
    if (value === 2) {
      getNewPlaylets({ app_type: props?.app_type, effective: true });
    } else {
      getPromotionPlaylet();
    }
    form.setFieldsValue({ book_name: null });
    setPlayletType(value);
  };

  // 优化目标切换
  const onExternalChange = async (e) => {
    let value = e.target.value;
    setExternalAction(value);
    if (value === "AD_CONVERT_TYPE_GAME_ADDICTION") {
      const res = await getAppletsPlat();
      if (res?.code === 200) {
        const { data } = res;
        let newData = data?.map((item) => {
          return { label: item.name, value: item.name };
        });
        setAppletOptions(newData);
      }
    }
  };

  // form排版
  const layout = {
    labelCol: { sm: { span: 6 } },
    wrapperCol: { xl: { span: 16 } },
  };

  // 推广名称搜索
  const onTitleSearch = (value) => {
    props.onTitleSearch(value);
  };

  // 卡回传设置
  const backSet = async (record) => {
    let id_promotionId = record?.id.split("@@##");
    const res = await playletBackSet({
      id: id_promotionId[0],
      promotion_id: record?.promotion_id,
      app_type: props.app_type,
    });
    const { data } = res;
    backForm.setFieldsValue({ items: data?.info });
    backForm.setFieldsValue({
      id: id_promotionId[0],
      promotion_id: id_promotionId[0],
      back_hours: data?.back_hours,
      is_first_pay: parseInt(data?.is_first_pay),
    });
    props.backSet(record);
  };

  // 添加推广链接
  const onSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        let params = form.getFieldsValue();
        if (params["send_time"]) {
          params.send_time = params["send_time"].format("YYYY-MM-DD HH:mm");
        }
        if (params["launch_time"]) {
          params.launch_time = params["launch_time"].format("YYYY-MM-DD HH:mm");
        }
        params.content = props.editor?.toHTML();
        params.data_config = defaultData;
        params.grade = grade;
        if (params.playlet_type === 2) {
          let filtered = newPlayletOptions.filter((item) => {
            return item.value === params.book_name;
          });
          params.book_name = filtered[0]?.label;
        }
        if (props.operate === "update" && params?.id) {
          if (!params.platform) {
            message.error("请选择投放平台", 2);
            return false;
          }
          handleUpdatePromotion(params, form);
        } else {
          let filtered = promotionPlayletOptions.find((item) => {
            return item.value === params.book_id;
          });
          params.book_name = filtered?.label;
          handleAddPromotion(params, form);
        }
      })
      .catch(() => {
        return;
      });
  };

  // 添加或更新推广链接
  const backFormSubmit = () => {
    let params = backForm.getFieldsValue();
    const newParams = params.items?.map((item, key) => {
      item.index = key;
      return item;
    });
    // 限制金额区间
    const filtered = newParams?.filter((item) => {
      return parseInt(item?.max_money) < parseInt(item.min_money);
    });
    if (filtered.length > 0) {
      filtered.forEach((item) => {
        message.error(
          `配置${item.index + 1}: 回传金额区间最大值不能小于最小值`
        );
      });
      return false;
    }
    props.handleBackSet(params);
  };

  // 删除推广链接
  const promotionDel = (record) => {
    confirm({
      title: "确认删除该推广链接 ?",
      onOk: async () => {
        let id_promotionId = record?.id.split("@@##");
        const res = await promotionDelete({
          id: id_promotionId[0],
          app_type: record?.app_type,
        });
        if (res?.code === 200) {
          message.success("删除成功", 2);
          setTimeout(() => {
            props.handleDeletePromotion();
          }, 1000);
        }
      },
    });
  };

  // 编辑
  const promotionEdit = (record) => {
    form.setFieldsValue(record);
    props.updatePromotion(record);
  };

  // 剧场切换
  const playletChange = (value) => {
    props.onSearch({ category: value });
  };

  // 修改备注(标题)
  const onTitleChange = async (e, record) => {
    let value = e.target.value;
    let id_promotionId = record?.id.split("@@##");
    if (e.keyCode === 13) {
      props.promotionTitleEdit({ id: id_promotionId[0], value });
    }
  };

  // 获取新剧列表信息
  const getNewPlaylets = async (params = {}) => {
    const res = await getNewPlayletList(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item?.name, value: item.book_id };
      });
      setNewPlayletOptions(newList);
    }
  };

  // 获取老剧列表信息
  const getPromotionPlaylet = async (params = {}) => {
    const res = await getPromotionPlayletList(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item?.book_name, value: item.book_id };
      });
      setPromotionPlayletOptions(newList);
    }
  };

  // 添加短剧
  const addItem = async (e) => {
    e.preventDefault();
    if (!customPlayletName) {
      return false;
    }
    setAddItemLoading(true);
    const res = await promotionPlayletAdd({
      app_type: props?.app_type,
      book_name: customPlayletName,
    });
    if (res?.code === 200) {
      const {
        data: { book_id },
      } = res;
      let filtered = promotionPlayletOptions.find((item) => {
        return item.value === book_id;
      });
      // 已存在的不追加
      if (filtered === undefined) {
        setPromotionPlayletOptions([
          { label: customPlayletName, value: book_id },
          ...promotionPlayletOptions,
        ]);
        setCustomPlayletName("");
      }
    }
    setAddItemLoading(false);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  // 修改状态
  const onStatusChange = (record, status) => {
    let id_promotionId = record?.id.split("@@##");
    let value = status === true ? 1 : 0;
    props.promotionStatusEdit({ id: id_promotionId[0], status: value });
  };

  // 获取内容
  const onEditorChange = (value) => {
    props.onEditorChange(value);
  };

  const getDefaultPerPrice = (e) => {
    let value = e.target.value;
    let data = defaultData;
    const newConfig = data?.map((item) => {
      item.start_pay = value;
      if (item.money && item.unlock) {
        item.second_pay = Math.floor(parseFloat(item.unlock) + parseInt(value));
      }
      return item;
    });
    let config = props.promotionItem?.promotion_config;
    delete config?.recharge_part;
    setDefaultData(newConfig);
  };

  // 单集价格修改后触发
  const onChangePrePrice = (e) => {
    let value = e.target.value;
    let data = defaultData;
    const newConfig = data?.map((item) => {
      if (item.money && item.gold) {
        item.unlock = numDiv(numAdd(item.gold, item.gift), value).toFixed(2);
        item.second_pay = Math.floor(
          parseFloat(item.unlock) + parseInt(item.start_pay)
        );
      }
      return item;
    });
    let config = props.promotionItem?.promotion_config;
    delete config?.pre_price;
    setDefaultData(newConfig);
  };

  // 是否vip切换
  const onVipChange = (e, record) => {
    let checked = e.target.checked;
    if (checked) {
      let config = props.promotionItem?.promotion_config;
      if (config) {
        let content = JSON.parse(config?.content);
        let newContent = content.map((item) => {
          if (item.key === record.key) {
            item.gift = 0;
            item.gold = 0;
            item.start_pay = 0;
            item.unlock = "";
            item.second_pay = 0;
          }
          return item;
        });
        config.content = JSON.stringify(newContent);
      }
      let newData = defaultData.map((item) => {
        if (item.key === record.key) {
          item.is_vip = true;
          item.gift = 0;
          item.gold = "";
          item.start_pay = 0;
          item.unlock = "";
          item.second_pay = "";
        }
        return item;
      });
      setDefaultData(newData);
    }
  };

  // 处理编辑
  const handleSave = async (row) => {
    if (!row.money && !row.start_pay && row.gift) {
      return false;
    }
    let rechargePart = form.getFieldValue("recharge_part");
    if (!rechargePart) {
      message.error("请先设置收费集数");
      return false;
    }
    const newData = [...defaultData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    if (row.money && row.money !== "-") {
      row.money = parseFloat(row.money);
      row.gold = numMulti(parseFloat(row.money), 100);
      row.unlock = numDiv(
        numAdd(row.gold, item.gift),
        form.getFieldValue("pre_price")
      ).toFixed(2);
      row.second_pay = Math.floor(
        parseFloat(item.start_pay) + parseInt(row.unlock)
      );
    }
    if (row.gift && row.gift !== "-") {
      row.gift = parseFloat(row.gift);
      row.unlock = numDiv(
        numAdd(row.gold, row.gift),
        form.getFieldValue("pre_price")
      ).toFixed(2);
      row.second_pay = Math.floor(
        parseFloat(row.unlock) + parseInt(rechargePart)
      );
    }

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDefaultData(newData);
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  const promotionColumns = () => [
    {
      title: "充值金额",
      width: 40,
      align: "center",
      dataIndex: "money",
      key: "money",
      editable: true,
      render: (value) => <Link>{value}</Link>,
    },
    {
      title: "充值金币",
      width: 120,
      align: "center",
      dataIndex: "gold",
      key: "gold",
      render: (value) => (
        <Typography.Text type="danger" style={{ fontSize: 12 }}>
          {isNaN(value) ? "" : value}
        </Typography.Text>
      ),
    },
    {
      title: "赠送金币",
      width: 40,
      align: "center",
      dataIndex: "gift",
      key: "gift",
      editable: true,
      render: (value) => <Link>{value}</Link>,
    },
    {
      title: "付费集数",
      width: 40,
      align: "center",
      dataIndex: "start_pay",
      key: "start_pay",
      editable: true,
      render: (value) => <Link>{value}</Link>,
    },
    {
      title: "解锁集数",
      width: 120,
      align: "center",
      dataIndex: "unlock",
      key: "unlock",
      render: (value) => (
        <Typography.Text type="danger" style={{ fontSize: 12 }}>
          {value}
        </Typography.Text>
      ),
    },
    {
      title: "二充付费集",
      width: 110,
      align: "center",
      dataIndex: "second_pay",
      key: "second_pay",
      render: (value) => (
        <Typography.Text type="danger" style={{ fontSize: 12 }}>
          {isNaN(value) ? "" : value}
        </Typography.Text>
      ),
    },
    {
      title: "是否会员",
      width: 60,
      align: "center",
      dataIndex: "is_vip",
      key: "is_vip",
      render: (value, record) => (
        <Checkbox
          onChange={(e) => onVipChange(e, record)}
          defaultChecked={value === true}
        />
      ),
    },
  ];

  const getPromotionNewColumns = () => {
    return promotionColumns().map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: col.dataIndex,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          width: 40,
          handleSave,
        }),
      };
    });
  };

  // 档位切换
  const onTapChange = (e) => {
    let rechargePart = form.getFieldValue("recharge_part");
    if (!rechargePart) {
      message.error("请先设置收费集数");
      return false;
    }
    let value = e.target.value;
    setGrade(value);
    let filterItem = panelList.filter((item) => {
      return item.id === value;
    });
    if (filterItem) {
      panelItems = filterItem[0]?.items;
    }
    let newData = defaultData.map((item) => {
      let filtered = panelItems?.filter((datum) => {
        return datum.key - 1 === item.key;
      });
      item.money = 0;
      item.gold = "";
      item.gift = 0;
      item.start_pay = rechargePart;
      item.unlock = "";
      item.second_pay = "";
      if (filtered && filtered.length > 0) {
        item.money = filtered[0].money;
        item.gold = numMulti(parseFloat(item.money), 100);
        item.gift = filtered[0].gift;
        if (form.getFieldValue("pre_price")) {
          item.unlock = numDiv(
            numAdd(item.gold, item.gift),
            form.getFieldValue("pre_price")
          ).toFixed(2);
          item.second_pay = Math.floor(
            parseFloat(item.unlock) + parseInt(rechargePart)
          );
        }
      }
      return item;
    });
    let config = props.promotionItem?.promotion_config;
    if (config && config !== undefined) {
      config.content = "[]";
    }
    setDefaultData(newData);
  };

  const playletTitles = {
    1002: "影潭短剧",
    1000: "羚羊短剧",
    1001: "触摸短剧",
    1003: "花生短剧",
    1004: "友和短剧",
    1005: "点众短剧",
    1006: "新剧场",
    1008: "山海短剧",
    1009: "掌阅短剧",
    1010: "天桥短剧",
    1011: "番茄短剧",
    1012: "节奏(嘉书)剧场",
    1013: "咪咕短剧",
    1014: "掌中云",
  };

  if (props?.promotionItem && props?.operate === "update") {
    let editorContent = props?.promotionItem?.content;
    form.setFieldsValue({
      content: BraftEditor.createEditorState(editorContent),
      book_id: props?.promotionItem?.book_id,
      playlet_type: props?.promotionItem?.playlet_type,
    });
    if (props.promotionItem?.promotion_config) {
      let config = props.promotionItem?.promotion_config;
      let recharge_part = config?.recharge_part;
      let pre_price = config?.pre_price;
      let send_time = config?.send_time;
      let launch_time = config?.launch_time;

      let params = {
        send_time: dayjs(send_time),
        launch_time: dayjs(launch_time),
      };
      if (recharge_part !== undefined) {
        params.recharge_part = recharge_part;
      }
      if (pre_price !== undefined) {
        params.pre_price = pre_price;
      }
      form.setFieldsValue(params);
      let content = config?.content ? JSON.parse(config?.content) : [];
      if (content.length > 0) {
        defaultData.map((item) => {
          let filtered = content.filter((datum) => {
            return item.key === datum.key;
          });
          if (filtered && filtered.length > 0) {
            item.money = item?.money || parseFloat(filtered[0]?.money);
            item.gold = item.gold || parseFloat(filtered[0]?.gold);
            item.gift = item.gift || parseFloat(filtered[0]?.gift);
            item.second_pay =
              item.second_pay || parseFloat(filtered[0]?.second_pay);
            item.start_pay =
              item.start_pay || parseFloat(filtered[0]?.start_pay);
            item.unlock = item.unlock || filtered[0]?.unlock;
          }
        });
      }
    }
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  return (
    <>
      <Card
        title={
          !category ? (
            <Button type="primary" size="small" onClick={addPromotion}>
              添加推广链接
            </Button>
          ) : (
            <Select
              allowClear
              placeholder="选择剧场"
              options={props?.playlets}
              style={{ width: 180 }}
              onChange={playletChange}
            />
          )
        }
        extra={
          !category ? (
            <Input.Search
              allowClear
              placeholder="输入推广链接名称"
              onSearch={onTitleSearch}
            />
          ) : (
            ""
          )
        }
      >
        <Table
          dataSource={list}
          columns={columns({
            backSet,
            promotionDel,
            onTitleChange,
            onStatusChange,
            statusLoading,
            promotionEdit,
          })}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
        />
      </Card>

      {/* 添加推广链接弹窗 */}
      <Modal
        title={
          <Card
            title={
              props?.operate === "add" ? "绑定添加推广链接" : "绑定编辑推广链接"
            }
            style={{ border: "none" }}
            styles={{ body: { display: "none" } }}
          />
        }
        open={show}
        onCancel={onClose}
        onOk={onSubmit}
        width={700}
        maskClosable={false}
        confirmLoading={props.confirmLoading}
        size="small"
        className="promotionAddModal"
      >
        {props?.editLoading ? (
          <Spin size="large" />
        ) : (
          <Form
            form={form}
            size="small"
            initialValues={{
              external_action: externalAction,
              playlet_type: playletType,
            }}
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="推广链接名称"
                  name="title"
                  rules={[{ required: true, message: "推广链接名称不能为空" }]}
                >
                  <Input
                    placeholder="输入推广链接名称（备注）"
                    allowClear
                    disabled={props?.operate === "update"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="跳转外链id"
                  name="promotion_id"
                  rules={[{ required: true, message: "跳转链接id不为空" }]}
                >
                  <Form.Item name="promotion_id" style={{ marginBottom: 0 }}>
                    <Input
                      placeholder="输入跳转外链id"
                      allowClear
                      disabled={props?.operate === "update"}
                    />
                  </Form.Item>
                  <Text type="secondary" style={{ fontSize: 12 }}>
                    短剧后台橙子建站里面复制跳转外链id
                  </Text>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="投放短剧类型"
                  name="playlet_type"
                  rules={[{ required: true, message: "短剧名称不能为空" }]}
                  tooltip="首发剧需要先添加维护并分配给指定的投手后才能选择"
                >
                  <Radio.Group
                    options={[
                      { label: "老剧", value: 1 },
                      { label: "首发剧", value: 2 },
                    ]}
                    onChange={playletTypeChange}
                    disabled={props?.operate === "update"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="是否用于投放"
                  name="type"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: "请选择是否用于投放" }]}
                >
                  <Radio.Group
                    options={[
                      { label: "自己平台", value: 1 },
                      { label: "只同步数据", value: 2 },
                    ]}
                    disabled={props?.operate === "update"}
                  />
                </Form.Item>
              </Col>
            </Row>
            {playletType === 1 ? (
              <Form.Item
                label="短剧名称"
                name="book_id"
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  placeholder="请选择短剧"
                  disabled={props?.operate === "update"}
                  showSearch
                  filterOption={filterOption}
                  dropdownRender={(item) => (
                    <>
                      {item}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="输入短剧名称"
                          ref={inputRef}
                          value={customPlayletName}
                          onChange={onNameChange}
                          onKeyDown={(e) => e.stopPropagation()}
                          style={{ width: 300 }}
                          allowClear
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addItem}
                          loading={addItemLoading}
                        >
                          添加短剧
                        </Button>
                      </Space>
                    </>
                  )}
                  options={promotionPlayletOptions}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="短剧名称"
                name="book_id"
                rules={[{ required: true, message: "请选择首发剧" }]}
              >
                <Select
                  allowClear
                  placeholder="请选择首发剧"
                  options={newPlayletOptions}
                  disabled={props?.operate === "update"}
                />
              </Form.Item>
            )}
            <Form.Item
              label="跳转链接"
              name="promotion_url"
              rules={[{ required: true, message: "跳转链接不能为空" }]}
            >
              <Form.Item name="promotion_url" style={{ marginBottom: 0 }}>
                <Input
                  placeholder="输入跳转链接"
                  allowClear
                  disabled={props?.operate === "update"}
                />
              </Form.Item>
              <Text type="secondary" style={{ fontSize: 12 }}>
                短剧后台橙子建站里面复制跳转链接
              </Text>
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="投放平台"
                  name="platform"
                  rules={[{ required: true, message: "请选择投放平台" }]}
                  tooltip="用于区分统计抖小，微小平台的数据"
                >
                  <Radio.Group
                    options={[
                      { label: "微信小程序", value: 1 },
                      { label: "抖音小程序", value: 2 },
                    ]}
                    // disabled={props?.operate === "update"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="优化目标"
                  name="external_action"
                  rules={[{ required: true, message: "请选择投放平台" }]}
                  tooltip="用于区分统计IPA, IAA数据"
                >
                  <Radio.Group
                    options={[
                      { label: "付费", value: "AD_CONVERT_TYPE_PAY" },
                      {
                        label: "关键行为",
                        value: "AD_CONVERT_TYPE_GAME_ADDICTION",
                      },
                      {
                        label: "激活",
                        value: "AD_CONVERT_TYPE_ACTIVE",
                      },
                    ]}
                    // disabled={props?.operate === "update"}
                    onChange={onExternalChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            {externalAction === "AD_CONVERT_TYPE_GAME_ADDICTION" || externalAction === 'AD_CONVERT_TYPE_ACTIVE' ? (
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="小程序剧场"
                    name="app_name"
                    rules={[{ required: true, message: "请选择小程序剧场" }]}
                    tooltip="用于区分统计小程序数据"
                  >
                    <Select placeholder="选择小程序" options={appletOptions} />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row gutter={8}>
              <Col span={4}>
                <Form.Item
                  label="收费集数"
                  labelCol={{ xl: { span: 12 } }}
                  name="recharge_part"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    onBlur={getDefaultPerPrice}
                    size="small"
                    min={1}
                    step={1}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="单集价格"
                  name="pre_price"
                  labelCol={{ xl: { span: 12 } }}
                  min={1}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    size="small"
                    onBlur={onChangePrePrice}
                    step={10}
                    min={10}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="投放时间" name="send_time">
                  <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="首发时间" name="launch_time">
                  <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label colon={false}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "10%", paddingTop: "8%" }}>
                  <Form.Item>
                    <Radio.Group
                      onChange={onTapChange}
                      value={grade}
                      defaultValue={props?.promotionItem?.grade}
                    >
                      <Space direction="vertical">
                        {panelTitles?.map((item) => {
                          return (
                            <Radio value={item.id} key={item.id}>
                              {item.name}
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div style={{ width: "75%" }}>
                  <Table
                    size="small"
                    components={components}
                    dataSource={defaultData}
                    columns={getPromotionNewColumns()}
                    pagination={false}
                    bordered={true}
                    scroll={0}
                  />
                </div>
                <div style={{ width: "22%" }} className="set_rule_config">
                  <div>{playletTitles[props?.app_type]}投放规则说明:</div>
                  <ul>
                    {props?.configDesc
                      ? props.configDesc?.map((value, key) => {
                          return <li key={key}>{value}</li>;
                        })
                      : ""}
                  </ul>
                </div>
              </div>
            </Form.Item>
            <Form.Item labelWrap wrapperCol={{ flex: 1 }} name="content">
              <div style={{ display: "flex" }}>
                <div style={{ width: "10%", fontSize: 12 }}>
                  面板或卡回传截图
                </div>
                <div style={{ width: "100%" }}>
                  <RichEditor
                    content={props.editor}
                    placeholder="请输入内容"
                    imageUploadSource="prize-policy"
                    editorChange={onEditorChange}
                  />
                </div>
              </div>
            </Form.Item>
          </Form>
        )}
      </Modal>

      {/* 卡回传设置弹窗 */}
      <Modal
        title={
          <Card
            title="卡回传设置"
            style={{ border: "none" }}
            styles={{ body: { display: "none" } }}
          />
        }
        open={backShow}
        onCancel={onSetClose}
        onOk={backFormSubmit}
        width={850}
        styles={{ body: { paddingTop: 10 } }}
        maskClosable={false}
        confirmLoading={props.confirmLoading}
      >
        <Form
          {...layout}
          form={backForm}
          initialValues={{
            items: [{}],
          }}
          size="small"
        >
          <Form.Item hidden name="id"></Form.Item>
          <Form.Item hidden name="promotion_id"></Form.Item>
          <Form.Item
            label="回传染色时间"
            name="back_hours"
            style={{ marginBottom: 10 }}
          >
            <InputNumber
              addonAfter="小时"
              min={0}
              placeholder="0: 自然日, 正整数: 几小时内循环"
              style={{ width: 300 }}
            />
          </Form.Item>
          <Form.Item
            label="是否首充"
            name="is_first_pay"
            style={{ marginBottom: 10 }}
          >
            <Radio.Group
              options={[
                { label: "所有充值", value: 0 },
                { label: "首次充值", value: 1 },
              ]}
            />
          </Form.Item>
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: "flex",
                  rowGap: 16,
                  flexDirection: "column",
                }}
              >
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`配置 ${field.name + 1}`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                    styles={{ body: { paddingBottom: 0 } }}
                    style={{ marginBottom: -10 }}
                  >
                    <Form.Item label="回传金额" style={{ marginBottom: -10 }}>
                      <Form.Item
                        name={[field.name, "min_money"]}
                        rules={[
                          { required: true, message: "填入回传最小金额" },
                        ]}
                        style={{ display: "inline-block" }}
                      >
                        <InputNumber
                          placeholder="最小金额 (>=)"
                          style={{ width: "80%" }}
                        />
                      </Form.Item>
                      <span style={{ marginLeft: -35 }}> - </span>
                      <Form.Item
                        name={[field.name, "max_money"]}
                        rules={[
                          { required: true, message: "填入回传最大金额" },
                        ]}
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <InputNumber
                          placeholder="最大金额 (不含)"
                          style={{ width: "80%" }}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item label="回传比例" style={{ marginBottom: -10 }}>
                      <Form.Item
                        name={[field.name, "num"]}
                        rules={[{ required: true, message: "填入每几条" }]}
                        style={{ display: "inline-block" }}
                      >
                        <InputNumber
                          placeholder="每几条"
                          style={{ width: "80%" }}
                        />
                      </Form.Item>
                      <span style={{ marginLeft: -35 }}> - </span>
                      <Form.Item
                        name={[field.name, "back_num"]}
                        rules={[{ required: true, message: "填入传几条" }]}
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <InputNumber
                          placeholder="传几条"
                          style={{ width: "80%" }}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label="前几条不卡"
                      name={[field.name, "block_nums"]}
                    >
                      <InputNumber
                        placeholder="前几条不卡"
                        style={{ width: "60%" }}
                      />
                    </Form.Item>
                  </Card>
                ))}

                <Form.Item label colon={false}>
                  <Button onClick={() => add()}>+ 添加配置</Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}
