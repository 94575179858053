import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./addplan.less";
import { createFromIconfontCN } from "@ant-design/icons";

const { RangePicker } = DatePicker;

export default function UploadImg(props) {
  const { show, imageClose, list, total, sure, form, selectImageList } = props;
  let rows = [];
  if (selectImageList.length > 0) {
    rows = selectImageList?.map((item) => {
      return item.id;
    });
  }

  const [activeTabKey, setActiveTabKey] = useState("tab1");
  const [sort, setSort] = useState("asc");
  const [aboveActive, setAboveActive] = useState(false);
  const [belowActive, setBelowActive] = useState(false);
  const [checkedEleValue, setCheckEleValue] = useState(0);
  const [selectRowKeys, setSelectRowKeys] = useState([]);
  const [selectImages, setSelectImages] = useState([]);

  // 各元素排序高亮显示
  const handleSort = (value) => {
    setCheckEleValue(value);
    if (sort === "asc") {
      setSort("desc");
      setBelowActive(true);
      setAboveActive(false);
    } else {
      setSort("asc");
      setAboveActive(true);
      setBelowActive(false);
    }
    const items = [
      { title: "create_time", value: 1 },
      { title: "cost", value: 2 },
      { title: "use_num", value: 3 },
      { title: "audit_fail_num", value: 4 },
    ];
    let filterd = items.filter((item) => {
      return item.value === value;
    });
    let sortType = sort === "asc" ? "desc" : "asc";
    let params = { order: filterd[0]?.title + "_" + sortType };
    props.onSearch(params);
  };

  // 选中图片
  const selectImage = (data) => {
    let tempKeys = selectRowKeys;
    if (selectRowKeys.includes(data?.id)) {
      const newRows = selectRowKeys.filter((item) => {
        return item !== data?.id;
      });
      const newImages = selectImages.filter((item) => {
        return item.id !== data?.id;
      });
      setSelectRowKeys(newRows);
      setSelectImages(newImages);
    } else {
      let newRows;
      let newImages;
      if (tempKeys.length > 0) {
        newRows = [...selectRowKeys, data?.id];
        newImages = [
          ...selectImages,
          { id: data?.id, img: data?.attach_img?.oss_url },
        ];
      } else {
        newRows = [...selectRowKeys, ...rows, data?.id];
        newImages = [
          ...selectImages,
          ...selectImageList,
          { id: data?.id, img: data?.attach_img?.oss_url },
        ];
      }
      // const newRows = [...selectRowKeys, id]
      // const newImages = [...selectImages, { id, img }]
      setSelectRowKeys(newRows);
      setSelectImages(newImages);
    }
  };

  // tablist元素
  const items = [
    {
      key: "tab1",
      label: "我的图片",
    },
    // {
    //     key: 'tab2',
    //     label: '上传图片',
    // },
  ];

  // 关键词查询
  const keywordSearch = (value) => {
    props.onSearch({ keyword: value });
  };

  // 时间切换
  const dateChange = (value) => {
    let createdAt;
    if (value) {
      createdAt = value.map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    props.onSearch({ create_time: createdAt });
  };

  // 素材手切换
  const materialChange = (value) => {
    props.onSearch({ show_type: value });
  };

  // 标签切换
  const changeLable = (value) => {
    props.onSearch({ label: value });
  };

  // 低效素材筛选
  const lowMaterailChange = (value) => {
    props.onSearch({ is_low: value });
  };

  // 素材手切换
  const sucaiChange = (value) => {
    props.onSearch({ sucaishou_id: value });
  };

  // tab切换
  const tabChange = (key) => {
    setActiveTabKey(key);
  };

  // 对图片进行排序操作
  const getSortBtnItem = () => {
    const items = [
      { title: "按创建时间", value: 1 },
      { title: "按消耗", value: 2 },
      { title: "使用数", value: 3 },
      { title: "被拒数", value: 4 },
    ];
    return items.map((item, key) => {
      return (
        <Button
          type="text"
          onClick={() => {
            handleSort(item.value);
          }}
          key={key}
        >
          <div style={{ display: "flex" }}>
            <div>{item.title}</div>
            <div
              style={{
                marginTop: -1,
                fontSize: 12,
                marginLeft: 2,
                color: "#999",
              }}
            >
              <div
                style={{ marginBottom: -12 }}
                className={
                  aboveActive && item.value === checkedEleValue
                    ? "sort-live"
                    : ""
                }
              >
                <CaretUpOutlined />
              </div>
              <div
                className={
                  belowActive && item.value === checkedEleValue
                    ? "sort-live"
                    : ""
                }
              >
                <CaretDownOutlined />
              </div>
            </div>
          </div>
        </Button>
      );
    });
  };

  // 处理关闭弹窗
  const handleClose = () => {
    setSelectRowKeys([]);
    setSelectImages([]);
    form.resetFields();
    imageClose();
  };

  const onSure = () => {
    sure(selectImages);
    handleClose();
  };

  // 条件查询
  const onSearch = (values) => {
    let createdAt;
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    let params = { create_time: createdAt, keyword: values.keyword };
    props.onSearch(params);
  };

  // 分页
  const handleChange = (page, size) => {
    props.pagesize(page, size);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 清空所有已选中视频
  const onClear = () => {
    setSelectRowKeys([]);
    setSelectImages([]);
    props.onClear();
    form.resetFields();
  };

  const MyIcon = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_34nl23a4nvb.js", // 在 iconfont.cn 上生成
  });

  return (
    <Modal
      open={show}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={imageClose}
      title={
        <Tabs items={items} onChange={tabChange} activeKey={activeTabKey} />
      }
      width={900}
      closable={false}
      footer={null}
    >
      <Card
        bodyStyle={{ display: "none" }}
        style={{ marginBottom: 10 }}
        headStyle={{ height: 100 }}
        title={
          <Form layout="inline" onFinish={onSearch}>
            <Form.Item name="keyword">
              <Input.Search
                placeholder="可搜索图片名称或ID"
                onSearch={keywordSearch}
                allowClear
              />
            </Form.Item>
            <Form.Item name="range_date">
              <RangePicker onChange={dateChange} />
            </Form.Item>
            <Form.Item name="user">
              <Select
                options={[
                  { label: "公有", value: 1 },
                  { label: "私有", value: 2 },
                ]}
                style={{ width: 150 }}
                placeholder="素材归属"
                allowClear
                onChange={materialChange}
              />
            </Form.Item>
            <Row gutter={24} style={{ marginTop: 10 }}>
              <Col span={8}>
                <Form.Item name="show_type">
                  <Select
                    options={props.sucaiOptions}
                    placeholder="选择素材手"
                    onChange={sucaiChange}
                    allowClear
                    showSearch
                    filterOption={filterOption}
                    style={{ width: 180 }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="label">
                  <Select
                    placeholder="选择标签"
                    options={props.labels}
                    allowClear
                    onChange={changeLable}
                    showSearch
                    filterOption={filterOption}
                    style={{ width: 180 }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="is_low">
                  <Select
                    placeholder="筛选低效素材"
                    options={[
                      { label: "是", value: 1 },
                      { label: "否", value: 0 },
                    ]}
                    allowClear
                    onChange={lowMaterailChange}
                    showSearch
                    filterOption={filterOption}
                    style={{ width: 180 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      />
      <Card
        title={
          <Typography.Text style={{ color: "#999" }}>
            共 {total} 个图片
          </Typography.Text>
        }
        extra={<Space>{getSortBtnItem()}</Space>}
      >
        <List
          grid={{
            gutter: 16,
            column: 5,
          }}
          pagination={{
            total,
            onChange: handleChange,
            defaultPageSize: 20,
            pageSizeOptions: [20, 25, 30],
          }}
          loading={props.loading}
          dataSource={list}
          renderItem={(item) => (
            <List.Item>
              <div
                style={{ overflowX: "hidden", width: 150, marginLeft: -16 }}
                onClick={() => selectImage(item)}
                className={
                  selectRowKeys && selectRowKeys.length > 0
                    ? selectRowKeys.includes(item.id)
                      ? "live"
                      : ""
                    : rows.includes(item.id)
                    ? "live"
                    : ""
                }
              >
                <div style={{ overflowX: "hidden", width: 150 }}>
                  <Tooltip title={item.title}>{item.title}</Tooltip>
                  <img src={item?.attach_img?.oss_url} alt="" />
                  <div style={{ display: "flex", backgroundColor: "#eee" }}>
                    <div style={{ marginRight: 3, color: "red", width: "33%" }}>
                      ￥{item?.cost}
                    </div>
                    <div
                      style={{ marginRight: 3, color: "green", width: "38%" }}
                    >
                      使用:{item?.use_num}
                    </div>
                    <div>拒:{item?.audit_fail_num}</div>
                  </div>
                </div>

                {selectRowKeys && selectRowKeys.length > 0 ? (
                  selectRowKeys.includes(item.id) ? (
                    <div
                      style={{
                        fontSize: 35,
                        position: "absolute",
                        left: "50%",
                        top: "36%",
                      }}
                    >
                      <MyIcon type="icon-xuanzhong-copy" />
                    </div>
                  ) : (
                    ""
                  )
                ) : rows.includes(item.id) ? (
                  <div
                    style={{
                      fontSize: 35,
                      position: "absolute",
                      left: "50%",
                      top: "36%",
                    }}
                  >
                    <MyIcon type="icon-xuanzhong-copy" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </List.Item>
          )}
        />
      </Card>

      <Card
        extra={
          <Space>
            <Button onClick={handleClose}>取消</Button>
            <Button type="primary" danger onClick={onClear}>
              清空
            </Button>
            <Button type="primary" onClick={onSure}>
              确定
            </Button>
          </Space>
        }
        title={
          <div style={{ display: "flex" }}>
            <div>已选择 {selectImages.length || selectImageList.length}：</div>
            <div style={{ width: "85%", whiteSpace: "pre-wrap" }}>
              {selectImages && selectImages.length > 0
                ? selectImages.map((item) => {
                    return (
                      <img
                        src={item.img}
                        alt=""
                        key={item.id}
                        width={40}
                        style={{ marginRight: 8 }}
                      />
                    );
                  })
                : selectImageList.map((item) => {
                    return (
                      <img
                        src={item.img}
                        alt=""
                        key={item.id}
                        width={40}
                        style={{ marginRight: 8 }}
                      />
                    );
                  })}
            </div>
          </div>
        }
        style={{ marginTop: 10 }}
        bodyStyle={{ display: "none" }}
      />
    </Modal>
  );
}
