import { Button } from "antd";
import dayjs from "dayjs";

const columns = ({handlePushAgain}) => [
    {
        title: "渠道",
        width: 120,
        align: "center",
        dataIndex: "uid",
        key: "uid",
        render: (_,record) => (
            record?.user?.nickname || '-'
        )
    },
    {
        title: "推广链接",
        width: 110,
        align: "center",
        dataIndex: "promotion_id",
        key: "promotion_id",
        render: (_,record) => (
            record?.promotion?.remark || '-'
        )
    },
    {
        title: "计划iD",
        width: 100,
        align: "center",
        dataIndex: "aid",
        key: "aid",
        ellipsis: true,
    },
    {
        title: "是否虚拟提交",
        width: 80,
        align: "center",
        dataIndex: "is_fake",
        key: "is_fake",
        render: value => (
            value === 1 ? '是' : '否'
        )
    },
    {
        title: "真实回传",
        width: 100,
        align: "center",
        dataIndex: "is_real_up",
        key: "is_real_up",
        render: value => (
            value === 1 ? '回传了' : '卡回传策略过滤'
        )
    },
    {
        title: "是否卡掉后重新回传",
        width: 80,
        align: "center",
        dataIndex: "is_retry",
        key: "is_retry",
        render: value => (
            value === 1 ? '是' : '否'
        )
    },
    {
        title: "回传时间",
        width: 150,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
           dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "操作",
        width: 100,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
           record.is_real_up === 0 ? <Button type="primary" onClick={() => handlePushAgain(record.id)}>重新回传</Button> : ''
        )
    },
]

export default columns