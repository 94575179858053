import React, { useState } from "react";
import { Card } from "antd";
import EcpmPromotion from "./List/ecpm_promotion";
import EcpmBook from "./List/ecpm_book";

export default function Index() {
  const [activeTabKey, setActiveTabKey] = useState(1);

  const tabList = [
    { key: 1, tab: "推广链接" },
    { key: 2, tab: "短剧收益" },
  ];

  const contentList = {
    1: <EcpmPromotion />,
    2: <EcpmBook />,
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <>
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        size="small"
      >
        {contentList[activeTabKey]}
      </Card>
    </>
  );
}
