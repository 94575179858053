/*
 * @Author: fq
 * @email: 3229058754@163.com
 * @Date: 2024-09-03 09:52:17
 * @LastEditTime: 2024-09-03 10:11:56
 * @Description: this is a page
 */
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Table,
  Typography,
  Modal,
  Divider,
  InputNumber,
  Tag,
  Drawer,
  Radio,
  Spin,
  Empty,
} from "antd";
import React, { Fragment } from "react";
import { useState } from "react";
import playletColumns from "./playletColumns";
import novelColumns from "./novelColumns";
import adLogColumns from "./adLogColumns";
import { useEffect } from "react";
import { CloseOutlined, createFromIconfontCN } from "@ant-design/icons";
import {
  accountPlanSummary,
  adRemarkEdit,
  costPromotionTimeDivision,
  filterPitchers,
  recycleAccountPlan,
  getLogList,
} from "../../../api/statistics";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  handleDeletePlan,
  handleUpdatePlanStatus,
  saveBidData,
  getBidUpdateNum,
  batchSaveBidData,
  qiliangConfigSet,
  getQiliangDetail,
  qiliangStop,
  qiliangStatusCheck,
  handleBatchUpdatePlanStatus,
  handleBatchDeletePlan,
  batchQiliangConfigSet,
  getKeywordAccountList,
} from "../../../api/advertise";
import { connect } from "react-redux";
import { saveMenu } from "../../../redux/actions/menu";
import ChartDualAxes from "../../../components/ChartDualAxes";
import EditableCell from "../../../components/Editable/EditableCell";
import dayjs from "dayjs";
import "./index.less";
import { numDiv } from "../../../util/calculate";

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { confirm } = Modal;

function Index(props) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [measureLoading, setMeasureLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [accountSummary, setAccountSummary] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [divisionCost, setDivisionCost] = useState([]);
  const [newBid, setNewBid] = useState(false); //新出价
  const [bidNum, setBidNum] = useState(0); //出价修改次数
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bidModalShow, setBidModalShow] = useState(false);
  const [logList, setLogList] = useState([]); //日志
  const [logModalShow, setLogModalShow] = useState(false); //日志弹窗
  const [measureShow, setMeasureShow] = useState(false);
  const [measureDetailShow, setMeasureDetailShow] = useState(false);
  const [measureDetailLoading, setMeasureDetailLoading] = useState(false);
  const [startType, setStartType] = useState([]);
  const [raiseStatus, setRaiseStatus] = useState("");
  const [detailRaiseStatus, setDetailRaiseStatus] = useState("");
  const [qiliangItems, setQiliangItems] = useState([]);
  const [qiliangData, setQiliangData] = useState([]);
  const [qiliangLoading, setQiliangLoading] = useState(false);
  const [qiliangVersions, setQiliangVersions] = useState([]);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [batchOperateType, setBatchOperateType] = useState(0);
  const [qiliangType, SetQiliangType] = useState("single");
  const [spinning, SetSpinning] = useState(false);
  const [selectAccountId, setSelectAccountId] = useState(0);

  // 当前路径
  const pathKey = useLocation().pathname;

  const { state } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [bidForm] = Form.useForm();
  const [measureForm] = Form.useForm();
  const [measureDetailForm] = Form.useForm();

  let promotion_id = props?.promotionId || null
  let userId = props?.user_id || null
  if (promotion_id) {
    form.setFieldsValue({ promotion_id });
  }
  if (userId) {
    form.setFieldsValue({ admin_id: parseInt(userId) });
  }
  useEffect(() => {
    if(localStorage.getItem('system_from_ecpm_ID')){
      form.setFieldsValue({promotion_id: localStorage.getItem('system_from_ecpm_ID')})
    }
    getPitcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getList();
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotion_id])

  // 获取列表信息
  const getList = async (params = {}) => {
    setLoading(true);
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    if(localStorage.getItem('system_from_ecpm_ID')){
      params.promotion_id = localStorage.getItem('system_from_ecpm_ID')
    }
    const res = await recycleAccountPlan({
      admin_id: props?.admin_id || props?.conditions?.admin_id || props?.user_id || null,
      advertiser:
        props?.conditions?.keyword || props?.conditions?.advertiser_id,
      advertiser_name: state?.advertise?.account_name,
      promotion_id: promotion_id || localStorage.getItem('system_from_ecpm_ID'),
      ...params,
    });
    const { data } = res;
    if (res?.code === 200) {
      let newList = data?.lists?.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newList);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 处理编辑备注
  const handleSave = async (row) => {
    if (!row.remark) {
      return false;
    }
    setLoading(true);
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    const res = await adRemarkEdit({ id: row.id, remark: row.remark });
    if (res?.code === 200) {
      setList(newData);
    }
    setLoading(false);
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  const getColumns = ({
    updatePlanStatus,
    deletePlan,
    switchover,
    showTimeDivision,
    handleEable,
    qiliangDetail,
  }) => {
    let columns;
    if (pathKey.split("/").includes("novel")) {
      columns = novelColumns({
        updatePlanStatus,
        deletePlan,
        switchover,
        showTimeDivision,
      });
    } else if (pathKey.split("/").includes("theater")) {
      let newColumns = playletColumns({
        updatePlanStatus,
        deletePlan,
        switchover,
        showTimeDivision,
        updateBidNum, //出价修改次数
        editBid, //修改出价界面
        saveBid, //提交出价
        showOperateLog, //显示操作日志
        handleEable,
        qiliangDetail,
      });

      columns = newColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            field: "remark",
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      });
    }
    return columns;
  };

  // 获取列表数据汇总
  const getSummary = async (params = {}) => {
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await accountPlanSummary({
      admin_id: props?.admin_id || props?.conditions?.admin_id || props?.user_id || null,
      advertiser:
        props?.conditions?.keyword || props?.conditions?.advertiser_id,
      advertiser_name: state?.advertise?.account_name,
      promotion_id: promotion_id || localStorage.getItem('system_from_ecpm_ID'),
      ...params,
    });
    if (res?.code === 200) {
      const {
        data: { summary },
      } = res;
      setAccountSummary(summary);
    }
  };

  // 获取筛选的投手信息
  const getPitcher = async (params = {}) => {
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else if (pathKey.split("/").includes("theater")) {
      params.type = "playlet";
    }
    const res = await filterPitchers(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 分页
  const onShowChange = (page, size) => {
    setPage(page);
    setPerPage(size);
    let params = { ...conditions, page, pageSize: size };
    setConditions(params);
    getList({ ...params, advertiser_id: selectAccountId });
  };

  // 获取广告主列表
  const getAccountList = async (params = {}) => {
    SetSpinning(true);
    const res = await getKeywordAccountList(params);
    if (res?.code === 200) {
      const { data } = res;
      if (data.length === 0) {
        setSelectAccountId(0);
      }
      setAdvertiserOptions(data);
      SetSpinning(false);
    }
  };

  // 条件筛选查询
  const onSearch = (values) => {
    let createdAt;
    let params = { ...values };
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
    }
    params.range_date = createdAt;
    params.advertiser = values.keyword;
    params.advertiser_id = selectAccountId;
    setConditions(params);
    props.onSearch(params);
    getList(params);
    getSummary(params);
  };

  const handleSearch = (value) => {
    getAccountList(value);
  };

  // 切换广告账户拉取素材列表数据
  const onAdvertiseSelect = async (e, item) => {
    let advertiserId = item?.account_id;
    setSelectAccountId(advertiserId);
    setConditions({ page: 1, pageSize: 10 });
    getList({
      ...conditions,
      advertiser_id: advertiserId,
      page: 1,
      pageSize: 10,
    });
    getSummary({
      ...conditions,
      advertiser_id: advertiserId,
    });
    setPage(1);
    setPerPage(10);
  };

  // 重置
  const formReset = () => {
    form.resetFields();
    props.onSearch([]);
  };

  // 计划状态修改
  const updatePlanStatus = async (record, status) => {
    let params = {
      advertiser_id: record?.advertiser_id,
      ad_id: record?.ad_id,
      status,
    };
    const res = await handleUpdatePlanStatus(params);
    if (res?.code) {
      message.success("状态修改成功", 1);
      getList({ ...conditions });
    }
  };

  // 删除计划
  const deletePlan = async (record) => {
    let params = { advertiser_id: record?.advertier_id, ad_id: record?.ad_id };
    const res = await handleDeletePlan(params);
    if (res?.code) {
      message.success("头条计划已删除", 1);
      getList({ ...conditions });
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const switchover = (record, tab) => {
    props.switchover(record, tab);
  };

  // 弹窗显示分时曲线
  const showTimeDivision = async (record) => {
    setConfirmLoading(true);
    setModalShow(true);
    const res = await costPromotionTimeDivision({
      advertiser_id: record?.advertiser_id,
      ...conditions,
      ad_id: record?.ad_id,
    });
    if (res?.code === 200) {
      const { data } = res;
      setDivisionCost(data);
      setConfirmLoading(false);
    }
  };

  const modalClose = () => {
    setModalShow(false);
  };

  // 消耗 、 转化数排序
  const onTableChange = (pagination, filter, sorter) => {
    let field = sorter.field;
    let orderType = sorter.order === "ascend" ? "asc" : "desc";
    let order = `${field}_${orderType}`;
    if (field !== undefined) {
      getList({ ...conditions, order });
    }
  };

  const updateBidNum = async (record) => {
    //验证当前次数
    const result = await getBidUpdateNum({ ad_id: record?.ad_id });
    let hasNum = 0;
    if (result?.code === 200) {
      hasNum = result?.data?.num;
    }
    setBidNum(hasNum);
  };
  //修改出价
  const editBid = (record) => {
    return (
      <>
        <card>
          <InputNumber min={1} max={500} onChange={changeBid} />
          <br />
          <Tag color="red">修改次数:{bidNum}</Tag>
        </card>
      </>
    );
  };
  //修改出价回调
  const changeBid = async (value) => {
    setNewBid(value);
  };

  //出价修改提交
  const saveBid = async (record) => {
    const res = await saveBidData({
      advertiser_id: record?.advertiser_id,
      ad_id: record?.ad_id,
      new_bid: newBid,
      id: record?.id,
    });
    if (res?.code === 200) {
      message.success("出价修改成功", 1);
      getList({ ...conditions });
    }
    return;
  };

  // 批量修改出价弹窗
  const batchUpdateBidPrice = () => {
    setBidModalShow(true);
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  // 批量操作时，复选框是否可选
  const getBatchDisabled = (record) => {
    if (batchOperateType === 1) {
      return false;
    } else if (batchOperateType === 2) {
      return (
        record?.plan_ad_create?.status === "DISABLED" ||
        record?.plan_ad_create?.status === "AD_STATUS_DISABLE"
      );
    } else if (batchOperateType === 3) {
      return (
        record?.plan_ad_create?.cpa_bid === "0.00" ||
        record?.plan_ad_create?.cpa_bid === 0
      );
    } else if (batchOperateType === 4) {
      return (
        record?.qi_liang?.status === "RAISING" ||
        record?.plan_ad_create?.cpa_bid === "0.00"
      );
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",

    // 选择框是否可选
    getCheckboxProps: (record) => ({
      disabled: getBatchDisabled(record),
      name: record.name,
    }),
  };

  const bidModalClose = () => {
    bidForm.resetFields();
    setSelectedRowKeys([]);
    setBidModalShow(false);
  };

  const bidInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(parseFloat(value))) {
      bidForm.setFieldsValue({ bid: "" });
    }
  };

  // 处理批量出价
  const handleBatchUpdate = async () => {
    setConfirmLoading(true);
    let bidPrice = bidForm.getFieldValue("bid");
    if (!bidPrice || parseFloat(bidPrice) === 0) {
      message.error("请输入修改的出价");
      setConfirmLoading(false);
      return false;
    }

    let filtered = list.filter((item) => {
      return selectedRowKeys.includes(item.id);
    });
    if (filtered.length > 0) {
      let params = filtered.map((item) => {
        return {
          advertiser_id: item.advertiser_id,
          ad_id: item.ad_id,
          bid: parseFloat(bidPrice),
        };
      });
      const res = await batchSaveBidData(params);
      if (res?.code === 200) {
        message.success("修改出价成功");
        bidModalClose();
        setTimeout(() => {
          getList({ ...conditions });
        }, 1000);
      }
    }
    setConfirmLoading(false);
  };

  const batchOperate = (e) => {
    let value = e.target.value;
    setSelectedRowKeys([]);
    setBatchOperateType(value);
  };

  // 批量删除操作
  const batchAdDelete = () => {
    let filtered = list.filter((item) => {
      return selectedRowKeys.includes(item.id);
    });
    let items = filtered?.map((item) => {
      return { advertiser_id: item?.advertiser_id, ad_id: item?.ad_id };
    });
    let params = { items };
    confirm({
      title: "确认删除选中的广告?",
      onOk: async () => {
        const res = await handleBatchDeletePlan(params);
        if (res?.code === 200) {
          message.success("批量删除操作成功");
          getList({ ...conditions });
        }
      },
    });
  };

  // 批量暂停操作
  const batchAdStop = () => {
    let filtered = list.filter((item) => {
      return selectedRowKeys.includes(item.id);
    });
    let items = filtered?.map((item) => {
      return { advertiser_id: item?.advertiser_id, ad_id: item?.ad_id };
    });
    let params = { items, status: "DISABLE" };
    confirm({
      title: "确认暂停选中的广告?",
      onOk: async () => {
        const res = await handleBatchUpdatePlanStatus(params);
        if (res?.code === 200) {
          message.success("批量暂停操作成功");
          getList({ ...conditions });
        }
      },
    });
  };

  //广告操作日志
  const showOperateLog = async (record) => {
    setLogModalShow(true);
    setConfirmLoading(true);
    const res = await getLogList({ id: record.id });
    if (res?.code === 200) {
      setLogList(res?.data);
      setConfirmLoading(false);
    }
  };
  //关闭广告操作日志
  const logModalClose = () => {
    setLogModalShow(false);
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectedRowKeys.length > 0;

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  // 生效时间切换
  const onTypeChange = (e, fieldName) => {
    let v = e.target.value;
    let value = { [`type-${fieldName}`]: v };
    let params = startType;
    if (params.length === 0) {
      params = [value];
    } else {
      let filtered = params.filter((item) => {
        return (
          JSON.stringify(Object.keys(item)) !==
          JSON.stringify([`type-${fieldName}`])
        );
      });
      filtered.push(value);
      params = filtered;
    }
    setStartType(params);
  };

  // 批量一键起量
  const batchQiliang = () => {
    setMeasureShow(true);
    SetQiliangType("batch");
  };

  // 开启一键启量
  const handleEable = (record) => {
    SetQiliangType("single");
    // 检查起量状态，并保存入库
    qiliangStatusCheck(record);

    let status = record?.qi_liang?.status;
    setRaiseStatus(status);
    measureForm.setFieldsValue({
      advertiser_id: record?.advertiser_id,
      promotion_id: record?.ad_id,
    });

    // 这里主要是方便判断是否为disabled
    let info = record?.info;
    if (status === "RAISING") {
      let filterItem = info.filter((item) => {
        return (
          dayjs(item?.start_time).isBefore(dayjs()) &&
          dayjs(item?.end_time).isAfter(dayjs())
        );
      });
      setQiliangItems(filterItem);
    }

    // 只有起量状态为进行中或已预约状态时，才回显之前的设置选项
    if (status === "RAISING") {
      const startTypeParams = info.map((item, key) => {
        return { [`type-${key}`]: false };
      });
      setStartType(startTypeParams);
    }
    setMeasureShow(true);
  };

  // 关闭一键启量弹窗
  const onMeasureClose = () => {
    measureForm.setFieldsValue({
      items: [{ is_effective_now: true }],
      advertiser_id: 0,
      promotion_id: 0,
    });
    setStartType([]);
    setMeasureShow(false);
    setQiliangVersions([]);
    setMeasureLoading(false);
    getList(conditions);
  };

  // 一键启量表单提交
  const measureFormSubmit = () => {
    measureForm
      .validateFields()
      .then(async () => {
        setMeasureLoading(true);
        let params = measureForm.getFieldsValue();
        let newParams = {};
        let items = params["items"].map((item, key) => {
          item.version = key + 1;
          if (item["raise_time"]) {
            let createdAt;
            createdAt = item["raise_time"].format("YYYY-MM-DD HH:mm");
            if (
              qiliangItems &&
              qiliangItems[key] === undefined &&
              Date.parse(createdAt) < dayjs().valueOf()
            ) {
              message.error("起量时间不能小于当前时间");
              return "error";
            }
            item.raise_time = createdAt;
          }
          delete item.cycle;
          if (item.is_effective_now === true) {
            delete item.raise_time;
          } else {
            item["appointed_time"] = {
              raise_time: item["raise_time"],
            };
            delete item.raise_time;
          }
          return item;
        });
        if (items.includes("error")) {
          return false;
        }
        if (raiseStatus === "RAISING") {
          items.unshift(qiliangItems);
        }
        newParams.items = items;
        let res = {};
        if (qiliangType === "single") {
          newParams.advertiser_id = params.advertiser_id;
          newParams.promotion_id = params.promotion_id;
          res = await qiliangConfigSet(newParams);
        } else if (qiliangType === "batch") {
          let filtered = list.filter((item) => {
            return selectedRowKeys.includes(item.id);
          });
          let options = filtered?.map((item) => {
            return { advertiser_id: item?.advertiser_id, ad_id: item?.ad_id };
          });
          res = await batchQiliangConfigSet({ items, options });
        }
        if (res?.code === 200) {
          message.success("一键起量设置成功");
          setTimeout(() => {
            onMeasureClose();
            setMeasureLoading(false);
            getList(conditions);
          }, 1000);
        } else {
          setMeasureLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  // 启量详情
  const qiliangDetail = async (params) => {
    // 检查起量状态，并保存入库
    qiliangStatusCheck(params);

    setDetailRaiseStatus(params?.qi_liang?.status);
    setMeasureDetailShow(true);
    setQiliangLoading(true);
    measureDetailForm.setFieldsValue({
      advertiser_id: params?.advertiser_id,
      ad_id: params?.ad_id,
    });
    const res = await getQiliangDetail({
      ...params,
      advertiser_id: params?.advertiser_id,
      ad_id: params?.ad_id,
    });
    if (res?.code === 200) {
      const { data } = res;
      let versions = data?.version;
      let newVersions = versions?.map((item) => {
        let label =
          dayjs(item.start_time).format("YYYY-MM-DD HH:mm") +
          " ~ " +
          dayjs(item.end_time).format("YYYY-MM-DD HH:mm");
        if (dayjs(item?.end_time).isAfter(dayjs())) {
          label = dayjs(item.start_time).format("YYYY-MM-DD HH:mm") + " ~ 现在";
        }
        return { label, value: item.raise_version };
      });
      setQiliangData(data);
      if (newVersions) {
        measureDetailForm.setFieldsValue({
          raise_version: newVersions[0]?.value,
        });
      }
      setQiliangVersions(newVersions);
      setQiliangLoading(false);
    }
  };

  // 关闭启量详情
  const onMeasureDetailClose = () => {
    setMeasureDetailShow(false);
    setMeasureDetailLoading(false);
    getList(conditions);
  };

  // 切换起量时间段
  const onQiliangTimeChange = (value) => {
    qiliangDetail({
      ...measureDetailForm.getFieldsValue(["advertiser_id", "ad_id"]),
      version: value,
    });
  };

  // 关闭一键起量
  const handleQiliangStop = async () => {
    confirm({
      title: "确定关闭起量?",
      onOk: () => {
        setMeasureDetailLoading(true);
        const res = qiliangStop(
          measureDetailForm.getFieldsValue(["advertiser_id", "ad_id"])
        );
        if (res?.code === 200) {
          message.success("广告一键起量已关闭");
          setTimeout(() => {
            onMeasureDetailClose();
            getList(conditions);
          }, 500);
        } else {
          setMeasureDetailLoading(false);
        }
      },
    });
  };

  // 自动规则
  const authRule = () => {
    navigate("/admin/statistics/theater/push_plt/toutiao_plt/auto_rule");
  };

  const MyIcon = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_8jxoc34pinv.js", // 在 iconfont.cn 上生成
  });

  return (
    <>
      <Card
        size="small"
        style={{paddingTop: 10}}
        title={
          <>
            <Form
              onFinish={onSearch}
              form={form}
              initialValues={{
                admin_id:
                  props?.admin_id || props?.conditions?.admin_id || null,
                keyword:
                  props?.conditions?.keyword ||
                  props?.conditions?.advertiser_id,
              }}
            >
              <Row gutter={16}>
                <Col span={5}>
                  <Form.Item label="筛选投手" name="admin_id">
                    <Select
                      allowClear
                      options={pitcherOptions}
                      placeholder="筛选投手"
                      showSearch
                      filterOption={filterOption}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name="ad_name" label="广告名称">
                    <Input placeholder="输入广告名称" allowClear />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ad_id" label="广告ID">
                    <Input placeholder="输入广告ID" allowClear />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="出价范围" style={{ marginBottom: -10 }}>
                    <Form.Item
                      name="min_money"
                      style={{ 
                        display: "inline-block",
                        width: 'calc(50% - 8px)',
                      }}
                    >
                      <InputNumber
                        placeholder="最小值"
                        style={{
                          width: "100%",
                        }}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      name="min_money"
                      style={{ 
                        display: "inline-block",
                        margin: '0 5px',
                      }}
                    >
                      <div>-</div>
                    </Form.Item>
     
                    <Form.Item
                      name="max_money"
                      style={{
                        display: "inline-block",
                        width: 'calc(50% - 8px)',
                      }}
                    >
                      <InputNumber
                        placeholder="最大值"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={5}>
                  <Form.Item label="广告状态" name="status">
                    <Select
                      allowClear
                      placeholder="选择广告状态"
                      options={[
                        { label: "投放中", value: "AD_STATUS_DELIVERY_OK" },
                        { label: "已暂停", value: "AD_STATUS_DISABLE" },
                        { label: "已被项目暂停", value: "PROJECT_DISABLED" },
                        { label: "已删除", value: "AD_STATUS_DELETE" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item label="投放日期" name="range_date">
                    <RangePicker style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="广告账户" name="keyword">
                    <Input
                      allowClear
                      placeholder="广告账户名称、账户id"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="推广外链" name="promotion_id">
                    <Input placeholder="输入推广外链id" allowClear onChange={() => {localStorage.removeItem('system_from_ecpm_ID')}}/>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "right" }}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    确定
                  </Button>
                  <Button onClick={formReset}>
                    重置
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => navigate(-1)}
                    ghost
                  >
                    返回
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </>
        }
      >
        <Card
          type="inner"
          size="small"
          styles={{ body: { padding: 0 } }}
          title={
            <Space>
              <Button size="small" onClick={authRule} type="primary" ghost>
                自动规则
              </Button>
              <Radio.Group
                options={[
                  { label: "批量删除", value: 1 },
                  { label: "批量暂停", value: 2 },
                  { label: "批量修改出价", value: 3 },
                  { label: "批量一键起量", value: 4 },
                ]}
                onChange={batchOperate}
              />
              {hasSelected ? (
                <Button
                  type="link"
                  onClick={
                    batchOperateType === 1
                      ? batchAdDelete
                      : batchOperateType === 2
                      ? batchAdStop
                      : batchOperateType === 3
                      ? batchUpdateBidPrice
                      : batchQiliang
                  }
                >
                  <>
                    {batchOperateType === 1
                      ? "批量删除"
                      : batchOperateType === 2
                      ? "批最暂停"
                      : batchOperateType === 3
                      ? "批量修改出价"
                      : "批量一键起量"}
                  </>
                </Button>
              ) : (
                ""
              )}
            </Space>
          }
          extra={
            <Form
              layout="inline"
              style={{ marginTop: 10 }}
              onFinish={handleSearch}
            >
              <Form.Item label="广告账户筛选" name="keyword">
                <Input placeholder="通过关键词搜索广告账户" allowClear />
              </Form.Item>
              <Form.Item label colon={false}>
                <Button htmlType="submit" type="primary">
                  查询
                </Button>
              </Form.Item>
            </Form>
          }
        >
          {advertiserOptions.length > 0 ? (
            <div
              style={{
                border: "1px solid #D9D9D9",
                width: "100%",
                height: 200,
                borderRadius: 5,
                overflowY: "auto",
              }}
            >
              <ul className="container">
                {advertiserOptions?.map((item) => {
                  return (
                    <li key={item.account_id} className="item">
                      <Space>
                        <Typography.Text
                          style={{ width: "100%" }}
                          ellipsis={{ tooltip: item?.account_name }}
                        >
                          <Link
                            onClick={(e) => {
                              onAdvertiseSelect(e, item);
                            }}
                            style={{ fontSize: 12 }}
                            className={
                              selectAccountId === item.account_id ? "live" : ""
                            }
                            disabled={selectAccountId === item.account_id}
                          >
                            {item?.account_name}
                          </Link>
                        </Typography.Text>
                      </Space>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <Spin spinning={spinning} />
          )}
          <Table
            rowSelection={rowSelection}
            components={components}
            size="small"
            sticky
            dataSource={list}
            columns={getColumns({
              updatePlanStatus,
              deletePlan,
              switchover,
              showTimeDivision,
              showOperateLog,
              handleEable,
              qiliangDetail,
            })}
            loading={loading}
            bordered={true}
            pagination={{
              total: total,
              showSizeChanger: true,
              showQuickJumper: props.total > 50 ? true : false,
              showTotal: (total) => `共 ${total} 条`,
              pageSizeOptions: [10, 15, 20, 30],
              current: page || 1,
              onChange: onShowChange,
              pageSize: perPage,
            }}
            onChange={onTableChange}
            scroll={{ x: 500 }}
            summary={(pageData) => {
              return (
                <>
                  {pageData && pageData.length > 0 ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={5} align="center">
                        <Text type="danger" strong={true}>
                          汇总
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} align="center">
                        <Text>-</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} align="center">
                        <Text>-</Text>
                      </Table.Summary.Cell>
                      {pathKey.split("/").includes("theater") ? (
                        <>
                          <Table.Summary.Cell index={7} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8} align="center">
                            <Text>{accountSummary?.cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={9} align="center">
                            <Text>{accountSummary?.money}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={10} align="center">
                            <Text>{accountSummary?.total_rate}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={11} align="center">
                            <Text>{accountSummary?.ad_cash}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={12} align="center">
                            <Text>{accountSummary?.total_ad_cash_rate}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={13} align="center">
                            <Text>{accountSummary?.real_convert_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={14} align="center">
                            <Text>{accountSummary?.convert_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={15} align="center">
                            <Text>{accountSummary?.convert}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={16} align="center">
                            <Text>{accountSummary?.convert_rate}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={17} align="center">
                            <Text>{accountSummary?.upnums}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={18} align="center">
                            <Text>{accountSummary?.active}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={19} align="center">
                            <Text>{accountSummary?.active_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={20} align="center">
                            <Text>{accountSummary?.active ? numDiv(accountSummary?.ad_cash, accountSummary?.active).toFixed(2) : 0}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={21} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={22} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={23} align="center">
                            <Text>{accountSummary?.show}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={24} align="center">
                            <Text>{accountSummary?.click}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={25} align="center">
                            <Text>{accountSummary?.ctr}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={26} align="center">
                            <Text>{accountSummary?.avg_click_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={27} align="center">
                            <Text>{accountSummary?.avg_show_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={28} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={29} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={30} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={31} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={32} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={33} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                        </>
                      ) : (
                        <>
                          <Table.Summary.Cell index={7} align="center">
                            <Text>{accountSummary?.cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8} align="center">
                            <Text>{accountSummary?.real_convert_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={9} align="center">
                            <Text>{accountSummary?.convert_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={10} align="center">
                            <Text>{accountSummary?.convert}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={11} align="center">
                            <Text>{accountSummary?.convert_rate}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={12} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={13} align="center">
                            <Text>{accountSummary?.upnums}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={14} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={15} align="center">
                            <Text>{accountSummary?.show}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={16} align="center">
                            <Text>{accountSummary?.click}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={17} align="center">
                            <Text>{accountSummary?.ctr}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={18} align="center">
                            <Text>{accountSummary?.avg_click_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={19} align="center">
                            <Text>{accountSummary?.avg_show_cost}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={20} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={21} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={22} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={23} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={24} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={25} align="center">
                            <Text>-</Text>
                          </Table.Summary.Cell>
                        </>
                      )}
                    </Table.Summary.Row>
                  ) : (
                    ""
                  )}
                </>
              );
            }}
          />
        </Card>
      </Card>

      {/* 弹窗显示分时曲线 */}
      <Modal
        open={modalShow}
        onCancel={modalClose}
        title="分时消耗数据"
        maskClosable={false}
        confirmLoading={confirmLoading}
        footer={null}
        width={1000}
      >
        <Divider />
        <ChartDualAxes
          data={[divisionCost, divisionCost]}
          xField="time_hour"
          yField={["cost", "convert"]}
          meta={{
            cost: {
              alias: "消耗",
            },
            convert: {
              alias: "转化",
            },
          }}
          geometryOptions={[
            {
              geometry: "line",
              color: "#5B8FF9",
            },
            {
              geometry: "line",
              color: "#FFD546",
            },
          ]}
          xAxis={{
            label: {
              offsetX: 10,
              offsetY: 5,
              style: {
                // fill: '#5A8DF6',
                fontSize: 10,
                margin: 10,
              },
            },
          }}
        />
      </Modal>

      {/* 批量修改出价弹窗 */}
      <Modal
        open={bidModalShow}
        onCancel={bidModalClose}
        title={
          <Space>
            <div>批量修改出价</div>
            <div>{`（已选择${selectedRowKeys.length}条广告）`}</div>
          </Space>
        }
        size="small"
        onOk={handleBatchUpdate}
        confirmLoading={confirmLoading}
      >
        <Form form={bidForm}>
          <Form.Item name="bid">
            <Input
              allowClear
              placeholder="请输入"
              suffix="元"
              onChange={bidInputChange}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* 操作日志 */}
      <Modal
        open={logModalShow}
        onCancel={logModalClose}
        title="操作日志"
        maskClosable={false}
        confirmLoading={confirmLoading}
        footer={null}
        width={1000}
      >
        <Table
          size="small"
          sticky
          dataSource={logList}
          columns={adLogColumns()}
          loading={loading}
          pagination={false}
          bordered={true}
          scroll={{ x: 500 }}
        />
        ;
      </Modal>

      {/* 启用一键起量 */}
      <Drawer
        title={
          <div>
            起量设置{" "}
            {qiliangType === "batch"
              ? "(已选择" + selectedRowKeys.length + "条广告)"
              : ""}
          </div>
        }
        placement="right"
        width={500}
        onClose={onMeasureClose}
        open={measureShow}
        footer={
          <Card style={{ textAlign: "right" }}>
            <Space>
              <Button onClick={onMeasureClose}>取消</Button>
              <Button
                type="primary"
                onClick={measureFormSubmit}
                loading={measureLoading}
              >
                保存
              </Button>
            </Space>
          </Card>
        }
      >
        <Card>
          <Form
            layout="vertical"
            form={measureForm}
            initialValues={{
              items: [{}],
            }}
          >
            <Form.Item hidden name="advertiser_id">
              <Input />
            </Form.Item>
            <Form.Item hidden name="promotion_id">
              <Input />
            </Form.Item>
            {raiseStatus === "RAISING" ? (
              <Card
                size="small"
                style={{ padding: 8, paddingBottom: 0, marginBottom: 10 }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: -15,
                    marginBottom: 10,
                  }}
                >
                  起量中
                </div>
                <Form.Item label="起量预算">
                  <Input
                    disabled
                    value={
                      (qiliangItems[0] &&
                        parseFloat(qiliangItems[0]?.raise_budget)) ||
                      0
                    }
                  />
                </Form.Item>
                <Form.Item label="预计结束时间">
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    value={dayjs(qiliangItems[0]?.end_time)}
                    disabled
                  />
                </Form.Item>
              </Card>
            ) : (
              ""
            )}
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      title={`起量方案 ${field.name + 1}`}
                      key={field.key}
                      extra={
                        qiliangItems[field.name] === undefined ? (
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : (
                          ""
                        )
                      }
                      bodyStyle={{ paddingBottom: 0 }}
                      style={{ marginBottom: -10 }}
                    >
                      <Form.Item
                        label="起量预算"
                        name={[field.name, "raise_budget"]}
                        rules={[{ required: true }]}
                      >
                        <Form.Item
                          name={[field.name, "raise_budget"]}
                          style={{ marginBottom: 0 }}
                        >
                          <Input placeholder="请输入起量预算" allowClear />
                        </Form.Item>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: 12 }}
                        >
                          该广告预算：9999999.00元，建议设置预算为110.00元，可为您带来最佳增量效果。
                        </Typography.Text>
                      </Form.Item>
                      <Form.Item
                        label="生效时间"
                        name={[field.name, "is_effective_now"]}
                        rules={[{ required: true }]}
                        initialValue={raiseStatus === "RAISING" ? false : true}
                        hidden={raiseStatus === "RAISING"}
                      >
                        <Radio.Group
                          onChange={(e) => onTypeChange(e, field.name)}
                          options={[
                            { label: "立即生效", value: true },
                            { label: "指定时间", value: false },
                          ]}
                          optionType="button"
                          disabled={
                            raiseStatus === "RAISING" &&
                            Object.keys(qiliangItems).includes(
                              field.name?.toString()
                            )
                          }
                        />
                      </Form.Item>
                      {startType.map((item) => {
                        if (
                          JSON.stringify(Object.keys(item)) ===
                            JSON.stringify([`type-${field.name}`]) &&
                          item[[`type-${field.name}`]] === false
                        ) {
                          return (
                            <Fragment key={[field.name, "cycle_key"]}>
                              <Form.Item
                                label="重复周期"
                                name={[field.name, "cycle"]}
                                initialValue={"only"}
                                rules={[{ required: true }]}
                                key={[field.name, "key"]}
                              >
                                <Radio.Group
                                  options={[{ label: "仅一次", value: "only" }]}
                                  optionType="button"
                                />
                              </Form.Item>
                              <Form.Item
                                label="起量时间"
                                name={[field.name, "raise_time"]}
                                rules={[{ required: true }]}
                                key={[field.name, "raise_time"]}
                                initialValue={dayjs().add(1, "hour")}
                              >
                                <DatePicker
                                  showTime
                                  format="YYYY-MM-DD HH:mm"
                                  disabledDate={disabledDate}
                                />
                              </Form.Item>
                            </Fragment>
                          );
                        } else {
                          return ''
                        }
                      })}
                    </Card>
                  ))}
                  <Form.Item label colon={false}>
                    <Button onClick={() => add()}>+ 新增起量方案</Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Form>
        </Card>
      </Drawer>

      {/* 一键起量详情 */}
      <Drawer
        title={<div>报告查看</div>}
        placement="right"
        width={700}
        onClose={onMeasureDetailClose}
        open={measureDetailShow}
        size="small"
      >
        {qiliangLoading ? (
          <Spin />
        ) : qiliangVersions?.length > 0 ? (
          <>
            <Card size="small">
              <Form layout="vertical" form={measureDetailForm}>
                <Form.Item hidden name="advertiser_id">
                  <Input />
                </Form.Item>
                <Form.Item hidden name="ad_id">
                  <Input />
                </Form.Item>
                <Form.Item label="起量报告" name="raise_version">
                  <Select
                    allowClear
                    placeholder="选择查看报告时间段"
                    style={{ width: "65%" }}
                    onChange={onQiliangTimeChange}
                    options={qiliangVersions}
                  />
                </Form.Item>
                <Form.Item label="总预算">
                  <div
                    style={{ fontWeight: "bold", fontSize: 30, marginTop: -10 }}
                  >
                    {parseFloat(qiliangData?.budget) > 0
                      ? qiliangData?.budget?.toFixed(2)
                      : 0}
                  </div>
                  <Form.Item style={{ marginBottom: -10 }}>
                    <Row gutter={16}>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>消耗</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.stat_cost !== 0
                              ? (
                                  (qiliangData?.incremental?.stat_cost /
                                    qiliangData?.base?.stat_cost) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(qiliangData?.base?.stat_cost) <
                              parseFloat(qiliangData?.incremental?.stat_cost) &&
                            parseFloat(qiliangData?.incremental?.stat_cost) >
                              0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础消耗 {qiliangData?.base?.stat_cost.toFixed(2)}
                              元
                            </li>
                            <li>
                              起量消耗
                              {qiliangData?.incremental?.stat_cost.toFixed(2)}元
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>展示</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.show_cnt !== 0
                              ? (
                                  (qiliangData?.incremental?.show_cnt /
                                    qiliangData?.base?.show_cnt) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(qiliangData?.base?.show_cnt) <
                              parseFloat(qiliangData?.incremental?.show_cnt) &&
                            parseFloat(qiliangData?.incremental?.show_cnt) >
                              0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础展示 {qiliangData?.base?.show_cnt}
                            </li>
                            <li>
                              起量展示 {qiliangData?.incremental?.show_cnt}
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>点击</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.click_cnt !== 0
                              ? (
                                  (qiliangData?.incremental?.click_cnt /
                                    qiliangData?.base?.click_cnt) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(qiliangData?.base?.click_cnt) <
                              parseFloat(qiliangData?.incremental?.click_cnt) &&
                            parseFloat(qiliangData?.incremental?.click_cnt) >
                              0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础点击 {qiliangData?.base?.click_cnt}
                            </li>
                            <li>
                              起量点击 {qiliangData?.incremental?.click_cnt}
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>转化(计费时间)</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.attribution_convert_cnt !== 0
                              ? (
                                  (qiliangData?.incremental
                                    ?.attribution_convert_cnt /
                                    qiliangData?.base
                                      ?.attribution_convert_cnt) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(
                              qiliangData?.base?.attribution_convert_cnt
                            ) <
                              parseFloat(
                                qiliangData?.incremental
                                  ?.attribution_convert_cnt
                              ) &&
                            parseFloat(
                              qiliangData?.incremental?.attribution_convert_cnt
                            ) > 0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础转化
                              {qiliangData?.base?.attribution_convert_cnt}
                            </li>
                            <li>
                              起量转化
                              {
                                qiliangData?.incremental
                                  ?.attribution_convert_cnt
                              }
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Card>
            {detailRaiseStatus &&
            (detailRaiseStatus === "RAISING" ||
              detailRaiseStatus === "HAS_PRERAISE") ? (
              <div
                style={{
                  textAlign: "right",
                  marginTop: 50,
                  border: "1px solid #F0F0F0",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <Button
                  type="primary"
                  onClick={handleQiliangStop}
                  loading={measureDetailLoading}
                >
                  关闭起量
                </Button>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <Empty
            description={
              <div style={{ marginTop: 20 }}>
                <div style={{ fontSize: 12 }}>友情提示</div>
                <div style={{ fontSize: 12, color: "#9B9EA4" }}>
                  暂无起量报告数据，请在起量进行中或起量结束后查看数据报告
                </div>
              </div>
            }
          />
        )}
      </Drawer>
    </>
  );
}

export default connect((state) => ({ user: state.user, menu: state.menu }), {
  saveMenu,
})(Index);
