import { Col, Row } from "antd"

const columns = () => [
    {
        title: "创意、广告主、计划、投手",
        width: 250,
        align: "left",
        dataIndex: "advertiser_id_info",
        key: "advertiser_id_info",
        render: (_,record) => {
            return <Row justify="start">
                <Col span={24}>创意ID: {record?.creative_id}</Col>
                <Col span={24}>广告主: {record?.advertiser?.account_name}</Col>
                <Col span={24}>计划ID: {record?.ad_id}</Col>
                <Col span={24}>投手: {record?.user?.nickname}</Col>
            </Row>
        }
    },
    {
        title: "消耗",
        width: 100,
        align: "center",
        dataIndex: "cost",
        key: "cost",
        sorter: {},
    },
    {
        title: "展示数",
        width: 100,
        align: "center",
        dataIndex: "show",
        key: "show",
        sorter: {},
    },
    {
        title: "点击数",
        width: 100,
        align: "center",
        dataIndex: "click",
        key: "click",
        sorter: {},
    },
    {
        title: "点击率",
        width: 100,
        align: "center",
        dataIndex: "ctr",
        key: "ctr",
        sorter: {},
    },
    {
        title: "平均点击单价",
        width: 120,
        align: "center",
        dataIndex: "avg_click_cost",
        key: "avg_click_cost",
        sorter: {},
    },
    {
        title: "平均千次展现费用",
        width: 120,
        align: "center",
        dataIndex: "avg_show_cost",
        key: "avg_show_cost",
        sorter: {},
    },
    {
        title: "转化数",
        width: 100,
        align: "center",
        dataIndex: "convert",
        key: "convert",
        sorter: {},
    },
    {
        title: "转化率",
        width: 100,
        align: "center",
        dataIndex: "convert_rate",
        key: "convert_rate",
        sorter: {},
    },
    {
        title: "平均转化成本",
        width: 120,
        align: "center",
        dataIndex: "convert_cost",
        key: "convert_cost",
        sorter: {},
    },
]

export default columns