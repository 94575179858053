import { postRequest } from "./request"

// 组织架构树形列表
export const getOrganizationTree = data => postRequest('/admin/organization/tree_list', data)

// 组织架构分页列表
export const getOrganizationList = data => postRequest('/admin/organization', data)

// 组织架构添加
export const addOrganization = data => postRequest('/admin/organization/add', data)

// 组织架构编辑
export const editOrganization = data => postRequest('/admin/organization/edit', data)

// 组织架构删除
export const deleteOrganization = id => postRequest('/admin/organization/delete', id)

// 角色列表
export const roleList = data => postRequest('/admin/role', data)

// 无分页的角色列表
export const roleAllList = data => postRequest('/admin/role/list', data)

// 添加角色
export const addRole = data => postRequest('/admin/role/add', data)

// 修改角色
export const editRole = data => postRequest('/admin/role/edit', data)

// 删除角色
export const deleteRole = data => postRequest('/admin/role/delete', data)

// 菜单树形列表
export const getMenuTree = data => postRequest('/admin/menu/tree_list', data)

// 获取后台用户列表
export const getUserList = data => postRequest('/admin/user', data)

// 删除用户
export const deleteUser = data => postRequest('/admin/user/delete', data)

// 添加后台用户
export const createUser = data => postRequest('/admin/user/add', data)

// 修改后台用户
export const updateUser = data => postRequest('/admin/user/edit', data)

// 角色分配菜单
export const dispathMenu = data => postRequest('/admin/role/dispatch_menu', data)

// 获取对应授权角色的用户
export const authorizationUser = data => postRequest('/admin/role/authorization_uses', data)

// 待分配角色用户
export const getDispathUser = data => postRequest('/admin/user/role_dispatch', data)

// 分配角色给用户
export const doDispatchUser = data => postRequest('/admin/role/dispatch_user', data)

// 取消用户角色授权
export const doAuthorizationCancel = data => postRequest('/admin/role/cancel_authorization', data)

// 修改菜单 
export const menuUpdate = data => postRequest('/admin/menu/update', data)

// 获取当前登录用户所在的组织架构 (并不获取全部)
export const getActiveOrg = data => postRequest('/admin/organization/active_org', data)

// 获取登录的账户角色组织
export const getRoleOrgan = data => postRequest('/admin/role/organ', data)

// 获取筛选的投手
export const getFilterPitchers = data => postRequest('/admin/user/filter_pitcher', data)