import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React from "react";
import { useState } from "react";
import Table from "../../../components/TableNoSelection";
import { useEffect } from "react";
import columns from "./columns";
import {
  getFilterSuCai,
  getFilterTouShou,
  materialDailyCost,
  materialDailyCostTotal,
} from "../../../api/advertise";
import { useLocation } from "react-router-dom";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Index() {
  const [searchType, setSearchType] = useState(1);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [suCaiOptions, setSuCaiOptions] = useState([]);
  const [costTotal, setCostTotal] = useState(-1);

  // 当前的路径
  const pathKey = useLocation().pathname;

  useEffect(() => {
    getList();
    getToushou();
    getSuCaiShou();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathKey]);

  // 列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    if (pathKey.split("/").includes("novel")) {
      params.type = "novel";
    } else {
      params.type = "playlet";
    }
    const res = await materialDailyCost(params);
    const { data } = res;
    if (res?.code === 200) {
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  const getDialyCostTotal = async (params = {}) => {
    const res = await materialDailyCostTotal(params);
    const { data } = res;
    if (res?.code === 200) {
      setCostTotal(data?.total_cost);
    }
  };

  // 获取投手数据
  const getToushou = async () => {
    const res = await getFilterTouShou();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists
      const result = list?.map((item) => {
        return { label: item.nickname, value: item.nickname };
      });
      setPitcherOptions(result);
    }
  };

  // 获取素材手筛选数据
  const getSuCaiShou = async (params = {}) => {
    const res = await getFilterSuCai(params);
    const { data } = res;
    if (res?.code === 200) {
      let list = data?.lists;
      const result = list?.map((item) => {
        return { label: item.nickname, value: item.nickname };
      });
      setSuCaiOptions(result);
    }
  };

  const onChange = (value) => {
    setSearchType(value);
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange}>
      <Option value={1}>素材名称</Option>
      <Option value={2}>素材ID</Option>
    </Select>
  );

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPageSize(size);
    let params = { ...conditions, page, pageSize: size };
    setConditions(params);
    getList(params);
  };

  // 条件查询
  const onHandleSearch = (values) => {
    let createdAt;
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = createdAt;
    }
    let submit = false;
    for (const key in values) {
      if (values.hasOwnProperty.call(values, key)) {
        const element = values[key];
        if (element) {
          submit = true;
        }
      }
    }
    let params = { ...values };
    setConditions(params);
    getList(params);
    getSuCaiShou(params);
    if (submit) {
      getDialyCostTotal(params);
    } else {
      setCostTotal(-1);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  return (
    <Card
      title={
        <Form onFinish={onHandleSearch} labelCol={{ span: 6 }}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="投手" name="toushou">
                <Select
                  placeholder="投手筛选"
                  allowClear
                  showSearch
                  options={pitcherOptions}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="上传者" name="sucaishou">
                <Select
                  placeholder="上传者筛选"
                  allowClear
                  showSearch
                  options={suCaiOptions}
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={searchType === 1 ? "title" : "material_id"}>
                <Input
                  placeholder={searchType === 1 ? "输入素材名称" : "输入素材ID"}
                  addonBefore={selectBefore}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="投放日期筛选" name="range_date">
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="有效素材筛选" name="is_effectivity">
                <Select
                  options={[
                    { label: "是", value: 1 },
                    { label: "否", value: 2 },
                  ]}
                  allowClear
                  placeholder="筛选有效素材"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button>重置</Button>
              </Space>
            </Col>
            {costTotal >= 0 ? (
              <Col span={10}>
                <Typography.Text type="danger">
                  总消耗: {costTotal}
                </Typography.Text>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Form>
      }
      styles={{ header: { marginTop: 10 } }}
    >
      {/* <div style={{ marginBottom: 10, marginTop: -10 }}>
        <Button type="primary" ghost onClick={exportExcel}>
          导出
        </Button>
      </div> */}
      <Table
        dataSource={list}
        columns={columns()}
        total={total}
        page={page}
        perPage={pageSize}
        loading={loading}
        pagesize={pagesize}
      />
    </Card>
  );
}
