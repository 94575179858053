import { postRequest } from "./request"

// 获取小程序资产列表
export const getAssetList = data => postRequest('/admin/advertise_assets/list', data)

// 获取小程序资产获取已审核通过的所有账户
export const getAdvertiserIdsFromAsset = data => postRequest('/admin/advertise_assets/advertiser_ids', data)

// 获取小程序筛选的选项列表
export const getAppletsPlat = data => postRequest('/admin/advertise_assets/applets_plat', data)

// 获取落地页图片
export const getLandingImages = data => postRequest('/admin/advertise_assets/get_landing_img', data)

// 创建落地页时保存图片
export const saveLandingImages = data => postRequest('/admin/advertise_assets/save_landing_img', data)

// 小程序资产共享
export const assetShare = data => postRequest('/admin/advertise_assets/share', data)

// 小程序资产创建
export const assetCreate = data => postRequest('/admin/advertise_assets/create_assets', data)

// 获取落地页列表
export const getLandingList = data => postRequest('/admin/advertise_assets/landing_list', data)

// 获取任务列表
export const getTaskList = data => postRequest('/admin/advertise_assets/task_list', data)

// 获取资产事件列表
export const getEventList = data => postRequest('/admin/advertise_assets/event_list', data)

// 小程序资产同步
export const appletAsyncPull = data => postRequest('/admin/advertise_assets/async_pull', data)

// 根据搜索短剧关键获取推广链接列表
export const getPromotionList = data => postRequest('/admin/advertise_assets/promotion_list', data)

// 落地页保存水印图片
export const saveWaterImage = data => postRequest('/admin/advertise_assets/save_water_image', data)
