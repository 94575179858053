import { Button, Space } from "antd"

const columns = ({handleEdit, handleDel}) => [
    {
        title: "剧场名称",
        width: 100,
        align: "center",
        dataIndex: "playlet_name",
        key: "playlet_name",
    },
    {
        title: "规则说明",
        width: 500,
        dataIndex: "desc",
        key: "desc",
        render: (value) => {
            let data = JSON.parse(value)
            return <ul>
                {
                    data ? data.map((val, key) => {
                        return <li key={key}>{val}</li>
                    }) : ''
                }
            </ul>
            
        }
    },
    {
        title: "操作",
        width: 120,
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Space>
                <Button type="primary" onClick={() => handleEdit(record)}>编辑</Button>
                <Button type="primary" danger onClick={() => handleDel(record)}>删除</Button>
            </Space>
        )
    },
]

export default columns