
//引入combineReducers，用于汇总多个reducer
import {combineReducers} from "redux";

//引入为Login组件服务的reducer
import login from './login'

//引入为LeftNav组件服务的reducer
import menu from './menu'

//引入为LeftNav组件服务的reducer
import message from './message'

//引入为Admin组件（菜单）服务的reducer
// import admin from './admin'

//汇总所有的reducer，变为一个总的reducer
export default combineReducers({
    user:login,
    menu,
    message,
    // admin
})
