import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";
import { useState } from "react";
import columns from "./columns";
import { getAgentData, materialUserCostReal } from "../../../api/advertise";
import { useEffect } from "react";

const { Text } = Typography;
const { RangePicker } = DatePicker;

export default function Index() {
  const [list, setList] = useState([]);
  const [materialOptions, setMaterialOptioins] = useState([]);
  const [agentOptions, setAgentOptioins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuper, setIsSuper] = useState(false);

  useEffect(() => {
    getList();
    getAgents();
  }, []);

  const [form] = Form.useForm();

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await materialUserCostReal(params);
    if (res?.code === 200) {
      const { data } = res;
      let newList = data?.lists?.map((item) => {
        item.key = item.id;
        return item;
      });
      setIsSuper(data?.is_super);
      setList(newList);
      let options = data?.materials?.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setMaterialOptioins(options);
      setLoading(false);
    }
  };

  // 获取代理商数据
  const getAgents = async () => {
    const res = await getAgentData();
    if (res?.code === 200) {
      const { data } = res;
      let optioins = data.map((item) => {
        return { label: item.name, value: item.name };
      });
      setAgentOptioins(optioins);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 表单筛选查询
  const onFormSubmit = () => {
    let values = form.getFieldsValue();
    let rangeDate;
    if (values?.dates) {
      rangeDate = values?.dates.map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.dates;
      values.range_date = rangeDate;
    }
    getList(values);
  };

  return (
    <Card
      title={
        <Form layout="inline" form={form}>
          {isSuper ? (
            <Form.Item label="代理商" name="agent_name">
              <Select
                allowClear
                placeholder="选择代理商"
                options={agentOptions}
                size="small"
                style={{ width: 150 }}
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item label="素材手" name="su_cai_user_id">
            <Select
              allowClear
              placeholder="选择素材手"
              showSearch
              filterOption={filterOption}
              options={materialOptions}
              size="small"
              style={{ width: 150 }}
            />
          </Form.Item>
          <Form.Item label="日期" name="dates">
            <RangePicker />
          </Form.Item>
          <Form.Item label colon={false}>
            <Space>
              <Button type="primary" onClick={onFormSubmit}>
                确定
              </Button>
              <Button onClick={() => form.resetFields()}>重置</Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Table
        sticky
        dataSource={list}
        columns={columns()}
        loading={loading}
        pagination={false}
        summary={(pageData) => {
          let totalCost = 0;
          let totalShow = 0;
          let totalClick = 0;
          let totalConvert = 0;
          pageData.forEach(({ cost, click, show, convert }) => {
            totalCost += parseFloat(cost);
            totalClick += parseInt(click);
            totalShow += parseInt(show);
            totalConvert += parseInt(convert);
          });
          return (
            <>
              {pageData && pageData.length > 0 ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2} align="center">
                    <Text type="danger" strong={true}>
                      汇总
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <Text>{totalCost.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Text>{totalClick}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <Text>{totalConvert}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <Text>{totalShow}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </Card>
  );
}
