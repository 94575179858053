import { Button, Space, Tooltip, message } from "antd";
import dayjs from "dayjs";
import CopyToClipboard from "react-copy-to-clipboard";

const columns = ({backSet}) => [
    {
        title: "备注",
        width: 120,
        align: "center",
        dataIndex: "title",
        key: "title",
        ellipsis: true,
    },
    {
        title: "外链ID",
        width: 120,
        align: "center",
        dataIndex: "promotion_id",
        key: "promotion_id",
    },
    {
        title: "推广链接地址",
        width: 265,
        align: "center",
        dataIndex: "promotion_url",
        key: "promotion_url",
        ellipsis: true,
        render: (value, record) => {
            let idArr = record.id.split('@@##')
            let jianceUrl = `https://jiance.shuyueyun.cn/admin/advertise/eventclick?clickid=__CLICK_ID__&adid=__PROMOTION_ID__&ip=__IP__&ua=__UA__&__&os=__OS__&TIMESTAMP=__TS__&callback=__CALLBACK_PARAM__&uid=${record.admin_id || record.uid}&promotion_id=${idArr[0] || 0}&app_type=${record?.app_type}`
            return <>
                <Tooltip title={value} >{value}</Tooltip>
                <div>
                    <CopyToClipboard text={value} onCopy={() => {
                            message.success('复制成功')
                        }}>
                        <Button>复制推广链接</Button>
                    </CopyToClipboard>

                    <CopyToClipboard style={{marginLeft: 5}} text={jianceUrl} onCopy={() => {
                            message.success('复制成功')
                        }}>
                        <Button>复制监测链接</Button>
                    </CopyToClipboard>
                </div>
            </>
        }
    },
    {
        title: "投放小说",
        width: 120,
        align: "center",
        dataIndex: "book_name",
        key: "book_name",
        ellipsis: true,
    },
    {
        title: "类型",
        width: 120,
        align: "center",
        dataIndex: "type",
        key: "type",
        render: value => (
            parseInt(value) === 1 ? '自己平台投放' : '只同步数据'
        )
    },
    {
        title: "投放目标",
        width: 120,
        align: "center",
        dataIndex: "external_action",
        key: "external_action",
        render: value => (
            value === 'AD_CONVERT_TYPE_PAY' ? '付费 (IAP)' : '免费(IAA)'
        )
    },
    {
        title: "记录创建时间",
        width: 160,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "操作",
        width: 180,
        align: "center",
        dataIndex: "operation",
        key: "operation",
        render: (_, record) => (
            <Space>
                {/* <Button type="primary" ghost>付费趋势</Button> */}
                <Button type="primary" onClick={() => backSet(record)}>卡回传设置</Button>
                {/* <Popover
                    placement="top"
                    content={
                        <Button type="primary" ghost>修改推广链接</Button>
                    }
                >
                    <MoreOutlined />
                </Popover> */}
            </Space>
        )
    },
]

export default columns