import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import columns from "./promotionlossColumns";
import {getPromotionCheck} from "../../api/selfcheck";

export default function Monitor(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);

  // 当前路径
  const pathKey = useLocation().pathname;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, [pathKey]);

  // 列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getPromotionCheck(params);
    if (res?.code === 200) {
      const { data } = res;
      let lists = data;
      setList(lists);
      setLoading(false);
    }
  };

  // 修改备注
  const onChange = () => {};

  // 列表条件查询
  const onSearch = async(values) => {
    let date;
    if (values["date"]) {
      date = values["date"].format("YYYYMMDD");
      values.date = date;
    }
    console.log(values,'valuesssss')
    values.advertiser = values.keyword;
    getList(values);
    setConditions(values);
    if (values.advertiser && typeof(values.advertiser) === 'string') {
      // setAccountNums(-1)
      // setPromotionNums(-1)
      // const res = await getPromotionsFromPlaylet({advertiser: values.keyword, date})
      // if (res?.code === 200) {
      //   const {data} = res
      //   setAccountNums(data?.account_nums)
      //   setPromotionNums(data?.promotion_nums)
      // }
    }
    props.onSearch(values);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const switchover = (record, tab) => {
    props.switchover(record, tab);
  };

  // 表单重置
  const formReset = () => {
    form.resetFields();
    props.onSearch([]);
  };


  return (
    <>
      <Card
        title={
          <Form
            form={form}
            onFinish={onSearch}
          >
            <Row gutter={16}>
              <Col span={7}>
                <Form.Item label="订单日期筛选" name="date">
                  <DatePicker size="small" style={{width: '100%'}} />
                </Form.Item> 
              </Col>
              <Col span={4}>
                <Form.Item style={{ marginLeft: 20 }}>
                  <Space>
                    <Button type="primary" htmlType="submit" size="small">
                      确定
                    </Button>
                    <Button onClick={formReset} size="small">
                      重置
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      ghost
                      onClick={() => navigate(-1)}
                    >
                      返回
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
        headStyle={{ marginTop: 10 }}
        bodyStyle={{padding:0}}
        size="small"
      >
        <Table
          sticky
          size="small"
          dataSource={list}
          columns={columns()}
          loading={loading}
          bordered={true}
          scroll={{ x: 500 }}
        />
      </Card>
    </>
  );
}
