import dayjs from "dayjs"

const columns = () => [
    {
        title: "ID",
        width: 150,
        align: "center",
        dataIndex: "book",
        key: "book",
        render: (_, record) => (
            record?.book?.title
        )
    },
    {
        title: "签到时间",
        width: 180,
        align: "center",
        dataIndex: "addtime",
        key: "addtime",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    
]

export default columns