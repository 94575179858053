import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { filterPitchers } from "../../api/statistics";

const Pitchers = ({ unLimit, onChange, value }) => {
  const [pitchers, setPitchers] = useState([]);

  useEffect(() => {
    getPitchers();
  }, []);

  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet", unLimit });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitchers(newList);
    }
  };

  const onChangeValue = (val) => {
    onChange?.(val);
  };

  return (
    <Select
      allowClear
      placeholder="请选择"
      value={value}
      onSelect={onChangeValue}
      onClear={onChangeValue}
      options={pitchers}
      style={{ width: 150 }}
    />
  );
};

export default Pitchers;
