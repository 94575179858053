import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  message,
  Modal,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";
import { useState } from "react";
import columns from "./columns";
import { useEffect } from "react";
import { getRecycleStatistics, recycleTimerDivision } from "../../../../api/statistics";
import { numDiv, numMulti } from "../../../../util/calculate";
import { useLocation, useNavigate } from "react-router";
import { getMaterialGroup } from "../../../../api/advertise";
import ChartLine from "../../../../components/ChartLine";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [isSuper, setIsSuper] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [modalShow, setModalShow] = useState(false)
  const [divisionData, setDivisionData] = useState([]);

  const { state } = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    getList();
    getOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 组织架构数据
  const getOrganization = async (params = {}) => {
    const res = await getMaterialGroup({ ...params, tag: 'pitcher' });
    if (res?.code === 200) {
      const { data } = res;
        let lists = data?.lists?.map((item) => {
            return {label: item?.name, value: item?.org_id}
        })
        setOrganizationOptions(lists)
    }
  };

  // 列表统计数据
  const getList = async (params = {}) => {
    params.type = "playlet";
    setLoading(true);
    if (state) {
      params.org_id = state?.org_id;
    }
    const res = await getRecycleStatistics(params);
    const { data } = res;
    if (res?.code === 200) {
      setLoading(false);
      let lists = data?.lists;
      const newList = lists?.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newList);
      setIsSuper(data?.is_super);
      setIsAgent(data?.is_agent);
    }
  };

  // 日期范围查询
  const onSubmit = (values) => {
    let createdAt = [];
    if (values["range_date"]) {
      createdAt = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.range_date;
    }
    let params = { ...conditions, ...values, range_date: createdAt };
    getList(params);
    setConditions(params);
  };

  // 表单重置
  const onReset = () => {
    form.resetFields();
    navigate("/admin/statistics/theater/push_plt/recycle_plt", { state: null });
  };

  // 打开分时消耗时速弹窗
  const showEstimateDetail = async (adminId) => {
    let rangeDate = conditions?.range_date
    let day
    if (rangeDate) {
      const uniqueArray = [...new Set(rangeDate)];
      if (uniqueArray.length > 1) {
        message.error('跨日期范围不支持查看消耗时速详情')
        return false
      }
      day = rangeDate[0]
    } else {
      // 当前日期
      day = dayjs().format('YYYYMMDD')
    }
    setModalShow(true)
    const res = await recycleTimerDivision({admin_id: adminId, day})
    if (res?.code === 200) {
      const {data} = res
      let lists = data?.lists
      let newList = lists.map((item) => {
        item.cost = parseFloat(item.cost)
        return item
      })
      setDivisionData(newList)
    }
  }

  // 分时消耗时速弹窗关闭
  const modalClose = () => {
    setModalShow(false)
  } 

  return (
    <div className="recycle">
      <Card
        title={
          <Form
            layout="inline"
            onFinish={onSubmit}
            form={form}
            initialValues={{
              org_id: state?.org_id,
            }}
          >
            {isSuper ? (
              <Form.Item label="组织架构" name="org_id">
                <Select
                  options={organizationOptions}
                  style={{ width: 150 }}
                  allowClear
                  placeholder="选择组织架构"
                />
              </Form.Item>
            ) : (
              ""
            )}
            {/* <Form.Item label="广告类型" name="ad_type">
              <Select
                style={{ width: 150 }}
                options={[
                  { label: "头条", value: 1 },
                  { label: "adp", value: 5 },
                ]}
                allowClear
                placeholder="选择广告类型"
              />
            </Form.Item> */}
            <Form.Item name="range_date">
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  确定
                </Button>
                <Button onClick={onReset}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
      >
        <Table
          sticky
          dataSource={list}
          columns={columns({ isAgent, isSuper, showEstimateDetail })}
          bordered={true}
          pagination={false}
          loading={loading}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalCost = 0;
            let totalMoneyCount = 0;
            let totalPayNum = 0;
            let totalZaiPlan = 0;
            let totalVerifyPlan = 0;
            let totalAllPlan = 0;
            let totalAccountCount = 0;
            pageData.forEach(
              ({
                cost,
                money,
                hui_shou_lv,
                pay_user,
                pay_cost,
                per_money,
                ad_count,
                success_plan,
                all_plan,
                account_count,
              }) => {
                totalCost += parseFloat(cost);
                totalMoneyCount += parseFloat(money);
                totalPayNum += parseFloat(pay_user);
                totalZaiPlan +=
                  ad_count === "-" ? 0 : parseFloat(ad_count);
                totalVerifyPlan += success_plan === "-" ? 0 : parseFloat(success_plan);
                totalAllPlan += all_plan === "-" ? 0 : parseFloat(all_plan);
                totalAccountCount +=
                  account_count === null ? 0 : parseInt(account_count);
              }
            );
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={2} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text>{totalCost.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{totalMoneyCount.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>
                        {parseInt(totalCost)
                          ? numMulti(
                              numDiv(totalMoneyCount, totalCost),
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>{totalPayNum}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>
                        {totalPayNum > 0
                          ? numDiv(totalCost, totalPayNum).toFixed(2)
                          : "0.00"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>
                        {totalPayNum > 0
                          ? numDiv(totalMoneyCount, totalPayNum).toFixed(2)
                          : "0.00"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>{totalZaiPlan}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align="center">
                      <Text>{totalVerifyPlan}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align="center">
                      <Text>{totalAllPlan}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align="center">
                      <Text>{totalAccountCount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12} align="center">
                      <Text>---</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={13} align="center">
                      <Text>---</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>

      {/* 弹窗显示分时消耗时速 */}
      <Modal 
        open={modalShow}
        onCancel={modalClose}
        title="分时消耗时速"
        maskClosable={false}
        footer={null}
        width={1000}
      >
        <Divider />
        <ChartLine 
           data={divisionData}
           xField="time"
           yField="cost"
          //  seriesField="day"
        />
      </Modal>
    </div>
  );
}
