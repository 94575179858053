const columns = () => [
  {
    title: "日期",
    width: 90,
    align: "center",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "短剧名称",
    width: 130,
    align: "center",
    dataIndex: "book_name",
    key: "book_name",
  },
  {
    title: "投手",
    width: 80,
    align: "center",
    dataIndex: "nickname",
    key: "nickname",
  },
  {
    title: "新建广告数",
    width: 100,
    align: "center",
    dataIndex: "ad_count",
    key: "ad_count",
    sorter: (a, b) => a.ad_count - b.ad_count,
    sortDirections: ["descend"],
  },
  {
    title: "新建广告账户数",
    width: 100,
    align: "center",
    dataIndex: "account_count",
    key: "account_count",
    sorter: (a, b) => a.account_count - b.account_count,
    sortDirections: ["descend"],
  },
  {
    title: "在消耗广告数",
    width: 100,
    align: "center",
    dataIndex: "active_ad_count",
    key: "active_ad_count",
    sorter: (a, b) => a.active_ad_count - b.active_ad_count,
    sortDirections: ["descend"],
  },
  {
    title: "在消耗账户数",
    width: 100,
    align: "center",
    dataIndex: "active_account_count",
    key: "active_account_count",
    sorter: (a, b) => a.active_account_count - b.active_account_count,
    sortDirections: ["descend"],
  },
  {
    title: "消耗",
    width: 100,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => a.cost - b.cost,
    sortDirections: ["descend"],
    render: (value) => (
      value || 0
    )
  },
  {
    title: "回收",
    width: 100,
    align: "center",
    dataIndex: "money",
    key: "money",
    sorter: (a, b) => a.money - b.money,
    sortDirections: ["descend"],
    render: (value) => (
      value || 0
    )
  },
  {
    title: "回收率",
    width: 100,
    align: "center",
    dataIndex: "huishoulv",
    key: "huishoulv",
    render: (value) => (
      value || 0
    )
  },
];

export default columns;
