import React, { useEffect, useState } from "react";
import { Card, Form, DatePicker, Select, Button, Spin } from "antd";
import {
  DollarOutlined,
  ThunderboltOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import "./index.less";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import {
  getBoardPlayletList,
  getBoardPlayletTotalStatistics,
  playletTimerBook,
} from "../../../api/statistics";

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [infoLoading, setInfoLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [playletOptions, setPlayletOptions] = useState([]);
  const [statisticsInfo, setStatisticsInfo] = useState([]);

  useEffect(() => {
    getData()
  }, []);

  // 获取页面数据
  const getData = () => {
    Promise.all([getList(), getBoardInfo(), getPlaylets()])
  }

  // 获取看板统计数据
  const getBoardInfo = async (params = {}) => {
    setInfoLoading(true);
    const res = await getBoardPlayletTotalStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setStatisticsInfo(data);
      setInfoLoading(false);
    }
  };

  const getList = async (params = {}) => {
    setListLoading(true);
    const res = await getBoardPlayletList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
      setListLoading(false);
    }
  };

  // 获取所有的短剧
  const getPlaylets = async () => {
    const res = await playletTimerBook();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.book_name, value: item.book_id };
      });
      setPlayletOptions(newLists);
    }
  };

  // 查询表单提交
  const onSubmit = (values) => {
    let day
    if (values['day']) {
      day = values['day'].format("YYYYMMDD");
    }
    values.day = day
    getBoardInfo(values)
    getList(values);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  return (
    <Card
      title={
        <>
          <Form layout="inline" style={{ marginLeft: 10 }} onFinish={onSubmit}>
            <Form.Item label="当日累计数据" colon={false}></Form.Item>
            <Form.Item
              label={<div style={{ fontWeight: "normal" }}>数据日期</div>}
              name="day"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label={<div style={{ fontWeight: "normal" }}>短剧名称</div>}
              name="book_id"
            >
              <Select
                allowClear
                placeholder="请选择短剧"
                showSearch
                filterOption={filterOption}
                options={playletOptions}
                style={{ width: 150 }}
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
          {infoLoading ? (
            <div className="example">
              <Spin />
            </div>
          ) : (
            <div className="statistics_main">
              <div className="statistics_item">
                <div className="item_icon">{<DollarOutlined />}</div>
                <div className="item_desc">
                  <div>总消耗金额</div>
                  <div className="item_desc_value">
                    {statisticsInfo?.cost ? `￥${statisticsInfo?.cost}` : 0}
                  </div>
                </div>
              </div>
              <div className="statistics_item">
                <div className="item_icon">{<ThunderboltOutlined />}</div>
                <div className="item_desc">
                  <div>总时速</div>
                  <div className="item_desc_value">
                    {statisticsInfo?.total_estimate_cost
                      ? `￥${statisticsInfo?.total_estimate_cost}`
                      : 0}
                  </div>
                </div>
              </div>
              <div className="statistics_item">
                <div className="item_icon">{<DollarOutlined />}</div>
                <div className="item_desc">
                  <div>总充值金额</div>
                  <div className="item_desc_value">
                    {statisticsInfo?.total_money
                      ? `￥${statisticsInfo?.total_money}`
                      : 0}
                  </div>
                </div>
              </div>
              <div className="statistics_item">
                <div className="item_icon">{<TeamOutlined />}</div>
                <div className="item_desc">
                  <div>总充值人数</div>
                  <div className="item_desc_value">
                    {statisticsInfo?.total_user
                      ? `￥${statisticsInfo?.total_user}`
                      : 0}
                  </div>
                </div>
              </div>
              <div className="statistics_item">
                <div className="item_icon">{<ClockCircleOutlined />}</div>
                <div className="item_desc">
                  <div>实时ROI</div>
                  <div className="item_desc_value">
                    {statisticsInfo?.roi_rate
                      ? `${statisticsInfo?.roi_rate}`
                      : 0}
                  </div>
                </div>
              </div>
              <div className="statistics_item">
                <div className="item_icon">{<TransactionOutlined />}</div>
                <div className="item_desc">
                  <div>转化成本</div>
                  <div className="item_desc_value">
                    {statisticsInfo?.convert
                      ? `￥${statisticsInfo?.convert}`
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      }
      style={{ paddingTop: 10 }}
      size="small"
    >
      <Table
        dataSource={list}
        columns={columns()}
        total={total}
        page={page}
        perPage={perPage}
        pagesize={pagesize}
        loading={listLoading}
      />
    </Card>
  );
}
