import dayjs from "dayjs"
import { numSub } from "../../../../util/calculate"

const columns = () => [
    {
        title: "ID",
        width: 80,
        align: "center",
        dataIndex: "order_id",
        key: "order_id",
    },
    {
        title: "推广链接名称",
        width: 120,
        align: "center",
        dataIndex: "promotion_name",
        key: "promotion_name",
        ellipsis: true,
        render: (_, record) => (
            record?.playlet_push_url?.title
        )
    },
    {
        title: "用户ID/OPENID",
        width: 120,
        align: "center",
        dataIndex: "user_id",
        key: "user_id",
    },
    {
        title: "外链ID",
        width: 90,
        align: "center",
        dataIndex: "promotion_id",
        key: "promotion_id",
    },
    {
        title: "染色时间",
        width: 130,
        align: "center",
        dataIndex: "update_promotions_time",
        key: "update_promotions_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "支付时间",
        width: 130,
        align: "center",
        dataIndex: "pay_time",
        key: "pay_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "订单入库时间",
        width: 130,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
        render: value => (
            dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: "延时时间",
        width: 100,
        align: "center",
        dataIndex: "delay_time",
        key: "delay_time",
        render: (_, record) => {
            let diffTime = numSub(parseInt(record?.create_time), parseInt(record?.pay_time)) 
            return parseInt(dayjs.unix(diffTime).format('mm')) +'分钟'
        }
    },
    {
        title: "充值金额",
        width: 80,
        align: "center",
        dataIndex: "amount",
        key: "amount",
        sorter: {},
    },
    {
        title: "广告ID",
        width: 140,
        align: "center",
        dataIndex: "ad_id",
        key: "ad_id",
        ellipsis: true,
    },
    {
        title: "短剧订单号",
        width: 120,
        align: "center",
        dataIndex: "order_num",
        key: "order_num",
        ellipsis: true,
    },
    // {
    //     title: "回传状态",
    //     width: 160,
    //     align: "center",
    //     dataIndex: "is_up",
    //     key: "is_up",
    //     render: value => (
    //         value === 0 ? '未回传或不满足' : '已回传或卡掉'
    //     )
    // },
]

export default columns