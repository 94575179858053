import { Card } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react';
import Advertise from './list'
import Promotion from '../Plan'
import Material from './Material'
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export default function Index() {
    const [activeTabKey, setActiveTabKey] = useState((localStorage.getItem('system_from_ecpm_BY_NAME')) ? 'tab1' : 'tab2');
    const [conditions, setConditions] = useState({})
    const [searchParams] = useSearchParams()
    const {state} = useLocation()
    const navigate = useNavigate()
    let promotionId = searchParams.get('promotion_id')
    let userId = searchParams.get('user_id')

    useEffect(() => {
        setActiveTabKey(state?.tab || ((localStorage.getItem('system_from_ecpm_BY_NAME')) ? 'tab1' : 'tab2'))
    }, [state])

    // 当前的路径
    const pathKey = useLocation().pathname
    
    const tabList = [
        {
            key: 'tab1',
            tab: '账户',
        },
        {
            key: 'tab2',
            tab: '广告',
        },
        {
            key: 'tab3',
            tab: '素材',
        },
    ];

    const switchover = (record, tab) => {
        setActiveTabKey(tab)
        localStorage.setItem('record', JSON.stringify(record))
        setConditions(record)
        navigate(pathKey)
    }

    // 用于保存搜索的条件
    const onSearch = values => {
        setConditions(values)
    }

    const contentList = {
        tab1: <Advertise switchover={switchover} admin_id={state?.admin_id} user_id={userId} onSearch={onSearch} conditions={conditions} playletId={state?.playletId} />,
        tab2: <Promotion switchover={switchover} admin_id={state?.admin_id} user_id={userId} onSearch={onSearch} conditions={conditions} promotionId={promotionId} />,
        tab3: <Material  admin_id={state?.admin_id} user_id={userId} onSearch={onSearch} conditions={conditions}/>,
    };

    const onTabChange = key => {
        setActiveTabKey(key);
        setConditions({})
        localStorage.removeItem('system_from_ecpm_ID');
        localStorage.removeItem('system_from_ecpm_BY_NAME')
        localStorage.removeItem('record')
    }

    return (
        <Card
            size="small"
            tabList={tabList}
            onTabChange={onTabChange}
            activeTabKey={activeTabKey}
            tabProps={{size: 'small'}}
        >
            {/* {
                state ? contentList[state?.tab] 
                :contentList[activeTabKey]
            } */}
            {contentList[activeTabKey]}
        </Card>
    )
}
