import { SAVE_USER_INFO, DELETE_USER_INFO } from "../constant"

// 登录保存用户数据
export const saveUserInfo = userObj => {
    // 保存用户登录信息到localStage
    localStorage.setItem('username', userObj.username)
    localStorage.setItem('shortname', userObj.shortname)
    localStorage.setItem('nickname', userObj.nickname)
    localStorage.setItem('avatar', userObj.avatar)
    return {type: SAVE_USER_INFO, data: userObj}
}

// 退出删除缓存与redux数据
export const deleteUserInfo = () => {
    localStorage.removeItem('username')
    localStorage.removeItem('shortname')
    localStorage.removeItem('nickname')
    localStorage.removeItem('avatar')
    return {type: DELETE_USER_INFO}
}