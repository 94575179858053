import dayjs from "dayjs";

const columns = () => [
  {
    title: "用户ID",
    width: 90,
    align: "center",
    dataIndex: "uid",
    key: "uid",
  },
  {
    title: "用户",
    width: 120,
    align: "center",
    dataIndex: "nickname",
    key: "nickname",
  },
  {
    title: "原书币",
    width: 100,
    align: "center",
    dataIndex: "yscore",
    key: "yscore",
  },
  {
    title: "新书币",
    width: 100,
    align: "center",
    dataIndex: "nscore",
    key: "nscore",
  },
  {
    title: "添加时间",
    width: 180,
    align: "center",
    dataIndex: "addtime",
    key: "addtime",
    render: value => (
        dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    )
  },
];

export default columns;
