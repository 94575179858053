import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image as ImageComponents,
  Input,
  List,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import React, { createRef, useRef, useState } from "react";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getFileNameUUID } from "../../../../lib/AliOss";
import { tosSingleUpload } from "../../../../lib/TosClient";
import { saveLandingImages, saveWaterImage } from "../../../../api/asset";
import "./index.less";
import { promotionPlayletAdd } from "../../../../api";

export default function Index(props) {
  const [show, setShow] = useState(false);
  const [selectedImgId, setSelectedImgId] = useState(0);
  const [imageUrl, setImageUrl] = useState(
    "https://advertiser-material2.tos-cn-beijing.volces.com/uploads/2024-09-13/video/20240912142057_1726215004688_afb0.png"
  );
  const [copyInput, setCopyInput] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [addItemLoading, setAddItemLoading] = useState(false)
  const [landingImageInfo, setLandingImageInfo] = useState();
  const [customPlayletName, setCustomPlayletName] = useState("");

  const ref = createRef();
  const textRef = createRef();
  const inputRef = useRef(null);

  // 添加图片弹窗
  const imageAdd = () => {
    setShow(true);
  };

  // 关闭弹窗
  const closeModal = () => {
    setSelectedImgId(0);
    setConfirmLoading(false);
    setShow(false);
  };

  // 上传图片尺寸限制
  const checkIconWH = (file, width, height) => {
    // 参数分别是上传的file，想要限制的宽，想要限制的高
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = (e) => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function () {
          if (this.width < width || this.height < height) {
            resolve({
              code: 400,
              message: "图片尺寸大小控制为宽大于等于700,高大于等于900",
            });
          } else {
            resolve({ code: 200, message: "success" });
          }
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  };

  // 上传前图片尺寸限制
  const beforeUpload = async (file) => {
    // 只有投放短剧时，才限制主品图片的尺寸大小
    const res = await checkIconWH(file, 700, 900);
    if (res?.code === 400) {
      message.error(res?.message, 2);
      return false;
    }
  };

  // 处理上传图片
  const doHandleUpload = async ({ file }) => {
    const domain = "https://advertiser-material2.tos-cn-beijing.volces.com/";
    let date = dayjs().format("YYYY-MM-DD");
    let uuid = getFileNameUUID();
    let name = file.name;
    let ext = name.substring(name.lastIndexOf("."));
    let path = `uploads/${date}/video/${uuid}${ext}`;
    return (
      new Promise((resolve, reject) => {
        // 创建FileReader实例
        const reader = new FileReader();
        reader.onload = async function (e) {
          // 文件流
          let body = e.target.result;
          const res = await tosSingleUpload(path, body);
          if (res["content-length"]) {
            resolve();
          } else {
            reject();
          }
        };
        reader.readAsArrayBuffer(file);
      })
        // 上传完成后保存文件到本地数据库
        .then(async () => {
          // 生成落地页图片库
          let url = domain + path;
          const res = await saveLandingImages({ url });
          if (res?.code === 200) {
            props.getImages();
          }
        })
        .catch(() => {})
    );
  };

  // upload组件属性
  const uploadProps = {
    name: "file",
    showUploadList: false,
    accept: "image/*",
    beforeUpload,
    customRequest: doHandleUpload,
  };

  // 获取图片尺寸
  function getImageSize(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = reject;
      img.src = url;
    });
  }

  const selectImg = async (item) => {
    const res = await getImageSize(item.url);
    setLandingImageInfo(res);
    if (selectedImgId === item.id) {
      setSelectedImgId(0);
    } else {
      setSelectedImgId(item?.id);
    }
  };

  // 确认选择图片
  const onSelected = async () => {
    let imgItem = props?.landingImgs?.find((item) => {
      return item.id === selectedImgId;
    });
    let imgUrl = imgItem?.url;
    if (!imgUrl) {
      message.error("请选择图片");
      return false;
    }
    setConfirmLoading(true);
    let text = textRef.current.input.value;
    props.form.setFieldsValue({
      img_width: landingImageInfo.width,
      img_height: landingImageInfo.height,
    });
    if (text) {
      const res = await saveWaterImage({ img_url: imgUrl, text });
      if (res?.code === 200) {
        const { data } = res;
        setImageUrl(data?.img_url);
        props.form.setFieldsValue({ image_url: data?.img_url });
      }
    } else {
      setImageUrl(imgUrl);
      props.form.setFieldsValue({ image_url: imgUrl });
    }
    closeModal();
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  function clearBr(key) {
    key = key.replace(/<\/?.+?>/g, "::");
    key = key.replace(/[\r\n]/g, "::");
    return key;
  }

  // 显示复制账户input框
  const advertiserCopy = () => {
    setCopyInput(true);
  };

  const copyInputClose = () => {
    setCopyInput(false);
  };

  // 处理copy的广告账户
  const copySure = () => {
    let value = ref.current.resizableTextArea.textArea.value;
    let values = clearBr(value);
    let params = values.split(":").filter((value) => value);
    let newParams = params.map((value) => {
      return Number(value);
    });
    let selectList = props?.accounts.filter((item) => {
      return newParams.includes(item.value);
    });

    let advertiserIds = selectList.map((item) => {
      return item.value;
    });

    props.form.setFieldsValue({ advertiser_ids: advertiserIds });
  };

  const onNameChange = (event) => {
    setCustomPlayletName(event.target.value);
  };

  // 添加短剧
  const addItem = async (e) => {
    e.preventDefault();
    if (!customPlayletName) {
      return false;
    }
    let appId = props.form.getFieldValue("app_id")
    if (!appId) {
      message.error('新增短剧时请先选择剧场')
      return false
    }
    
    let filterItem = props?.packageList?.find((item) => {
      return item.value === appId
    })
    let appType = filterItem?.app_type
    setAddItemLoading(true);
    const res = await promotionPlayletAdd({
      app_type: appType,
      book_name: customPlayletName,
    });
    if (res?.code === 200) {
      const {
        data: { book_id },
      } = res;
      let filtered = props?.playlets.find((item) => {
        return item.value === book_id;
      });
      // 已存在的不追加
      if (filtered === undefined) {
        props.savePlaylets({ label: customPlayletName, value: book_id });
        setCustomPlayletName("");
      }
    }
    setAddItemLoading(false);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        initialValues={{
          asset_type: "TIK_PROGRAME",
          create_type: "account",
          type: 2,
          external_action: "AD_CONVERT_TYPE_GAME_ADDICTION",
          image_url: imageUrl,
          img_width: 750,
          img_height: 1000,
        }}
      >
        <Divider>推广链接</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="推广链接名称"
              name="promotion_title"
              rules={[{ required: true, message: "请输入推广链接名称" }]}
            >
              <Input placeholder="输入推广链接名称" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="推广外链ID"
              name="promotion_id"
              rules={[{ required: true, message: "请输入推广外链ID" }]}
            >
              <Input placeholder="输入跳转外链id" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="是否用于投放"
              name="type"
              rules={[{ required: true, message: "请选择投放平台" }]}
            >
              <Radio.Group
                options={[
                  { label: "自己平台", value: 1 },
                  { label: "只同步数据", value: 2 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="优化目标"
              name="external_action"
              rules={[{ required: true, message: "请选择优化目标" }]}
              tooltip="用于区分统计IPA, IAA数据"
            >
              <Radio.Group
                options={[
                  {
                    label: "付费",
                    value: "AD_CONVERT_TYPE_PAY",
                    disabled: true,
                  },
                  {
                    label: "关键行为",
                    value: "AD_CONVERT_TYPE_GAME_ADDICTION",
                  },
                  {
                    label: "广告ROI",
                    value: "AD_CONVERT_TYPE_ACTIVE",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label colon={false}>
          {copyInput ? (
            <>
              <Input.TextArea autoSize ref={ref}></Input.TextArea>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "80%", fontSize: 12, color: "red" }}>
                  复制多个账户换行
                </div>
                <div style={{ marginTop: 10 }}>
                  <Space>
                    <Button type="primary" onClick={copySure}>
                      确定
                    </Button>
                    <Button onClick={copyInputClose}>取消</Button>
                  </Space>
                </div>
              </div>
            </>
          ) : (
            <Button type="primary" ghost onClick={advertiserCopy}>
              批量复制账户
            </Button>
          )}
        </Form.Item>
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="选择广告账户"
                name="advertiser_ids"
                rules={[{ required: true, message: "请选择广告账户" }]}
              >
                <Select
                  mode="multiple"
                  placeholder="请选择广告账户 (可多选)"
                  showSearch
                  filterOption={filterOption}
                  options={props?.accounts}
                  autoClearSearchValue={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="选择剧场"
                name="app_id"
                rules={[{ required: true, message: "请选择剧场" }]}
              >
                <Select
                  placeholder="请选择剧场"
                  showSearch
                  filterOption={filterOption}
                  options={props?.packageList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="选择短剧"
                name="book_id"
                rules={[{ required: true, message: "请选择短剧" }]}
              >
                <Select
                  placeholder="请选择短剧"
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  dropdownRender={(item) => (
                    <>
                      {item}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="输入短剧名称"
                          ref={inputRef}
                          value={customPlayletName}
                          onChange={onNameChange}
                          onKeyDown={(e) => e.stopPropagation()}
                          style={{ width: 150 }}
                          allowClear
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addItem}
                          loading={addItemLoading}
                        >
                          添加短剧
                        </Button>
                      </Space>
                    </>
                  )}
                  options={props?.playlets}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="短剧简称"
                name="book_short"
                tooltip="用于更新账户时,短剧简称会拼接到账户名称中"
              >
                <Input placeholder="输入短剧简称" />
              </Form.Item>
            </Col>
          </Row>
        </>
        <Divider>小程序</Divider>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="启动参数"
              name="start_param"
              tooltip="示例: name=test&age=20"
            >
              <Input placeholder="输入启动参数" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="小程序备注" name="remark">
              <Input
                placeholder="请输入小程序备注"
                allowClear
                showCount
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="链接备注"
              name="link_remark"
              rules={[{ required: true, message: "请输入链接备注" }]}
            >
              <Input placeholder="输入备注" />
            </Form.Item>
          </Col>
        </Row>
        <Divider>落地页</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="落地页图片">
              <Form.Item
                hidden
                name="image_url"
                noStyle
                rules={[{ required: true, message: "请设置落地页图片" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item hidden name="img_width">
                <Input />
              </Form.Item>
              <Form.Item hidden name="img_height">
                <Input />
              </Form.Item>
              <Button
                type="primary"
                ghost
                onClick={imageAdd}
                icon={<PlusOutlined />}
              >
                添加图片
              </Button>
              {imageUrl ? (
                <div style={{ marginTop: 10 }}>
                  <ImageComponents width={200} src={imageUrl} preview={false} />
                </div>
              ) : (
                ""
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/* 添加图片 */}
      <Modal
        title="选择添加图片"
        open={show}
        onOk={onSelected}
        onCancel={closeModal}
        width={650}
        maskClosable={false}
        closeIcon={false}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
      >
        <Card
          title={
            <Form layout="inline">
              <Form.Item label="图片水印文案">
                <Input placeholder="输入水印方案" ref={textRef} />
              </Form.Item>
            </Form>
          }
          extra={
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>本地上传</Button>
            </Upload>
          }
          size="small"
        >
          <List
            grid={{
              gutter: 16,
              column: 3,
            }}
            size="small"
            pagination={false}
            dataSource={props?.landingImgs}
            loading={props.loading}
            renderItem={(item) => (
              <div
                style={{
                  overflowX: "hidden",
                  width: 150,
                  //   marginLeft: -16,
                  marginTop: 10,
                }}
                onClick={() => selectImg(item)}
                className={selectedImgId === item.id ? "live" : ""}
              >
                <ImageComponents width={200} src={item?.url} preview={false} />
              </div>
            )}
          />
        </Card>
      </Modal>
    </>
  );
}
