import {
  Button,
  Card,
  Form,
  Select,
  message,
  Modal,
  List,
  Typography,
  Space,
  Divider,
  Input,
  Checkbox,
  Tag,
} from "antd";
import React, { useState } from "react";
import {
  AccountMaterialClear,
  advertiseMaterialDelete,
  advertiseMaterialPull,
  advertiseMaterialSharePush,
  getAdvertiserList,
  getKeywordAccountList,
  getMaterialLabels,
  getMaterialMan,
} from "../../../../api/advertise";
import VideoModal from "./videoModal";
import { getMaterialOptions } from "../../../../api";
import "./index.less";
import { Link } from "react-router-dom";

const { confirm } = Modal;

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pushModalShow, setPushModalShow] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const [show, setShow] = useState(false);
  const [materialLabels, setMaterialLabels] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [sucaiOptions, setSucaiOptions] = useState([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const [pushForm] = Form.useForm();

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await advertiseMaterialPull(params);
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list?.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newList);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 关键词搜索
  const handleSearch = (values) => {
    getAccountList(values);
  };

  // 获取广告主列表
  const getAccountList = async (params = {}) => {
    const res = await getKeywordAccountList(params);
    if (res?.code === 200) {
      const { data } = res;
      setAdvertiserOptions(data);
      setList([]);
    }
  };

  // 共享推送广告主
  const getAdvertisers = async () => {
    const res = await getAdvertiserList();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((item) => {
        return { label: item.account_name, value: item.account_id };
      });
      setAccountOptions(newList);
    }
  };

  // 获取素材标签
  const getLabels = async () => {
    const res = await getMaterialLabels();
    const { data } = res;
    if (res?.code === 200) {
      let labels = data?.labels;
      let newLabels = labels.map((item) => {
        return { label: item, value: item };
      });
      setMaterialLabels(newLabels);
    }
  };

  // 获取素材分类
  const getCategory = async () => {
    const materialOptions = await getMaterialOptions();
    const options = materialOptions?.data.map((value, key) => {
      return { label: value, value: key };
    });
    setCategoryOptions(options);
  };

  // 获取筛选的素材手
  const getSucai = async () => {
    const res = await getMaterialMan();
    const { data } = res;
    if (res?.code === 200) {
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setSucaiOptions(newLists);
    }
  };

  // 分页
  const pagesize = (page) => {
    setPage(page);
    getList({ page, ...conditions });
  };

  // 切换广告账户拉取素材列表数据
  const onAdvertiseSelect = async (e, value) => {
    e.stopPropagation();
    if (value) {
      setConditions({ advertiser_id: value });
      setSelectKeys([]);
      getList({ advertiser_id: value });
    } else {
      setConditions([]);
      selectKeys([]);
      setList([]);
    }
  };

  // 全选
  const checkALL = () => {
    const rowIds = list.map((item) => {
      return item.id;
    });
    setSelectKeys(rowIds);
  };

  // 取消全选
  const checkCancel = () => {
    setSelectKeys([]);
  };

  // 复选框选中
  const checkedChange = (e, advertser_id) => {
    let value = e.target.checked;
    if (value) {
      setSelectedAccountIds([...selectedAccountIds, advertser_id]);
    } else {
      let newAccountIds = selectedAccountIds.filter((id) => {
        return id !== advertser_id;
      });
      setSelectedAccountIds(newAccountIds);
    }
  };

  // 单个素材选中
  const onSelect = (record) => {
    let newRows;
    if (selectKeys.includes(record?.id)) {
      newRows = selectKeys.filter((item) => {
        return item !== record?.id;
      });
    } else {
      newRows = [...selectKeys, record?.id];
    }
    setSelectKeys(newRows);
  };

  // 上传素材
  const materialPush = () => {
    if (!conditions?.advertiser_id) {
      message.error("请先选择广告账户");
      return false;
    }
    setShow(true);
    getLabels();
    getCategory();
    getSucai();
  };

  const onClose = () => {
    setShow(false);
  };

  // 批量清理选中素材
  const materialBatchClear = () => {
    confirm({
      title: `确定删除选中的${selectKeys.length}条素材?`,
      onOk: async () => {
        setLoading(true);
        const res = await advertiseMaterialDelete({
          advertiser_id: conditions?.advertiser_id,
          video_ids: selectKeys,
        });
        if (res?.code === 200) {
          message.success("素材清理成功", 2);
          setTimeout(() => {
            getList(conditions);
            setSelectKeys([]);
            setLoading(false);
          }, 2500);
        } else {
          message.error("素材清理失败");
          setLoading(false);
        }
      },
    });
  };

  // 批量清理账户素材
  const accountMaterialBatchClear = () => {
    confirm({
      title: "确定要清理已选中账户的所有素材?",
      onOk: async () => {
        setDeleteBtnLoading(true);
        const res = await AccountMaterialClear({
          advertiser_ids: selectedAccountIds,
        });
        if (res?.code === 200) {
          setTimeout(() => {
            message.success("操作成功,可以查看任务是否处理成功", 2);
            setSelectedAccountIds([]);
          }, 2000);
        }
        setDeleteBtnLoading(false);
      },
    });
  };

  // 批量推送素材
  const videoFilePush = () => {
    if (selectKeys.length > 10) {
      message.error("一次批量最多支持10个素材", 2);
      return false;
    }
    getAdvertisers();
    setPushModalShow(true);
  };

  // 确定全部上传完成
  const handleSure = () => {
    setLoading(true);
    setTimeout(() => {
      getList(conditions);
      setLoading(false);
    }, 2500);
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 处理共享推送
  const handleSharePush = async () => {
    if (selectKeys.length === 0) {
      message.error("请选择要推送的素材", 2);
      return false;
    }
    let values = pushForm.getFieldsValue();
    if (conditions?.advertiser_id === values?.advertiser_id) {
      message.error("不支持同一广告账户推送素材", 2);
      return false;
    }
    setConfirmLoading(true);
    let videoIds = [];
    list.map((item) => {
      if (selectKeys.includes(item.id)) {
        videoIds.push(item.id);
      }
    });
    const res = await advertiseMaterialSharePush({
      ...conditions,
      target_advertiser_ids: [values?.advertiser_id],
      video_ids: videoIds,
    });
    if (res?.code === 200) {
      setTimeout(() => {
        message.success("推送成功, 稍后可筛选该户进行查看", 2);
        setPushModalShow(false);
        setConfirmLoading(false);
        pushForm.resetFields();
      }, 2000);
    }
  };

  return (
    <>
      <Card
        title={
          <>
            <Form layout="inline" onFinish={handleSearch}>
              <Form.Item label="广告账户筛选" name="keyword">
                <Input placeholder="通过关键词搜索广告账户" allowClear />
              </Form.Item>
              <Form.Item label colon={false}>
                <Button htmlType="submit" type="primary">
                  查询
                </Button>
              </Form.Item>
            </Form>
            {advertiserOptions.length > 0 ? (
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  width: "100%",
                  height: 200,
                  borderRadius: 5,
                  overflowY: "auto",
                }}
              >
                <ul className="container">
                  {advertiserOptions.map((item) => {
                    return (
                      <li key={item.account_id} className="item">
                        <Space>
                          <Checkbox
                            onChange={(e) => checkedChange(e, item.account_id)}
                          />
                          <Typography.Text
                            style={{ width: "100%" }}
                            ellipsis={{ tooltip: item?.account_name }}
                          >
                            <Link
                              onClick={(e) => {
                                onAdvertiseSelect(e, item.account_id);
                              }}
                              style={{ fontSize: 12 }}
                              className={conditions?.advertiser_id == item.account_id ? "live" : ""}
                              disabled={conditions?.advertiser_id == item.account_id}
                            >
                              {item?.account_name}
                            </Link>
                          </Typography.Text>
                        </Space>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
          </>
        }
      >
        <div>
          <Form layout="inline">
            <Form.Item>
              <Button type="primary" ghost onClick={materialPush}>
                上传素材
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                disabled={selectKeys.length == 0}
                onClick={videoFilePush}
              >
                推送素材到其他户
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                danger
                disabled={selectedAccountIds.length == 0}
                onClick={accountMaterialBatchClear}
                loading={deleteBtnLoading}
              >
                批量一键清理广告账户素材
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                danger
                disabled={selectKeys.length == 0}
                onClick={materialBatchClear}
              >
                批量一键清理选中素材
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ marginBottom: -20, marginTop: 10 }}>
          <Space>
            <Button size="small" onClick={checkALL} type="link">
              全选当页
            </Button>
            <Button size="small" onClick={checkCancel} type="link">
              取消全选
            </Button>
          </Space>
        </div>
        <Divider />
        <div>
          <List
            grid={{
              gutter: 8,
              column: 5,
            }}
            pagination={{
              position: "bottom",
              pageSize: 50,
              onChange: pagesize,
              hideOnSinglePage: true,
              current: page,
              total,
            }}
            dataSource={list}
            loading={loading}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <div
                  style={{
                    overflowX: "hidden",
                    width: 190,
                    marginLeft: -8,
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: 2, marginLeft: "8%" }}
                    className={selectKeys.includes(item.id) ? "live" : ""}
                    onClick={() => onSelect(item)}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: 5,
                        wordWrap: "break-word",
                      }}
                    >
                      {
                        item?.is_low ? <div><Tag color="#f50">低效素材</Tag></div> : ''
                      }
                      <Typography.Text
                        style={{
                          maxWidth: "100%",
                        }}
                        ellipsis={{
                          tooltip: item.filename,
                        }}
                      >
                        {item.filename}
                      </Typography.Text>
                    </div>

                    <video
                      width="100%"
                      src={item?.url}
                      alt=""
                      controls
                      preload="none"
                      poster={`${item?.poster_url}`}
                    />
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </Card>
      {/* 视频上传到头条弹窗 */}
      <VideoModal
        isShow={show}
        closeVideo={onClose}
        advertiserId={conditions?.advertiser_id}
        handleSure={handleSure}
        labels={materialLabels}
        categoryOptions={categoryOptions}
        sucaiOptions={sucaiOptions}
      />
      {/* 推送视频到其他户弹窗 */}
      <Modal
        open={pushModalShow}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setPushModalShow(false);
          pushForm.resetFields();
        }}
        title="素材推送"
        onOk={handleSharePush}
        width={650}
      >
        <Divider />
        <Form form={pushForm}>
          <Form.Item label="推送素材广告账户" name="advertiser_id">
            <Select
              showSearch
              placeholder="请选择要推送素材的广告账户"
              allowClear
              filterOption={filterOption}
              options={accountOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
