import { Button, Card, DatePicker, Form, Select } from "antd";
import React from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import EditableCell from "../../../../components/Editable/EditableCell";
import { updatePayQuClaim } from "../../../../api/playlet";

const { RangePicker } = DatePicker;

export default function Index(props) {
  const { list, total, page, loading, pagesize, perPage } = props;

  // 日期范围筛选
  const onSearch = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.range_date;
    }
    props.onSearch({ range_date: rangeDate, ...values });
  };

  // 修改赔付金额
  const handleSave = async (row) => {
    if (parseFloat(row.claim) === 0) {
      return false;
    }
    const newData = [...list];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    const res = await updatePayQuClaim({
      id: row?.id,
      claim: row?.claim,
    });
    if (res?.code === 200) {
      props.onSearch()
    }
  };

  const components = {
    body: {
      // row: EditableRow,
      cell: EditableCell,
    },
  };

  const getColumns = () => {
    let newColumns = columns();
    return newColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          field: "claim",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    });
  };

  return (
    <Card
      title={
        <Form layout="inline" onFinish={onSearch}>
          <Form.Item name="range_date">
            <RangePicker />
          </Form.Item>
          {props?.category ? (
            <Form.Item label="剧场" name="category">
              <Select
                allowClear
                placeholder="选择剧场"
                style={{ width: 180 }}
                options={props.playlets}
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <Table
        components={components}
        dataSource={list}
        columns={getColumns()}
        total={total}
        page={page}
        loading={loading}
        pagesize={pagesize}
        perPage={perPage}
      />
    </Card>
  );
}
