import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import Table from "../../../components/TableNoSelection";
import { getIaaPlaylet } from "../../../api/statistics";
import { numAdd, numSub } from "../../../util/calculate";
import { getRoleOrgan } from "../../../api/permission";
import {
  getDeliveryEcpmNovelLevel,
  getDeliveryNovelARPU,
  getDeliveryNovelEcpm,
} from "../../../api/delivery";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playletOptions, setPlayletOptions] = useState([]);
  const [agentId, setAgentId] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [rangeDate, setRangeDate] = useState([]);
  const [playletId, setPlayletId] = useState("");
  const [ipuLevel, setIpuLevel] = useState(0);
  const [arpuLevel, setArpuLevel] = useState(0);
  const [ecpmLevel, setEcpmLevel] = useState(0);
  const [ecpmStep, setEcpmStep] = useState(10);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // tab切换
  const onTabChange = (value) => {
    setActiveTabKey(value);
    form.resetFields();
    setColumns([]);
    setList([]);
  };

  const getData = () => {
    Promise.all([getPlaylet(), getAgent()]);
  };

  // 获取组织代理商id
  const getAgent = async () => {
    const res = await getRoleOrgan();
    if (res?.code === 200) {
      const { data } = res;
      setAgentId(data?.agent_id);
    }
  };

  // 动态生成列数据
  const generateColumns = (columns) => {
    let title = activeTabKey === 1 ? "ARPU/IPU" : "ECPM/IPU";
    let newColumns = columns.map((element) => {
      return {
        title: element,
        width: 50,
        align: "center",
        dataIndex: `ipu_${element}`,
        key: `ipu_${element}`,
      };
    });
    newColumns.unshift({
      title,
      width: 50,
      align: "center",
      dataIndex: `arpu`,
      key: `arpu`,
    });
    setColumns(newColumns);
  };

  // 动态生成行数据
  const generateData = (list) => {
    let newList = {};
    list?.forEach((item) => {
      newList[`ipu_${item.column}`] = item?.value;
    });
    return newList;
  };

  // 获取ecpm档位接口
  const getEcpmLevel = async (params = {}) => {
    params.agentId = agentId === 0 ? 13 : agentId
    const res = await getDeliveryEcpmNovelLevel(params);
    if (res?.code === 200) {
      const { data } = res;
      setIpuLevel(data?.ipuLevel);
      setArpuLevel(data?.arpuLevel * 0.1);
      setEcpmLevel(data?.ecpmLevel);
    }
  };

  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    params.agentId = agentId === 0 ? 13 : agentId;
    let res;
    if (activeTabKey === 1) {
      res = await getDeliveryNovelARPU(params);
    } else {
      res = await getDeliveryNovelEcpm(params);
    }
    if (res?.code === 200) {
      const { data } = res;
      let titles = data.column;
      generateColumns(titles);
      let list = data?.data;
      let newList = list.map((item) => {
        let columns = item?.columns;
        let obj = generateData(columns);
        obj.arpu = item?.row;
        // 对象id赋值主要是防页面警告提示
        obj.id = item?.row;
        return obj;
      });
      setList(newList);
    }
    setLoading(false);
  };

  // 在输入取值范围时，检测是否已有请求档位
  const getLevelCheck = () => {
    if (rangeDate.length === 0 || !playletId) {
      message.error("设置取值范围时，请先筛选日期及选择对应的短剧");
      return false;
    }
    return true;
  };

  // ipu最值小切换
  const onIpuMinChange = (value) => {
    if (value) {
      if (!getLevelCheck()) {
        return false;
      }
      if (ipuLevel === 0) {
        message.error("ipu取值档位获取中, 稍等");
        return false;
      }
      let ipuMaxVal = parseInt(value) + (parseInt(ipuLevel) - 1);
      form.setFieldsValue({ ipuEnd: ipuMaxVal > 15 ? 15 : ipuMaxVal });
    }
  };

  // ipu最大值切换
  const onIpuMaxChange = (value) => {
    if (value) {
      if (!getLevelCheck()) {
        return false;
      }
      if (ipuLevel === 0) {
        message.error("ipu取值档位获取中, 稍等");
        return false;
      }
      let ipuMinVal = parseInt(value) - (parseInt(ipuLevel) - 1);
      form.setFieldsValue({ ipuStart: ipuMinVal < 2 ? 2 : ipuMinVal });
    }
  };

  // arpu最小值切换
  const onArpuMinChange = (value) => {
    if (value) {
      if (!getLevelCheck()) {
        return false;
      }
      if (arpuLevel === 0) {
        message.error("arpu取值档位获取中, 稍等");
        return false;
      }
      let arpuMaxVal = parseFloat(
        numAdd(parseFloat(value), numSub(parseFloat(arpuLevel), 0.1))
      );
      form.setFieldsValue({ arpuEnd: arpuMaxVal > 5 ? 5 : arpuMaxVal });
    }
  };

  // arpu最大值切换
  const onArpuMaxChange = (value) => {
    if (value) {
      if (!getLevelCheck()) {
        return false;
      }
      if (arpuLevel === 0) {
        message.error("arpu取值档位获取中, 稍等");
        return false;
      }
      let arpuMinVal = parseFloat(
        numSub(parseFloat(value), numSub(parseFloat(arpuLevel), 0.1))
      );
      form.setFieldsValue({ arpuStart: arpuMinVal < 0.2 ? 0.2 : arpuMinVal });
    }
  };

  // ecpm最小值切换
  const onEcpmMinChange = (value) => {
    if (value) {
      if (value >= 160) {
        setEcpmStep(20);
      } else {
        setEcpmStep(10);
      }
      if (!getLevelCheck()) {
        return false;
      }
      if (ecpmLevel === 0) {
        message.error("ecpm取值档位获取中, 稍等");
        return false;
      }
      let ecpmMaxVal
      if (ecpmLevel >= 33) {
        ecpmMaxVal = 600
      } else if (ecpmLevel === 10) {
        let baseVal = (10-1) * 10 + value
        if (baseVal <= 160) {
          ecpmMaxVal = baseVal
        } else {
          // 算出到160的档位
          let v = ((160 - value) / 10) + 1
          // 超过160后每个档位加20
          let lv = 10 - v
          ecpmMaxVal = 160 + lv * 20
        }
      } else if (ecpmLevel === 20) {
        // 60-160为11个档位
        let v = ((160 - value) / 10) + 1
        ecpmMaxVal = (20 - v) * 20 + 160
      }
      form.setFieldsValue({ ecpmEnd: ecpmMaxVal > 600 ? 600 : ecpmMaxVal });
    }
  };

  // ecpm 最大值切换
  const onEcpmMaxChange = (value) => {
    if (value) {
      if (value >= 160) {
        setEcpmStep(20);
      } else {
        setEcpmStep(10);
      }
      if (!getLevelCheck()) {
        return false;
      }
      if (ecpmLevel === 0) {
        message.error("ecpm取值档位获取中, 稍等");
        return false;
      }
      let ecpmMinVal
      if (ecpmLevel === 33) {
        ecpmMinVal = 60
      } else if (ecpmLevel === 20) {
        if (value <= 160) {
          ecpmMinVal = 60
        } else {
          let v = ((value - 180) / 20) + 1
          if (v <= 9) {
            ecpmMinVal = 60
          } else if ( v < 20) {
            ecpmMinVal = 160 - (((20 - v) - 1) * 10)
          } else {
            ecpmMinVal = (value - v * 20) +  (v - 20 + 1) * 20
          }
        }
      } else if (ecpmLevel === 10) {
        if (value < 160) {
          ecpmMinVal = 60
        } else if (value === 160) {
          ecpmMinVal = 70
        } else {
          let v = ((value - 180) / 20) + 1
          if (v < 10) {
            ecpmMinVal = 160 - ((10 - (v+1)) * 10)
          } else {
            ecpmMinVal = (v-10) * 20 + 180
          }
        }
      }
      form.setFieldsValue({ ecpmStart: ecpmMinVal < 60 ? 60 : ecpmMinVal });
    }
  };

  // 表单查询提交验证
  const validator = (values) => {
    let rangeDate = values?.range_date;
    let playletId = values?.playletId;
    let ipuMin = values?.ipuStart;
    let ipuMax = values?.ipuEnd;
    let arpuMin = values?.arpuStart;
    let arpuMax = values?.arpuEnd;
    let ecpmMin = values?.ecpmStart;
    let ecpmMax = values?.ecpmEnd;
    if (
      !rangeDate ||
      !playletId ||
      !ipuMin ||
      !ipuMax ||
      (activeTabKey === 1 && (!arpuMin || !arpuMax)) ||
      (activeTabKey === 2 && (!ecpmMin || !ecpmMax))
    ) {
      message.error("筛选查询所有参数必选");
      return false;
    }
    if (ipuMin < 2 || ipuMin > 15) {
      message.error("ipu最小值取值范围为2-15");
      return false;
    }
    if (ipuMax < 2 || ipuMax > 15) {
      message.error("ipu最大值取值范围为2-15");
      return false;
    }
    if (arpuMin < 0.2 || arpuMin > 5) {
      message.error("arpu最小值取值范围为0.2-5");
      return false;
    }
    if (arpuMax < 0.2 || arpuMax > 5) {
      message.error("arpu最大值取值范围为0.2-5");
      return false;
    }
    if (ipuMin > ipuMax) {
      message.error("ipu最小值不能大于最大值");
      return false;
    }
    if (parseInt(ipuMax) - parseInt(ipuMin) > ipuLevel) {
      message.error(`调整ipu筛选范围为${ipuLevel}`);
      return false;
    }
    if (arpuMin > arpuMax) {
      message.error("arpu最小值不能大于最大值");
      return false;
    }
    if (parseFloat(arpuMax) - parseFloat(arpuMin) > arpuLevel) {
      message.error(`调整arpu筛选范围为${arpuLevel}`);
      return false;
    }
    return true;
  };

  // 日期筛选切换时调用档位接口
  const onDatesChange = (values) => {
    let dates;
    if (values) {
      dates = values.map((date) => {
        return date.format("YYYY-MM-DD");
      });
      if (playletId) {
        // 请求档位接口
        getEcpmLevel({ playletId, startDate: dates[0], endDate: dates[1] });
      }
      setRangeDate(dates);
    } else {
      setRangeDate([]);
    }
  };

  // 短剧切换时调用档位接口
  const onPlayletChange = (value) => {
    if (value) {
      if (rangeDate.length > 1) {
        // 请求档位接口
        getEcpmLevel({
          playletId: value,
          startDate: rangeDate[0],
          endDate: rangeDate[1],
        });
      }
      setPlayletId(value);
    } else {
      setPlayletId("");
    }
  };

  // 表单条件筛选
  const onSubmit = (values) => {
    if (!validator(values)) {
      return false;
    }
    let rangeDate;
    if (values?.range_date) {
      rangeDate = values.range_date.map((date) => {
        return date.format("YYYY-MM-DD");
      });
      values.startDate = rangeDate[0];
      values.endDate = rangeDate[1];
      delete values.range_date;
    }
    getList(values);
  };

  // 获取短剧列表
  const getPlaylet = async () => {
    const res = await getIaaPlaylet();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.book_name, value: item.book_id };
      });
      form.setFieldsValue({ playletld: newLists[0]?.value });
      setPlayletOptions(newLists);
    }
  };

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // Disabled 2 days from the selected date
  const getYearMonth = (date) => date.year() * 12 + date.month();
  const disabled2DaysDate = (current, { from, type }) => {
    if (from) {
      const minDate = from.add(0, "days");
      const maxDate = from.add(2, "days");
      switch (type) {
        case "year":
          return (
            current.year() < minDate.year() || current.year() > maxDate.year()
          );
        case "month":
          return (
            getYearMonth(current) < getYearMonth(minDate) ||
            getYearMonth(current) > getYearMonth(maxDate)
          );
        default:
          return Math.abs(current.diff(from, "days")) >= 3;
      }
    }
    return false;
  };

  // form排版
  const layout = {
    labelCol: { sm: { span: 6 } },
    wrapperCol: { xl: { span: 16 } },
  };

  return (
    <Card
      tabList={[
        { key: 1, tab: "ARPU" },
        { key: 2, tab: "ECPM" },
      ]}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      size="small"
    >
      <Card
        type="inner"
        title={
          <Form onFinish={onSubmit} form={form} {...layout}>
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item
                  label="日期"
                  name="range_date"
                  // labelCol={{ sm: { span: 2 } }}
                >
                  <RangePicker
                    disabledDate={disabled2DaysDate}
                    onChange={onDatesChange}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="小说" name="playletId">
                  <Select
                    allowClear
                    placeholder="选择小说"
                    showSearch
                    filterOption={filterOption}
                    options={playletOptions}
                    onChange={onPlayletChange}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label="IPU值范围" name="ipu_range">
                  <Form.Item
                    name="ipuStart"
                    style={{ display: "inline-block" }}
                  >
                    <InputNumber
                      placeholder="最小值 (>=)"
                      style={{
                        width: 100,
                      }}
                      min={2}
                      max={15}
                      onChange={onIpuMinChange}
                    />
                  </Form.Item>
                  <span style={{ marginLeft: -10, marginRight: 5 }}>-</span>
                  <Form.Item
                    name="ipuEnd"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <InputNumber
                      placeholder="最大值 (<=)"
                      style={{
                        width: 100,
                      }}
                      min={2}
                      max={15}
                      onChange={onIpuMaxChange}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={9}>
                <Form.Item
                  label={`${activeTabKey === 1 ? "ARPU值范围" : "ECPM值范围"}`}
                  name="arpu_range"
                >
                  <Form.Item
                    name={`${activeTabKey === 1 ? "arpuStart" : "ecpmStart"}`}
                    style={{ display: "inline-block" }}
                  >
                    <InputNumber
                      placeholder="最小值 (>=)"
                      style={{
                        width: 100,
                      }}
                      min={activeTabKey === 1 ? 0.2 : 60}
                      max={activeTabKey === 1 ? 5 : 600}
                      step={activeTabKey === 1 ? 0.1 : ecpmStep}
                      onChange={
                        activeTabKey === 1 ? onArpuMinChange : onEcpmMinChange
                      }
                    />
                  </Form.Item>
                  <span style={{ marginLeft: -10, marginRight: 5 }}>-</span>
                  <Form.Item
                    name={`${activeTabKey === 1 ? "arpuEnd" : "ecpmEnd"}`}
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <InputNumber
                      placeholder="最大值 (<=)"
                      style={{
                        width: 100,
                      }}
                      min={activeTabKey === 1 ? 0.2 : 60}
                      max={activeTabKey === 1 ? 5 : 600}
                      step={activeTabKey === 1 ? 0.1 : ecpmStep}
                      onChange={
                        activeTabKey === 1 ? onArpuMaxChange : onEcpmMaxChange
                      }
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label colon={false}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      查询
                    </Button>
                    <Button onClick={() => form.resetFields()}>重置</Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      >
        <div style={{ textAlign: "right", marginTop: -10 }}>
          <Text type="secondary">ipu占比值为(单位): %</Text>
        </div>
        <Table
          dataSource={list}
          columns={columns}
          pagination={false}
          bordered={true}
          loading={loading}
        />
      </Card>
    </Card>
  );
}
