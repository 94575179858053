import { Card, Col, Row, Statistic, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./home.less";
import { getStatistics, sumCostRangeDate } from "../../api";
import ChartLine from "../../components/ChartLine";
import { numDiv, numMulti } from "../../util/calculate";

export default function Home() {
  const [statistics, setStatistics] = useState([]);
  const [chartsData, setChartsData] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(1);
  const [activeTypeTabKey, setActiveTypeTabKey] = useState(1);
  const [isSuper, setIsSuper] = useState(false);

  useEffect(() => {
    statistcis();
    getChartsData();
  }, []);

  // 图表数据
  const getChartsData = async (params = {}) => {
    const res = await sumCostRangeDate(params);
    const { data } = res;
    if (res?.code === 200) {
      const list = data?.map((item) => {
        item.value = parseFloat(item.value)
        return item
      })
      setChartsData(list);
    }
  };

  // 获取统计数据
  const statistcis = async (params = {}) => {
    const res = await getStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setIsSuper(data?.is_super);
      setStatistics(data);
    }
  };

  const onTabChange = (value) => {
    setActiveTabKey(value);
    getChartsData({ range_day: value === 1 ? 7 : 30, type: activeTypeTabKey === 1 ? 'playlet' : 'novel' });
  };

  const onTypeTabChange = value => {
    setActiveTypeTabKey(value)
    statistcis({type: value === 1 ? 'playlet' : 'novel'})
    getChartsData({type: value === 1 ? 'playlet' : 'novel'})
  }

  return (
    <>
      {isSuper ? (
        <Card
          tabList={[
            { key: 1, tab: "短剧" },
            { key: 2, tab: "小说" },
          ]}
          tabProps={{ type: "card" }}
          activeTabKey={activeTypeTabKey}
          onTabChange={onTypeTabChange}
        >
          <div className="home-header">
            <Row gutter={16}>
              <Col xl={{ span: 8 }}>
                <Card
                  className="home-statistic"
                  title={
                    <>
                      <span style={{ marginRight: "5px" }}>今日</span>
                      <Tooltip title="今日总览说明">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <Row gutter={24}>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="新建计划"
                        value={statistics?.today && statistics?.today[0]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="审核计划"
                        value={statistics?.today && statistics?.today[1]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="在消耗计划"
                        value={statistics?.today && statistics?.today[2]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总消耗"
                        prefix="¥"
                        value={statistics?.today && statistics?.today[3]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收"
                        prefix="¥"
                        value={statistics?.today && statistics?.today[4]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收率"
                        suffix="%"
                        value={statistics?.today && statistics?.today[3] ? numMulti(numDiv(statistics?.today && statistics?.today[4], statistics?.today && statistics?.today[3]), 100).toFixed(2) : 0}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={{ span: 8 }}>
                <Card
                  title="昨日"
                  className="home-statistic"
                  style={{ backgroundColor: "white" }}
                >
                  <Row gutter={24}>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="新建计划"
                        value={
                          statistics?.yesterday && statistics?.yesterday[0]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="审核计划"
                        value={
                          statistics?.yesterday && statistics?.yesterday[1]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="在消耗计划"
                        value={
                          statistics?.yesterday && statistics?.yesterday[2]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总消耗"
                        prefix="¥"
                        value={
                          statistics?.yesterday && statistics?.yesterday[3]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收"
                        prefix="¥"
                        value={
                          statistics?.yesterday && statistics?.yesterday[4]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收率"
                        suffix="%"
                        value={statistics?.yesterday && statistics?.yesterday[3] ? numMulti(numDiv(statistics?.yesterday && statistics?.yesterday[4], statistics?.yesterday && statistics?.yesterday[3]), 100).toFixed(2) : 0}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={{ span: 8 }}>
                <Card
                  className="home-statistic"
                  title={
                    <>
                      <span style={{ marginRight: "5px" }}>本月</span>
                      <Tooltip title="本月总览说明">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <Row gutter={24}>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="新建计划"
                        value={statistics?.month && statistics?.month[0]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="审核计划"
                        value={statistics?.month && statistics?.month[1]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总消耗"
                        prefix="¥"
                        value={statistics?.month && statistics?.month[2]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收"
                        prefix="¥"
                        value={statistics?.month && statistics?.month[3]}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收率"
                        suffix="%"
                        value={statistics?.month && statistics?.month[2] ? numMulti(numDiv(statistics?.month && statistics?.month[3], statistics?.month && statistics?.month[2]), 100).toFixed(2) : 0}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="home-list">
            <Card
              tabList={[
                { key: 1, tab: "近7天" },
                { key: 2, tab: "近30天" },
              ]}
              tabProps={{ type: "card" }}
              activeTabKey={activeTabKey}
              onTabChange={onTabChange}
            >
              <ChartLine
                data={chartsData}
                xField="day"
                yField="value"
                seriesField="category"
              />
            </Card>
          </div>
        </Card>
      ) : (
        <Card>
          <div className="home-header">
            <Row gutter={16}>
              <Col xl={{ span: 8 }}>
                <Card
                  className="home-statistic"
                  title={
                    <>
                      <span style={{ marginRight: "5px" }}>今日</span>
                      <Tooltip title="今日总览说明">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <Row gutter={24}>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="新建计划"
                        value={statistics?.today && statistics?.today[0]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="审核计划"
                        value={statistics?.today && statistics?.today[1]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="在消耗计划"
                        value={statistics?.today && statistics?.today[2]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总消耗"
                        prefix="¥"
                        value={statistics?.today && statistics?.today[3]}
                        precision={2}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收"
                        prefix="¥"
                        value={statistics?.today && statistics?.today[4]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收率"
                        suffix="%"
                        value={statistics?.today && statistics?.today[3] ? numMulti(numDiv(statistics?.today && statistics?.today[4], statistics?.today && statistics?.today[3]), 100).toFixed(2) : 0}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={{ span: 8 }}>
                <Card
                  title="昨日"
                  className="home-statistic"
                  style={{ backgroundColor: "white" }}
                >
                  <Row gutter={24}>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="新建计划"
                        value={
                          statistics?.yesterday && statistics?.yesterday[0]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="审核计划"
                        value={
                          statistics?.yesterday && statistics?.yesterday[1]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 8 }}>
                      <Statistic
                        title="在消耗计划"
                        value={
                          statistics?.yesterday && statistics?.yesterday[2]
                        }
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总消耗"
                        prefix="¥"
                        value={
                          statistics?.yesterday && statistics?.yesterday[3]
                        }
                        precision={2}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收"
                        prefix="¥"
                        value={statistics?.today && statistics?.yesterday[4]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收率"
                        suffix="%"
                        value={statistics?.today && statistics?.yesterday[3] ? numMulti(numDiv(statistics?.yesterday && statistics?.yesterday[4], statistics?.yesterday && statistics?.yesterday[3]), 100).toFixed(2) : 0}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={{ span: 8 }}>
                <Card
                  className="home-statistic"
                  title={
                    <>
                      <span style={{ marginRight: "5px" }}>本月</span>
                      <Tooltip title="本月总览说明">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <Row gutter={24}>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="新建计划"
                        value={statistics?.month && statistics?.month[0]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="审核计划"
                        value={statistics?.month && statistics?.month[1]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总消耗"
                        prefix="¥"
                        value={statistics?.month && statistics?.month[2]}
                        precision={2}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收"
                        prefix="¥"
                        value={statistics?.month && statistics?.month[3]}
                        valueStyle={{ color: "#44B4A6", fontSize: "20px" }}
                      />
                    </Col>
                    <Col xl={{ span: 12 }}>
                      <Statistic
                        title="总回收率"
                        suffix="%"
                        value={statistics?.month && statistics?.month[2] ? numMulti(numDiv(statistics?.month && statistics?.month[3], statistics?.month && statistics?.month[2]), 100).toFixed(2) : 0}
                        valueStyle={{ color: "#44B4A6", fontSize: "16px" }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="home-list">
            <Card
              tabList={[
                { key: 1, tab: "近7天" },
                { key: 2, tab: "近30天" },
              ]}
              tabProps={{ type: "card" }}
              activeTabKey={activeTabKey}
              onTabChange={onTabChange}
            >
              <ChartLine
                data={chartsData}
                xField="day"
                yField="value"
                seriesField="category"
              />
            </Card>
          </div>
        </Card>
      )}
    </>
  );
}
