import React, { useEffect, useState } from "react";
import Table from "../../../../components/TableNoSelection";
// import columns from "./book_columns";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Statistic,
} from "antd";
import {
  filterPitchers,
  getThirdECPMPlaylet,
} from "../../../../api/statistics";
import { getPlayletEcpmList, getPlayletECPStatistics } from "../../../../api/delivery";
import dayjs from "dayjs";
import { getRoleOrgan } from "../../../../api/permission";

const { RangePicker } = DatePicker;

const today = dayjs().startOf('day');

export default function EcpmBook(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [loading, setLoading] = useState(false);
  const [pitcherOptions, setPitcherOptions] = useState([]);
  const [statisticsData, setStatisticsData] = useState({});
  const [conditions, setConditions] = useState({});
  const [appOptions, setAppOptions] = useState([]);

  const [form] = Form.useForm();
  useEffect(() => {
    if(localStorage.getItem('system_from_name_ID')){
      form.setFieldsValue({adminId: Number(localStorage.getItem('system_from_name_ID'))})
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // 获取列表记录
  const getList = async (params = {}) => {
    setLoading(true);
    if (!params?.range_date) {
      let current = dayjs().format('YYYYMMDD')
      params.startDay = current
      params.endDay = current
    } else {
      let rangeDate = params?.range_date
      params.startDay = rangeDate[0]
      params.endDay = rangeDate[1]
    }
    params.statsDim = Number(props.curKey) === 2 ? 'admin_book' : 'book';;
    params.pageNum = params.pageNum || page
    params.pageSize = params.pageSize || perPage
    let agentId
    const org = await getRoleOrgan();
    if (org?.code === 200) {
      const { data } = org;
      agentId = data?.agent_id === 0 ? 13 : data?.agent_id
    }
    params.agentId = agentId
    params.profitMode = 'IAA'
    const res = await getPlayletEcpmList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.list);
      setTotal(data?.total);
    }
    setLoading(false);
  };

  // 总计数据
  const ecpmStatistics = async (params = {}) => {
    if (!params?.range_date) {
      let current = dayjs().format('YYYYMMDD')
      params.startDay = current
      params.endDay = current
    } else {
      let rangeDate = params?.range_date
      params.startDay = rangeDate[0]
      params.endDay = rangeDate[1]
    }
    params.statsDim = Number(props.curKey) === 2 ? 'admin_book' : 'book';
    let agentId
    const org = await getRoleOrgan();
    if (org?.code === 200) {
      const { data } = org;
      agentId = data?.agent_id === 0 ? 13 : data?.agent_id
    }
    params.agentId = agentId
    params.profitMode = 'IAA'
    const res = await getPlayletECPStatistics(params);
    if (res?.code === 200) {
      const { data } = res;
      setStatisticsData(data);
      // setTotal(data?.total);
    }
  };

  // 获取筛选的投手信息
  const getPitchers = async () => {
    const res = await filterPitchers({ type: "playlet" });
    if (res?.code === 200) {
      const { data } = res;
      const list = data?.lists;
      const newList = list.map((item) => {
        return { label: item.nickname, value: item.id };
      });
      setPitcherOptions(newList);
    }
  };

  // 获取剧场
  const getPlaylets = async () => {
    const res = await getThirdECPMPlaylet();
    if (res?.code === 200) {
      const { data } = res;
      let list = data?.lists;
      let newList = list.map((appName) => {
        return { label: appName, value: appName };
      });
      setAppOptions(newList);
    }
  };

  const getData = () => {
    let values = form.getFieldsValue();
    setConditions({ ...values });
    Promise.all([getList(values), ecpmStatistics(values), getPitchers(), getPlaylets()]);
  };

  // 表单筛选查询
  const onSubmit = () => {
    let values = form.getFieldsValue();
    let rangeDate;
    if (values?.dates) {
      rangeDate = values.dates.map((date) => {
        return date.format("YYYYMMDD");
      });
      delete values.dates;
      values.range_date = rangeDate;
    }
    const params = {...values, pageNum: 1, pageSize: 20};
    setConditions(params);
    setPage(1);
    setPerPage(20);
    Promise.all([getList(params), ecpmStatistics(params)]);
  };

  const onReset = () => {
    form.resetFields();
    onSubmit();
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  const toThree = (obj) => {
    if(props.changeThreeTab){
      props.changeThreeTab(obj)
    }
  }
  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
  };

  // 排序
  const onSortChange = (pagination, filters, sorter) => {
    console.log(sorter, 'sortersorter')
    
    const sortBy = sorter.order;
    if (sortBy !== undefined) {
      const order = sorter.columnKey;
      const orderByAsc = sortBy === "ascend" ? 1 : 0;
      setConditions({ ...conditions, orderBy: order, orderByAsc });
      getList({
        ...conditions,
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
        orderBy: order,
        orderByAsc,
      });
    } else {
      delete conditions.orderBy
      delete conditions.orderByAsc
      setPage(pagination.current);
      setPerPage(pagination.pageSize);
      getList({
        ...conditions,
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
      })
    }

    // let order;
    // if (sorter) {
    //   let feild = sorter.columnKey;
    //   let sortBy = sorter.order;
    //   if (feild !== undefined) {
    //     if (sortBy === "ascend") {
    //       order = feild + "_" + "asc";
    //     } else {
    //       order = feild + "_" + "desc";
    //     }
    //     setConditions({ ...conditions, order });
    //   }
    // }
    // getList({
    //   ...conditions,
    //   pageSize: pagination.pageSize,
    //   pageNum: pagination.current,
    //   order,
    // });
  };

  const columnsNew =  [
    {
      title: "日期",
      width: 80,
      align: "center",
      dataIndex: "day",
      key: "day",
      fixed: "left",
    },
    {
      title: "短剧名称",
      width: 130,
      align: "center",
      dataIndex: "bookName",
      key: "bookName",
      render: (value) => (<Link onClick={()=>toLink(value)}>{value}</Link>)
    },
    {
      title: "剧场名称",
      width: 100,
      align: "center",
      dataIndex: "appName",
      key: "appName",
    },
    {
      title: "消耗",
      width: 100,
      align: "center",
      dataIndex: "cost",
      key: "cost",
      sorter: {},
      render: (value) => (
        value || 0
      )
    },
    {
      title: "ECPM当日收益",
      width: 100,
      align: "center",
      dataIndex: "today_ad_cash",
      key: "today_ad_cash",
      sorter: {},
      render: (_, record) => (
        record?.todayAdCash || 0
      )
    },
    {
      title: "ECPM总收益",
      width: 100,
      align: "center",
      dataIndex: "total_ad_cash",
      key: "total_ad_cash",
      sorter: {},
      render: (_, record) => (
        record?.totalAdCash || 0
      )
    },
    {
      title: "新增回收率",
      width: 80,
      align: "center",
      dataIndex: "new_recovery_rate",
      key: "new_recovery_rate",
      sorter: {},
      render: (_,record) => (
        record?.newRecoveryRate === 0 ? '0.00%' : record?.newRecoveryRate + '%'
      )
    },
    {
      title: "总回收率",
      width: 80,
      align: "center",
      dataIndex: "total_recovery_rate",
      key: "total_recovery_rate",
      sorter: {},
      render: (_,record) => (
        record?.totalRecoveryRate === 0 ? '0.00%' : record?.totalRecoveryRate + '%'
      )
    },
    {
      title: "当天新建广告数",
      width: 80,
      align: "center",
      dataIndex: "ad_Count",
      key: "ad_Count",
      sorter: {},
      render: (_, record) => (record.adCount || 0)
    },
    {
      title: "当天新建广告数(有消耗)",
      width: 100,
      align: "center",
      dataIndex: "ad_New_Active_Count",
      key: "ad_New_Active_Count",
      sorter: {},
      render: (_, record) => (record.adNewActiveCount || 0)
    },
    {
      title: "在消耗广告数",
      width: 80,
      align: "center",
      dataIndex: "active_Ad_Count",
      key: "active_Ad_Count",
      sorter: {},
      render: (_, record) => (record.activeAdCount || 0)
    },
    {
      title: "当天新建账户数(含老剧)",
      width: 100,
      align: "center",
      dataIndex: "account_Count",
      key: "account_Count",
      sorter: {},
      render: (_, record) => (record.accountCount || 0)
    },
    {
      title: "在消耗账户数",
      width: 80,
      align: "center",
      dataIndex: "active_Account_Count",
      key: "active_Account_Count",
      sorter: {},
      render: (_, record) => (record.activeAccountCount || 0)
    },
  ];

  const columnsTwo = [
    {
      title: "日期",
      width: 80,
      align: "center",
      dataIndex: "day",
      key: "day",
      fixed: "left",
    },
    {
      title: "投手",
      width: 80,
      align: "center",
      dataIndex: "adminName",
      key: "adminName",
      render: (text,record) => (<Link to={`/admin/statistics/theater/push_plt/toutiao_plt`} state={{tab: "tab1", playletId: record.bookId }} onClick={() => {localStorage.setItem('system_from_ecpm_BY_NAME', record.adminId);}}>{text}</Link>)
    },
    {
      title: "短剧名称",
      width: 130,
      align: "center",
      dataIndex: "bookName",
      key: "bookName",
      render: (text,record) => (<Link onClick={() => toThree(record)}>{text}</Link>)
    },
    {
      title: "剧场名称",
      width: 100,
      align: "center",
      dataIndex: "appName",
      key: "appName",
    },
    {
      title: "消耗",
      width: 100,
      align: "center",
      dataIndex: "cost",
      key: "cost",
      sorter: {},
      render: (value) => (
        value || 0
      )
    },
    {
      title: "ECPM当日收益",
      width: 100,
      align: "center",
      dataIndex: "today_ad_cash",
      key: "today_ad_cash",
      sorter: {},
      render: (_, record) => (
        record?.todayAdCash || 0
      )
    },
    {
      title: "ECPM总收益",
      width: 100,
      align: "center",
      dataIndex: "total_ad_cash",
      key: "total_ad_cash",
      sorter: {},
      render: (_, record) => (
        record?.totalAdCash || 0
      )
    },
    {
      title: "新增回收率",
      width: 80,
      align: "center",
      dataIndex: "new_recovery_rate",
      key: "new_recovery_rate",
      sorter: {},
      render: (_,record) => (
        record?.newRecoveryRate === 0 ? '0.00%' : record?.newRecoveryRate + '%'
      )
    },
    {
      title: "总回收率",
      width: 80,
      align: "center",
      dataIndex: "total_recovery_rate",
      key: "total_recovery_rate",
      sorter: {},
      render: (_,record) => (
        record?.totalRecoveryRate === 0 ? '0.00%' : record?.totalRecoveryRate + '%'
      )
    },
    {
      title: "当天新建广告数",
      width: 80,
      align: "center",
      dataIndex: "ad_Count",
      key: "ad_Count",
      sorter: {},
      render: (_, record) => (record.adCount || 0)
    },
    {
      title: "当天新建广告数(有消耗)",
      width: 100,
      align: "center",
      dataIndex: "ad_New_Active_Count",
      key: "ad_New_Active_Count",
      sorter: {},
      render: (_, record) => (record.adNewActiveCount || 0)
    },
    {
      title: "在消耗广告数",
      width: 80,
      align: "center",
      dataIndex: "active_Ad_Count",
      key: "active_Ad_Count",
      sorter: {},
      render: (_, record) => (record.activeAdCount || 0)
    },
    {
      title: "当天新建账户数(含老剧)",
      width: 100,
      align: "center",
      dataIndex: "account_Count",
      key: "account_Count",
      sorter: {},
      render: (_, record) => (record.accountCount || 0)
    },
    {
      title: "在消耗账户数",
      width: 80,
      align: "center",
      dataIndex: "active_Account_Count",
      key: "active_Account_Count",
      sorter: {},
      render: (_, record) => (record.activeAccountCount || 0)
    },
  ]

  const toLink = val => {
    if(props.changeTab){
      props.changeTab(val)
    }
  }

  return (
    <>
      <Card
        title={
          <Form layout="inline" form={form}>
            <Form.Item label="日期" name="dates">
              <RangePicker defaultValue={[today, today]} allowClear={false}/>
            </Form.Item>
            <Form.Item label="短剧名称" name="bookName">
              <Input placeholder="输入短剧名称" allowClear />
            </Form.Item>
            {props.curKey !== 1 && <Form.Item label="投手" name="adminId">
              <Select
                allowClear
                showSearch
                placeholder="请选择投手"
                options={pitcherOptions}
                style={{ width: 150 }}
                filterOption={filterOption}
              />
            </Form.Item>}
            
            <Form.Item label="剧场" name="appName">
              <Select
                allowClear
                showSearch
                placeholder="请选择剧场"
                options={appOptions}
                style={{ width: 150 }}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item label colon={false}>
              <Space>
                <Button type="primary" onClick={onSubmit}>
                  确定
                </Button>
                <Button onClick={onReset}>重置</Button>
              </Space>
            </Form.Item>
          </Form>
        }
        extra={
          <div style={{width: 200}}>
            <Row gutter={12}>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总计消耗</div>}
                  value={statisticsData?.cost}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总收益</div>}
                  value={statisticsData?.totalAdCash}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>新增收益</div>}
                  value={statisticsData?.todayAdCash}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              {/* <Col span={7}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总回收</div>}
                  value={statisticsData?.money}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col> */}
              <Col span={8}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>总回收率</div>}
                  value={statisticsData?.totalRecoveryRate + '%'}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
              <Col span={9}>
                <Statistic
                  title={<div style={{ fontSize: 12 }}>新增回收率</div>}
                  value={statisticsData?.newRecoveryRate + '%'}
                  precision={2}
                  valueStyle={{ color: "#44B4A6", fontSize: "12px" }}
                />
              </Col>
            </Row>
          </div>
        }
      >
        <Table
          dataSource={list}
          columns={props.curKey === 1 ? columnsNew : columnsTwo}
          total={total}
          page={page}
          key={props.curKey}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
          pageOptions={[20, 50, 80]}
          onChange={onSortChange}
        />
      </Card>
    </>
  );
}
