const columns = () => [
    {
        title: "操作时间",
        width: 80,
        align: "center",
        dataIndex: "create_time",
        key: "create_time",
    },
    {
        title: "操作人员",
        width: 100,
        align: "center",
        dataIndex: "operator",
        key: "operator",
    },
    {
        title: "操作动作",
        width: 50,
        align: "center",
        dataIndex: "content_title",
        key: "content_title",
    },
    {
        title: "操作结果",
        width: 300,
        align: "center",
        dataIndex: "content_log",
        key: "content_log",
        render:(value,render)=> (
            <>
                <div style={{maxHeight:'100px',overflow:'scroll'}}>
                    {value}
                </div>
            </>
        ),
    },
]
export default columns;
