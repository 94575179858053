import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import React, { useState } from "react";
import Table from "../../../components/TableNoSelection";
import columns from "./columns";
import { useEffect } from "react";
import {
  getPlayletPackage,
  playletConfigAdd,
  playletConfigDelete,
  playletConfigList,
  playletConfigUpdate,
} from "../../../api/playlet";

const {confirm} = Modal

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [show, setShow] = useState(false);
  const [operate, setOperate] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setLoading(true);
    const res = await playletConfigList();
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  // 获取所有的短剧
  const getPlaylets = async () => {
    const res = await getPlayletPackage();
    if (res?.code === 200) {
      const { data } = res;
      let lists = data?.lists;
      let newLists = lists.map((item) => {
        return { label: item.aliasname, value: item.app_type };
      });
      setPackages(newLists);
    }
  };

  // 添加弹窗
  const descAdd = () => {
    getPlaylets();
    setOperate("add");
    setShow(true);
  };

  // 关闭弹窗
  const modalClose = () => {
    form.resetFields();
    setShow(false);
    setConfirmLoading(false)
  };

  // 过滤换行
  function clearBr(key) {
    key = key.replace(/<\/?.+?>/g, ",");
    key = key.replace(/[\r\n]/g, ",");
    return key;
  }

  // 表单提交
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        setConfirmLoading(true);
        let params = form.getFieldsValue();
        params.desc = clearBr(params.desc).split(",");
        let filtered = packages.filter((item) => {
          return item.value === params.app_type;
        });
        params.playlet_name = filtered.length > 0 ? filtered[0]?.label : "";
        if (operate === "update" && params["id"]) {
          doEdit(params);
        } else {
          doAdd(params)
        }
      })
      .catch(() => {
        return;
      });
  };

  // 处理添加
  const doAdd = async (params) => {
    const res = await playletConfigAdd(params);
    if (res?.code === 200) {
      message.success("添加成功");
      setTimeout(() => {
        modalClose();
        getList();
      }, 500);
    } 
    setConfirmLoading(false);
  };

  // 处理编辑
  const doEdit = async (params) => {
    const res = await playletConfigUpdate(params);
    if (res?.code === 200) {
      message.success("编辑成功");
      setTimeout(() => {
        modalClose();
        getList();
      }, 500);
    }
    setConfirmLoading(false);
  };

  // 编辑
  const handleEdit = (record) => {
    let desc = JSON.parse(record?.desc).join(",");
    desc = desc.replace(/[,]/g, "\r\n");
    getPlaylets();
    setOperate("update");
    setShow(true);
    form.setFieldsValue({ app_type: record?.app_type, desc, id: record?.id });
  };

  // 删除
  const handleDel = (record) => {
    confirm({
      title: `确定删除 ${record?.playlet_name}配置说明内容?`,
      onOk: async() => {
        const res = await playletConfigDelete({id: record?.id})
        if (res?.code === 200) {
          message.success("删除成功");
          setTimeout(() => {
            setConfirmLoading(false);
            modalClose();
            getList();
          }, 500);
        }
      }
    })
  };

  return (
    <>
      <Card
        title={
          <Button type="primary" onClick={descAdd}>
            投放说明添加
          </Button>
        }
      >
        <Table
          dataSource={list}
          columns={columns({ handleEdit, handleDel })}
          loading={loading}
          pagination={false}
        />
      </Card>
      <Modal
        open={show}
        title={operate === "add" ? "投放说明添加" : "投放说明编辑"}
        onCancel={modalClose}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
      >
        <Divider />
        <Form labelCol={{ sm: { span: 4 } }} form={form}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="剧场" name="app_type" rules={[{ required: true }]}>
            <Select
              allowClear
              placeholder="选择剧场"
              options={packages}
              disabled={operate === "update"}
            />
          </Form.Item>
          <Form.Item label="说明内容" name="desc" rules={[{ required: true }]}>
            <Input.TextArea
              allowClear
              placeholder="配置规则说明内容, 多条换行"
              rows={6}
            ></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
