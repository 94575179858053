import React from 'react'
import OrderComponent from '../Public/Order/index'
import { useState } from 'react'
import { useEffect } from 'react'
import { playletOrder } from '../../../api/playlet'

export default function Order() {
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [conditions, setConditions] = useState([])
  const [totalMoney, setTotalMoney] = useState(0)

  useEffect(() => {
    getList()
  }, [])

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true)
    const res = await playletOrder({...params, app_type: 1008})
    const { data } = res
    if (res?.code === 200) {
      setTotalMoney(data?.totalAmount)
      setList(data?.lists)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 日期条件筛选
  const onSearch = params => {
    setConditions(params)
    getList(params)
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    let params = { page, pageSize: size, ...conditions }
    getList(params)
  }

  // 排序
  const onChange = (pagination, filter, sorter) => {
    let field = sorter.field
    let orderType = sorter.order === 'ascend' ? 'asc' : 'desc'
    let order = `${field}_${orderType}`
    if (field !== undefined) {
      getList({ ...conditions, order })
    }
  }

  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage: pageSize,
    onSearch, onChange, app_type: 1008,
    totalMoney
  }
  return (
    <div className='yingtan_order'>
        <OrderComponent {...propsData} />
    </div>
  )
}
