import { Form, Input, Modal, Radio } from 'antd'
import React from 'react'

const {TextArea} = Input

export default function SaveModal(props) {
    const { showModal, close, saveType, submit, form, loading } = props

    const layout = {
        labelCol: { sm: { span: 5 } },
        wrapperCol: { xl: { span: 16 } }
    }

    // form表单提交
    const formSubmit = () => {
        form.validateFields().then(() => {
            const values = form.getFieldValue()
            submit(values)
        }).catch(() => {
            return
        })
    }

    return (
        <Modal
            title={saveType === 'add' ? '添加角色' : '修改角色'}
            open={showModal}
            onCancel={close}
            width={650}
            onOk={formSubmit}
            confirmLoading={loading}
        >
            <Form
                initialValues={{ status: 1, sort: 0 }}
                {...layout}
                form={form}
                // labelAlign='left'
            >
                <Form.Item name='id' style={{display: 'none'}}><Input /></Form.Item>
                <Form.Item label="角色名称" name='name' rules={[{ required: true, message: '请输入角色名称' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="角色字符" name='role_key' rules={[{ required: true, message: '请输入角色字符' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="显示顺序" name='sort'>
                    <Input type='number' min={0} />
                </Form.Item>
                <Form.Item label="角色状态" name='status' rules={[{ required: true, message: '请选择角色状态' }]}>
                    <Radio.Group>
                        <Radio value={0}>停用</Radio>
                        <Radio value={1}>启用</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="角色描述" name='description'>
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}
