import { Card, Divider, Form, Input, Modal, Select, message } from "antd";
import React, { useState } from "react";
import Table from "../../../../components/TableHasTotal";

export default function Index(props) {
  const [selectKeys, setSelectKeys] = useState([]);
  const { isShow, closeModal, handleSure, list } = props;

  const columns = [
    {
      title: "ID",
      width: 100,
      align: "center",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "投手名称",
      width: 120,
      align: "center",
      dataIndex: "nickname",
      key: "nickname",
    },
  ];

  // 根据投手名称筛选列表
  const onSearch = (value) => {
    props.onSearch({nickname: value})
  };

  // 根据组织架构筛选列表
  const orgOnchange = value => {
    props.onSearch({org_id: value})
  }

  // 搜索
  const filterOption = (inputValue, option) => {
    const { label } = option;
    return label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  // 全选或全不选
  const selectRows = (selectRows) => {
    setSelectKeys(selectRows);
  };

  // 投手确认选中
  const onSelected = () => {
    if (selectKeys.length === 0) {
      message.error("还未选择投手", 2);
      return;
    }
    handleSure(selectKeys);
    closeModal();
  };

  return (
    <>
      <Modal
        open={isShow}
        onCancel={closeModal}
        width={600}
        style={{ height: 600, overflowY: "auto" }}
        destroyOnClose={true}
        onOk={onSelected}
      >
        <Divider />
        <Card
          title={
            <Input.Search
              allowClear
              placeholder="输入投手名称"
              enterButton="搜索"
              onSearch={onSearch}
              style={{width: 250}}
            />
          }
          extra={
            <Select
              allowClear
              placeholder="请选择组织架构"
              showSearch
              options={props.orgOptions}
              filterOption={filterOption}
              style={{ width: 180 }}
              onChange={orgOnchange}
            />
          }
        >
          <Table
            dataSource={list}
            columns={columns}
            pagination={false}
            selectRows={selectRows}
            loading={props.loading}
          />
        </Card>
      </Modal>
    </>
  );
}
