import React, { useState } from 'react'
import screenfull from 'screenfull'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

/** 全屏缩放组件 */
export default function Screenfull() {
    const [fullscreen, setFullscreen] = useState(false)

    const showHandle = () => {
        const element = document.body;
        screenfull.toggle(element);
        setFullscreen(!fullscreen)
    }
    return (
        <span className='screenfull' onClick={showHandle} style={{cursor: 'pointer'}}>
            <Tooltip title='全屏缩放'>
                {fullscreen ? <FullscreenExitOutlined style={{ fontSize: '25px' }} /> : <FullscreenOutlined style={{ fontSize: '25px' }}  />}
            </Tooltip>
        </span>
    )
}
