import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import Table from "../../../../components/TableNoSelection";
import columns from "./columns";
import { useEffect } from "react";
import {
  addAgentGuan,
  getAgentGuanList,
  unbindAgentGuan,
} from "../../../../api/advertise";

const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

export default function Index() {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchType, setSearchType] = useState(1);

  const [form] = Form.useForm();

  useEffect(() => {
    getList();
  }, []);

  // 获取管家列表
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getAgentGuanList(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 添加管家弹窗
  const addGuan = () => {
    setShow(true);
  };

  // 关闭弹窗
  const onCancel = () => {
    setShow(false);
    setConfirmLoading(false);
    form.resetFields();
  };

  const onChange = (value) => {
    setSearchType(value);
  };

  const selectBefore = (
    <Select defaultValue={1} onChange={onChange} style={{ width: 100 }}>
      <Option value={1}>管家id</Option>
      <Option value={2}>管家名称</Option>
    </Select>
  );

  // 条件查询
  const onSearch = (value) => {
    let params = {};
    if (value) {
      searchType === 1
        ? (params.account_id = value)
        : (params.account_name = value);
    }
    getList(params);
  };

  // 表单提交
  const handleSubmit = () => {
    form.validateFields().then(async () => {
      setConfirmLoading(true);
      let values = form.getFieldsValue();
      const res = await addAgentGuan(values);
      if (res?.code === 200) {
        message.success("添加成功", 2);
        getList();
        onCancel();
      } else {
        setConfirmLoading(false);
      }
    });
  };

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ page, pageSize: size });
  };

  // 解绑管家
  const unbindGuan = (record) => {
    confirm({
      title: `确定解绑管家-${record?.account_name}?`,
      onOk: async () => {
        const res = await unbindAgentGuan({ ...record });
        if (res?.code === 200) {
          message.success("管家已解绑");
          setTimeout(() => {
            getList();
          }, 500);
        }
      },
    });
  };

  return (
    <>
      <Divider orientation="left" plain={true}>
        管家列表
      </Divider>
      <Card
        title={
          <Button type="primary" icon={<PlusOutlined />} onClick={addGuan}>
            添加管家
          </Button>
        }
        extra={
          <Search
            placeholder={searchType === 1 ? "输入管家id" : "输入管家名称"}
            allowClear
            enterButton="搜索"
            addonBefore={selectBefore}
            onSearch={onSearch}
          />
        }
      >
        <Table
          dataSource={list}
          columns={columns({ unbindGuan })}
          total={total}
          page={page}
          perPage={perPage}
          loading={loading}
          pagesize={pagesize}
        />
      </Card>

      {/* 添加管家弹窗 */}
      <Modal
        open={show}
        onCancel={onCancel}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="管家id"
            name="advertiser_id"
            rules={[{ required: true, message: "请输入管家id" }]}
          >
            <Input placeholder="请输入管家id" allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
