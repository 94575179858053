/*
 * axios全局配置
 */
import axios from "axios";
import { message } from "antd";
import store from "../redux/store";
import { deleteUserInfo } from "../redux/actions/login";
import qs from "qs";
import { BASE_URL } from "../config";

console.log(process.env.NODE_ENV, 'process.env.NODE_ENV')
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ?  "" : "https://japi.suyueyun.com/api/prod",
  timeout: 20000,
});

//配置请求拦截器
instance.interceptors.request.use(
  (config) => {
    //从redux中获取之前保存的token
    let token = store.getState().user.token;
    if (token) {
      //如果token存在，就设置到请求头中
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//配置响应拦截器
instance.interceptors.response.use((response) => {
  if (response.data.code === 200) {
    return response.data;
  } else if (response.data.code === 403 || response.data.code === 401) {
    message.warning(`${response.data.message}，请重新登录`, 2);
    //分发删除用户信息的action
    store.dispatch(deleteUserInfo());
    // 创建资产失败
  } else if (response.data.code === 202) {
    message.error(`${response.data.message}`, 2);
    return response.data;
  } else {
    //提示消息
    if (response?.config?.severTip) {
      message.error(`${response?.data?.message}`, 2);
    };
    return Promise.reject(response?.data?.message);
  }
  //中断Promise链
  return new Promise(() => {});
}, (err) => {
  console.log(err, 'errrrrrrrrrr');
  if (err && err.stack.indexOf('Network Error') > -1) {
    message.error('网络错误');
  } else if (err && err.stack.indexOf('503') > -1) {
    message.error('服务不可用 503');
  } else if (err && err.stack.indexOf('500') > -1) {
    message.error('服务器内部错误 500');
  };
  return {};
});

export const getRequest = (url = "", data = {}, option = {}) => {
  const defaultOption = { timeout: 20000, severTip: true };
  const _option = Object.assign({}, defaultOption, option);
  if (qs.stringify(data)) {
    url += url.includes("?")
      ? "&" + qs.stringify(data)
      : "?" + qs.stringify(data);
  }
  return instance.get(url, _option);
};

export const postRequest = (url = "", data = {}, option = {}) => {
  const defaultOption = { timeout: 20000, severTip: true }
  const _option = Object.assign({}, defaultOption, option);
  return instance.post(url, data, _option);
};
