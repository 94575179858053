import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  batchAdSaveBid,
  batchQiliangConfigSet,
  getAdToutiaoPromotions,
  getBidUpdateNum,
  getKeywordAccountList,
  getQiliangDetail,
  handleBatchDeleteADPlan,
  handleBatchUpdatePlanStatus,
  handleDeletePlan,
  handleUpdatePlanStatus,
  qiliangConfigSet,
  qiliangStatusCheck,
  qiliangStop,
  saveBidData,
} from "../../../api/advertise";
import columns from "./columns";
import "./index.less";
import EditableCell from "../../../components/Editable/EditableCell";
import dayjs from "dayjs";
import { CloseOutlined, createFromIconfontCN } from "@ant-design/icons";

const { confirm } = Modal;

export default function Index() {
  const [list, setList] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [spinning, SetSpinning] = useState(false);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [newBid, setNewBid] = useState(0);
  const [bidNum, setBidNum] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [batchOperateType, setBatchOperateType] = useState(0);
  const [bidModalShow, setBidModalShow] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [qiliangType, SetQiliangType] = useState("single");
  const [raiseStatus, setRaiseStatus] = useState("");
  const [qiliangItems, setQiliangItems] = useState([]);
  const [startType, setStartType] = useState([]);
  const [measureShow, setMeasureShow] = useState(false);
  const [qiliangVersions, setQiliangVersions] = useState([]);
  const [measureLoading, setMeasureLoading] = useState(false);
  const [measureDetailShow, setMeasureDetailShow] = useState(false);
  const [qiliangLoading, setQiliangLoading] = useState(false);
  const [measureDetailLoading, setMeasureDetailLoading] = useState(false);
  const [detailRaiseStatus, setDetailRaiseStatus] = useState("");
  const [qiliangData, setQiliangData] = useState([]);

  const [bidForm] = Form.useForm();
  const [measureForm] = Form.useForm();
  const [measureDetailForm] = Form.useForm();

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    const res = await getAdToutiaoPromotions(params);
    if (res?.code === 200) {
      const { data } = res;
      const lists = data?.lists;
      const newlist = lists.map((item) => {
        item.key = item.id;
        return item;
      });
      setList(newlist);
      setTotal(data?.total);
      setLoading(false);
    }
  };

  // 获取广告主列表
  const getAccountList = async (params = {}) => {
    SetSpinning(true)
    const res = await getKeywordAccountList(params);
    if (res?.code === 200) {
      const { data } = res;
      setAdvertiserOptions(data);
      SetSpinning(false)
      setList([]);
    }
  };

  // 关键词搜索
  const handleSearch = (values) => {
    getAccountList(values);
  };

  // 切换广告账户拉取素材列表数据
  const onAdvertiseSelect = async (e, item) => {
    e.stopPropagation();
    let advertiserId = item?.account_id
    if (advertiserId) {
      setConditions({ advertiser_id: advertiserId, account_name: item?.account_name });
      setPage(1)
      setPerPage(10)
      getList({ advertiser_id: advertiserId, account_name: item?.account_name });
    } else {
      setConditions([]);
      setList([]);
    }
  };

  // 计划状态修改
  const updatePlanStatus = async (record, status) => {
    let params = {
      advertiser_id: record?.advertiser_id,
      ad_id: record?.promotion_id,
      status,
    };
    const res = await handleUpdatePlanStatus(params);
    if (res?.code) {
      message.success("状态修改成功", 1);
      getList({ ...conditions });
    }
  };

  // 删除计划
  const deletePlan = async (record) => {
    let params = {
      advertiser_id: record?.advertier_id,
      ad_id: record?.promotion_id,
    };
    const res = await handleDeletePlan(params);
    if (res?.code) {
      message.success("头条计划已删除", 1);
      getList({ ...conditions });
    }
  };

  //修改出价回调
  const changeBid = async (value) => {
    setNewBid(value);
  };

  //修改出价
  const editBid = (record) => {
    return (
      <>
        <card>
          <InputNumber min={1} max={500} onChange={changeBid} />
          <br />
          <Tag color="red">修改次数: {bidNum}</Tag>
        </card>
      </>
    );
  };

  //出价修改提交
  const saveBid = async (record) => {
    const res = await saveBidData({
      advertiser_id: record?.advertiser_id,
      ad_id: record?.promotion_id,
      new_bid: newBid,
    });
    if (res?.code == 200) {
      message.success("出价修改成功", 1);
      getList({ ...conditions });
    }
    return;
  };

  const updateBidNum = async (record) => {
    //验证当前次数
    const result = await getBidUpdateNum({ ad_id: record?.promotion_id });
    let hasNum = 0;
    if (result?.code == 200) {
      hasNum = result?.data?.num;
    }
    setBidNum(hasNum);
  };

  const showTimeDivision = () => {
    
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page);
    setPerPage(size);
    getList({ ...conditions, page, pageSize: size });
  };

  // 消耗 、 转化数排序
  const onTableChange = (pagination, filter, sorter) => {
    let field = sorter.field;
    let orderType = sorter.order === "ascend" ? "asc" : "desc";
    let order = `${field}_${orderType}`;
    if (field !== undefined) {
      getList({ ...conditions, order });
    }
  };

  // 表单条件筛选查询
  const handleSubmit = (values) => {
    getList({ ...conditions, ...values });
  };

  // 批量处理
  const batchOperate = (e) => {
    let value = e.target.value;
    setSelectedRowKeys([]);
    setBatchOperateType(value);
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  // 批量操作时，复选框是否可选
  const getBatchDisabled = (record) => {
    if (batchOperateType === 1) {
      return false;
    } else if (batchOperateType === 2) {
      return (
        record?.status === "DISABLED" || record?.status === "AD_STATUS_DISABLE"
      );
    } else if (batchOperateType === 3) {
      return record?.cpa_bid === "0.00" || record?.cpa_bid === 0;
    } else if (batchOperateType === 4) {
      return (
        record?.qi_liang?.status === "RAISING" ||
        record?.plan_ad_create?.cpa_bid === "0.00"
      );
    }
  };

  // 批量选中
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",

    // 选择框是否可选
    getCheckboxProps: (record) => ({
      disabled: getBatchDisabled(record),
      name: record.name,
    }),
  };

  // 批量操作按钮是否可点击操作
  const hasSelected = selectedRowKeys.length > 0;

  // 批量删除操作
  const batchAdDelete = () => {
    let params = {
      advertiser_id: conditions?.advertiser_id,
      ad_id: selectedRowKeys,
    };
    confirm({
      title: "确认删除选中的广告?",
      onOk: async () => {
        await handleBatchDeleteADPlan(params);
        setTimeout(() => {
          message.success("批量删除操作成功");
          getList({ ...conditions });
        }, 2000);
      },
    });
  };

  // 批量暂停操作
  const batchAdStatus = () => {
    let status = batchOperateType === 2 ? "DISABLE" : "ENABLE";
    let params = {
      advertiser_id: conditions?.advertiser_id,
      ad_id: selectedRowKeys,
      status,
    };
    confirm({
      title: `确认${batchOperateType === 2 ? "暂停" : "启用"}选中的广告?`,
      onOk: async () => {
        const res = await handleBatchUpdatePlanStatus(params);
        if (res?.code === 200) {
          message.success("批量暂停操作成功");
          getList({ ...conditions });
        }
      },
    });
  };

  const bidInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(parseFloat(value))) {
      bidForm.setFieldsValue({ bid: "" });
    }
  };

  // 批量修改出价
  const batchUpdateBidPrice = () => {
    setBidModalShow(true);
  };

  const bidModalClose = () => {
    bidForm.resetFields();
    setSelectedRowKeys([]);
    setBidModalShow(false);
    setConfirmLoading(false);
  };

  // 处理批量出价
  const handleBatchUpdate = async () => {
    setConfirmLoading(true);
    let bidPrice = bidForm.getFieldValue("bid");
    if (!bidPrice || parseFloat(bidPrice) === 0) {
      message.error("请输入修改的出价");
      setConfirmLoading(false);
      return false;
    }
    let params = {
      advertiser_id: conditions?.advertiser_id,
      ad_id: selectedRowKeys,
      bid: parseFloat(bidPrice),
    };
    await batchAdSaveBid(params);
    setTimeout(() => {
      message.success("修改出价成功");
      bidModalClose();
      getList({ ...conditions });
      setConfirmLoading(false);
    }, 2000);
  };

  // 生效时间切换
  const onTypeChange = (e, fieldName) => {
    let v = e.target.value;
    let value = { [`type-${fieldName}`]: v };
    let params = startType;
    if (params.length === 0) {
      params = [value];
    } else {
      let filtered = params.filter((item) => {
        return (
          JSON.stringify(Object.keys(item)) !==
          JSON.stringify([`type-${fieldName}`])
        );
      });
      filtered.push(value);
      params = filtered;
    }
    setStartType(params);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  // 批量一键起量
  const batchQiliang = () => {
    setMeasureShow(true);
    SetQiliangType("batch");
  };

  // 启量详情
  const qiliangDetail = async (params) => {
    // 检查起量状态，并保存入库
    qiliangStatusCheck(params);

    setDetailRaiseStatus(params?.qi_liang?.status);
    setMeasureDetailShow(true);
    setQiliangLoading(true);
    measureDetailForm.setFieldsValue({
      advertiser_id: params?.advertiser_id,
      ad_id: params?.ad_id,
    });
    const res = await getQiliangDetail({
      ...params,
      advertiser_id: params?.advertiser_id,
      ad_id: params?.ad_id,
    });
    if (res?.code === 200) {
      const { data } = res;
      let versions = data?.version;
      let newVersions = versions?.map((item) => {
        let label =
          dayjs(item.start_time).format("YYYY-MM-DD HH:mm") +
          " ~ " +
          dayjs(item.end_time).format("YYYY-MM-DD HH:mm");
        if (dayjs(item?.end_time).isAfter(dayjs())) {
          label = dayjs(item.start_time).format("YYYY-MM-DD HH:mm") + " ~ 现在";
        }
        return { label, value: item.raise_version };
      });
      setQiliangData(data);
      if (newVersions) {
        measureDetailForm.setFieldsValue({
          raise_version: newVersions[0]?.value,
        });
      }
      setQiliangVersions(newVersions);
      setQiliangLoading(false);
    }
  };

  // 开启一键启量
  const handleEable = (record) => {
    SetQiliangType("single");
    // 检查起量状态，并保存入库
    qiliangStatusCheck(record);

    let status = record?.raise_status;
    setRaiseStatus(status);
    measureForm.setFieldsValue({
      advertiser_id: record?.advertiser_id,
      promotion_id: record?.promotion_id,
    });

    // 这里主要是方便判断是否为disabled
    let info = record?.raise_info;
    if (status === "RAISING") {
      let filterItem = info.filter((item) => {
        return (
          dayjs(item?.start_time).isBefore(dayjs()) &&
          dayjs(item?.end_time).isAfter(dayjs())
        );
      });
      setQiliangItems(filterItem);
    }

    // 只有起量状态为进行中或已预约状态时，才回显之前的设置选项
    if (status === "RAISING") {
      const startTypeParams = info.map((item, key) => {
        return { [`type-${key}`]: false };
      });
      setStartType(startTypeParams);
    }
    setMeasureShow(true);
  };

  // 关闭一键启量弹窗
  const onMeasureClose = () => {
    measureForm.setFieldsValue({
      items: [{ is_effective_now: true }],
      advertiser_id: 0,
      promotion_id: 0,
    });
    setStartType([]);
    setMeasureShow(false);
    setQiliangVersions([]);
    setMeasureLoading(false);
    setSelectedRowKeys([]);
    getList(conditions);
  };

  // 一键启量表单提交
  const measureFormSubmit = () => {
    measureForm
      .validateFields()
      .then(async () => {
        setMeasureLoading(true);
        let params = measureForm.getFieldsValue();
        let newParams = {};
        let items = params["items"].map((item, key) => {
          item.version = key + 1;
          if (item["raise_time"]) {
            let createdAt;
            createdAt = item["raise_time"].format("YYYY-MM-DD HH:mm");
            if (
              qiliangItems &&
              qiliangItems[key] === undefined &&
              Date.parse(createdAt) < dayjs().valueOf()
            ) {
              message.error("起量时间不能小于当前时间");
              return "error";
            }
            item.raise_time = createdAt;
          }
          delete item.cycle;
          if (item.is_effective_now === true) {
            delete item.raise_time;
          } else {
            item["appointed_time"] = {
              raise_time: item["raise_time"],
            };
            delete item.raise_time;
          }
          return item;
        });
        if (items.includes("error")) {
          return false;
        }
        if (raiseStatus === "RAISING") {
          items.unshift(qiliangItems);
        }
        newParams.items = items;
        let res = {};
        if (qiliangType === "single") {
          newParams.advertiser_id = params.advertiser_id;
          newParams.promotion_id = params.promotion_id;
          console.log(newParams);
          res = await qiliangConfigSet(newParams);
        } else if (qiliangType === "batch") {
          let filtered = list.filter((item) => {
            return selectedRowKeys.includes(item.id);
          });
          let options = filtered?.map((item) => {
            return {
              advertiser_id: item?.advertiser_id,
              ad_id: item?.promotion_id,
            };
          });
          res = await batchQiliangConfigSet({ items, options });
        }
        if (res?.code === 200) {
          message.success("一键起量设置成功");
          setTimeout(() => {
            onMeasureClose();
            setMeasureLoading(false);
            getList(conditions);
          }, 1000);
        } else {
          setMeasureLoading(false);
        }
      })
      .catch(() => {
        return;
      });
  };

  // 关闭启量详情
  const onMeasureDetailClose = () => {
    setMeasureDetailShow(false);
    setMeasureDetailLoading(false);
    setSelectedRowKeys([]);
    getList(conditions);
  };

  // 切换起量时间段
  const onQiliangTimeChange = (value) => {
    qiliangDetail({
      ...measureDetailForm.getFieldsValue(["advertiser_id", "ad_id"]),
      version: value,
    });
  };

  // 关闭一键起量
  const handleQiliangStop = async () => {
    confirm({
      title: "确定关闭起量?",
      onOk: () => {
        setMeasureDetailLoading(true);
        const res = qiliangStop(
          measureDetailForm.getFieldsValue(["advertiser_id", "ad_id"])
        );
        if (res?.code === 200) {
          message.success("广告一键起量已关闭");
          setTimeout(() => {
            onMeasureDetailClose();
            getList(conditions);
          }, 500);
        } else {
          setMeasureDetailLoading(false);
        }
      },
    });
  };

  const MyIcon = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_8jxoc34pinv.js", // 在 iconfont.cn 上生成
  });

  return (
    <>
      <Card
        title={
          <>
            <Form layout="inline" onFinish={handleSearch}>
              <Form.Item label="广告账户筛选" name="keyword">
                <Input placeholder="通过关键词搜索广告账户" allowClear />
              </Form.Item>
              <Form.Item label colon={false}>
                <Button htmlType="submit" type="primary">
                  查询
                </Button>
              </Form.Item>
            </Form>
            {advertiserOptions.length > 0 ? (
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  width: "100%",
                  height: 200,
                  borderRadius: 5,
                  overflowY: "auto",
                }}
              >
                <ul className="container">
                  {advertiserOptions?.map((item) => {
                    return (
                      <li key={item.account_id} className="item">
                        <Space>
                          <Typography.Text
                            style={{ width: "100%" }}
                            ellipsis={{ tooltip: item?.account_name }}
                          >
                            <Link
                              onClick={(e) => {
                                onAdvertiseSelect(e, item);
                              }}
                            >
                              {item?.account_name}
                            </Link>
                          </Typography.Text>
                        </Space>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <Spin spinning={spinning} />
            )}
          </>
        }
        size="small"
      >
        <Card
          title={
            <Space>
              <Radio.Group
                options={[
                  { label: "批量删除", value: 1 },
                  { label: "批量暂停", value: 2 },
                  { label: "批量启用", value: 3 },
                  { label: "批量修改出价", value: 4 },
                  { label: "批量一键起量", value: 5 },
                ]}
                size="small"
                onChange={batchOperate}
              />
              {hasSelected ? (
                <Button
                  type="link"
                  onClick={
                    batchOperateType === 1
                      ? batchAdDelete
                      : batchOperateType === 2 || batchOperateType == 3
                      ? batchAdStatus
                      : batchOperateType === 4
                      ? batchUpdateBidPrice
                      : batchQiliang
                  }
                >
                  <>
                    {batchOperateType === 1
                      ? "批量删除"
                      : batchOperateType === 2
                      ? "批最暂停"
                      : batchOperateType === 3
                      ? "批量启用"
                      : batchOperateType === 4
                      ? "批量修改出价"
                      : "批量一键起量"}
                  </>
                </Button>
              ) : (
                ""
              )}
            </Space>
          }
          type="inner"
        >
          <Form layout="inline" onFinish={handleSubmit} size="small">
            <Form.Item label="广告名称" name="name">
              <Input allowClear placeholder="通过关键词搜索广告" />
            </Form.Item>
            <Form.Item label="广告状态" name="status">
              <Select
                allowClear
                placeholder="选择广告状态"
                options={[
                  { label: "投放中", value: "OK" },
                  { label: "已暂停", value: "DISABLED" },
                  { label: "已被项目暂停", value: "PROJECT_DISABLED" },
                  { label: "已删除", value: "DELETE" },
                ]}
                style={{ width: 150 }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
          <Table
            rowSelection={rowSelection}
            components={components}
            size="small"
            sticky
            dataSource={list}
            columns={columns({
              updatePlanStatus,
              deletePlan,
              editBid,
              saveBid,
              showTimeDivision,
              updateBidNum,
              handleEable,
              qiliangDetail,
            })}
            loading={loading}
            total={total}
            bordered={true}
            pagination={{
              total: total,
              showSizeChanger: true,
              showQuickJumper: total > 50 ? true : false,
              showTotal: (total) => `共 ${total} 条`,
              pageSizeOptions: [10, 20, 30],
              current: page || 1,
              onChange: pagesize,
              pageSize: perPage,
            }}
            onChange={onTableChange}
            scroll={{ x: 500 }}
          />
        </Card>
      </Card>

      {/* 批量修改出价弹窗 */}
      <Modal
        open={bidModalShow}
        onCancel={bidModalClose}
        title={
          <Space>
            <div>批量修改出价</div>
            <div>{`（已选择${selectedRowKeys.length}条广告）`}</div>
          </Space>
        }
        size="small"
        onOk={handleBatchUpdate}
        confirmLoading={confirmLoading}
      >
        <Form form={bidForm}>
          <Form.Item name="bid">
            <Input
              allowClear
              placeholder="请输入"
              suffix="元"
              onChange={bidInputChange}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* 启用一键起量 */}
      <Drawer
        title={
          <div>
            起量设置{" "}
            {qiliangType === "batch"
              ? "(已选择" + selectedRowKeys.length + "条广告)"
              : ""}
          </div>
        }
        placement="right"
        width={500}
        onClose={onMeasureClose}
        open={measureShow}
        footer={
          <Card style={{ textAlign: "right" }}>
            <Space>
              <Button onClick={onMeasureClose}>取消</Button>
              <Button
                type="primary"
                onClick={measureFormSubmit}
                loading={measureLoading}
              >
                保存
              </Button>
            </Space>
          </Card>
        }
      >
        <Card>
          <Form
            layout="vertical"
            form={measureForm}
            initialValues={{
              items: [{}],
            }}
          >
            <Form.Item hidden name="advertiser_id">
              <Input />
            </Form.Item>
            <Form.Item hidden name="promotion_id">
              <Input />
            </Form.Item>
            {raiseStatus === "RAISING" ? (
              <Card
                size="small"
                style={{ padding: 8, paddingBottom: 0, marginBottom: 10 }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: -15,
                    marginBottom: 10,
                  }}
                >
                  起量中
                </div>
                <Form.Item label="起量预算">
                  <Input
                    disabled
                    value={
                      (qiliangItems[0] &&
                        parseFloat(qiliangItems[0]?.raise_budget)) ||
                      0
                    }
                  />
                </Form.Item>
                <Form.Item label="预计结束时间">
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    value={dayjs(qiliangItems[0]?.end_time)}
                    disabled
                  />
                </Form.Item>
              </Card>
            ) : (
              ""
            )}
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      title={`起量方案 ${field.name + 1}`}
                      key={field.key}
                      extra={
                        qiliangItems[field.name] === undefined ? (
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : (
                          ""
                        )
                      }
                      bodyStyle={{ paddingBottom: 0 }}
                      style={{ marginBottom: -10 }}
                    >
                      <Form.Item
                        label="起量预算"
                        name={[field.name, "raise_budget"]}
                        rules={[{ required: true }]}
                      >
                        <Form.Item
                          name={[field.name, "raise_budget"]}
                          style={{ marginBottom: 0 }}
                        >
                          <Input placeholder="请输入起量预算" allowClear />
                        </Form.Item>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: 12 }}
                        >
                          该广告预算：9999999.00元，建议设置预算为110.00元，可为您带来最佳增量效果。
                        </Typography.Text>
                      </Form.Item>
                      <Form.Item
                        label="生效时间"
                        name={[field.name, "is_effective_now"]}
                        rules={[{ required: true }]}
                        initialValue={raiseStatus === "RAISING" ? false : true}
                        hidden={raiseStatus === "RAISING"}
                      >
                        <Radio.Group
                          onChange={(e) => onTypeChange(e, field.name)}
                          options={[
                            { label: "立即生效", value: true },
                            { label: "指定时间", value: false },
                          ]}
                          optionType="button"
                          disabled={
                            raiseStatus === "RAISING" &&
                            Object.keys(qiliangItems).includes(
                              field.name?.toString()
                            )
                          }
                        />
                      </Form.Item>
                      {startType.map((item) => {
                        if (
                          JSON.stringify(Object.keys(item)) ===
                            JSON.stringify([`type-${field.name}`]) &&
                          item[[`type-${field.name}`]] === false
                        ) {
                          return (
                            <Fragment key={[field.name, "cycle_key"]}>
                              <Form.Item
                                label="重复周期"
                                name={[field.name, "cycle"]}
                                initialValue={"only"}
                                rules={[{ required: true }]}
                                key={[field.name, "key"]}
                              >
                                <Radio.Group
                                  options={[{ label: "仅一次", value: "only" }]}
                                  optionType="button"
                                />
                              </Form.Item>
                              <Form.Item
                                label="起量时间"
                                name={[field.name, "raise_time"]}
                                rules={[{ required: true }]}
                                key={[field.name, "raise_time"]}
                                initialValue={dayjs().add(1, "hour")}
                              >
                                <DatePicker
                                  showTime
                                  format="YYYY-MM-DD HH:mm"
                                  disabledDate={disabledDate}
                                />
                              </Form.Item>
                            </Fragment>
                          );
                        }
                      })}
                    </Card>
                  ))}
                  <Form.Item label colon={false}>
                    <Button onClick={() => add()}>+ 新增起量方案</Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Form>
        </Card>
      </Drawer>

      {/* 一键起量详情 */}
      <Drawer
        title={<div>报告查看</div>}
        placement="right"
        width={700}
        onClose={onMeasureDetailClose}
        open={measureDetailShow}
        size="small"
      >
        {qiliangLoading ? (
          <Spin />
        ) : qiliangVersions?.length > 0 ? (
          <>
            <Card size="small">
              <Form layout="vertical" form={measureDetailForm}>
                <Form.Item hidden name="advertiser_id">
                  <Input />
                </Form.Item>
                <Form.Item hidden name="ad_id">
                  <Input />
                </Form.Item>
                <Form.Item label="起量报告" name="raise_version">
                  <Select
                    allowClear
                    placeholder="选择查看报告时间段"
                    style={{ width: "65%" }}
                    onChange={onQiliangTimeChange}
                    options={qiliangVersions}
                  />
                </Form.Item>
                <Form.Item label="总预算">
                  <div
                    style={{ fontWeight: "bold", fontSize: 30, marginTop: -10 }}
                  >
                    {parseFloat(qiliangData?.budget) > 0
                      ? qiliangData?.budget?.toFixed(2)
                      : 0}
                  </div>
                  <Form.Item style={{ marginBottom: -10 }}>
                    <Row gutter={16}>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>消耗</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.stat_cost !== 0
                              ? (
                                  (qiliangData?.incremental?.stat_cost /
                                    qiliangData?.base?.stat_cost) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(qiliangData?.base?.stat_cost) <
                              parseFloat(qiliangData?.incremental?.stat_cost) &&
                            parseFloat(qiliangData?.incremental?.stat_cost) >
                              0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础消耗 {qiliangData?.base?.stat_cost.toFixed(2)}
                              元
                            </li>
                            <li>
                              起量消耗
                              {qiliangData?.incremental?.stat_cost.toFixed(2)}元
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>展示</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.show_cnt !== 0
                              ? (
                                  (qiliangData?.incremental?.show_cnt /
                                    qiliangData?.base?.show_cnt) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(qiliangData?.base?.show_cnt) <
                              parseFloat(qiliangData?.incremental?.show_cnt) &&
                            parseFloat(qiliangData?.incremental?.show_cnt) >
                              0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础展示 {qiliangData?.base?.show_cnt}
                            </li>
                            <li>
                              起量展示 {qiliangData?.incremental?.show_cnt}
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>点击</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.click_cnt !== 0
                              ? (
                                  (qiliangData?.incremental?.click_cnt /
                                    qiliangData?.base?.click_cnt) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(qiliangData?.base?.click_cnt) <
                              parseFloat(qiliangData?.incremental?.click_cnt) &&
                            parseFloat(qiliangData?.incremental?.click_cnt) >
                              0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础点击 {qiliangData?.base?.click_cnt}
                            </li>
                            <li>
                              起量点击 {qiliangData?.incremental?.click_cnt}
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div
                          style={{
                            backgroundColor: "#F8F8F9",
                            width: 150,
                            padding: 8,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ fontSize: 12 }}>转化(计费时间)</div>
                          <span style={{ fontWeight: "bold", fontSize: 16 }}>
                            {qiliangData?.base?.attribution_convert_cnt !== 0
                              ? (
                                  (qiliangData?.incremental
                                    ?.attribution_convert_cnt /
                                    qiliangData?.base
                                      ?.attribution_convert_cnt) *
                                  100
                                ).toFixed(2) + "%"
                              : "0.00%"}
                            {parseFloat(
                              qiliangData?.base?.attribution_convert_cnt
                            ) <
                              parseFloat(
                                qiliangData?.incremental
                                  ?.attribution_convert_cnt
                              ) &&
                            parseFloat(
                              qiliangData?.incremental?.attribution_convert_cnt
                            ) > 0 ? (
                              <MyIcon type="icon-shangsheng" />
                            ) : (
                              <MyIcon type="icon-jiantouxiajiangxia" />
                            )}
                          </span>
                          <ul style={{ fontSize: 12, marginTop: 2 }}>
                            <li style={{ marginBottom: 5 }}>
                              基础转化
                              {qiliangData?.base?.attribution_convert_cnt}
                            </li>
                            <li>
                              起量转化
                              {
                                qiliangData?.incremental
                                  ?.attribution_convert_cnt
                              }
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Card>
            {detailRaiseStatus &&
            (detailRaiseStatus === "RAISING" ||
              detailRaiseStatus === "HAS_PRERAISE") ? (
              <div
                style={{
                  textAlign: "right",
                  marginTop: 50,
                  border: "1px solid #F0F0F0",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <Button
                  type="primary"
                  onClick={handleQiliangStop}
                  loading={measureDetailLoading}
                >
                  关闭起量
                </Button>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <Empty
            description={
              <div style={{ marginTop: 20 }}>
                <div style={{ fontSize: 12 }}>友情提示</div>
                <div style={{ fontSize: 12, color: "#9B9EA4" }}>
                  暂无起量报告数据，请在起量进行中或起量结束后查看数据报告
                </div>
              </div>
            }
          />
        )}
      </Drawer>
    </>
  );
}
