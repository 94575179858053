import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  createFromIconfontCN,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
  List,
  Menu,
  Modal,
  Popover,
  Space,
  message as Message
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header.less";
import menuList from "../../../config/menuConfig";
import Screenfull from "../../../components/Screenfull";
import { connect } from "react-redux";
import { deleteUserInfo, saveUserInfo } from "../../../redux/actions/login";
import { deleteMenu } from "../../../redux/actions/menu";
import ResetForm from "./form";
import { getMenuChildren, handleResetPasswd } from "../../../api";
import useWebSocket from "../../../hooks/useWebSocket";
import { getMessageNoticeList } from "../../../api/message";

function Header(props) {
  const { menu } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    getMessageList();
  }, []);

  const getMessageList = async () => {
    if (props?.message && props?.message?.length > 0) {
      setMessageList(props?.message);
    } else {
      const res = await getMessageNoticeList();
      if (res?.code === 200) {
        const { data } = res;
        setMessageList(data?.lists);
      }
    }
  };

  const { sendMessage, readyState, reconnect, message, ws } = useWebSocket({
    url: process.env.REACT_APP_WEBSOCK_RUL,
    verify: true,
  });

  if (ws) {
    // 获取从服务端推送过来的数据
    ws.onmessage = (e) => {
      let data = JSON.parse(e.data);
      setMessageList(data?.lists);
    };
  }

  const isJsonStr = (str) => {
    if (typeof str === "string") {
      try {
        let obj = JSON.parse(str);
        console.log("转换成功:" + obj);
        return true;
      } catch (error) {
        console.log("error:" + str + "!!!" + error);
      }
    }
    return false;
  };

  useEffect(() => {
    if (Object.keys(message)?.length !== 0 && isJsonStr(message)) {
      const data = JSON.parse(message)
      setMessageList(data?.lists);
    } else {
      setMessageList(props.message)
    }

    // 如果已关闭自动重连
    if (readyState.key === 3) {
      reconnect();
    }
  }, [message, readyState]);

  const { confirm } = Modal;
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/c/font_3965974_0equg0kzn0eu.js",
  });

  // 当前的路径
  const pathKey = useLocation().pathname;

  // 退出登录
  const logout = () => {
    showConfirm();
  };

  // 退出确认弹窗
  const showConfirm = () => {
    confirm({
      title: "确定退出登录?",
      icon: <ExclamationCircleOutlined />,
      cancelText: "取消",
      okText: "确定",
      onOk() {
        props.logout();
        props.deleteUser();
        props.deleteMenu();
        localStorage.removeItem("admin_token");
        navigate("/login");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // 重置密码
  const resetPasswd = () => {
    let username = localStorage.getItem("username");
    let nickname = localStorage.getItem("nickname");
    form.setFieldsValue({ username, nickname });
    setIsModalOpen(true);
  };

  // 关闭重置密码弹窗
  const close = () => {
    setIsModalOpen(false);
    setLoading(false)
    form.resetFields();
  };

  const items = [
    {
      key: 1,
      label: (
        <Button block type="text" onClick={resetPasswd}>
          密码设置
        </Button>
      ),
    },
    {
      key: 2,
      label: (
        <Button block type="text" onClick={logout}>
          退出账号
        </Button>
      ),
    },
  ];

  // 获取默认选中的头部菜单
  const getDefaultSelected = () => {
    return pathKey.split("/").splice(2);
  };

  // 展示头部菜单
  const getMenu = () => {
    return menu?.parents.map((item) => {
      return {
        label: item?.title,
        key: item?.value,
        icon: <IconFont type={item?.icon} />,
      };
    });
  };

  // 获取菜单跳转地址
  const getRedirectPath = (key, childrenMenus) => {
    let path = "/admin/" + key + "/";
    if (key === 'index') {
      path += 'welcome'
    } else {
      let firstMenu = childrenMenus.slice(0, 1);
      if (firstMenu[0]?.children) {
        let childrenFirstMenu = firstMenu[0]?.children.slice(0, 1);
        if (Reflect.has(childrenFirstMenu[0], "children")) {
          let subChildreFirstMenu = childrenFirstMenu[0]?.children.slice(0, 1);
          path +=
            firstMenu[0]?.key +
            "/" +
            childrenFirstMenu[0]?.key +
            "/" +
            subChildreFirstMenu[0]?.key;
        } else {
          path += firstMenu[0]?.key + "/" + childrenFirstMenu[0]?.key;
        }
      } else {
        path += firstMenu[0]?.key;
      }
    }
    return path;
  };

  const getChildrenMenus = async (key, isRedirect = true) => {
    const res = await getMenuChildren({ key });
    let path;
    if (res?.code === 200) {
      const { data } = res;
      props.getChangeMenus(data);
      path = getRedirectPath(key, data);
    }
    setSelectedKeys([key]);
    if (isRedirect) {
      navigate(path);
    }
  };

  // 菜单切换
  const onMenuClick = async (e) => {
    if(props.clearMenuSty) {
      props.clearMenuSty()
    }
    getChildrenMenus(e.key);
    if (readyState.key === 1) {
      sendMessage(
        JSON.stringify({ username: props?.user?.username, sourse: "header头部定时发送" })
      );
    }
  };

  // 菜单收缩
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    props.toggleCollapsed(!collapsed);
  };

  // 主要用于多层级下级菜单的情况用递归处理
  const getChildrenMenu = (titles, element) => {
    let arrKeys = pathKey.split("/");
    arrKeys.shift();
    if (arrKeys.indexOf(element.key) !== -1) {
      if (element.href) {
        titles.push({ title: <Link to={element.href}>{element.title}</Link> });
      } else {
        titles.push({ title: element.title });
      }
    }
    let tmp = element.children.find((item) => {
      if (item.children instanceof Array) {
        getChildrenMenu(titles, item);
      } else {
        return arrKeys.indexOf(item.key) !== -1;
      }
    });
    tmp && titles.push({ title: tmp.title });
  };

  // 获取面包屑导航条数据
  const getBreadcrumb = () => {
    let arrKeys = pathKey.split("/");
    arrKeys.shift();
    let titles = [{ title: "投放后台管理" }];

    menuList.forEach((element) => {
      if (element.children instanceof Array) {
        getChildrenMenu(titles, element);
      } else {
        if (arrKeys.indexOf(element.key) !== -1)
          titles.push({ title: element.title });
      }
    });
    return titles;
  };

  // 关闭弹窗
  const handleClose = () => {
    form.resetFields();
    close();
  };

  // 表单提交
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        setLoading(true);
        const values = form.getFieldValue();
        const res = await handleResetPasswd(values);
        if (res?.code === 200) {
          Message.success("修改密码成功");
          let shortname = localStorage.getItem("shortname");
          let token = localStorage.getItem("token");
          props.saveUser({
            username: values?.username,
            token,
            shortname,
            nickname: values?.nickname,
            avatar: values?.avatar,
          });
          setLoading(false);
          handleClose();
        }
      })
      .catch(() => {
        return;
      });
  };

  // 弹窗内容
  const content = () => (
    <Card
      title="消息提醒"
      extra={<Button type="link">忽略全部</Button>}
      size="small"
      style={{ width: 650 }}
      bodyStyle={{ padding: 0 }}
    >
      <List
        bordered={messageList.length !== 0}
        dataSource={messageList}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <div style={{ display: "flex" }}>
                  <div>
                    <Badge status="error" />
                  </div>
                  <div style={{ width: "75%" }}>
                    【{item?.category}】{item?.title}
                  </div>
                  <div style={{ color: "#AAADB2" }}>{item?.created_at}</div>
                </div>
              }
              description={`${item.content}`}
            />
          </List.Item>
        )}
        footer={
          <Button type="link" onClick={showAll}>{`查看所有消息 >`}</Button>
        }
      ></List>
    </Card>
  );

  // 查看所有消息
  const showAll = () => {
    let url = `/admin/index/notification`;
    getChildrenMenus("index", false);
    navigate(url);
  };

  return (
    <>
      <header className="header">
        <div className="header-top">
          <div className="header-top-left" onClick={toggleCollapsed}>
            {collapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: "20px" }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: "20px" }} />
            )}
          </div>
          {menu?.parents ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-start",
                margin: '0 2%'
              }}
            >
              {
                <Menu
                  mode="horizontal"
                  onSelect={(e) => onMenuClick(e)}
                  items={getMenu()}
                  style={{ fontSize: 16,width: '75%', textAlign: 'left'}}
                  selectedKeys={
                    selectedKeys && selectedKeys.length > 0
                      ? selectedKeys
                      : getDefaultSelected()
                  }
                />
              }
            </div>
          ) : (
            ""
          )}

          <div className="header-top-right">
            <Space size="middle">
              <div className="header-top-right-full">
                <Screenfull />
              </div>
              <div style={{ cursor: "pointer" }}>
                <Popover content={content} style={{ width: 500 }}>
                  {messageList.length > 0 ? (
                    <Badge size="small" dot={true}>
                      <Avatar
                        shape="square"
                        size="middle"
                        icon={<UserOutlined />}
                        src={props.user.avatar}
                      />
                    </Badge>
                  ) : (
                    <Avatar
                      shape="square"
                      size="middle"
                      icon={<UserOutlined />}
                      src={props.user.avatar}
                    />
                  )}
                </Popover>
              </div>
              <div className="header-top-right-dropdown">
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <Space>
                    {props.user.username}({props.user.nickname})
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </div>
            </Space>
          </div>
        </div>
        <div className="header-bottom">
          <Breadcrumb items={getBreadcrumb()} separator=">" />
        </div>
        <Modal
          title="个人设置"
          open={isModalOpen}
          okText="确定"
          cancelText="取消"
          closable={false}
          onCancel={handleClose}
          onOk={handleSubmit}
          destroyOnClose={true}
          confirmLoading={loading}
        >
          <ResetForm close={close} name="file" form={form} />
        </Modal>
      </header>
    </>
  );
}

export default connect((state) => ({ user: state.user, menu: state.menu, message: state.message }), {
  deleteUser: deleteUserInfo,
  saveUser: saveUserInfo,
  deleteMenu,
})(Header);
