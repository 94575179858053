import { Space, Typography } from "antd";

const columns = () => [
  {
    title: "日期",
    width: 80,
    align: "center",
    dataIndex: "day",
    key: "day",
    fixed: "left",
  },
  {
    title: "推广链接",
    width: 150,
    align: "center",
    dataIndex: "promotion",
    key: "promotion",
    render: (_, record) => {
      return (
        <Space direction="vertical">
          <Typography.Text>
            名称：{record?.promotionTitle}
          </Typography.Text>
          <Typography.Text>外链ID: {record?.promotionId}</Typography.Text>
        </Space>
      );
    },
  },
  {
    title: "投手",
    width: 80,
    align: "center",
    dataIndex: "adminName",
    key: "adminName",
  },
  {
    title: "小说名称",
    width: 130,
    align: "center",
    dataIndex: "bookName",
    key: "bookName",
  },
  {
    title: "消耗",
    width: 100,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
    render: (value) => (
      value || 0
    )
  },
  {
    title: "回收",
    width: 100,
    align: "center",
    dataIndex: "money",
    key: "money",
    sorter: {},
    render: (value) => (
      value || 0
    )
  },
  {
    title: "ECPM当日收益",
    width: 100,
    align: "center",
    dataIndex: "today_ad_cash",
    key: "today_ad_cash",
    sorter: {},
    render: (_, record) => (
      record?.todayAdCash || 0
    )
  },
  {
    title: "ECPM总收益",
    width: 100,
    align: "center",
    dataIndex: "total_ad_cash",
    key: "total_ad_cash",
    sorter: {},
    render: (_, record) => (
      record?.totalAdCash || 0
    )
  },
  {
    title: "新增回收率",
    width: 80,
    align: "center",
    dataIndex: "new_recovery_rate",
    key: "new_recovery_rate",
    sorter: {},
    render: (_, record) => (
      record?.newRecoveryRate === 0 ? '0.00%' : record?.newRecoveryRate + '%'
    )
  },
  {
    title: "总回收率",
    width: 80,
    align: "center",
    dataIndex: "total_recovery_rate",
    key: "total_recovery_rate",
    sorter: {},
    render: (_, record) => (
      record?.totalRecoveryRate === 0 ? '0.00%' : record?.totalRecoveryRate + '%'
    )
  },
];

export default columns;
