import { Button, Card, DatePicker, Form, Typography, Table } from "antd";
import React from "react";
import { useState } from "react";
import columns from "./columns";
import { getRecyclePlayletGroupTrend } from "../../../../api/statistics";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { numDiv, numMulti } from "../../../../util/calculate";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true);
    if (state) {
      params.org_id = state?.id;
    }
    const res = await getRecyclePlayletGroupTrend(params);
    const { data } = res;
    if (res?.code === 200) {
      let newList = data.map((item) => {
        item.key = item.id;
        return item;
      }, []);
      setList(newList);
      setLoading(false);
    }
  };

  // 表单筛选查询
  const onSubmit = (values) => {
    let rangeDate;
    if (values["range_date"]) {
      rangeDate = values["range_date"].map((date) => {
        return date.format("YYYYMMDD");
      });
      values.range_date = rangeDate;
    }
    getList(values);
  };

  const get60Days = () => {
    let days = [];
    for (let i = 1; i <= 60; i++) {
      days.push(i);
    }
    return days;
  };

  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontWeight: "bold",
          marginBottom: 20,
          marginLeft: "3%",
        }}
      >
        {state?.name}组付费趋势
      </div>
      <Card
        title={
          <Form layout="inline" onFinish={onSubmit}>
            <Form.Item name="range_date">
              <RangePicker />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        }
      >
        <Table
          sticky
          dataSource={list}
          columns={columns()}
          loading={loading}
          pagination={false}
          bordered={true}
          scroll={{ x: 500 }}
          summary={(pageData) => {
            let totalNewUser = 0;
            let totalCost = 0;
            let totalMoney = 0;
            let totalPayNum = 0;
            let totalFirstDayFirtPay = 0;
            let totalFirstDayPay = 0;
            let totalPayUser = 0;
            pageData.forEach(
              ({
                new_user,
                cost,
                money,
                pay_user,
                first_day_first_pay,
                first_day_pay,
                total_pay_user,
              }) => {
                totalNewUser += new_user;
                totalCost += parseFloat(cost);
                totalMoney += parseFloat(money);
                totalPayNum += pay_user;
                totalFirstDayFirtPay += parseFloat(first_day_first_pay);
                totalFirstDayPay += parseFloat(first_day_pay);
                totalPayUser += total_pay_user;
              }
            );
            return (
              <>
                {pageData && pageData.length > 0 ? (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                      <Text type="danger" strong={true}>
                        汇总
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <Text>{totalNewUser}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text>{totalMoney.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      <Text>{totalCost.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="center">
                      <Text>
                        {parseInt(totalCost)
                          ? numMulti(
                              numDiv(totalMoney, totalCost),
                              100
                            ).toFixed(2) + "%"
                          : "0%"}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="center">
                      <Text>{totalPayNum}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="center">
                      <Text>{totalFirstDayFirtPay.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="center">
                      <Text>{totalFirstDayPay.toFixed(2)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="center">
                      <Text>{totalPayUser}</Text>
                    </Table.Summary.Cell>
                    {get60Days().map((day) => {
                      let index = parseInt(day) + 9;
                      let key = `day${day}`;
                      let dayTotalRecharge = 0;
                      pageData.forEach(({ rangeData }) => {
                        dayTotalRecharge += rangeData[key]?.recharge;
                      });
                      return (
                        <Table.Summary.Cell
                          index={index}
                          align="center"
                          key={index}
                        >
                          <div style={{ textAlign: "left" }}>
                            充值：{dayTotalRecharge}
                          </div>
                          <div style={{ textAlign: "left" }}>
                            回本率：
                            {parseInt(totalCost)
                              ? numDiv(dayTotalRecharge, totalCost).toFixed(2) +
                                "%"
                              : 0}
                          </div>
                        </Table.Summary.Cell>
                      );
                    })}
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </Card>
    </>
  );
}
