import {
    Button,
    Card,
    DatePicker,
    Form,
    Select,
    Space,
    Table,
    Typography,
  } from "antd";
  import React from "react";
  import { useState, useEffect } from "react";
  import columns from "./columns";
  import { getRecycleStatistics } from "../../../../api/statistics";
  import { numDiv, numMulti } from "../../../../util/calculate";
  import { useLocation, useNavigate } from "react-router";
  import { getMaterialGroup } from "../../../../api/advertise";
  
  const { RangePicker } = DatePicker;
  const { Text } = Typography;
  
  export default function Index() {
    const [list, setList] = useState([]);
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [isSuper, setIsSuper] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
  
    const { state } = useLocation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
  
    useEffect(() => {
      getList({ org_id: state?.org_id });
      getOrganization({tag: 'iaa_novel_pitcher'});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    // 组织架构数据
    const getOrganization = async (params = {}) => {
      const res = await getMaterialGroup({ ...params});
      if (res?.code === 200) {
        const { data } = res;
        let lists = data?.lists?.map((item) => {
          return { label: item?.name, value: item?.org_id };
        });
        setOrganizationOptions(lists);
      }
    };
  
    // 列表统计数据
    const getList = async (params = {}) => {
      setLoading(true);
      params.external_actions = 2;
      params.type = 'novel'
      let orgId = params?.org_id;
      if (orgId) {
        params.org_id = orgId;
      }
      const res = await getRecycleStatistics(params);
      const { data } = res;
      if (res?.code === 200) {
        setLoading(false);
        let lists = data?.lists;
        const newList = lists?.map((item) => {
          item.key = item.id;
          return item;
        });
        setList(newList);
        setIsSuper(data?.is_super);
        setIsAgent(data?.is_agent);
      }
    };
  
    // 日期范围查询
    const onSubmit = (values) => {
      let createdAt = [];
      if (values["range_date"]) {
        createdAt = values["range_date"].map((date) => {
          return date.format("YYYYMMDD");
        });
        delete values.range_date;
      }
      let params = { ...conditions, ...values, range_date: createdAt };
      getList(params);
      setConditions(params);
    };
  
    // 表单重置
    const onReset = () => {
      form.resetFields();
      navigate("/admin/statistics/theater/push_plt/recycle_plt", { state: null });
    };
  
  
    return (
      <div className="recycle">
        <Card
          title={
            <Form
              layout="inline"
              onFinish={onSubmit}
              form={form}
              initialValues={{
                org_id: state?.org_id,
              }}
            >
              {isSuper ? (
                <>
                  <Form.Item label="分组筛选" name="org_id">
                    <Select
                      options={organizationOptions}
                      style={{ width: 150 }}
                      allowClear
                      placeholder="选择分组"
                    />
                  </Form.Item>
                </>
              ) : (
                ""
              )}
              {/* <Form.Item label="广告类型" name="ad_type">
                <Select
                  style={{ width: 150 }}
                  options={[
                    { label: "头条", value: 1 },
                    { label: "adp", value: 5 },
                  ]}
                  allowClear
                  placeholder="选择广告类型"
                />
              </Form.Item> */}
              <Form.Item name="range_date">
                <RangePicker />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit">
                    确定
                  </Button>
                  <Button onClick={onReset}>重置</Button>
                </Space>
              </Form.Item>
            </Form>
          }
        >
          <Table
            sticky
            dataSource={list}
            columns={columns({ isAgent, isSuper })}
            bordered={true}
            pagination={false}
            loading={loading}
            scroll={{ x: 500 }}
            summary={(pageData) => {
              let totalCost = 0;
              let totalAdCash = 0;
              let totalNewAdCash = 0;
              let totalMoneyCount = 0;
              let totalPayNum = 0;
              let totalAccountCount = 0;
              let totalAdCount = 0;
              let totalSuccessPlan = 0;
              let totalAllPlan = 0;
              pageData.forEach(
                ({
                  cost,
                  ad_cash,
                  new_ad_cash,
                  money,
                  hui_shou_lv,
                  pay_user,
                  pay_cost,
                  per_money,
                  account_count,
                  ad_count,
                  success_plan,
                  all_plan,
                }) => {
                  totalCost += parseFloat(cost);
                  totalAdCash += parseFloat(ad_cash);
                  totalNewAdCash += parseFloat(new_ad_cash);
                  totalMoneyCount += isNaN(money) ? 0 : parseFloat(money);
                  totalPayNum += isNaN(pay_user) ? 0 : parseFloat(pay_user);
                  totalAccountCount +=
                    account_count === null ? 0 : parseInt(account_count);
                  totalAdCount += ad_count === null ? 0 : parseInt(ad_count);
                  totalSuccessPlan += parseInt(success_plan);
                  totalAllPlan += parseInt(all_plan);
                }
              );
              return (
                <>
                  {pageData && pageData.length > 0 ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={2} align="center">
                        <Text type="danger" strong={true}>
                          汇总
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align="center">
                        <Text>{totalCost.toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} align="center">
                        <Text>{totalMoneyCount.toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} align="center">
                        <Text>{totalNewAdCash.toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} align="center">
                        <Text>{totalAdCash.toFixed(2)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} align="center">
                        <Text>
                          {parseInt(totalCost)
                            ? numMulti(
                                numDiv(
                                  totalMoneyCount * 1 + totalNewAdCash * 1,
                                  totalCost
                                ),
                                100
                              ).toFixed(2) + "%"
                            : "0%"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} align="center">
                        <Text>
                          {parseInt(totalCost)
                            ? numMulti(
                                numDiv(
                                  totalMoneyCount * 1 + totalAdCash * 1,
                                  totalCost
                                ),
                                100
                              ).toFixed(2) + "%"
                            : "0%"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} align="center">
                        <Text>{totalPayNum}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9} align="center">
                        <Text>
                          {totalPayNum > 0
                            ? numDiv(totalCost, totalPayNum).toFixed(2)
                            : "0.00"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10} align="center">
                        <Text>
                          {totalPayNum > 0
                            ? numDiv(totalMoneyCount, totalPayNum).toFixed(2)
                            : "0.00"}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11} align="center">
                        <Text>{totalAdCount}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12} align="center">
                        <Text>{totalSuccessPlan}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13} align="center">
                        <Text>{totalAllPlan}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14} align="center">
                        <Text>{totalAccountCount}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={15} align="center">
                        <Text>---</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  ) : (
                    ""
                  )}
                </>
              );
            }}
          />
        </Card>
      </div>
    );
  }
  