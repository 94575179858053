import React, { useState } from "react";
import { Card, DatePicker, Form, Table, Typography } from "antd";
import columns from "./columns";
import { numDiv, numMulti } from "../../../util/calculate";
import { getPlayletEcpmList } from "../../../api/delivery.js";
import useTableList from "../../../hooks/useTableList";
import dayjs from "dayjs";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const today = dayjs().startOf('day');

export default function Index() {
  const [conditions, setConditions] = useState({
    pageNum: 1,
    pageSize: 200,
    startDay: dayjs().format('YYYYMMDD'),
    endDay: dayjs().format('YYYYMMDD'),
    statsDim: 'org',
    profitMode: 'IAA',
    isGetRole: true,
  });
  const { loading, data } = useTableList(getPlayletEcpmList, conditions);
  const { list } = data;

  // 日期切换
  const onDateChange = (dates) => {
    const rangeDate = dates?.map((date) => {
      return date.format("YYYYMMDD");
    }) || [];

    const startDay = rangeDate[0];
    const endDay = rangeDate[1];
    const submitParams = {
      startDay,
      endDay,
      pageNum: 1,
      pageSize: 200,
    }

    setConditions({...conditions, ...submitParams});
  };

  return (
    <Card
      title={
        <Form   
          initialValues={{
            range_date: [today, today],
          }}
        > 
          <Form.Item label="日期" name="range_date">
            <RangePicker onChange={onDateChange} />
          </Form.Item>
        </Form>
      }
    >
      <Table
        sticky
        dataSource={list}
        columns={columns()}
        bordered={true}
        loading={loading}
        pagination={false}
        scroll={{ x: 500 }}
        summary={(pageData) => {
          let _cost = 0;
          let _todayAdCash = 0;
          let _totalAdCash = 0;
          let _adCount = 0;
          let _adNewActiveCount = 0;
          let _accountCount = 0;
          let _activeAdCount = 0;
          let _activeAccountCount = 0;
          let _playletCount = 0;
          pageData.forEach(
            ({
              cost,
              todayAdCash,
              totalAdCash,
              adCount,
              adNewActiveCount,
              accountCount,
              activeAdCount,
              activeAccountCount,
              playletCount,
            }) => {
              _cost += isNaN(cost) ? 0 : parseFloat(cost);
              _todayAdCash += isNaN(todayAdCash) ? 0 : parseFloat(todayAdCash);
              _totalAdCash += isNaN(totalAdCash) ? 0 : parseFloat(totalAdCash);
              _adCount += isNaN(adCount) ? 0 : parseFloat(adCount);
              _adNewActiveCount += isNaN(adNewActiveCount) ? 0 : parseFloat(adNewActiveCount);
              _accountCount += isNaN(accountCount) ? 0 : parseFloat(accountCount);
              _activeAdCount += isNaN(activeAdCount) ? 0 : parseFloat(activeAdCount);
              _activeAccountCount += isNaN(activeAccountCount) ? 0 : parseFloat(activeAccountCount);
              _playletCount += isNaN(playletCount) ? 0 : parseFloat(playletCount);
            }
          );
          return (
            <>
              {pageData && pageData.length > 0 ? (
                 <Table.Summary.Row>
                 <Table.Summary.Cell index={0} colSpan={2} align="center">
                   <Text type="danger" strong={true}>
                     汇总
                   </Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={2} align="center">
                   <Text>{_cost.toFixed(2)}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={3} align="center">
                   <Text>{_todayAdCash.toFixed(2)}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={4} align="center">
                   <Text>{_totalAdCash.toFixed(2)}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={5} align="center">
                   <Text>{_cost > 0 ? numMulti(numDiv(_todayAdCash, _cost), 100).toFixed(2) + "%" : "0%"}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={6} align="center">
                   <Text>{_cost > 0 ? numMulti(numDiv(_totalAdCash, _cost), 100).toFixed(2) + "%": "0%"}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={7} align="center">
                   <Text>{_adCount}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={7} align="center">
                   <Text>{_adNewActiveCount}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={9} align="center">
                   <Text>{_activeAdCount}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={8} align="center">
                   <Text>{_accountCount}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={10} align="center">
                   <Text>{_activeAccountCount}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={11} align="center">
                   <Text>{_playletCount}</Text>
                 </Table.Summary.Cell>
                 <Table.Summary.Cell index={12} align="center">
                   <Text>--</Text>
                 </Table.Summary.Cell>
               </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </Card>
  );
}
