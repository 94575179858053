const columns = () => [
    {
        title: "作者",
        width: 150,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        ellipsis: true,
    },
    {
        title: "日期",
        width: 100,
        align: "center",
        dataIndex: "created",
        key: "created",
    },
    {
        title: "上传图片素材数",
        width: 100,
        align: "center",
        dataIndex: "image_count",
        key: "image_count",
    },
    {
        title: "上传视频素材数",
        width: 100,
        align: "center",
        dataIndex: "video_count",
        key: "video_count",
    },
    {
        title: "上传标题素材数",
        width: 100,
        align: "center",
        dataIndex: "title_count",
        key: "title_count",
    },
]

export default columns