const columns = () => [
    {
        title: "投手",
        width: 100,
        align: "center",
        dataIndex: "nickname",
        key: "nickname",
        fixed: 'left',
    },
    {
        title: "日期",
        width: 120,
        align: "center",
        dataIndex: "day",
        key: "day",
    },
    {
        title: "主体",
        width: 180,
        align: "center",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
    },
    {
        title: "计划数",
        width: 100,
        align: "center",
        dataIndex: "count",
        key: "count",
    },
]

export default columns