import React, { useState } from "react";

import AddProject from "./AddProject/addProject";
import AddPlan from "./AddPlan/addPlan";

export default function Add(props) {
  const [projectFinish, setProjectFinish] = useState(props?.projectFinish);
  const [projectData, setProjectData] = useState({});

  const onSubmit = (data) => {
    setProjectFinish(true);
    setProjectData(data);
  };

  // 从创建广告表单返回创建项目表单
  const returnPrevious = () => {
    setProjectFinish(false);
  };
  return (
    <>
      <div style={{ marginTop: 16 }}>
        {projectFinish ? (
          <AddPlan
            projectData={projectData}
            returnPrevious={returnPrevious}
            {...props}
          />
        ) : (
          <AddProject onSubmit={onSubmit} projectData={projectData} />
        )}
      </div>
    </>
  );
}
