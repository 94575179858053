import { Card, DatePicker, Form, Table, Typography } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import columns from "./columns";
import { thirdNovelSummary } from "../../../api/statistics";
import {numMulti, numDiv} from '../../../util/calculate'

const { RangePicker } = DatePicker;
const {Text} = Typography

export default function Index() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  const getList = async (params = {}) => {
    setLoading(true);
    const res = await thirdNovelSummary(params);
    if (res?.code === 200) {
      const { data } = res;
      setList(data?.lists);
      setLoading(false);
    }
  };

  const onChanage = (values) => {
    let rangeDate
    if (values) {
      rangeDate = values.map((date) => {
        return date.format('YYYYMMDD')
      })
    }
    console.log(rangeDate);
    getList({range_date: rangeDate})
  }

  return (
    <Card
      title={
        <Form layout="inline">
          <Form.Item>
            <RangePicker onChange={onChanage}/>
          </Form.Item>
        </Form>
      }
    >
      <Table
        sticky
        dataSource={list}
        columns={columns()}
        loading={loading}
        pagination={false}
        bordered={true}
        scroll={{ x: 500 }}
        summary={(pageData) => {
          let totalCost = 0;
          let totalMoneyCount = 0;
          let totalPayman = 0;
          let totalZaikaiPlan = 0;
          let totalAuditPlan = 0;
          let totalAllPlan = 0;
          pageData.forEach(
            ({ cost, money, pay_man, zaikai_plan, audit_plan, all_plan }) => {
              totalCost += parseFloat(cost);
              totalMoneyCount += parseFloat(money);
              totalPayman += parseInt(pay_man);
              totalZaikaiPlan += parseInt(zaikai_plan);
              totalAuditPlan += parseInt(audit_plan);
              totalAllPlan += parseInt(all_plan);
            }
          );
          return (
            <>
              {pageData && pageData.length > 0 ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2} align="center">
                    <Text type="danger" strong={true}>
                      汇总
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <Text>{totalCost?.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <Text>{totalMoneyCount?.toFixed(2)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <Text>
                      {parseInt(totalCost)
                        ? numMulti(
                            numDiv(totalMoneyCount, totalCost),
                            100
                          ).toFixed(2) + "%"
                        : "0%"}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="center">
                    <Text>{totalPayman}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    <Text>
                      {parseInt(totalPayman)
                        ? numMulti(
                            numDiv(totalCost, totalPayman),
                            100
                          ).toFixed(2) + "%"
                        : "0%"}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <Text>{parseInt(totalPayman)
                        ? numMulti(
                            numDiv(totalMoneyCount, totalPayman),
                            100
                          ).toFixed(2) + "%"
                        : "0%"}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    <Text>{totalZaikaiPlan}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    <Text>{totalAuditPlan}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center">
                    <Text>{totalAllPlan}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : (
                ""
              )}
            </>
          );
        }}
      />
    </Card>
  );
}
