import { Button, Space } from "antd";
import { Link } from "react-router-dom";

const columns = ({ isAgent, showEstimateDetail }) => [
  {
    title: "优化师",
    width: 100,
    align: "center",
    dataIndex: "nickname",
    key: "nickname",
    // fixed: 'left',
  },
  {
    title: "日期",
    width: 110,
    align: "center",
    dataIndex: "day",
    key: "day",
  },
  {
    title: "消耗",
    width: 95,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => a.cost - b.cost,
    sortDirections: ["descend"],
  },
  {
    title: "回收",
    width: 100,
    align: "center",
    dataIndex: "money",
    key: "money",
    sorter: (a, b) => a.money - b.money,
    sortDirections: ["descend"],
    render: (value) => value || 0,
  },
  {
    title: "当日收益",
    width: 100,
    align: "center",
    dataIndex: "new_ad_cash",
    key: "new_ad_cash",
    sorter: (a, b) => a.new_ad_cash - b.new_ad_cash,
    sortDirections: ["descend"],
    render: (value) => value || 0,
  },
  {
    title: "总收益",
    width: 100,
    align: "center",
    dataIndex: "ad_cash",
    key: "ad_cash",
    sorter: (a, b) => a.ad_cash - b.ad_cash,
    sortDirections: ["descend"],
    render: (value) => value || 0,
  },
  {
    title: "新增回收率",
    width: 105,
    align: "center",
    dataIndex: "new_hui_shou_lv",
    key: "new_hui_shou_lv",
  },
  {
    title: "总回收率",
    width: 105,
    align: "center",
    dataIndex: "hui_shou_lv",
    key: "hui_shou_lv",
  },
  {
    title: "支付人数",
    width: 105,
    align: "center",
    dataIndex: "pay_user",
    key: "pay_user",
    render: (value) => value || 0,
  },
  {
    title: "付费成本",
    width: 90,
    align: "center",
    dataIndex: "pay_cost",
    key: "pay_cost",
  },
  {
    title: "客单价",
    width: 90,
    align: "center",
    dataIndex: "per_money",
    key: "per_money",
  },
  {
    title: "在消耗计划",
    width: 100,
    align: "center",
    dataIndex: "ad_count",
    key: "ad_count",
  },
  {
    title: "审核通过计划",
    width: 105,
    align: "center",
    dataIndex: "success_plan",
    key: "success_plan",
  },
  {
    title: "所有计划",
    width: 100,
    align: "center",
    dataIndex: "all_plan",
    key: "all_plan",
  },
  {
    title: "在消耗账户数",
    width: 80,
    align: "center",
    dataIndex: "account_count",
    key: "account_count",
  },
  {
    title: "消耗时速",
    width: 100,
    align: "center",
    dataIndex: "estimate_cost",
    key: "estimate_cost",
    render: (value, record) => {
      return (
        <Space direction="vertical" size="small">
          <span>{value}</span>
          <Button
            type="link"
            onClick={() => showEstimateDetail(record?.admin_id)}
          >
            查看详情
          </Button>
        </Space>
      );
    },
  },
  {
    title: "操作",
    width: 210,
    align: "center",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => (
      <Space size="small">
        <Button type="primary" ghost>
          <Link
            to={
              isAgent
                ? "/admin/statistics/theater/push_plt/toutiao_agtplt"
                : "/admin/statistics/theater/push_plt/toutiao_plt"
            }
            state={{ ...record, tab: "tab1" }}
          >
            头条数据
          </Link>
        </Button>
      </Space>
    ),
  },
];

export default columns;
