import { postRequest } from "./request"

// 推广链接
export const getPlayletPromotion = data => postRequest('/admin/playlet/promotion', data)

// 推广链接添加
export const playletPromotionAdd = data => postRequest('/admin/playlet/add', data)

// 获取单条推广链接
export const playletPromotionItem = data => postRequest('/admin/playlet/promotion_item', data)

// 推广链接编辑
export const promotionUpdate = data => postRequest('/admin/playlet/update', data)

// 推广链接名称编辑
export const playletPromotionTitleEdit = data => postRequest('/admin/playlet/title_edit', data)

// 推广链接投放状态编辑
export const playletPromotionStatusEdit = data => postRequest('/admin/playlet/status_edit', data)

// 付费趋势
export const playletPayQu = data => postRequest('/admin/playlet/pay_qu', data)

// 订单
export const playletOrder = data => postRequest('/admin/playlet/order', data)

// 订单支付比例
export const playletOrderPayRatio = data => postRequest('/admin/playlet/pay_ratio', data)

// 短剧切换获取推广链接列表
export const getSelectPromotion = data => postRequest('/admin/playlet/select_promotions', data)

// 短剧卡回传设置
export const playletBackSetSave = data => postRequest('/admin/playlet/back_set_save', data)

// 短剧根据推广链接获取卡回传
export const playletBackSet = data => postRequest('/admin/playlet/get_back_set', data)

// 短剧根据推广链接获取卡回传
export const playletResult = data => postRequest('/admin/playlet/back_result', data)

// 重新回传
export const resultReUp = data => postRequest('/admin/playlet/result_back_up', data)

// 删除推广链接
export const promotionDelete = data => postRequest('/admin/playlet/delete', data)

// 获取对应的短剧平台的账号列表
export const getPlayletAccountList = data => postRequest('/admin/playlet/get_playlet_account', data)

// 检查是否绑定
export const checkIsBind = data => postRequest('/admin/playlet/check_is_bind', data)

// 获取已绑定当前用户与短剧平台用户
export const getRelateAccount = data => postRequest('/admin/playlet/relation_account', data)

// 处理账号绑定
export const accountHandleBind = data => postRequest('/admin/playlet/account_bind', data)

// 处理账号解绑
export const accountUnBind = data => postRequest('/admin/playlet/account_unbind', data)

// 获取自研短剧所有的剧场
export const getAllPlaylet = data => postRequest('/admin/playlet/get_playlet', data)

// 获取三方剧场的应用包
export const getPlayletPackage = data => postRequest('/admin/package/playlet', data)

// 获取三方剧场及自研短剧的应用包
export const getFastPlaylet = data => postRequest('/admin/package/fast_playlet', data)

// 添加素材时获取对应的剧场
export const getMaterialPlaylet = data => postRequest('/admin/package/material_playlet', data)

// 添加虚拟回传
export const playletVirtualBack = data => postRequest('/admin/playlet/virtual_back', data)

// 剧场配置说明内容添加
export const playletConfigAdd = data => postRequest('/admin/playlet/config_add', data)

// 剧场配置说明内容编辑
export const playletConfigUpdate = data => postRequest('/admin/playlet/config_update', data)

// 剧场配置说明内容删除
export const playletConfigDelete = data => postRequest('/admin/playlet/config_delete', data)

// 剧场配置说明内容列表
export const playletConfigList = data => postRequest('/admin/playlet/config_list', data)

// 剧场付费趋势赔付金额修改
export const updatePayQuClaim = data => postRequest('/admin/playlet/payQu_claim_update', data)

