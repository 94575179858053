const columns = () => [
  {
    title: "事件名称",
    width: 80,
    align: "center",
    dataIndex: "event_name",
    key: "landevent_nameing",
  },
  {
    title: "优化目标",
    width: 80,
    align: "center",
    dataIndex: "external_action",
    key: "external_action",
    render: (value) => (
        value === 'AD_CONVERT_TYPE_GAME_ADDICTION' ? '关键行为' : value === 'AD_CONVERT_TYPE_ACTIVE' ? '广告ROI' : '付费'
    )
  },
  {
    title: "事件资产ID",
    width: 100,
    align: "center",
    dataIndex: "event_asset_id",
    key: "event_asset_id",
  },
  {
    title: "所属账户",
    width: 120,
    align: "center",
    dataIndex: "advertiser_id",
    key: "advertiser_id",
  },
];

export default columns
