import React, { useState } from 'react'
import RecycleComponents from '../Public/Recycle/index'
import { playletPayQu } from '../../../api/playlet'
import { useEffect } from 'react'

export default function Recycle() {
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [conditions, setConditions] = useState([])

  useEffect(() => {
    getList()
  }, [])

  // 获取列表数据
  const getList = async (params = {}) => {
    setLoading(true)
    const res = await playletPayQu({...params, app_type: 1002})
    const {data} = res
    if (res?.code === 200) {
      setList(data?.lists)
      setTotal(data?.total)
      setLoading(false)
    }
  }

  // 日期条件筛选
  const onSearch = params => {
    getList(params)
    setConditions(params)
  }

  // 分页
  const pagesize = (page, size) => {
    setPage(page)
    setPageSize(size)
    getList({page, pageSize: size, ...conditions})
  }

  // 传到子组件的参数
  const propsData = {
    list, total, page, loading,
    pagesize, perPage:pageSize,
    onSearch
  }
  
  return (
    <div className='yingtan_recycle'>
        <RecycleComponents {...propsData} />
    </div>
  )
}
