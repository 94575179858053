import { numMulti } from "../../../../util/calculate";

const bookColumns = () => [
  {
    title: "日期",
    width: 80,
    align: "center",
    dataIndex: "day",
    key: "day",
    fixed: "left",
  },
  {
    title: "投手",
    width: 80,
    align: "center",
    dataIndex: "nickname",
    key: "nickname",
  },
  {
    title: "短剧名称",
    width: 130,
    align: "center",
    dataIndex: "book_name",
    key: "book_name",
  },
  {
    title: "剧场名称",
    width: 100,
    align: "center",
    dataIndex: "app_name",
    key: "app_name",
  },
  {
    title: "消耗",
    width: 100,
    align: "center",
    dataIndex: "cost",
    key: "cost",
    sorter: {},
    sortDirections: ["descend"],
    render: (value) => (
      value || 0
    )
  },
  {
    title: "回收",
    width: 100,
    align: "center",
    dataIndex: "money",
    key: "money",
    sorter: {},
    sortDirections: ["descend"],
    render: (value) => (
      value || 0
    )
  },
  {
    title: "ECPM当日收益",
    width: 100,
    align: "center",
    dataIndex: "total_ad_new_cash",
    key: "total_ad_new_cash",
    sorter: {},
    sortDirections: ["descend"],
    render: (value) => (
      value || 0
    )
  },
  {
    title: "ECPM总收益",
    width: 100,
    align: "center",
    dataIndex: "total_ad_cash",
    key: "total_ad_cash",
    sorter: {},
    sortDirections: ["descend"],
    render: (value) => (
      value || 0
    )
  },
  {
    title: "新增回收率",
    width: 80,
    align: "center",
    dataIndex: "new_huishoulv",
    key: "new_huishoulv",
    sorter: {},
    sortDirections: ["descend"],
    render: (value) => (
      value === 0 ? '0.00%' : numMulti(parseFloat(value), 100).toFixed(2) + '%'
    )
  },
  {
    title: "总回收率",
    width: 80,
    align: "center",
    dataIndex: "huishoulv",
    key: "huishoulv",
    sorter: {},
    sortDirections: ["descend"],
    render: (value) => (
      value === 0 ? '0.00%' : numMulti(parseFloat(value), 100).toFixed(2) + '%'
    )
  },
];

export default bookColumns;
